import React, { useState, useEffect } from 'react'
import { Wrapper } from './style'
import { toAbsoluteUrl } from '../../utils/assetHelper'
import { getKeyByValue, countryCode } from '../../utils/country'

const Index = ({ reference, selectedOrder, selectedItem }) => {

  return (
    <Wrapper ref={reference}>
      <div className="header">
        <img src={toAbsoluteUrl('/media/logowhite.png')} alt="" />
        <p>© Identifyn™ {new Date().getFullYear()}</p>
      </div>
      {selectedOrder && <div className="content">
        <div className="order-details">
          <h2>Invoice For Order #{selectedOrder?.display_id}</h2>
          <div className="address-container">
            <div className="left-container">
              <h4>Shipping Address</h4>
              <div className="address">
                <div className="left-container">
                  <h4>{selectedOrder?.shipping_address?.company}</h4>
                  <h4 style={{ color: '#6c6a73', fontWeight: 400 }}>{selectedOrder?.shipping_address?.address_1}, {selectedOrder?.shipping_address?.city}, {selectedOrder?.shipping_address?.province} {selectedOrder?.billing_address?.postal_code}, {getKeyByValue(countryCode, selectedOrder?.billing_address?.country_code?.toUpperCase())}</h4>
                </div>
              </div>
            </div>
            <div className="right-container">
              <h4>Billing Address</h4>
              <div className="address">
                <div className="left-container">
                  <h4>{selectedOrder?.billing_address?.company}</h4>
                  <h4 style={{ color: '#6c6a73', fontWeight: 400 }}>{selectedOrder?.billing_address?.address_1}, {selectedOrder?.billing_address?.city}, {selectedOrder?.billing_address?.province} {selectedOrder?.billing_address?.postal_code}, {getKeyByValue(countryCode, selectedOrder?.billing_address?.country_code?.toUpperCase())}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="items-container">
            <div className="item-header">
              <h4>Items</h4>
            </div>
            <div className="items-wrapper">
              {selectedItem?.items?.map((item, index) => {
                return <div className="item" key={item.id}>
                  <h4>{item.variant?.title}</h4>
                  <div className="item-details">
                    <div className="left-container">
                      <div className="details">
                        <h4 style={{ color: '#9a9a9a' }}>Cat ID#</h4>
                        <h4>{item.variant?.product?.mid_code} {item.variant?.product?.material}</h4>
                      </div>
                      <div className="details">
                        <h4 style={{ color: '#9a9a9a' }}>Concentration</h4>
                        <h4>{item.variant?.product?.concentration} mg/ml</h4>
                      </div>
                    </div>
                    <div className="middle-container">
                      <h4 style={{ color: '#9a9a9a' }}>Q-ty:</h4>
                      <h4>{item.quantity}</h4>
                    </div>
                    <div className="right-container">
                      <h4>${((item.unit_price * item.quantity) / 100).toFixed(2)}</h4>
                      {item.quantity > 1 && <h6>{(item.unit_price / 100).toFixed(2)} x {item.quantity}</h6>}
                    </div>
                  </div>
                </div>
              })}
            </div>
          </div>
          <div className="invoice-container">
            <h4>Summary</h4>
            <div className="summary">
              <div className="summary-header">
                <h5>Payment Method</h5>
                <h5>{selectedOrder?.payments[0]?.provider_id === 'stripe' ? 'Credit Card' : 'Purchase Order'} {selectedOrder?.payments[0]?.provider_id === 'manual' && `(#${selectedItem?.context?.purchaseOrderNumber || '-'})`}</h5>
              </div>
              <span className="horizontal-line"></span>
              <div className="summary-details">
                <div className="left-container">
                  <h5>Total Items</h5>
                  <h5>Subtotal</h5>
                  <h5>Tax</h5>
                  <h5>Shipping & Handling</h5>
                  <h5 style={{ color: '#000' }}>Grand Total</h5>
                </div>
                <div className="right-container" style={{ textAlign: 'right', alignItems: 'flex-end' }}>
                  <h5>{selectedOrder?.items?.reduce((acc, item) => acc + item.quantity, 0)}</h5>
                  <h5>${selectedOrder?.items?.reduce((acc, item) => acc + item.refundable / 100, 0).toFixed(2)}</h5>
                  <h5>${selectedOrder?.tax_rate ? (selectedOrder?.tax_rate / 100)?.toFixed(2) : '0.00'}</h5>
                  <h5>${selectedOrder?.shipping_methods?.reduce((acc, item) => acc + item.price / 100, 0).toFixed(2)}</h5>
                  <h5 style={{ color: '#000', fontWeight: 700 }}>${(selectedOrder?.payments?.reduce((acc, item) => acc + item.amount / 100, 0)).toFixed(2)}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </Wrapper>
  )
}

export default Index