import { Outlet, useNavigate } from 'react-router-dom'
import { useEffect, useContext } from 'react'
import { AuthContext } from '../context/auth'
import { getLocalStorage } from '../helpers/localStorage'

export const ProtectedRoute = ({ children }) => {
const {isLoggedIn, user} = useContext(AuthContext)
    const navigate = useNavigate()
    const token = getLocalStorage('identifyntoken')

    useEffect(() => {
        if (!token) {
            return navigate('/')
        }
    }, [user])

    return children ? children : <Outlet />
}
