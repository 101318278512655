import React, { useState, useContext, useEffect } from 'react'
import Layout from "../layout"
import { Wrapper } from './style'
import { Checkbox } from '../../../components/Checkbox'
import { AuthContext } from '../../../context/auth'
import { updateCustomerAddressesApi, currentUserApi } from '../../../api'
import {countryCode, getKeyByValue} from '../../../utils/country'

const Index = () => {
    const { setNotification, shippingAddressModal, setShippingAddressModal, setSelectedAddress, user, setUser, setIsLoading } = useContext(AuthContext)
    const [address, setAddress] = useState(
        //         [
        //     {
        //         id:1,
        //         name:'Stanford University School Medicine',
        //         is_primary:false,
        //         address:'291 Campus Drive, Stanford, CA 94305, United States',
        //         department: 'Department of Biology',
        //         city: 'Stanford',
        //         state: 'California',
        //         street_address:'291 Campus Drive',
        //         country: 'United States',
        //         postal_code: '94305',
        //         Building:291,
        //         Lab:123,
        //         attention_to:'Dr Alex',
        //         company:'University of California, San Francisco',
        //     },
        //     {
        //         id:2,
        //         name:'University of California, San Francisco',
        //         is_primary:true,
        //         address:'505 Parnassus Ave, San Francisco, CA 94143, United States',
        //         department: 'Department of Biology',
        //         city: 'San Francisco',
        //         state: 'California',
        //         street_address:'505 Parnassus Ave',
        //         country: 'United States',
        //         postal_code: '94143',
        //         Building:291,
        //         Lab:123,
        //         attention_to:'Dr Alex',
        //         company:'University of California, San Francisco',
        //     }
        // ]
    )

    useEffect(() => {
        if (!shippingAddressModal) {
            setSelectedAddress(null)
        }
    }, [shippingAddressModal])


    const fetchCurrentUser = () => {
        setIsLoading(true)
        currentUserApi()
            .then(res => {
                if (res.data) {
                    setUser(res.data)
                    setIsLoading(false)
                }
            })
            .catch(() => {
                setIsLoading(false)
            })
    }



    useEffect(() => {
        setAddress(user?.customer?.shipping_addresses?.filter((item) => item.metadata?.type === 'shipping'))
    }, [user])



    // const updateAddressPrimary = (id) => {
    //     updateCustomerAddressesApi(id, { metadata:{
    //         is_primary:true
    //     } })
    // }

    const handlePrimary = (id) => {
        setIsLoading(true)
        if (address?.length > 1) {
            address?.map((item) => {
                Promise.all([updateCustomerAddressesApi(item.id, {
                    metadata: {
                        ...item.metadata,
                        is_primary: item.id == id ? true : false
                    }
                }
                )]).then(() => {
                    setNotification({
                        type: 'success',
                        message: 'Primary Shipping Address Updated!'
                    })
                    fetchCurrentUser()
                    setIsLoading(false)
                }).catch(() => {
                    setIsLoading(false)
                    setNotification({
                        type: 'red',
                        message: 'Something went wrong, please try again!'
                    })
                })
            })
        } else {
            setNotification({
                type: 'red',
                message: 'One Primary Shipping Address is required!'
            })
            setIsLoading(false)
        }

        // setAddress(address?.map((item) => {
        //     if (item.id == id) {
        //         item.is_primary = true
        //     } else {
        //         item.is_primary = false
        //     }
        //     return item
        // }))

        // setNotification({
        //     type: 'success',
        //     message: 'Primary Shipping Address Updated!'
        // })


    }


    return (
        <Layout>
            <Wrapper>
                <div className="header">
                    <h4>Shipping Address</h4>
                    <div className="header-btn-container">
                        <button className='btn transparent' style={{ fontSize: '16px' }} onClick={() => {
                            setShippingAddressModal(true)
                            setSelectedAddress(null)
                        }}><i className='far fa-plus'></i> Add New</button>
                    </div>
                </div>
                <div className="address-container">
                    {address && address.length > 0 ?
                        address?.map((item) => {
                            return (
                                <div className="address-card" key={item.id}>
                                    <div className="left-container">
                                        <h5>{item.company} {item.metadata?.is_primary && <span>Primary</span>}</h5>
                                        <p>{item.address_1}, {item.city}, {item.province} {item.postal_code}, {getKeyByValue(countryCode, item?.country_code?.toUpperCase())}</p>
                                        <Checkbox label={'Make Primary'} checked={item?.metadata?.is_primary} onChange={() => handlePrimary(item.id)} />
                                    </div>
                                    <div className="right-container">
                                        <button className='btn transparent' style={{ fontSize: '16px' }} onClick={() => {
                                            setShippingAddressModal(true)
                                            setSelectedAddress(item)
                                        }} >Edit</button>
                                    </div>
                                </div>
                            )
                        })
                        /* <div className="address-card">
                          <div className="left-container">
                              <h5>Stanford University School Medicine <span>Primary</span></h5>
                              <p>291 Campus Drive, Stanford, CA 94305, United States</p>
                              <Checkbox label={'Make Primary'}  />
                          </div>
                          <div className="right-container">
                              <button className='btn transparent' style={{ fontSize: '16px' }} >Edit</button>
                          </div>
                          </div>
                          <div className="address-card">
                          <div className="left-container">
                              <h5>University of California, San Francisco</h5>
                              <p>505 Parnassus Ave, San Francisco, CA 94143, United States</p>
                              <Checkbox label={'Make Primary'} checked={true} />
                          </div>
                          <div className="right-container">
                              <button className='btn transparent' style={{ fontSize: '16px' }} >Edit</button>
                          </div>
                          </div> */
                        : <div className='no-orders'>
                            <span className='icon'>
                                <i class="fal fa-boxes"></i>
                            </span>
                            <p>No Shipping Address Yet.</p>
                        </div>
                    }
                </div>
            </Wrapper>
        </Layout>
    )
}

export default Index