import React, { useState, useEffect, useContext } from 'react'
import Layout from "../../components/layout"
import { Wrapper } from './style'
import { Checkbox } from '../../components/Checkbox'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/auth'
import Input from '../../components/Input'
import { updateCartApi, getShippingOptionsApi, setShippingMethodsApi, createPaymentSessionsApi, updatePaymentSessionsApi, selectPaymentSessionApi, completeCartApi } from '../../api'

import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
    CardElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import { countryCode } from '../../utils/country'
import { removeLocalStorage } from '../../helpers/localStorage';

const Index = () => {
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { setBillingAddressModal, setShippingAddressModal, setSelectedAddress, setCompleteOrderModal, user, setIsLoading, cartData, setCartData, setNotification, setCompletedOrderData } = useContext(AuthContext)
    const [step, setStep] = useState(1)
    const [shippingAddress, setShippingAddress] = useState([]
        // [
        //     {
        //         id: 1,
        //         name: 'Stanford University School Medicine',
        //         is_primary: false,
        //         address: '291 Campus Drive, Stanford, CA 94305, United States',
        //         department: 'Department of Biology',
        //         city: 'Stanford',
        //         state: 'California',
        //         street_address: '291 Campus Drive',
        //         country: 'United States',
        //         postal_code: '94305',
        //         Building: 291,
        //         Lab: 123,
        //         attention_to: 'Dr Alex',
        //         company: 'University of California, San Francisco',
        //     },
        //     {
        //         id: 2,
        //         name: 'University of California, San Francisco',
        //         is_primary: true,
        //         address: '505 Parnassus Ave, San Francisco, CA 94143, United States',
        //         department: 'Department of Biology',
        //         city: 'San Francisco',
        //         state: 'California',
        //         street_address: '505 Parnassus Ave',
        //         country: 'United States',
        //         postal_code: '94143',
        //         Building: 391,
        //         Lab: 321,
        //         attention_to: 'Dr Alex',
        //         company: 'University of California, San Francisco',
        //     }
        // ]
    )
    const [billingAddress, setBillingAddress] = useState([])
    const [selectShippingAddress, setSelectShippingAddress] = useState(null)
    const [selectBillingAddress, setSelectBillingAddress] = useState(null)
    const [isAddressSame, setIsAddressSame] = useState(false)
    const [paymentType, setPaymentType] = useState('')
    const [creditCardHolderName, setCreditCardHolderName] = useState('')
    const [isAgree, setIsAgree] = useState(false)
    const [quantity, setQuantity] = useState(1)
    const [shippingOptions, setShippingOptions] = useState([])
    const [purchaseOrderNumber, setPurchaseOrderNumber] = useState('')
    const [purchaseOrderReference, setPurchaseOrderReference] = useState('')
    const [contract, setContract] = useState('')
    const [cardDetails, setCardDetails] = useState({
        cardNumber: false,
        cardCvc: false,
        cardExpiry: false,
    })
    const [paymentSession, setPaymentSession] = useState(null)
    const [cardNumberElement, setCardNumberElement] = useState(null)
    const [cardCvcElement, setCardCvcElement] = useState(null)
    const [cardExpiryElement, setCardExpiryElement] = useState(null)


    useEffect(() => {
        if (cartData && cartData?.items?.length > 0) {
            setQuantity(cartData?.items?.reduce((acc, curr) => acc + curr?.quantity, 0))
        } else if (cartData && !cartData?.items?.length) {
            navigate('/cart')
        }
    }, [cartData])

    useEffect(() => {
        if (cartData && cartData?.id) {
            setIsLoading(true)
            getShippingOptionsApi(cartData.id).then((res) => {
                if (res?.data?.shipping_options) {
                    setShippingOptions(res?.data?.shipping_options)
                    setIsLoading(false)
                }
            })
        }
    }, [])

    useEffect(() => {
        if (shippingOptions && shippingOptions?.length > 0) {
            setIsLoading(true)
            setShippingMethodsApi(cartData.id, {
                option_id: shippingOptions[0]?.id
            }).then((res) => {
                if (res?.data?.cart) {
                    createPaymentSessionsApi(cartData.id).then((cart) => {
                        if (cart?.data?.cart) {
                            setCartData(cart?.data?.cart)
                            setIsLoading(false)
                        }
                    })
                }
            })
        }
    }, [shippingOptions])


    function getKeyByValue(object, value) {
        for (const [key, val] of Object.entries(object)) {
            if (val === value) {
                return key;
            }
        }
        return null;
    }

    useEffect(() => {
        if (user) {
            setShippingAddress(user?.customer?.shipping_addresses?.filter((item) => item.metadata.type === 'shipping'))
            setBillingAddress(user?.customer?.shipping_addresses?.filter((item) => item.metadata.type === 'billing'))
        }
    }, [user])


    useEffect(() => {
        if (shippingAddress.length > 0) {
            let primary = shippingAddress?.find(item => item?.metadata?.is_primary)
            if (primary) {
                setSelectShippingAddress(primary)
            }
        }
        if (billingAddress.length > 0) {
            let primary = billingAddress?.find(item => item?.metadata?.is_primary)
            if (primary) {
                setSelectBillingAddress(primary)
            }
        }
    }, [shippingAddress, billingAddress])


    useEffect(() => {
        if (isAddressSame) {
            setSelectBillingAddress(selectShippingAddress)
        }
    }, [isAddressSame, selectShippingAddress])


    const selectPaymentSession = (e, providerId) => {
        setIsLoading(true)
        selectPaymentSessionApi(cartData.id, {
            provider_id: providerId
        }).then((cart) => {
            if (cart?.data?.cart) {
                setPaymentType(e.target.value)
                setPaymentSession(cart.data?.cart)
                setIsLoading(false)
            } else {
                setIsLoading(false)
                setNotification({
                    type: 'error',
                    message: 'Something went wrong, please try again!'
                })
            }
        })
    }



    const onContinue = () => {
        setCardNumberElement(elements.getElement(CardNumberElement));
        const purchaseData = {
            purchaseOrderNumber: purchaseOrderNumber,
            purchaseOrderReference: purchaseOrderReference ?? undefined,
            contract: contract ?? undefined,
        }
        const cardData = {
            cardHolderName: creditCardHolderName,
            purchaseOrderReference: purchaseOrderReference ?? undefined,
            contract: contract ?? undefined
        }
        setIsSubmitting(true)
        updateCartApi(cartData.id, {
            shipping_address: {
                first_name: selectShippingAddress?.first_name,
                last_name: selectShippingAddress?.last_name,
                company: selectShippingAddress?.company,
                address_1: selectShippingAddress?.address_1,
                city: selectShippingAddress?.city,
                province: selectShippingAddress?.province,
                country_code: selectShippingAddress?.country_code,
                postal_code: selectShippingAddress?.postal_code,
                metadata: {
                    department: selectShippingAddress?.metadata?.department,
                    building: selectShippingAddress?.metadata?.building,
                    lab: selectShippingAddress?.metadata?.lab,
                    attention_to: selectShippingAddress?.metadata?.attention_to
                }
            },
            billing_address: {
                first_name: selectBillingAddress?.first_name,
                last_name: selectBillingAddress?.last_name,
                company: selectBillingAddress?.company,
                address_1: selectBillingAddress?.address_1,
                city: selectBillingAddress?.city,
                province: selectBillingAddress?.province,
                country_code: selectBillingAddress?.country_code,
                postal_code: selectBillingAddress?.postal_code,
                metadata: {
                    department: selectBillingAddress?.metadata?.department,
                    building: selectBillingAddress?.metadata?.building,
                    lab: selectBillingAddress?.metadata?.lab,
                    attention_to: selectBillingAddress?.metadata?.attention_to,
                }
            },
            context: paymentType === 'purchase' ? purchaseData : cardData
        }).then((res) => {
            if (res && res.data && res.data?.cart) {
                setStep(2)
                setIsSubmitting(false)
                // if (paymentType === 'purchase') {
                //     updatePaymentSessionsApi(cartData.id, 'manual',
                //         { data: purchaseData })
                // }
                // setCartData(res?.data?.cart)
                // selectPaymentSessionApi(cartData.id, {
                //     provider_id: paymentType === 'purchase' ? "manual" : 'stripe',
                // }).then((cart) => {
                //     setPaymentSession(cart.data?.cart)
                //     setIsLoading(false)
                //     setStep(2)
                //     // updatePaymentSessionsApi(cartData.id, paymentType === 'purchase' ? 'manual' : 'stripe',
                //     //  {data: paymentType === 'purchase' ? purchaseData : cardData}).then((data) => {
                //     //     if(data?.data?.cart){
                //     //         setCartData(data.data?.cart)
                //     //         setIsLoading(false)
                //     //         setStep(2)
                //     //     }else{
                //     //         setIsLoading(false)
                //     //         setNotification({
                //     //             type: 'red',
                //     //             message: 'Something went wrong, please try again!'
                //     //         })
                //     //     }
                //     // })
                // })
            } else {
                setIsSubmitting(false)
                setNotification({
                    type: 'red',
                    message: 'Something went wrong, please try again!'
                })
            }
        })

    }

    const completePayment = async () => {
        setIsSubmitting(true)
        if (!stripe || !elements || !cardNumberElement || !cartData?.id) {
            setIsSubmitting(false)
            setNotification({
                type: 'red',
                message: 'Something went wrong, please try again!',
            })
        }

        await stripe
            .confirmCardPayment(
                paymentSession?.payment_session?.data?.client_secret, {
                payment_method: {
                    card: elements.getElement(CardNumberElement) || cardNumberElement,
                    billing_details: {
                        name:
                            creditCardHolderName,
                        address: {
                            city: selectBillingAddress?.city ?? undefined,
                            country: selectBillingAddress?.country_code ?? undefined,
                            line1: selectBillingAddress?.address_1 ?? undefined,
                            line2: selectBillingAddress?.address_2 ?? undefined,
                            postal_code: selectBillingAddress?.postal_code ?? undefined,
                            state: selectBillingAddress?.province ?? undefined,
                        },
                        email: user?.customer.email,
                        phone: user?.customer?.phone ?? undefined,
                    },
                },
            })
            .then(({ error, paymentIntent }) => {
                if (error) {
                    const pi = error.payment_intent

                    if (
                        (pi && pi.status === "requires_capture") ||
                        (pi && pi.status === "succeeded")
                    ) {
                        completeCartApi(cartData.id).then((res) => {
                            if (res.data) {
                                setCompletedOrderData(res.data.data?.display_id)
                                setIsSubmitting(false)
                                setCompleteOrderModal(true)
                                setCartData(null)
                                removeLocalStorage('cartId')
                            } else {
                                setIsSubmitting(false)
                                setNotification({
                                    type: 'red',
                                    message: 'Something went wrong, please try again!'
                                })
                            }
                        })
                    }
                    setIsSubmitting(false)
                    setNotification({
                        type: 'red',
                        message: error.message,
                    })
                    return
                }

                if (
                    (paymentIntent && paymentIntent.status === "requires_capture") ||
                    paymentIntent.status === "succeeded"
                ) {
                    return completeCartApi(cartData.id).then((res) => {
                        if (res.data) {
                            setCompletedOrderData(res?.data?.data?.display_id)
                            setIsSubmitting(false)
                            setCompleteOrderModal(true)
                            setCartData(null)
                            removeLocalStorage('cartId')
                        } else {
                            setIsSubmitting(false)
                            setNotification({
                                type: 'red',
                                message: 'Something went wrong, please try again!'
                            })
                        }
                    })
                }

                return
            })
    }


    const completeManualPayment = () => {
        completeCartApi(cartData.id).then((res) => {
            if (res.data?.data?.display_id) {
                setCompletedOrderData(res.data.data?.display_id)
                setIsLoading(false)
                setCompleteOrderModal(true)
                setCartData(null)
                removeLocalStorage('cartId')
            } else {
                setIsLoading(false)
                setNotification({
                    type: 'red',
                    message: 'Something went wrong, please try again!'
                })
            }
        })
    }



    return (
        <Layout>
            <Wrapper>
                <h3>Checkout</h3>
                <div className="content">
                    <div className="left-container">
                        <div className="checkout-container">
                            <div className="checkout-header">
                                <span style={{ width: '32px', height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: step === 1 ? '#FFF2E5' : 'linear-gradient(0deg, rgba(51, 194, 94, 0.12) 0%, rgba(51, 194, 94, 0.12) 100%), #FFF', color: step === 1 ? '#DC1E00' : '#33C25E', cursor: 'pointer' }}>
                                    {step === 1 ? 1 : <i className='far fa-check'></i>}
                                </span>
                                <h4>Shipping, Billing and Payment</h4>
                            </div>
                            {step === 2 && <div className="checkout-header">
                                <span style={{ width: '32px', height: '32px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#FFF2E5', color: '#DC1E00', cursor: 'pointer' }}>
                                    2
                                </span>
                                <h4>Review & Place Order</h4>
                            </div>
                            }
                            {/* {step === 1 ?  */}
                            <div className="cards-container">
                                {step === 1 ? <div className="card">
                                    <div className="header">
                                        <h4>Select your <span style={{ fontWeight: 700 }}>Shipping Address</span></h4>
                                        <div className="header-btn-container">
                                            <button className='btn transparent' style={{ fontSize: '16px' }} onClick={() => {
                                                setShippingAddressModal(true)
                                                setSelectedAddress(null)
                                            }}><i className='far fa-plus'></i> Add New</button>
                                        </div>
                                    </div>
                                    <div className="address-container">
                                        {shippingAddress.map((item, index) => {
                                            return (
                                                <div className={`address-card ${selectShippingAddress?.id === item.id ? 'active-card' : ''}`} key={item.id}>
                                                    <div className="card-left-container" onClick={() => setSelectShippingAddress(item)}>
                                                        <div className="first-container">
                                                            <span className={`selected-check ${selectShippingAddress?.id === item.id ? 'selected-check-active' : ''}`}></span>
                                                        </div>
                                                        <div className="second-container">
                                                            <h5> {item.company} {item.metadata?.is_primary ? <span>Primary</span> : ''}</h5>
                                                            <p>{item.address_1}, {item.city}, {item.province} {item.postal_code}, {getKeyByValue(countryCode, item?.country_code?.toUpperCase())}</p>
                                                        </div>
                                                    </div>
                                                    <div className="card-right-container">
                                                        <button className='btn transparent' style={{ fontSize: '16px' }}
                                                            onClick={() => {
                                                                setShippingAddressModal(true)
                                                                setSelectedAddress(item)
                                                            }}
                                                        >Edit</button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        {/* <div className="address-card">
                                        <div className="card-left-container" onClick={() => setSelectShippingAddress(1)}>
                                                <div className="first-container">
                                                <span className="selected-check selected-check-active"></span>
                                                </div>
                                                <div className="second-container">
                                              <h5> Stanford University School Medicine <span>Primary</span></h5>
                                                <p>291 Campus Drive, Stanford, CA 94305, United States</p>
                                                </div>
                                            </div>
                                            <div className="card-right-container">
                                                <button className='btn transparent' style={{ fontSize: '16px' }} >Edit</button>
                                            </div>
                                        </div>
                                        <div className="address-card">
                                        <div className="card-left-container">
                                                <div className="first-container">
                                                <span className="selected-check"></span>
                                                </div>
                                                <div className="second-container">
                                              <h5> Stanford University School Medicine <span>Primary</span></h5>
                                                <p>291 Campus Drive, Stanford, CA 94305, United States</p>
                                                </div>
                                            </div>
                                            <div className="card-right-container">
                                                <button className='btn transparent' style={{ fontSize: '16px' }} >Edit</button>
                                            </div>
                                        </div> */}
                                    </div>
                                    <hr />
                                    <div className="three-column">
                                        <div className="more-detail-card">
                                            <h5>Building</h5>
                                            <h4>{selectShippingAddress?.metadata?.building}</h4>
                                        </div>
                                        <div className="more-detail-card">
                                            <h5>Lab or Room Number</h5>
                                            <h4>{selectShippingAddress?.metadata?.lab}</h4>
                                        </div>
                                        <div className="more-detail-card">
                                            <h5>Attention To</h5>
                                            <h4>{selectShippingAddress?.metadata?.attention_to}</h4>
                                        </div>
                                    </div>
                                </div>
                                    :
                                    <div className="card">
                                        <div className="header">
                                            <h4><span style={{ fontWeight: 700 }}>Shipping Address</span></h4>
                                            <div className="header-btn-container">
                                                <button className='btn transparent' style={{ fontSize: '16px' }} onClick={() => {
                                                    setStep(1)
                                                }}>Change</button>
                                            </div>
                                        </div>
                                        <div className="address-container">
                                            <div className="address-card">
                                                <div className="card-left-container" style={{ cursor: 'default' }}>
                                                    <div className="second-container">
                                                        <h5> {selectShippingAddress.company}</h5>
                                                        <p>{selectShippingAddress.address_1}, {selectShippingAddress.city}, {selectShippingAddress.province} {selectShippingAddress.postal_code}, {getKeyByValue(countryCode, selectShippingAddress?.country_code?.toUpperCase())}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="address-card three-column">
                                                <div className='more-detail'>
                                                    <h5>Building</h5>
                                                    <h4>{selectShippingAddress.metadata?.building}</h4>
                                                </div>
                                                <div className='more-detail'>
                                                    <h5>Lab or Room Number</h5>
                                                    <h4>{selectShippingAddress.metadata?.lab}</h4>
                                                </div>
                                                <div className='more-detail'>
                                                    <h5>Attention to</h5>
                                                    <h4>{selectShippingAddress?.metadata?.attention_to}</h4>
                                                </div>
                                                <div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {step === 1 ? <div className="card">
                                    <div className="header">
                                        <h4>Select your <span style={{ fontWeight: 700 }}>Billing Address</span></h4>
                                        <div className="header-btn-container">
                                            <button className='btn transparent' style={{ fontSize: '16px' }} onClick={() => {
                                                setBillingAddressModal(true)
                                                setSelectedAddress(null)
                                            }}><i className='far fa-plus'></i> Add New</button>
                                        </div>
                                    </div>
                                    <div className="address-container">
                                        <div className={`address-card ${isAddressSame ? 'active-card' : ''}`}>
                                            <div className="card-left-container" onClick={() => {
                                                setIsAddressSame(true)
                                                setSelectBillingAddress(selectShippingAddress)
                                            }} style={{ justifyContent: 'center' }}>
                                                <div className="first-container">
                                                    <span className={`selected-check ${isAddressSame ? 'selected-check-active' : ''}`}></span>
                                                </div>
                                                <div className="second-container" style={{ justifyContent: 'center' }}>
                                                    <h5>Same as Shipping</h5>
                                                </div>
                                            </div>
                                        </div>
                                        {billingAddress.map((item, index) => {
                                            return (
                                                <div className={`address-card ${!isAddressSame && selectBillingAddress?.id === item.id ? 'active-card' : ''}`} key={item.id}>
                                                    <div className="card-left-container" onClick={() => {
                                                        setSelectBillingAddress(item)
                                                        setIsAddressSame(false)
                                                    }}>
                                                        <div className="first-container">
                                                            <span className={`selected-check ${!isAddressSame && selectBillingAddress?.id === item.id ? 'selected-check-active' : ''}`}></span>
                                                        </div>
                                                        <div className="second-container">
                                                            <h5> {item.company} {item.metadata?.is_primary ? <span>Primary</span> : ''}</h5>
                                                            <p>{item.address_1}, {item.city}, {item.province} {item.postal_code}, {getKeyByValue(countryCode, item?.country_code?.toUpperCase())}</p>
                                                        </div>
                                                    </div>
                                                    <div className="card-right-container">
                                                        <button className='btn transparent' style={{ fontSize: '16px' }}
                                                            onClick={() => {
                                                                setBillingAddressModal(true)
                                                                setSelectedAddress(item)
                                                            }}
                                                        >Edit</button>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <hr />
                                    <div className="three-column">
                                        <div className="more-detail-card">
                                            <h5>Building</h5>
                                            <h4>{selectBillingAddress?.metadata?.building}</h4>
                                        </div>
                                        <div className="more-detail-card">
                                            <h5>Lab or Room Number</h5>
                                            <h4>{selectBillingAddress?.metadata?.lab}</h4>
                                        </div>
                                        <div className="more-detail-card">
                                            <h5>Attention To</h5>
                                            <h4>{selectBillingAddress?.metadata?.attention_to}</h4>
                                        </div>
                                    </div>
                                </div>
                                    :
                                    <div className="card">
                                        <div className="header">
                                            <h5><span style={{ fontWeight: 700 }}>Billing Address</span></h5>
                                            <div className="header-btn-container">
                                                <button className='btn transparent' style={{ fontSize: '16px' }} onClick={() => {
                                                    setStep(1)
                                                }}>Change</button>
                                            </div>
                                        </div>
                                        <div className="address-container">
                                            <div className="address-card">
                                                <div className="card-left-container" style={{ cursor: 'default' }}>
                                                    <div className="second-container">
                                                        {isAddressSame ? <h5>Same as Shipping</h5>
                                                            :
                                                            <>
                                                                <h5> {selectBillingAddress.company}</h5>
                                                                <p>{selectBillingAddress.address_1}, {selectBillingAddress.city}, {selectBillingAddress.province} {selectBillingAddress.postal_code}, {getKeyByValue(countryCode, selectBillingAddress?.country_code?.toUpperCase())}</p>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {!isAddressSame && <div className="address-card three-column">
                                                <div className='more-detail'>
                                                    <h5>Building</h5>
                                                    <h4>{selectBillingAddress.metadata?.building}</h4>
                                                </div>
                                                <div className='more-detail'>
                                                    <h5>Lab or Room Number</h5>
                                                    <h4>{selectBillingAddress.metadata?.lab}</h4>
                                                </div>
                                                <div className='more-detail'>
                                                    <h5>Attention to</h5>
                                                    <h4>{selectBillingAddress?.metadata?.attention_to}</h4>
                                                </div>
                                                <div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                }
                                <div className="card" style={{ display: step === 1 ? 'block' : 'none', marginBottom: '20px' }}>
                                    <div className="header" style={{ marginBottom: '10px' }}>
                                        <h4>Select <span style={{ fontWeight: 700 }}>Payment</span></h4>
                                    </div>
                                    <div className="address-container">
                                        <div className="input-container">
                                            <div className="select-option" style={{ border: paymentType === 'purchase' ? '2px solid var(--primary-color)' : '1px solid #F1EEFC' }}>
                                                <label class="containers" >
                                                    <input type="radio" checked={paymentType === 'purchase'} name="payment" value="purchase" onChange={(e) => {
                                                        // setPaymentType(e.target.value)
                                                        selectPaymentSession(e, 'manual')
                                                    }} />
                                                    <span class="checkmark"></span>
                                                    <span>Purchase Order</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="input-container">
                                            <div className="select-option" style={{ border: paymentType === 'card' ? '2px solid var(--primary-color)' : '1px solid #F1EEFC' }}>
                                                <label class="containers" >
                                                    <input type="radio" checked={paymentType === 'card'} name="payment" value="card" onChange={(e) => {
                                                        // setPaymentType(e.target.value)
                                                        selectPaymentSession(e, 'stripe')
                                                    }} />
                                                    <span class="checkmark"></span>
                                                    <span>Credit Card</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="payment-container" style={{ marginTop: '10px' }}>
                                        {paymentType === 'purchase' ? <>
                                            <Input type='text' className={"purchase"} required label='Enter Purchase Order Number' value={purchaseOrderNumber} onChange={(e) => setPurchaseOrderNumber(e.target.value)} style={{height:'74px'}}/>
                                            <div className="two-column">
                                                <Input type='text' placeholder={'Purchase Order/Credit Card reference'} value={purchaseOrderReference} onChange={(e) => setPurchaseOrderReference(e.target.value)} style={{height:'74px'}} />
                                                <Input type='text' placeholder={'Contract'} value={contract} onChange={(e) => setContract(e.target.value)} style={{height:'74px'}} />
                                            </div>
                                        </>
                                            :
                                            paymentType === 'card' && <>
                                                <Input type='text' style={{ marginTop: '10px', height:'74px' }} required className={"credit-card"} label='Name on Credit Card' value={creditCardHolderName} onChange={(e) => setCreditCardHolderName(e.target.value)}/>
                                                <CardNumberElement
                                                    className='card-number-element'
                                                    onChange={(e) => {
                                                        if (e.complete) {
                                                            setCardDetails({
                                                                ...cardDetails,
                                                                cardNumber: true
                                                            })
                                                        }
                                                    }}
                                                    options={{
                                                        required: true,
                                                        showIcon: true,
                                                        iconStyle: "solid",
                                                        placeholder: "4242 4242 4242 4242",
                                                        style: {
                                                            base: {
                                                                fontSize: "16px",
                                                                opacity: 0.5,
                                                                lineHeight: "20px",
                                                                fontWeight: "400",
                                                                color: "#1e1e1e",
                                                                "::placeholder": { color: "#6c6a73" },
                                                                "::focus": {border:'1px solid #DC4405'}
                                                            },
                                                        }
                                                    }}
                                                />
                                                <div className="two-column">
                                                    <CardExpiryElement
                                                        className='card-number-element'
                                                        onChange={(e) => {
                                                            if (e.complete) {
                                                                setCardDetails({
                                                                    ...cardDetails,
                                                                    cardExpiry: true
                                                                })
                                                            }
                                                        }}
                                                        options={{
                                                            placeholder: "Expiration Date (MM/YY)*",
                                                            style: {
                                                                base: {
                                                                    fontSize: "16px",
                                                                    opacity: 0.5,
                                                                    lineHeight: "20px",
                                                                    fontWeight: "400",
                                                                    color: "#1e1e1e",
                                                                    "::placeholder": { color: "#6c6a73" },

                                                                }
                                                            }
                                                        }}
                                                    />
                                                    <CardCvcElement
                                                        className='card-number-element'
                                                        onChange={(e) => {
                                                            if (e.complete) {
                                                                setCardDetails({
                                                                    ...cardDetails,
                                                                    cardCvc: true
                                                                })
                                                            }
                                                        }}
                                                        options={{
                                                            placeholder: "CVV*",
                                                            style: {
                                                                base: {
                                                                    fontSize: "16px",
                                                                    opacity: 0.5,
                                                                    lineHeight: "20px",
                                                                    fontWeight: "400",
                                                                    color: "#1e1e1e",
                                                                    "::placeholder": { color: "#6c6a73" },
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="two-column">
                                                    <Input type='text' placeholder={'Purchase Order/Credit Card reference'} value={purchaseOrderReference} onChange={(e) => setPurchaseOrderReference(e.target.value)} style={{height:'74px'}} />
                                                    <Input type='text' placeholder={'Contract'} value={contract} onChange={(e) => setContract(e.target.value)} style={{height:'74px'}} />
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                                {step === 2 && <div className="card">
                                    <div className="header">
                                        <h5><span style={{ fontWeight: 700 }}>Payment</span></h5>
                                        <div className="header-btn-container">
                                            <button className='btn transparent' style={{ fontSize: '16px' }} onClick={() => {
                                                setStep(1)
                                            }}>Change</button>
                                        </div>
                                    </div>
                                    <div className="address-container">
                                        <div className="address-card">
                                            <div className="card-left-container" style={{ cursor: 'default' }}>
                                                <div className="second-container">
                                                    <h5>{paymentType === 'purchase' ? 'Purchase Order' : 'Credit Card'}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        {paymentType === 'purchase' && <div className="address-card">
                                            <div className="card-left-container" style={{ cursor: 'default' }}>
                                                <div className="second-container">
                                                    <h6 style={{ fontSize: '14px' }}>Purchase Order #</h6>
                                                    <h5>{purchaseOrderNumber}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                                }
                                {step === 2 &&
                                    <div className="card">
                                        <div className="header">
                                            <h5><span style={{ fontWeight: 700 }}>Items</span></h5>
                                            <div className="header-btn-container">
                                                <button className='btn transparent' style={{ fontSize: '16px' }} onClick={() => {
                                                    navigate('/cart')
                                                }}>Edit</button>
                                            </div>
                                        </div>
                                        <div className="address-container">
                                            {cartData?.items.map((item) => {
                                                return <div className="item" key={item.id}>
                                                    <div className="item-content">
                                                        {item?.mid_code?.search('SA') < 0 ? <h4>{item.variant.product.title}, {item.variant.product.metadata.subclass}</h4>
                                                            : <h4>{item.variant.product.title}</h4>
                                                        }
                                                        <div className="item-details">
                                                            <div className="details">
                                                                <h6>Cat ID#</h6>
                                                                <h5>{item.variant.product.mid_code}{item.variant.product.material}</h5>
                                                            </div>
                                                            <div className="details">
                                                                <h6>Concentration</h6>
                                                                <h5>{item.variant.product.concentration}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="quantity-content">
                                                        <h6>Q-ty:</h6>
                                                        <h5>{item.quantity}</h5>
                                                    </div>
                                                    <div className="price-content">
                                                        <h4>${(item.subtotal / 100).toFixed(2)}</h4>
                                                       {item.quantity > 1 && <h6>${(item.unit_price / 100).toFixed(2)} x {item.quantity}</h6>}
                                                    </div>
                                                </div>
                                            })}
                                            {/* <div className="item">
                                         <div className="item-content">
                                             <h4>Mouse Anti-Human PAR, IgG1</h4>
                                             <div className="item-details">
                                                 <div className="details">
                                                     <h6>Cat ID#</h6>
                                                     <h5>PA000001</h5>
                                                 </div>
                                                 <div className="details">
                                                     <h6>Concentration</h6>
                                                     <h5>10 mg/mL</h5>
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="quantity-content">
                                             <h6>Q-ty:</h6>
                                             <h5>2</h5>
                                         </div>
                                         <div className="price-content">
                                             <h4>$198.00</h4>
                                             <h6>$99.00 x 2</h6>
                                         </div>
                                     </div> */}
                                        </div>
                                    </div>
                                }
                            </div>
                            {/* :
                                <div className="cards-container">
                                    <div className="card">
                                        <div className="header">
                                            <h5><span style={{ fontWeight: 700 }}>Shipping Address</span></h5>
                                            <div className="header-btn-container">
                                                <button className='btn transparent' style={{ fontSize: '16px' }} onClick={() => {
                                                    setStep(1)
                                                }}>Change</button>
                                            </div>
                                        </div>
                                        <div className="address-container">
                                            <div className="address-card">
                                                <div className="card-left-container" style={{ cursor: 'default' }}>
                                                    <div className="second-container">
                                                        <h5> {selectShippingAddress.company}</h5>
                                                        <p>{selectShippingAddress.address_1}, {selectShippingAddress.city}, {selectShippingAddress.province} {selectShippingAddress.postal_code}, {getKeyByValue(countryCode, selectShippingAddress?.country_code?.toUpperCase())}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="address-card three-column">
                                                <div className='more-detail'>
                                                    <h5>Building</h5>
                                                    <h4>{selectShippingAddress.metadata?.building}</h4>
                                                </div>
                                                <div className='more-detail'>
                                                    <h5>Lab or Room Number</h5>
                                                    <h4>{selectShippingAddress.metadata?.lab}</h4>
                                                </div>
                                                <div className='more-detail'>
                                                    <h5>Attention to</h5>
                                                    <h4>{selectShippingAddress?.metadata?.attention_to}</h4>
                                                </div>
                                                <div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="header">
                                            <h5><span style={{ fontWeight: 700 }}>Billing Address</span></h5>
                                            <div className="header-btn-container">
                                                <button className='btn transparent' style={{ fontSize: '16px' }} onClick={() => {
                                                    setStep(1)
                                                }}>Change</button>
                                            </div>
                                        </div>
                                        <div className="address-container">
                                            <div className="address-card">
                                                <div className="card-left-container" style={{ cursor: 'default' }}>
                                                    <div className="second-container">
                                                        {isAddressSame ? <h5>Same as Shipping</h5>
                                                            :
                                                            <>
                                                                <h5> {selectBillingAddress.company}</h5>
                                                                <p>{selectBillingAddress.address_1}, {selectBillingAddress.city}, {selectBillingAddress.province} {selectBillingAddress.postal_code}, {getKeyByValue(countryCode, selectBillingAddress?.country_code?.toUpperCase())}</p>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {!isAddressSame && <div className="address-card three-column">
                                                <div className='more-detail'>
                                                    <h5>Building</h5>
                                                    <h4>{selectBillingAddress.metadata?.building}</h4>
                                                </div>
                                                <div className='more-detail'>
                                                    <h5>Lab or Room Number</h5>
                                                    <h4>{selectBillingAddress.metadata?.lab}</h4>
                                                </div>
                                                <div className='more-detail'>
                                                    <h5>Attention to</h5>
                                                    <h4>{selectBillingAddress?.metadata?.attention_to}</h4>
                                                </div>
                                                <div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="header">
                                            <h5><span style={{ fontWeight: 700 }}>Payment</span></h5>
                                            <div className="header-btn-container">
                                                <button className='btn transparent' style={{ fontSize: '16px' }} onClick={() => {
                                                    setStep(1)
                                                }}>Change</button>
                                            </div>
                                        </div>
                                        <div className="address-container">
                                            <div className="address-card">
                                                <div className="card-left-container" style={{ cursor: 'default' }}>
                                                    <div className="second-container">
                                                        <h5>{paymentType === 'purchase' ? 'Purchase Order' : 'Credit Card'}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="address-card">
                                                <div className="card-left-container" style={{ cursor: 'default' }}>
                                                    <div className="second-container">
                                                        <h6 style={{ fontSize: '14px' }}>Purchase Order #</h6>
                                                        <h5>{purchaseOrderNumber}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="header">
                                            <h5><span style={{ fontWeight: 700 }}>Items</span></h5>
                                            <div className="header-btn-container">
                                                <button className='btn transparent' style={{ fontSize: '16px' }} onClick={() => {
                                                    navigate('/cart')
                                                }}>Edit</button>
                                            </div>
                                        </div>
                                        <div className="address-container">
                                            {cartData?.items.map((item) => {
                                                return <div className="item">
                                                    <div className="item-content">
                                                        <h4>{item.variant.product.title}, {item.variant.product.metadata.subclass}</h4>
                                                        <div className="item-details">
                                                            <div className="details">
                                                                <h6>Cat ID#</h6>
                                                                <h5>{item.variant.product.mid_code}{item.variant.product.material}</h5>
                                                            </div>
                                                            <div className="details">
                                                                <h6>Concentration</h6>
                                                                <h5>{item.variant.product.concentration}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="quantity-content">
                                                        <h6>Q-ty:</h6>
                                                        <h5>{item.quantity}</h5>
                                                    </div>
                                                    <div className="price-content">
                                                        <h4>${(item.subtotal / 100).toFixed(2)}</h4>
                                                        <h6>${(item.unit_price / 100).toFixed(2)} x {item.quantity}</h6>
                                                    </div>
                                                </div>
                                            })}                                      
                                        </div>
                                    </div>
                                </div> */}
                            {/* } */}
                        </div>
                    </div>
                    <div className="right-container">
                        <h4>Summary</h4>
                        <div className="price-summary">
                            <div className="price">
                                <p>{quantity} Items</p>
                                <p>${cartData ? (cartData?.subtotal / 100)?.toFixed(2) : 0}</p>
                            </div>
                            <div className="price">
                                <p>Tax</p>
                                <p>${cartData ? (cartData?.tax_total / 100)?.toFixed(2) : 0}</p>
                            </div>
                            <div className="price">
                                <p>Shipping & Handling</p>
                                <p>${shippingOptions?.length > 0 ? (shippingOptions[0]?.amount / 100)?.toFixed(2) : 0}</p>
                            </div>
                            <hr />
                            <div className="price">
                                <p>Grand Total</p>
                                <p style={{ fontWeight: 700 }}>${cartData ? (cartData?.total / 100)?.toFixed(2) : 0}</p>
                            </div>
                        </div>
                        <Checkbox label='I have read and agreed to the' higlighted='Terms and Conditions of Sale'
                            checked={isAgree}
                            onChange={() => setIsAgree(!isAgree)}
                            higlightedLink={() => navigate("/terms-and-conditions-of-sale")}
                        />
                        <button className='btn'
                            disabled={paymentType === 'card' ? !isAgree || !cardDetails?.cardNumber || !cardDetails?.cardExpiry || !cardDetails?.cardCvc || !creditCardHolderName || isSubmitting : !purchaseOrderNumber || !isAgree || isSubmitting}
                            onClick={() => {
                                if (step == 1) {
                                    onContinue()
                                } else {
                                    paymentType === 'purchase' ?
                                        completeManualPayment()
                                        : completePayment()
                                    // setCompleteOrderModal(true)
                                }
                            }}
                        >{step === 1 ? 'Continue & Review' : 'Submit'}</button>
                        {step === 2 && <button className='btn transparent'
                            disabled={isSubmitting}
                            onClick={() => {
                                setStep(1)
                            }}
                        >Back to Address & Payment</button>}
                    </div>
                </div>
                {/* <div className='no-orders'>
                <span className='icon'>
                  <i class="fal fa-boxes"></i>
                </span>
                <p>No items added to the cart yet.</p>
              </div> */}
            </Wrapper>
        </Layout>
    )
}

export default Index