import { useEffect, useContext, lazy, Suspense } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { AuthContext } from '../context/auth'
import TopBarProgress from 'react-topbar-progress-indicator'
import { ProtectedRoute } from './ProtectedRoute'
import Home from '../pages/Home'    
import Bio from '../pages/Bio'
import Contact from '../pages/Contact'
import Reagents from '../pages/Reagents'
import Gallery from '../pages/Gallery'
import Pathways from '../pages/Pathways'
import SSB from '../pages/Products/SSB'
import DSBHR from '../pages/Products/DSBHR'
import DSBNH from '../pages/Products/DSBNH'
import AlexaFlourSecondary from '../pages/Products/AlexaFlourSecondary'
import CookiePolicy from '../pages/PrivacyPages/CookiePolicy'
import PrivacyPolicy from '../pages/PrivacyPages/PrivacyPolicy'
import TermsConditionUse from '../pages/PrivacyPages/TermsConditionUse'
import TermsConditionSale from '../pages/PrivacyPages/TermsConditionSale'
import Cart from '../pages/Cart'
import Checkout from '../pages/Checkout'
import ProfileInformation from '../pages/Profile/information'
import Orders from '../pages/Profile/orders'
import TaxExempt from '../pages/Profile/taxExempt'
import ShippingAddress from '../pages/Profile/shippingAddress'
import BillingAddress from '../pages/Profile/billingAddress'
import About from '../pages/About'
import Azure from '../pages/Technologies/Azure'
import Acquifier from '../pages/Technologies/Acquifier'
import Bruker from '../pages/Technologies/Bruker'
import ThermoFisher from '../pages/Technologies/ThermoFisher'
import PrimaryAntibodies from '../pages/Technologies/PrimaryAntibodies'
import Zeiss from '../pages/Technologies/Zeiss'
import AssayDevelopment from '../pages/Technologies/AssayDevelopment'
import Services from '../pages/Technologies/Services'
import Error from '../pages/Error'
import Coa from '../components/coA'
import Invoice from '../components/Invoice'
import B2B from '../pages/Support/B2B'
import Careers from "../pages/Support/Career"
import MicroscopyMethods from "../pages/Support/MicroscopyMethods"
import ProteinChemistryMethods from "../pages/Support/ProteinChemistryMethods"
import IntellectualProperty from "../pages/PrivacyPages/IntellectualProperty"
import StormCalibration from "../pages/Support/StormCalibration"
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "../helpers/localStorage";
import { currentUserApi, getCustomerGroupApi, createCartApi, getCartApi, updateCartApi, getRegionsApi } from '../api'
import { Loader } from "rsuite"



// const SuspensedView = ({ children }) => {
//     const baseColor = '#DC4405'
//     TopBarProgress.config({
//         barColors: {
//             '0': baseColor,
//         },
//         barThickness: 2,
//         shadowBlur: 5,
//     })
//     return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
// }




export const AppRouter = () => {
    const { setWidth, login, logout, setIsLoading, isLoading, setCartData , user, regions, setRegions, setChangePasswordRestoreModal} = useContext(AuthContext)
    const navigate = useNavigate()
    const token = getLocalStorage('identifyntoken')
    const cartId = getLocalStorage('cartId')

   const data = (new URL(document.location).searchParams)
    const tokenId = data.get('token')    

    // useEffect(() => {
    //     if(tokenId){
    //         setChangePasswordRestoreModal(true)
    //     }
    // },[])





    // const fetchRegions = () => {
    //     getRegionsApi().then(res => {
    //         if(res && res.data && res.data.regions){
    //             setRegions(res.data.regions)
    //         }
    //     })
    // }


    // useEffect(() => {
    //     if (token) {
    //         setIsLoading(true)
    //         currentUserApi()
    //             .then(res => {
    //                 if (res.data) {
    //                     let data = res.data
    //                     getCustomerGroupApi(data?.customer?.id).then((customerGroup) => {
    //                         data.customer.groups = customerGroup.data?.customerGroups[0]?.groups[0]?.name
    //                     })
    //                     login(data)
    //                     setIsLoading(false)
    //                 }else{
    //                     setIsLoading(false)
    //                     logout()
    //                 }
    //             })
    //             .catch(() => {
    //                 // navigate('/')
    //                 logout()
    //                 setIsLoading(false)
    //             })
    //     } else {
    //         // navigate('/')
    //         setIsLoading(false)
    //     }
    //     fetchRegions()
    // }, [])

    // useEffect(() => {
    //     if(!cartId){
    //       createCartApi().then(res => {
    //         if(res && res.data && res.data.cart){
    //             setLocalStorage('cartId', res.data.cart.id)
    //         }
    //       })  
    //     }else{
    //         getCartApi(cartId).then((res)=>{
    //             if(res && res.data && res.data.cart){
    //               setCartData(res?.data?.cart)
    //             }else{
    //               setCartData(null)
    //               removeLocalStorage('cartId')
    //             }
    //           })
    //     }
    // },[cartId])


    // useEffect(() => {
    //     if(user?.customer?.id && cartId){
    //         updateCartApi(cartId, {
    //             customer_id: user?.customer?.id,
    //             region_id: regions?.find((region) => region.countries?.find((country) => country.name === 'UNITED STATES'))?.id
    //         }).then((res)=>{
    //             if(res && res.data && res.data.cart){
    //               setCartData(res.data.cart)
    //             }else{
    //               setCartData(null)
    //             }
    //           })
    //     }

    // },[user, cartId])


    const updateWindowDimensions = () => {
        const newWidth = window.innerWidth;
        setWidth(newWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions);
    }, []);

    // const Home = lazy(() => import('../pages/Home'));
    // const Bio = lazy(() => import('../pages/Bio'));
    // const Contact = lazy(() => import('../pages/Contact'));
    // const Reagents = lazy(() => import('../pages/Reagents'));
    // const Gallery = lazy(() => import('../pages/Gallery'));
    // const ThermoFisher = lazy(() => import('../pages/Technologies/ThermoFisher'));
    // const Pathways = lazy(() => import('../pages/Pathways'));
    // const SSB = lazy(() => import('../pages/Products/SSB'));
    // const DSBHR = lazy(() => import('../pages/Products/DSBHR'));
    // const DSBNH = lazy(() => import('../pages/Products/DSBNH'));
    // const AlexaFlourSecondary = lazy(() => import('../pages/Products/AlexaFlourSecondary'));
    // const CookiePolicy = lazy(() => import('../pages/PrivacyPages/CookiePolicy'));
    // const PrivacyPolicy = lazy(() => import('../pages/PrivacyPages/PrivacyPolicy'));
    // const TermsConditionUse = lazy(() => import('../pages/PrivacyPages/TermsConditionUse'));
    // const TermsConditionSale = lazy(() => import('../pages/PrivacyPages/TermsConditionSale'));
    // const Cart = lazy(() => import('../pages/Cart'));
    // const Orders = lazy(() => import('../pages/Profile/orders'));
    // const ProfileInformation = lazy(() => import('../pages/Profile/information'));
    // const TaxExempt = lazy(() => import('../pages/Profile/taxExempt'));
    // const ShippingAddress = lazy(() => import('../pages/Profile/shippingAddress'));
    // const BillingAddress = lazy(() => import('../pages/Profile/billingAddress'));
    // const Checkout = lazy(() => import('../pages/Checkout'));


    return (
        <>
            {isLoading && (
                <Loader size="lg" backdrop vertical />
            )}
            <Routes>
                <Route exact path='/' element={<Home />
                    // <SuspensedView>
                    //     <Home />
                    //  </SuspensedView>
                } />
                <Route exact path='/technologies/acquifer' element={<Acquifier />
                    // <SuspensedView>
                        // <Acquifier />
                    // </SuspensedView>
                } />
                <Route exact path='/technologies/azure' element={<Azure/>
                    // <SuspensedView>
                        // <Azure />
                    // </SuspensedView>
                } />
                <Route exact path='/technologies/bruker' element={<Bruker />
                    // <SuspensedView>
                        // <Bruker />
                    // </SuspensedView>
                } />
                 <Route exact path='/technologies/zeiss' element={<Zeiss />
                    // <SuspensedView>
                        // <Bruker />
                    // </SuspensedView>
                } />
                  <Route exact path='/technologies/assay-development' element={<AssayDevelopment />
                    // <SuspensedView>
                        // <Bruker />
                    // </SuspensedView>
                } />
                 <Route exact path='/technologies/services' element={<Services />
                    // <SuspensedView>
                        // <Bruker />
                    // </SuspensedView>
                } />
                  <Route exact path='/technologies/alexaflour-conjugates' element={<ThermoFisher />
                    // <SuspensedView>
                        // <ThermoFisher />
                    // </SuspensedView>
                } />
                  <Route exact path='/technologies/primary-antibodies' element={<PrimaryAntibodies />
                    // <SuspensedView>
                        // <ThermoFisher />
                    // </SuspensedView>
                } />
                  <Route exact path='/b2b-opportunities' element={<B2B/>
                    // <SuspensedView>
                        // <About />
                    // </SuspensedView>
                } />
                  <Route exact path='/intellectual-property' element={<IntellectualProperty/>
                    // <SuspensedView>
                        // <About />
                    // </SuspensedView>
                } />
                  <Route exact path='/careers' element={<Careers/>
                    // <SuspensedView>
                        // <About />
                    // </SuspensedView>
                } />
                  <Route exact path='/b2b-opportunities' element={<B2B/>
                    // <SuspensedView>
                        // <About />
                    // </SuspensedView>
                } />
                    <Route exact path='/storm-calibration' element={<StormCalibration/>} />
                   <Route exact path='/microscopy-methods' element={<MicroscopyMethods />
                    // <SuspensedView>
                        // <About />
                    // </SuspensedView>
                } />
                   <Route exact path='/protein-chemistry-methods' element={<ProteinChemistryMethods />
                    // <SuspensedView>
                        // <About />
                    // </SuspensedView>
                } />
                <Route exact path='/about' element={<About/>
                    // <SuspensedView>
                        // <About />
                    // </SuspensedView>
                } />
                <Route exact path='/team' element={<Bio/>
                    // <SuspensedView>
                        // <Bio />
                    // </SuspensedView>
                } />
                <Route exact path='/contact' element={
                    // <SuspensedView>
                        <Contact />
                    // </SuspensedView>
                } />
                {/* <Route exact path='reagents' element={<Reagents/>
                    // <SuspensedView>
                        // <Reagents />
                    // </SuspensedView>
                } /> */}
                <Route exact path='/gallery' element={<Gallery/>
                    // <SuspensedView>
                        // <Gallery />
                    // </SuspensedView>
                } />
                <Route exact path='/product/pathways' element={<Pathways />
                    // <SuspensedView>
                        // <Pathways />
                    // </SuspensedView>
                } />
                <Route exact path='/product/pathways/ssb' element={<SSB />
                    // <SuspensedView>
                        // <SSB />
                    // </SuspensedView>
                } />
                <Route exact path='/product/pathways/dsb-hr' element={<DSBHR />
                    // <SuspensedView>
                        // <DSBHR />
                    // </SuspensedView>
                } />
                <Route exact path='/product/pathways/dsb-nh' element={<DSBNH />
                    // <SuspensedView>
                        // <DSBNH />
                    // </SuspensedView>
                } />
                <Route exact path='/product/alexa-fluor-secondary' element={<AlexaFlourSecondary />
                    // <SuspensedView>
                        // <AlexaFlourSecondary />
                    // </SuspensedView>
                } />
                <Route exact path='/cookie-policy' element={<CookiePolicy />
                    // <SuspensedView>
                        // <CookiePolicy />
                    // </SuspensedView>
                }
                />
                <Route exact path='/privacy-policy' element={<PrivacyPolicy />
                    // <SuspensedView>
                        // <PrivacyPolicy />
                    // </SuspensedView>
                } />
                <Route exact path='/terms-and-conditions-of-use' element={<TermsConditionUse />
                    // <SuspensedView>
                        // <TermsConditionUse />
                    // </SuspensedView>
                } />
                <Route exact path='/terms-and-conditions-of-sale' element={<TermsConditionSale />
                    // <SuspensedView>
                        // <TermsConditionSale />
                    // </SuspensedView>
                } />
                <Route exact path='/cart' element={<Cart />
                    // <SuspensedView>
                        // <Cart />
                    // </SuspensedView>
                } />
                  <Route path='*' element={<Error />} />
                {/* <Route exact path='/coa' element={<Coa/>}/> */}
                <Route element={<ProtectedRoute />}>
                    <Route exact path='/profile/orders' element={<Orders />
                        // <SuspensedView>
                            // <Orders />
                        // </SuspensedView>
                    } />
                    <Route exact path='/profile/account' element={<ProfileInformation />
                        // <SuspensedView>
                            // <ProfileInformation />
                        // </SuspensedView>
                    } />
                    <Route exact path='/profile/billing-address' element={<BillingAddress />
                        // <SuspensedView>
                            // <BillingAddress />
                        // </SuspensedView>
                    } />
                    <Route exact path='/profile/shipping-address' element={<ShippingAddress />
                        // <SuspensedView>
                            // <ShippingAddress />
                        // </SuspensedView>
                    } />
                    <Route exact path='/profile/tax-exempt' element={<TaxExempt />
                        // <SuspensedView>
                            // <TaxExempt />
                        // </SuspensedView>
                    } />
                    {/* <Route exact path='/checkout' element={<Checkout />
                        // <SuspensedView>
                            // <Checkout />
                        // </SuspensedView>
                    } /> */}
                </Route>
                <Route exact path="/invoice" element={<Invoice />} />
            </Routes>
        </>
    )
}
