import React, { useState, useContext, useEffect, useRef } from 'react'
import Layout from '../layout'
import { OrderWrapper } from './style'
import Pagination from '../../../components/pagination'
import Input from '../../../components/Input'
import { getCustomerOrdersApi, getCartApi } from '../../../api'
import { AuthContext } from '../../../context/auth'
import moment from 'moment'
import { getKeyByValue, countryCode } from '../../../utils/country'
import ReactPrint from "react-to-print";
import Invoice from '../../../components/Invoice'

const Index = () => {
  const invoiceRef = useRef(null)
  const onBeforeGetContentResolve = useRef();
  const { setIsLoading } = useContext(AuthContext);
  const [ordersTab, setOrdersTab] = useState("pending");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [current, setCurrent] = useState(2);
  const [currentPage, setCurrentPage] = useState(0);
  const [orders, setOrders] = useState({});
  const [search, setSearch] = useState('');
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)
  const [date, setDate] = useState(30);

  // const businessData = [
  //   {
  //     date: 'July 6, 2014, 01:00',
  //     email: 'user@useremail.com',
  //     account: '123456',
  //     order: '123456',
  //     total: '$198.00',
  //     status: 1,
  //   },
  //   {
  //     date: 'July 6, 2014, 01:00',
  //     email: 'user@useremail.com',
  //     account: '123456',
  //     order: '123456',
  //     total: '$198.00',
  //     status: 2,
  //   },
  //   {
  //     date: 'July 6, 2014, 01:00',
  //     email: 'user@useremail.com',
  //     account: '123456',
  //     order: '123456',
  //     total: '$198.00',
  //     status: 3,
  //   },
  //   {
  //     date: 'July 6, 2014, 01:00',
  //     email: 'user@useremail.com',
  //     account: '123456',
  //     order: '123456',
  //     total: '$198.00',
  //     status: 4,
  //   },
  //   {
  //     date: 'July 6, 2014, 01:00',
  //     email: 'user@useremail.com',
  //     account: '123456',
  //     order: '123456',
  //     total: '$198.00',
  //     status: 1,
  //   },
  //   {
  //     date: 'July 6, 2014, 01:00',
  //     email: 'user@useremail.com',
  //     account: '123456',
  //     order: '123456',
  //     total: '$198.00',
  //     status: 3,
  //   },
  //   {
  //     date: 'July 6, 2014, 01:00',
  //     email: 'user@useremail.com',
  //     account: '123456',
  //     order: '123456',
  //     total: '$198.00',
  //     status: 1,
  //   },
  //   {
  //     date: 'July 6, 2014, 01:00',
  //     email: 'user@useremail.com',
  //     account: '123456',
  //     order: '123456',
  //     total: '$198.00',
  //     status: 1,
  //   },
  //   {
  //     date: 'July 6, 2014, 01:00',
  //     email: 'user@useremail.com',
  //     account: '123456',
  //     order: '123456',
  //     total: '$198.00',
  //     status: 1,
  //   },
  //   {
  //     date: 'July 6, 2014, 01:00',
  //     email: 'user@useremail.com',
  //     account: '123456',
  //     order: '123456',
  //     total: '$198.00',
  //     status: 1,
  //   },
  // ];



  // const handleOnBeforeGetContent = (business) => {
  //   return new Promise((resolve) => {
  //     setIsLoading(true)
  //     onBeforeGetContentResolve.current = resolve;

  //   });
  // };

  useEffect(() => {
    if (selectedOrder) {
      onBeforeGetContentResolve.current && onBeforeGetContentResolve.current();
    }
  }, [selectedOrder]);


  useEffect(() => {
    setCurrentPage(0)
  }, [ordersTab])


  useEffect(() => {
    setIsLoading(true)
    getCustomerOrdersApi(ordersTab, search, moment().subtract(date, 'days').format('YYYY-MM-DD'), 10, currentPage * 10).then((res) => {
      if (res.data && res.data.orders?.length > 0) {
        const tempOrders = res.data?.orders?.map((order, index) => {
          return {
            ...order,
            orderStatus: order.status === 'pending' && order.payment_status !== 'captured' ? 1 : order.status === 'pending' && order.payment_status === 'captured' && order.fulfillment_status !== 'shipped' ? 2 : order.status === 'pending' && order.payment_status === 'captured' && order.fulfillment_status === 'shipped' ? 3 : order.status === 'completed' && 4
          }
        })
        setOrders({ ...res.data, orders: tempOrders })
        setIsLoading(false)
      } else {
        setOrders({})
        setIsLoading(false)
      }
    })
  }, [ordersTab, search, currentPage, date]);


  useEffect(() => {
    setSelectedIndex(null)
    setSelectedOrder(null)
    setSelectedItem(null)
  }, [ordersTab, currentPage])


  const getCartDataById = () => {
    setIsLoading(true)
    getCartApi(selectedOrder?.cart_id).then((res) => {
      if (res && res.data && res.data?.cart) {
        setSelectedItem(res?.data?.cart)
        setIsLoading(false)
      }else{
        setSelectedItem(null)
        setIsLoading(false)
      }
    })
  }

  useEffect(() => {
    if (selectedOrder?.cart_id) {
      getCartDataById()
    }
  }, [selectedOrder]);



  return (
    <Layout>
      <OrderWrapper>
        <div className="order-details-container">
          <div className="order-header">
            <div className="tab-container">
              <button style={{ borderTopLeftRadius: '12px', borderBottomLeftRadius: '12px' }} className={ordersTab === "pending" && 'tab-active'} onClick={() => setOrdersTab("pending")}>Current Orders</button>
              <button style={{ borderTopRightRadius: '12px', borderBottomRightRadius: '12px' }} className={ordersTab === "completed" && 'tab-active'} onClick={() => setOrdersTab("completed")}>Completed Orders</button>
            </div>
            <div className="header-search-container">
              <Input type="text" search style={{ height: '40px', paddingLeft: '30px', width: '100%' }} placeholder={'Search'} value={search} onChange={(e) => setSearch(e.target.value)} />
              <select
                data-placeholder='Topic'
                value={date}
                placeholder='Select From Days'
                onChange={(e) => { 
                  setDate(e.target.value)
                }}
              >
                <option value={30}>Last 30 Days</option>
                <option value={60}>Last 60 Days</option>
                <option value={90}>Last 90 Days</option>
              </select>
            </div>
          </div>
          <div className="table-container">
            {orders && orders.orders?.length ?
              <>
                <table className={"mainTable"}>
                  <thead>
                    <tr>
                      <th>Date & Time </th>
                      <th>Invoice Number</th>
                      <th>Order Number</th>
                      <th>Total</th>
                      <th style={{ textAlign: 'right' }}>Status</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders?.orders?.map((business, index) => {
                      return (
                        <>
                          <tr
                            key={business?.id}
                          >
                            <td>
                              {business?.created_at ? moment(business?.created_at).format('MMMM DD, YYYY, h:mm A') : "-"}
                            </td>
                            <td>
                            {business?.payments ? business?.payments[0]?.id.slice(business?.payments[0]?.id.length - 5, business?.payments[0]?.id.length) : "-"}
                            </td>
                            <td>
                              {business?.display_id ? business?.display_id : "-"}
                            </td>
                            <td>
                              {business?.payments ? `$${(business?.payments[0]?.amount / 100)?.toFixed(2)}` : "-"}
                            </td>
                            <td style={{ display: 'flex', alignItems: 'center', alignItems: 'flex-end', flexDirection: 'column', gap: '2px' }} className={`status`}>
                              <button className={business.orderStatus === 2 ? 'in-progress' : business.orderStatus === 3 ? 'shipped' : business.orderStatus === 4 && 'completed'} style={{ color: business.orderStatus === 1 ? '#FF8D24' : business.orderStatus === 2 ? '#3F1BD0' : business.orderStatus === 3 ? '#000' : '#33C25E' }}>{business?.orderStatus === 1 ? <i class="fas fa-dot-circle f900"></i> : business.orderStatus === 2 ? <i class="far fa-spinner"></i> : business.orderStatus === 3 ? <i class="far fa-spinner"></i> : <i className='fas fa-check'></i>} {business.orderStatus === 1 ? 'Received' : business.orderStatus === 2 ? 'In Progress' : business.orderStatus === 3 ? 'Shipped' : 'Delivered'} </button>
                            </td>
                            <td style={{ cursor: "pointer" }} onClick={() => {
                              if (index === selectedIndex) {
                                setSelectedIndex(null)
                                setSelectedOrder(null)
                                setSelectedItem(null)
                              } else {
                                setSelectedIndex(index)
                                setSelectedOrder(business)
                              }
                            }}>
                              <span style={{ display: 'flex', gap: '30px', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <button style={{ fontSize: '16px', color: '#6c6a43' }}>{selectedIndex === index ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i>}</button>
                              </span>
                            </td>
                          </tr>
                          {index === selectedIndex && <tr style={{ background: 'transparent' }}>
                            <td colSpan={7}>
                              <div className="order-details">
                                <span className='horizontal-line'></span>
                                <div className="steps" style={{ marginBottom: '50px' }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "start",
                                  alignItems: "center",
                                  marginBottom: "19px",
                                  marginTop: "20px",
                                }}
                              >
                                <div
                                  style={{
                                    position: 'relative',
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#3f1bd0",
                                    color: "#ffffff",
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "50%",
                                    border: "1px solid transparent",
                                  }}
                                >
                                  <span
                                    style={{
                                      background: selectedOrder.orderStatus > 1 ? "#3f1bd0" : selectedOrder.orderStatus === 1 ? "#fcfcfc" : "rgba(154, 154, 154, 0.2)",
                                      width: "8px",
                                      height: "8px",
                                      borderRadius: "50%",
                                    }}
                                  ></span>
                                  <div className="timeline-status" style={{ position: 'absolute', bottom: '-45px', width: 'max-content', left: 0 }}>
                                    <h5 style={{ fontSize: '14px', color: '#000' }}>Received</h5>
                                    <h6 style={{ fontSize: '12px', color: '#6c6a73', fontWeight:400 }}>{moment(selectedOrder?.created_at).format('MMMM DD, YYYY, h:mm A')}</h6>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    height: "1px",
                                    width: "33%",
                                    margin:' 0 5px',
                                    backgroundColor: selectedOrder.orderStatus > 1 ? "#3f1bd0" : "rgba(154, 154, 154, 0.2)",
                                  }}
                                  className="progress-connector-line"
                                ></div>
                                <div
                                  style={{
                                    position: 'relative',
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: selectedOrder.orderStatus >= 2 ? "#3f1bd0" : "rgba(154, 154, 154, 0.2)",
                                    color: "#ffffff",
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "50%",
                                    border: "1px solid transparent",
                                  }}
                                >
                                  <span
                                    style={{
                                      background: selectedOrder.orderStatus >= 3 ? "transparent" : selectedOrder.orderStatus == 2 ? "#fcfcfc" : "transparent",
                                      width: "8px",
                                      height: "8px",
                                      borderRadius: "50%",
                                    }}
                                  ></span>
                                  <div className="timeline-status" style={{ position: 'absolute', bottom: '-45px', width: 'max-content', textAlign: 'center' }}>
                                    <h5 style={{ fontSize: '14px', color: '#000' }}>In Progress</h5>
                                    <h6 style={{ fontSize: '12px', color: '#6c6a73', fontWeight:400 }}>{selectedOrder?.payments[0]?.captured_at ? moment(selectedOrder?.payments[0]?.captured_at).format('MMMM DD, YYYY, h:mm A') : "-- --"}</h6>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    height: "1px",
                                    width: "33%",
                                    margin:' 0 5px',
                                    backgroundColor: selectedOrder.orderStatus >= 3 ? "#3f1bd0" : "rgba(154, 154, 154, 0.2)",
                                  }}
                                  className="progress-connector-line"
                                ></div>
                                <div
                                  style={{
                                    position: 'relative',
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: selectedOrder.orderStatus >= 3 ? "#3f1bd0" : "rgba(154, 154, 154, 0.2)",
                                    color: "#ffffff",
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "50%",
                                    border: "1px solid transparent",
                                  }}
                                >
                                  <span
                                    style={{
                                      background: selectedOrder.orderStatus >= 4 ? "transparent" : selectedOrder.orderStatus == 3 ? "#fcfcfc" : 'transparent',
                                      width: "8px",
                                      height: "8px",
                                      borderRadius: "50%",
                                    }}
                                  ></span>
                                  <div className="timeline-status" style={{ position: 'absolute', bottom: '-45px', width: 'max-content', textAlign: 'center' }}>
                                    <h5 style={{ fontSize: '14px', color: '#000' }}>Shipped</h5>
                                    <h6 style={{ fontSize: '12px', color: '#6c6a73', fontWeight:400 }}>{selectedOrder?.fulfillments && selectedOrder?.fulfillments[0]?.shipped_at ? moment(selectedOrder?.fulfillments[0]?.shipped_at).format('MMMM DD, YYYY, h:mm A') : "-- --"}</h6>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    height: "1px",
                                    width: "33%",
                                    margin:' 0 5px',
                                    backgroundColor: selectedOrder.orderStatus >= 4 ? "#3f1bd0" : "rgba(154, 154, 154, 0.2)",
                                  }}
                                  className="progress-connector-line"
                                ></div>
                                <div
                                  style={{
                                    position: 'relative',
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: selectedOrder.orderStatus >= 4 ? "#3f1bd0" : "rgba(154, 154, 154, 0.2)",
                                    color: "#ffffff",
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "50%",
                                    border: "1px solid transparent",
                                  }}
                                >
                                  <span
                                    style={{
                                      background: selectedOrder.orderStatus >= 4 ? "#fcfcfc" : "transparent",
                                      width: "8px",
                                      height: "8px",
                                      borderRadius: "50%",
                                    }}
                                  ></span>
                                  <div className="timeline-status" style={{ position: 'absolute', bottom: '-45px', width: 'max-content', right: 0, textAlign: 'right' }}>
                                    <h5 style={{ fontSize: '14px', color: '#000' }}>Delivered</h5>
                                    <h6 style={{ fontSize: '12px', color: '#6c6a73', fontWeight:400 }}>{selectedOrder?.status === 'completed' ? moment(selectedOrder?.updated_at).format('MMMM DD, YYYY, h:mm A') : "-- --"}</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                                <div className="horizontal-line"></div>
                                <div className="address-container">
                                  <div className="left-container">
                                    <h4>Shipping Address</h4>
                                    <div className="address">
                                      <div className="left-container">
                                        <h4>{selectedOrder?.shipping_address?.company}</h4>
                                        <h4 style={{ color: '#6c6a73', fontWeight: 400 }}>{selectedOrder?.shipping_address?.address_1}, {selectedOrder?.shipping_address?.city}, {selectedOrder?.shipping_address?.province} {selectedOrder?.billing_address?.postal_code}, {getKeyByValue(countryCode, selectedOrder?.billing_address?.country_code?.toUpperCase())}</h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="right-container">
                                    <h4>Billing Address</h4>
                                    <div className="address">
                                      <div className="left-container">
                                        <h4>{selectedOrder?.billing_address?.company}</h4>
                                        <h4 style={{ color: '#6c6a73', fontWeight: 400 }}>{selectedOrder?.billing_address?.address_1}, {selectedOrder?.billing_address?.city}, {selectedOrder?.billing_address?.province} {selectedOrder?.billing_address?.postal_code}, {getKeyByValue(countryCode, selectedOrder?.billing_address?.country_code?.toUpperCase())}</h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="items-container">
                                  <div className="item-header">
                                    <h4>Items</h4>
                                    <ReactPrint
                                      
                                      // onBeforeGetContent={() => handleOnBeforeGetContent(selectedOrder)}
                                      onAfterPrint={() => {
                                        setIsLoading(false)
                                      }}
                                      trigger={() => <span><i className="fas fa-eye"></i> Invoice</span>}
                                      content={() => invoiceRef.current}
                                    />

                                  </div>
                                  <div className="items-wrapper">
                                    {selectedItem?.items?.map((item, index) => {
                                      return <div className="item" key={item.id}>
                                        <h4>{item.variant?.title}</h4>
                                        <div className="item-details">
                                          <div className="left-container">
                                            <div className="details">
                                              <h4 style={{ color: '#9a9a9a' }}>Cat ID#</h4>
                                              <h4>{item.variant?.product?.mid_code} {item.variant?.product?.material}</h4>
                                            </div>
                                            <div className="details">
                                              <h4 style={{ color: '#9a9a9a' }}>Concentration</h4>
                                              <h4>{item.variant?.product?.concentration} mg/ml</h4>
                                            </div>
                                          </div>
                                          <div className="middle-container">
                                            <h4 style={{ color: '#9a9a9a' }}>Q-ty:</h4>
                                            <h4>{item.quantity}</h4>
                                          </div>
                                          <div className="right-container">
                                            <h4>${((item.unit_price * item.quantity) / 100).toFixed(2)}</h4> 
                                           {item.quantity > 1 && <h6>{(item.unit_price / 100).toFixed(2)} x {item.quantity}</h6>}
                                          </div>
                                        </div>
                                      </div>
                                    })}
                                    {/* <div className="item">
                              <h4>Mouse Anti-Human PAR, IgG1</h4>
                              <div className="item-details">
                                <div className="left-container">
                                  <div className="details">
                                    <h4 style={{ color: '#9a9a9a' }}>Cat ID#</h4>
                                    <h4>PA000001</h4>
                                  </div>
                                  <div className="details">
                                    <h4 style={{ color: '#9a9a9a' }}>Concentration</h4>
                                    <h4>10 mg/ml</h4>
                                  </div>
                                </div>
                                <div className="middle-container">
                                  <h4 style={{ color: '#9a9a9a' }}>Q.ty</h4>
                                  <h4>2</h4>
                                </div>
                                <div className="right-container">
                                  <h4>$99.00</h4>
                                </div>
                              </div>
                            </div>
                            <div className="item">
                              <h4>Mouse Anti-Human PAR, IgG1</h4>
                              <div className="item-details">
                                <div className="left-container">
                                  <div className="details">
                                    <h4 style={{ color: '#9a9a9a' }}>Cat ID#</h4>
                                    <h4>PA000001</h4>
                                  </div>
                                  <div className="details">
                                    <h4 style={{ color: '#9a9a9a' }}>Concentration</h4>
                                    <h4>10 mg/ml</h4>
                                  </div>
                                </div>
                                <div className="middle-container">
                                  <h4 style={{ color: '#9a9a9a' }}>Q.ty</h4>
                                  <h4>2</h4>
                                </div>
                                <div className="right-container">
                                  <h4>$99.00</h4>
                                </div>
                              </div>
                            </div> */}
                                  </div>
                                </div>
                                <div className="invoice-container">
                                  <h4>Summary</h4>
                                  <div className="summary">
                                    <div className="summary-header">
                                      <h5>Payment Method</h5>
                                      <h5>{selectedOrder?.payments[0]?.provider_id === 'stripe' ? 'Credit Card' : 'Purchase Order'} {selectedOrder?.payments[0]?.provider_id === 'manual' && `(#${selectedItem?.context?.purchaseOrderNumber || '-'})`}</h5>
                                    </div>
                                    <span className="horizontal-line"></span>
                                    <div className="summary-details">
                                      <div className="left-container">
                                        <h5>Total Items</h5>
                                        <h5>Subtotal</h5>
                                        <h5>Tax</h5>
                                        <h5>Shipping & Handling</h5>
                                        <h5 style={{ color: '#000' }}>Grand Total</h5>
                                      </div>
                                      <div className="right-container" style={{ textAlign: 'right', alignItems: 'flex-end' }}>
                                        <h5>{selectedOrder?.items?.reduce((acc, item) => acc + item.quantity, 0)}</h5>
                                        <h5>${selectedOrder?.items?.reduce((acc, item) => acc + item.refundable / 100, 0).toFixed(2)}</h5>
                                        <h5>${selectedOrder?.tax_rate ? (selectedOrder?.tax_rate / 100)?.toFixed(2) : '0.00'}</h5>
                                        <h5>${selectedOrder?.shipping_methods?.reduce((acc, item) => acc + item.price / 100, 0).toFixed(2)}</h5>
                                        <h5 style={{ color: '#000', fontWeight: 700 }}>${(selectedOrder?.payments?.reduce((acc, item) => acc + item.amount / 100, 0)).toFixed(2)}</h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          }
                        </>
                      );
                    })}
                  </tbody>
                </table>
                {orders?.count / orders.limit > 1 && <Pagination total={orders?.count / orders.limit} setCurrentPage={setCurrentPage} currentPage={currentPage} />}
              </>
              : <div className='no-orders'>
                <span className='icon'>
                  <i class="fal fa-boxes"></i>
                </span>
                <p>No Orders Yet.</p>
              </div>
            }
          </div>
        </div>
        <div className="print-map" style={{ display: 'none' }}>
          <Invoice reference={invoiceRef} selectedOrder={selectedOrder} selectedItem={selectedItem} />
        </div>
      </OrderWrapper>
    </Layout>
  )
}

export default Index;