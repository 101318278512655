import React, { useState, useContext, useEffect } from 'react'
import Layout from "../layout"
import { Wrapper } from "./style"
import { toAbsoluteUrl } from '../../../utils/assetHelper'
import Input from "../../../components/Input"
import { CountryDropdown } from '../../../components/DropDowns'
import { AuthContext } from '../../../context/auth'
import { updateCustomerApi, currentUserApi, getCustomerGroupApi, updateCustomerToCustomerGroupApi, getCustomerGroupsApi, uploadApi } from '../../../api'

const Index = () => {
  const { setChangePasswordModal, setChangeEmailModal, setDeleteProfileModal, width, user, setUser, setIsLoading, setNotification } = useContext(AuthContext)
  const [selectedPhoto, setSelectedPhoto] = useState(null)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [position, setPosition] = useState("")
  const [email, setEmail] = useState("")
  const [country, setCountry] = useState("United States")
  const [academic, setAcademic] = useState("")
  const [types, setTypes] = useState([])
  const [verified, setVerified] = useState(false)
  const [isChanged, setIsChanged] = useState(false)


useEffect(() => {
  if (user?.customer) {
    const { first_name, last_name, position, country, avatar, groups } = user.customer;
    const fieldsChanged =
      first_name !== firstName ||
      last_name !== lastName ||
      position !== position ||
      country !== country ||
      avatar !== selectedPhoto ||
      groups !== academic

    setIsChanged(fieldsChanged);
  }

},[user, firstName, lastName, position, country, selectedPhoto, academic ])


  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };


  const onUpload = async (e) => {
    const file = e.target.files[0]
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      uploadApi(formData).then((res) => {
        if (res.data) {
          setSelectedPhoto(res.data.url)
        }
      })
      // const base64 = await convertBase64(file);
      // setSelectedPhoto(base64)
    }

  }



  const fetchCurrentUser = () => {
    currentUserApi()
    .then(res => {
        if (res.data) {
          let data = res.data
          getCustomerGroupApi(data.customer?.id).then((customerGroup) => {
            data.customer.groups = customerGroup.data?.customerGroups[0]?.groups[0]?.name 
          })
           setUser(res.data)
          setIsLoading(false)
        }
    })
    .catch(() => {
        setIsLoading(false)
    })
  }

  const fetchCustomerGroups = () => {
    setIsLoading(true)
    getCustomerGroupsApi().then((res) => {
      if(res?.data?.customerGroups){
        setTypes(res?.data?.customerGroups)
        setIsLoading(false)
      }else{
        setIsLoading(false)
      }
    })
  }



  

  const reset = () => {
    setIsChanged(false)
    setSelectedPhoto(user?.customer?.avatar);
    setFirstName(user?.customer?.first_name)
    setLastName(user?.customer?.last_name)
    setPosition(user?.customer?.position)
    setEmail(user?.customer?.email)
    setCountry(user?.customer?.metadata?.country)
    setVerified(user?.customer?.metadata?.is_email_verified ? true : false)
    setAcademic(user?.customer?.groups)
  }


  useEffect(() => {
    if (user) {
      reset()
     fetchCustomerGroups()
    }
  }, [user?.customer, user?.customer?.groups])



  const onSave = () => {
    setIsLoading(true)
    const data = {
      first_name: firstName,
      last_name: lastName,
      position: position,
      email: email,
      avatar: selectedPhoto,
      metadata: {
        avatar:"",
        country: country,
        is_email_verified: verified,
      }
    }
    updateCustomerApi(data).then(() => {
      if(user.groups !== academic){
        updateCustomerToCustomerGroupApi({
          id:user?.customer?.id,
          group_id: types.find((group) => group.name === academic)?.id
        }).then(() => {
          fetchCurrentUser()
          setIsLoading(false)
          setNotification({
            type: 'success',
            message: 'Changes saved successfully!'
          })
        })
      }else{
      fetchCurrentUser()
      setIsLoading(false)
      setNotification({
        type: 'success',
        message: 'Changes saved successfully!'
      })
    }
    }).catch(() => {
      setIsLoading(false)
      setNotification({
        type: 'red',
        message: 'Something went wrong, please try again!'
      })
    })
  }



  return (
    <Layout>
      <Wrapper>
        {width < 1100 && <h5 style={{ textAlign: 'right' }}>ID: {user?.customer?.id.slice(25, 30)}</h5>}
        <div className="header">
          <h4>Profile Information</h4>
        {isChanged &&  <div className="header-btn-container">
            <button className='btn transparent' onClick={() => {
              reset()
            }}>Cancel</button>
            <button className='btn' onClick={onSave}>Save Changes</button>
          </div>
}
        </div>
        <div className="img-container">
          <div className="avatar-uploader">
            <input type="file" className='image-file' accept="image/*" onChange={onUpload} />
           {selectedPhoto ? 
            <img src={selectedPhoto}  alt="" />
            : <div className="custom-profile">{user?.customer?.first_name[0]?.toUpperCase()}</div>
}
            <span>Change</span>
          </div>
        </div>
        <div className="input-wrapper">
          <div className="input-container">
            <label htmlFor="First Name">First Name</label>
            <Input type='text' placeholder='First Name' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
          </div>
          <div className="input-container">
            <label htmlFor="Last Name">Last Name</label>
            <Input type='text' placeholder='Last Name' value={lastName} onChange={(e) => setLastName(e.target.value)} />
          </div>
        </div>
        <div className="input-wrapper">
          <div className="input-container">
            <label htmlFor="Position">Position</label>
            <Input type='text' placeholder='Position' value={position} onChange={(e) => setPosition(e.target.value)} />
          </div>
          <div className="input-container">
            <label htmlFor="Email">Email</label>
            <Input type='email' placeholder='Email Address' verified={verified} readOnly onClick={() => setChangeEmailModal(true)} value={email} />
          </div>
        </div>
        <div className="input-wrapper">
          <div className="input-container">
            <label htmlFor="Country">Country</label>
            <CountryDropdown value={country} onChange={setCountry} />
          </div>
        </div>
        <hr />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <h5>I'm:</h5>
          <div className="input-wrapper">
            <div className="input-container">
              <div className={academic === 'Academic' ? 'select-active select-option' : 'select-option'}>
                <label class="containers" >
                  <input type="radio" name="academic" value="Academic" checked={academic === 'Academic'} onChange={(e) => setAcademic(e.target.value)} />
                  <span class="checkmark"></span>
                  <span>Academic</span>
                </label>
              </div>
            </div>
            <div className="input-container">
              <div className={academic === 'Industry' ? 'select-active select-option' : 'select-option'}>
                <label class="containers" >
                  <input type="radio" name="academic" value="Industry" checked={academic === 'Industry'} onChange={(e) => setAcademic(e.target.value)} />
                  <span class="checkmark"></span>
                  <span>Industry</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="button-container">
          {width < 1100 && isChanged && <button className='btn' onClick={onSave}>Save Changes</button>}
          <button className='btn password-btn'
            onClick={() => setChangePasswordModal(true)}
          ><i className='fal fa-lock' /> Change Password</button>
          <button className='btn transparent'
            onClick={() => setDeleteProfileModal(true)}
          ><i className='fal fa-trash-alt' /> Delete Account</button>
          {width < 1100 && isChanged && <button className='btn transparent' onClick={reset}>Cancel</button>}
        </div>
      </Wrapper>
    </Layout>
  )
}

export default Index