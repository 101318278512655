export const proteinsList = [
    'PARP1',
    'PARP1.pADPR',
    'XRCC1',
    'PARG',
    'APE1',
    'APE1(+/-) PCNA',
    'PCNA',
    'PCNA.Ub',
    'APE2',
    'ATR',
    'ATRIP',
    'RPA',
    'Chk1',
    'Chk1.p',
    'RPA2',
    'RPA2.p',
    'RAD17',
    'HUS1',
    'RAD1',
    'RAD9',
    'TopBP1',
    'LIG1',
    'FEN1',
    'Polß',
    'Polδ',
    'Pol ε',
    '53BP1',
    'ATM',
    '53BP1.p',
    'PTIP',
    'Artemis (SNM1C)',
    'Artemis',
    'RIF1',
    'ASF1',
    'REV7',
    'TRIP13',
    'RIF.p',
    'Ku70',
    'Ku80',
    'DNA-PKcs',
    'Artemis.p',
    'SUMO1',
    'XLF',
    'XRCC4',
    'LigaseIV',
    'Pol μ',
    'Pol λ',
    'MRE11',
    'NBS1 (P95)',
    'RAD50',
    'WRN',
    'BLM',
    'BRCA1',
    'CtIP (RBBP8)',
    'RAD51',
    'RAD52',
    'RAD54',
    'FANCN',
    'BRCA2 (FANCD1)',
    'FANCJ (BRIP1/BACH1)',
    'Ccdc98',
    'BRCC36',
    'BRCC45 (BRE/BABAM2)',
    'RAP80',
    'SUMO',
    'SUMO.Ub',
    'RNF4',
    'Ccdc98 (ABRAXAS1/Abra1)',
    'BARD1',
    'BRCA1.p',
    'CtIP',
    'H2AK125',
    'H2AK127',
    'H2AK129',
    'H2AK125U',
    'H2AK127U',
    'H2AK129U',
    'HERC2',
    'BAP1',
    'BAP1.p',
    'Chk2',
    'Chk2.p',
    'ATM.p',
    'H2Ax',
    'γH2Ax',
    'MDC1',
    'MDC1.p',
    'H2A',
    'H2AK13',
    'H2AK15',
    'H2AK13(K27)',
    'H2AK15(K27)',
    'H2AK13(K67)',
    'H2AK15(K67)',
    'Ub K27',
    'Ub K67',
    'RNF168',
    'H1 Histone.Ub',
    'L3MBTL2',
    'RNF8.Ub',
    'UBC13',
    'Mms2',
    'Ring',
    'RNF8',
    'FHA',
    'USP28',
    'USP48',
    'p53',
    'p53.p',
    'p21',
    'GADD45',
    'CIP1',
    'TIP60',
    'Ac.ATM',
    'DSS1'
]

export const proteinFunctions = [
    {
        name: 'PARP1',
        preview:"PARP1 (Poly(ADP-ribose) polymerase 1) has a multifaceted function in the detection of DNA damage, including single-strand breaks (SSBs) and double-strand breaks (DSBs) via binding at the site of damage. PARP1 catalyzes the synthesis of poly(ADP-ribose) (PAR) chains using NAD+; the resulting PAR chains signal the recruitment of DNA repair proteins to the break, initiating the repair process. PARP1 is implicated in regulating Homologous Recombination (HR) via DNA End Resection Inhibition, which is essential for HR. Additionally, preventing excessive DNA end resection promotes the repair of DNA double-strand breaks through alternative pathways, such as non-homologous end joining (NHEJ).  In the single-strand break repair pathway (SSBs), PARP1 recruits DNA repair effectors, such as X-ray repair cross-complementing protein 1 (XRCC1), to mediate DNA repair. PARP1 may prove vital for generating targeted therapies for cancer treatment and other DNA repair-associated diseases.",
        function: 'PARP1 (Poly(ADP-ribose) polymerase 1) has a multifaceted function in the detection of DNA damage, including single-strand breaks (SSBs) and double-strand breaks (DSBs) via binding at the site of damage. PARP1 catalyzes the synthesis of poly(ADP-ribose) (PAR) chains using NAD+; the resulting PAR chains signal the recruitment of DNA repair proteins to the break, initiating the repair process. PARP1 is implicated in regulating Homologous Recombination (HR) via DNA End Resection Inhibition, which is essential for HR. Additionally, preventing excessive DNA end resection promotes the repair of DNA double-strand breaks through alternative pathways, such as non-homologous end joining (NHEJ).  In the single-strand break repair pathway (SSBs), PARP1 recruits DNA repair effectors, such as X-ray repair cross-complementing protein 1 (XRCC1), to mediate DNA repair. PARP1 may prove vital for generating targeted therapies for cancer treatment and other DNA repair-associated diseases.',
        reference: [
            'https://www.cell.com/molecular-cell/fulltext/S1097-2765(22)00159-9',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6591728/',
            'https://www.sciencedirect.com/science/article/pii/S0021925820690305',
        ]
    },
    {
        name: 'PARP1.pADPR',
        preview:"PARP1 (Poly(ADP-ribose) polymerase 1) is a critical enzyme involved in DNA damage response (DDR), specifically in the detection and repair of DNA single-strand breaks (SSBs). It catalyzes the addition of poly(ADP-ribose) (pADPR) chains to target proteins, a process known as poly(ADP-ribosyl)ation or PARylation.",
        function: `
        PARP1 (Poly(ADP-ribose) polymerase 1) is a critical enzyme involved in DNA damage response (DDR), specifically in the detection and repair of DNA single-strand breaks (SSBs). It catalyzes the addition of poly(ADP-ribose) (pADPR) chains to target proteins, a process known as poly(ADP-ribosyl)ation or PARylation.
<p style="font-weight:600; font-size:18px">Role of PARP1 in DNA Repair</p>
<p>PARP1 detects DNA damage and catalyzes the synthesis of pADPR chains using NAD+ as a substrate. These chains are attached to PARP1 itself and other target proteins in the vicinity of DNA damage.</p>
<ul>
<li><strong>Detection of DNA Damage:</strong> PARP1 binds to DNA at sites of single-strand breaks, becoming activated upon recognizing DNA damage.</li>
<li><strong>Synthesis of pADPR Chains:</strong> Once activated, PARP1 catalyzes the addition of pADPR chains to itself and other target proteins. This modification can lead to changes in protein activity, protein-protein interactions, and recruitment of DNA repair proteins.</li>
<li><strong>Recruitment of DNA Repair Proteins:</strong> The presence of pADPR chains at sites of DNA damage acts as a signal to recruit DNA repair proteins. Proteins like XRCC1 and others involved in base excision repair (BER) are attracted to pADPR, facilitating the repair of single-strand breaks.</li>
<li><strong>Chromatin Remodeling:</strong> PARylation can also impact chromatin structure, leading to the relaxation of chromatin, which allows repair proteins to access damaged DNA more efficiently.</li>
</ul>
<p style="font-weight:600; font-size:18px">Functions of pADPR</p>
<p>Poly(ADP-ribose) (pADPR) has several essential functions in cellular processes:</p>
<ul>
<li><strong>Signal for DNA Repair:</strong> The presence of pADPR chains serves as a signal for the DNA damage response, attracting repair proteins and coordinating the repair process.</li>
<li><strong>Regulation of Protein-Protein Interactions:</strong> pADPR can influence protein-protein interactions by providing additional binding sites. This can impact the assembly of repair complexes and the overall DNA repair pathway.</li>
<li><strong>Cell Death and Apoptosis:</strong> If DNA damage is too severe to be repaired, extensive PARylation can lead to a process known as "PARP1 hyperactivation," which depletes cellular NAD+ and ATP, ultimately resulting in cell death through a form of apoptosis known as parthanatos.</li>
</ul>
<p style="font-weight:600; font-size:18px">Therapeutic Implications</p>
<p>PARP1 has become a target for cancer therapy, with PARP inhibitors (PARPi) used in treating cancers with defects in homologous recombination (like BRCA1/BRCA2 mutations). By inhibiting PARP1, these therapies exploit the vulnerability of cancer cells that rely heavily on PARP1-mediated repair pathways.</p>
<p>PARP1's poly(ADP-ribose) (pADPR) synthesis is a critical process in the DNA damage response, enabling the recruitment of DNA repair proteins and coordinating the repair of single-strand breaks. It also plays a role in chromatin remodeling and cell death when damage is irreparable. Given its importance in DNA repair and therapeutic potential, understanding PARP1 and pADPR is crucial for insights into DNA damage response and targeted cancer therapies.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/36515827/',
            'https://pubmed.ncbi.nlm.nih.gov/35271815/',
            'https://pubmed.ncbi.nlm.nih.gov/18025084/'
        ]
    },
    {
        name: 'XRCC1',
        function: 'It protects Transcription by preventing the PARP1-dependent accumulation and associated toxicity of the downstream factors',
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8294329/'
        ]
    },
    {
        name: 'PARG',
        function: 'Poly ADP ribose glycohydrolase. Removed ADPR from PARP1 which dissociates PARP1 from DNA-complex. Excessive accumulation of APE1 supports PARG function',
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7050487/'
        ]
    },
    {
        name: 'APE1',
        function: 'APE1 initiates end resection after sensing PARP1 localization',
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7038996/'
        ]
    },
    {
        name: 'APE1(+/-) PCNA',
        function: '',
        reference: []
    },
    {
        name: 'PCNA',
        function: 'PCNA in its phosphorylated form acts as a clamp / enzyme loader at the ssb for',
        reference: [
            'https://www.nature.com/articles/nrg2380'
        ]
    },
    {
        name: 'PCNA.Ub',
        function: 'PCNA in its phosphorylated form acts as a clamp / enzyme loader at the ssb for',
        reference: [
            'https://www.nature.com/articles/nrg2380'
        ]
    },
    {
        name: 'APE2',
        function: 'APE2 completes end resection after getting loaded by PCNA on the ssb site downstream of APE1',
        reference: [
            'https://academic.oup.com/nar/article/46/5/2479/4817400',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6122073/'
        ]
    },
    {
        name: 'ATR',
        function: 'Binds to ATRIP and guide ATRIP/ATR complex to bind RPA-stabilized ssDNA  and regulates Chk1 activation via phosphorylation',
        reference: [
            'https://www.sciencedirect.com/science/article/pii/S0163725819302025',
            'https://www.frontiersin.org/articles/10.3389/fcell.2020.602956/full#B41'
        ]
    },
    {
        name: 'ATRIP',
        function: 'ATRIP guides ATR activation for Chk1 activation',
        reference: [
            'https://www.sciencedirect.com/science/article/pii/S0163725819302025',
            'https://www.molbiolcell.org/doi/full/10.1091/mbc.e04-11-1006'
        ]
    },
    {
        name: 'RPA',
        function: '',
        reference: [
            'https://www.nature.com/articles/s41594-023-00932-w'
        ]
    },
    {
        name: 'Chk1',
        function: 'Chk1 regulates the cell to prepare for DNA repaor and controls the fate of the cellular viability from G2 to M phase. It is phophorylated by ATR to regulate cell cycle through interactions with p53, CDC25A and CDC25B',
        reference: [
            'https://www.frontiersin.org/articles/10.3389/fcell.2021.738502/full',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3731415/',
            'https://www.jstage.jst.go.jp/article/csf/40/1/40_14017/_html/-char/en',
        ]
    },
    {
        name: 'Chk1.p',
        function: 'Chk1 regulates the cell to prepare for DNA repaor and controls the fate of the cellular viability from G2 to M phase. It is phophorylated by ATR to regulate cell cycle through interactions with p53, CDC25A and CDC25B',
        reference: [
            'https://www.frontiersin.org/articles/10.3389/fcell.2021.738502/full',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3731415/',
            'https://www.jstage.jst.go.jp/article/csf/40/1/40_14017/_html/-char/en',
        ]
    },
    {
        name: 'RPA2',
        preview:"Replication Protein A (RPA) is a heterotrimeric protein complex comprised of three subunits: RPA1, RPA2, and RPA3.  RPA2, also known as RPA32, is one of the subunits of the RPA complex and plays several vital roles in DNA repair.  RPA2 is primarily responsible for binding to single-stranded DNA (ssDNA) regions exposed during various DNA metabolic processes, such as DNA replication, DNA repair, and recombination.",
        function: `
        <p>Replication Protein A (RPA) is a heterotrimeric protein complex comprised of three subunits: RPA1, RPA2, and RPA3.  RPA2, also known as RPA32, is one of the subunits of the RPA complex and plays several vital roles in DNA repair.  RPA2 is primarily responsible for binding to single-stranded DNA (ssDNA) regions exposed during various DNA metabolic processes, such as DNA replication, DNA repair, and recombination.</p>
<p>RPA2 interacts with the other subunits of the RPA complex to form a stable DNA-binding platform, which protects ssDNA from degradation and prevents it from forming secondary structures.  During DNA replication, RPA2 binds to the ssDNA regions generated by the action of the replicative helicase.  It stabilizes the ssDNA template and is a platform for recruiting various replication factors, including DNA polymerases and proteins in DNA synthesis and processing.  RPA2 also helps coordinate the assembly and disassembly of the replisome complex at the replication fork.</p>
<p>RPA2's function in the DNA repair pathways Homologous Recombination (HR), Nucleotide Excision Repair (NER), and Base Excision Repair (BER), RPA2 plays a critical role in recognizing and stabilizing ssDNA intermediates that arise during the repair process.  It facilitates the recruitment of repair proteins to the damaged sites and promotes the efficient repair of DNA lesions.  RPA2 is involved in cell cycle regulation by participating in DNA damage checkpoint signaling pathways.  Checkpoint kinases, ATR (ataxia telangiectasia and Rad3-related) and ATM (ataxia telangiectasia mutated) phosphorylate RPA2 in response to DNA damage.  Phosphorylated RPA2 helps activate checkpoint responses, leading to cell cycle arrest and DNA repair.   RPA2 acts in the maintenance of telomeres, the protective structures at the ends of chromosomes.  It binds to the single-stranded G-rich telomeric DNA overhangs and protects them from degradation and inappropriate processing.  RPA2 also participates in telomere replication and length regulation.</p>
<p>RPA2 is a multifunctional protein that plays crucial roles in DNA metabolism, including DNA replication and repair, cell cycle regulation, and telomere maintenance.  Its capacity to bind and stabilize single-stranded DNA intermediates is essential for maintaining genomic integrity and ensuring proper cellular function.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/31582797/',
            'https://pubmed.ncbi.nlm.nih.gov/16326383/',
            'https://pubmed.ncbi.nlm.nih.gov/24819595/',
            'https://pubmed.ncbi.nlm.nih.gov/36894693/'
        ]
    },
    {
        name: 'RPA2.p',
        function: '',
        reference: []
    },
    {
        name: 'RAD17',
        function: 'It is the clamp loader protein for 9-1-1 mediate ATR-related Chk1 activation machinery',
        reference: [
            'https://www.embopress.org/doi/full/10.1002/embj.201386064'
        ]
    },
    {
        name: 'HUS1',
        function: `HUS1 checkpoint clamp component (HUS1) is a member of 9-1-1 checkpoint complex (Rad9-Rad1-Hus1 [9-1-1] complex)
        Its upregulation inhibits cell proliferation, colony formation, migration, and invasion, as well as arrested cell cycle`,
        reference: [
            'https://www.sciencedirect.com/science/article/abs/pii/S156878642100001X'
        ]
    },
    {
        name: 'RAD1',
        function: 'It is part of the 9-1-1 complex that initiates ATR-mediated phosphorylation and activation of Chk1 regulating S-phase progression, G2/M arrest, and replication fork stabilization',
        reference: [
            'https://www.sciencedirect.com/science/article/abs/pii/S1568786404001041'
        ]
    },
    {
        name: 'RAD9',
        function: 'It is part of the 9-1-1 complex that initiates ATR-mediated phosphorylation and activation of Chk1 regulating S-phase progression, G2/M arrest, and replication fork stabilization',
        reference: [
            'https://academic.oup.com/jmcb/article/3/1/39/907432'
        ]
    },
    {
        name: 'TopBP1',
        function: 'TopBP1 recruits and stabilizes the 9-1-1/ATR machinery on the DNA damage site and directly stimulate the kinase activity of ATR.',
        reference: [
            'https://www.sciencedirect.com/science/article/abs/pii/B9780123808882000030?via%3Dihub',
            'https://www.sciencedirect.com/science/article/abs/pii/S1568786414001633?via%3Dihub'
        ]
    },
    {
        name: 'LIG1',
        function: '',
        reference: []
    },
    {
        name: 'FEN1',
        function: 'FEN1 repairs of Topoisomerase II deficiencies on Top2 - ssDNA complex',
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/26581212/',
        ]
    },
    {
        name: 'Polß',
        function: '',
        reference: []
    },
    {
        name: 'Polδ',
        function: '',
        reference: []
    },
    {
        name: 'Pol ε',
        function: '',
        reference: []
    },
    {
        name: '53BP1',
        preview:"53BP1 (P53-binding protein 1) is a crucial player in the DNA damage response (DDR), particularly in the repair of double-strand breaks (DSBs). It has a range of functions that contribute to genomic stability and the appropriate response to DNA damage. The following are the prominent roles and functions of 53BP1 in DNA repair:",
        function: `53BP1 (P53-binding protein 1) is a crucial player in the DNA damage response (DDR), particularly in the repair of double-strand breaks (DSBs). It has a range of functions that contribute to genomic stability and the appropriate response to DNA damage. The following are the prominent roles and functions of 53BP1 in DNA repair:
        <ul>
        <li><strong>DNA Damage Sensing:</strong> 53BP1 is among the early proteins recruited to sites of DNA damage. It recognizes and binds to the phosphorylated form of H2AX (γ-H2AX), a histone variant phosphorylated at double-strand break sites. This ability to localize to DNA damage sites helps to recruit other proteins involved in DNA repair and signaling.
        </li>
        <li><strong>Non-Homologous End Joining (NHEJ):</strong> 53BP1 significantly promotes NHEJ, a pathway to repair double-strand breaks, especially in the G1 phase of the cell cycle when a homologous template is unavailable. 53BP1 promotes this pathway by: 
        <ul style="margin-left: 15px; margin-top:8px">
        <li><strong>Facilitating Synapsis:</strong> 53BP1 helps align the broken DNA ends to prepare them for ligation by the NHEJ machinery.
        </li>
        <li><strong>Restricting End Resection:</strong> By inhibiting the end resection process (a step in homologous recombination), 53BP1 ensures that NHEJ remains the primary repair mechanism for DSBs in cells not in the S or G2 phase.
        </li>
        </ul>
        </li>
        <li><strong>Interaction with Other DNA Repair Proteins:</strong> 53BP1 interacts with other proteins to promote NHEJ, such as RIF1 (Rap1 Interacting Factor 1) and the Shieldin complex. These interactions help determine the repair pathway choice, favoring NHEJ over homologous recombination when appropriate.
        </li>
        <li><strong>DNA Damage Signaling:</strong> 53BP1 is involved in amplifying DNA damage signaling, contributing to the recruitment of additional repair proteins and activating cell cycle checkpoints. This signaling function helps maintain genomic stability by ensuring that cells do not progress through the cell cycle with unrepaired DNA damage.
        </li>
        <li><strong>Tumor Suppression and Genome Stability:</strong> 53BP1 has tumor suppressive properties, given its role in promoting accurate repair and maintaining genomic stability. Its ability to ensure proper repair of DSBs and its interaction with other DDR proteins make it an essential player in preventing mutations and chromosomal aberrations that can lead to cancer.</li>
        </ul>
        In summary, 53BP1 is a multifaceted protein in the DNA damage response, promoting non-homologous end joining, facilitating DNA damage signaling, and contributing to genomic stability. Its interactions with other DNA repair proteins and its role in regulating the choice of repair pathways are crucial for maintaining cellular integrity and preventing tumorigenesis.
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2150674/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3993578/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5065530/'
        ]
    },
    {
        name: 'ATM',
        preview:"ATM (ataxia-telangiectasia mutated) plays a crucial role in the homologous recombination (HR) pathway, an essential mechanism in the repair of DNA double-strand breaks (DSBs).",
        function: `<ul>
        <li>ATM (ataxia-telangiectasia mutated) plays a crucial role in the homologous recombination (HR) pathway, an essential mechanism in the repair of DNA double-strand breaks (DSBs).</li>
        <li>ATM is a serine/threonine protein kinase activated in response to DSBs. Once activated, ATM phosphorylates numerous downstream targets, including proteins in DNA repair pathways such as HR.  In the context of HR, ATM promotes the repair process by phosphorylating key proteins involved in DNA repair, such as BRCA1 (Breast Cancer 1) and RAD51.</li>
        <li>BRCA1 is known to facilitate the loading of RAD51 onto single-stranded DNA at the site of DSBs, which is a crucial step in the HR pathway.  ATM-mediated phosphorylation of BRCA1 helps in recruiting and stabilizing RAD51 at the sites of DNA damage, thereby facilitating the HR repair process.  ATM also plays a critical role in cell cycle checkpoint control, leading to the initiation of cell cycle checkpoints, which temporarily halt cell cycle progression to allow time for DNA repair.  Additionally, ATM activation can trigger the apoptotic cell death pathway, eliminating severely damaged DNA and reducing the propagation of genetic abnormality.</li>
        <li>Overall, ATM serves as a critical guardian of genome integrity, ensuring the maintenance of genomic stability and preventing the accumulation of mutations that could lead to various diseases, including cancer.</li>
        </ul>
        `,
        reference: [
            'https://www.hindawi.com/journals/bmri/2020/4834965/',
            'https://www.nature.com/articles/4400874',
            'https://pubmed.ncbi.nlm.nih.gov/24003211/'
        ]
    },
    {
        name: '53BP1.p',
        function: '',
        reference: []
    },
    {
        name: 'PTIP',
        function: 'PTIP Regulates 53BP1 locallization on the damage site and promotes phosphorylation of SMC1 by ATM',
        reference: [
            'https://academic.oup.com/abbs/article/48/7/641/2236638'
        ]
    },
    {
        name: 'Artemis (SNM1C)',
        function: 'It leads the DNA end-processing in the non homologous end joining',
        reference: [
            'https://www.embopress.org/doi/full/10.1038/embor.2013.99',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8450076/'
        ]
    },
    {
        name: 'Artemis',
        function: 'It leads the DNA end-processing in the non homologous end joining',
        reference: [
            'https://www.embopress.org/doi/full/10.1038/embor.2013.99',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8450076/'
        ]
    },
    {
        name: 'RIF1',
        function: `Rap1-interacting factor 1. RIF1 recruits the PP1 protein phosphatase to replication origins where PP1 antagonizes the phosphorylation, and activation, of replicative helicase components such as MCM4
        53BP1 recruits RIF1 at the site and where RIF1 binds to 53BP1 on phosphorylated epitopes`,
        reference: [
            'https://www.nature.com/articles/s41467-019-11246-1',
            'https://pubmed.ncbi.nlm.nih.gov/35216668/'
        ]
    },
    {
        name: 'ASF1',
        function: "Anti-silencing function 1. It is a chaperone of histones H3/H4 that assembles or disassembles chromatin during transcription, replication, and repair",
        reference: [
            'https://www.nature.com/articles/s41467-022-28588-y.pdf'
        ]
    },
    {
        name: 'REV7',
        function: `REV7 or Mad2L2 (mitotic arrest-deficient) protein modulates ubiquitin E3 ligase activity in spindle formation and correct chromatin segregation
        It is a non-catalytic subunit of the translesion synthesis (TLS) DNA polymerase Zeta and binds the DNA to facilitate DNA synthesis. Shielding Complex (20orf196 (SHLD1), FAM35A (SHLD2), and FLJ26957 (SHLD3)) provides stability to the polymerase machienry and specifity for binding at the DNA damage site
        
         It inhibits end resection leading to  NHEJ pathway. It doesn’t directly interact with 53BP1/RIF1 complex`,
        reference: [
            'https://www.nature.com/articles/s41556-019-0442-y/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6989217/'
        ]
    },
    {
        name: 'TRIP13',
        function: 'TRIP13 promotes HR by catalyzing conformational change on REV7-Shieldin complex which inactivates it and dissociates from pausing NHEJ propagation machienry. Similarly, it also disassembles the REV7-REV3 translesion synthesis (TLS) complex which is a component of the Fanconi anaemia pathway thus inhibiting error-prone replicative lesion bypass and interstrand crosslink repair',
        reference: [
            'https://www.nature.com/articles/s41556-019-0442-y/'
        ]
    },
    {
        name: 'RIF.p',
        function: '',
        reference: []
    },
    {
        name: 'Ku70',
        function: 'Along with its heterodimeric partner Ku80, it recruits DNA-dependent protein kinase (DNA-PKcs) and DNA end processing protein Artemis on the site of DNA damage',
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8566972/'
        ]
    },
    {
        name: 'DNA-PKcs',
        function: 'DNA-PKcs is auto-phosphorylated and phosphorylated by ATM at multiple sites that activates this catalytic subunit of the Ku70/Ku80 holozyme.',
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/33424929/'
        ]
    },
    {
        name: 'Artemis.p',
        function: 'It leads the DNA end-processing in the non homologous end joining',
        reference: [
            'https://www.embopress.org/doi/full/10.1038/embor.2013.99',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8450076/'
        ]
    },
    {
        name: 'SUMO1',
        function: 'small ubiquitin-like modifier 1. It regulates the RNF4 activity on RAP80 for recruitment of ccdc98 and BRCA1 to BRCA1-A complex',
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8161396/'
        ]
    },
    {
        name: 'XLF',
        function: 'XLF form a flexible scaffold that holds the broken DNA ends together for efficient movements of Ku70/Ku80 Complex along with polymerases and Ligases',
        reference: 'https://pubmed.ncbi.nlm.nih.gov/37503201/'
    },
    {
        name: 'XRCC4',
        function: 'X-ray repair cross-complementing protein 4. It interacts with Ku70/ Ku80 complex and act as a crucial scaffold protein between Ku70/Ku80 complex and DNA ligase IV',
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9921312/'
        ]
    },
    {
        name: 'LigaseIV',
        function: '',
        reference: []
    },
    {
        name: 'Pol μ',
        function: '',
        reference: []
    },
    {
        name: 'Pol λ',
        function: '',
        reference: []
    },
    {
        name: 'MRE11',
        preview:`MRE11 plays several crucial roles in DNA repair processes, particularly in recognizing, signaling, and repairing DNA double-strand breaks (DSBs). It is a vital component of the MRN (MRE11-RAD50-NBS1) complex that recognizes DSBs, activating the DNA repair process. MRE11 endonuclease activity allows for cleavage of DNA near the site of DSBs. Additionally, MRE11 is involved in the initial steps of end resection, generating a 3' single-stranded DNA (ssDNA) overhang at the site of DSBs. This end resection is essential to initiating Homologous Recombination (HR), providing for Rad51-mediated strand invasion. MRE11 also activates the ATM (ataxia-telangiectasia mutated) kinase, triggering downstream events culminating in cell cycle arrest, DNA repair, or apoptosis in response to DSBs.  MRE11 and is also a key factor in telomere maintenance and is critical for ensuring the fidelity of DNA repair processes and maintaining genome integrity.`,
        function: `MRE11 plays several crucial roles in DNA repair processes, particularly in recognizing, signaling, and repairing DNA double-strand breaks (DSBs). It is a vital component of the MRN (MRE11-RAD50-NBS1) complex that recognizes DSBs, activating the DNA repair process. MRE11 endonuclease activity allows for cleavage of DNA near the site of DSBs. Additionally, MRE11 is involved in the initial steps of end resection, generating a 3' single-stranded DNA (ssDNA) overhang at the site of DSBs. This end resection is essential to initiating Homologous Recombination (HR), providing for Rad51-mediated strand invasion. MRE11 also activates the ATM (ataxia-telangiectasia mutated) kinase, triggering downstream events culminating in cell cycle arrest, DNA repair, or apoptosis in response to DSBs.  MRE11 and is also a key factor in telomere maintenance and is critical for ensuring the fidelity of DNA repair processes and maintaining genome integrity.`,
        reference: [
            'https://www.sciencedirect.com/science/article/pii/S1568786420301178',
        ]
    },
    {
        name: 'NBS1 (P95)',
        preview:`NBS1 (Nijmegen breakage syndrome 1) plays a crucial part in the cellular response to DNA double-strand breaks in both the Homologous Recombination (HR) and Non-Homologous End Joining (NHEJ) pathways and is a component of the MRE11/RAD50/NBS1 complex (MRN). NBS1 acts as a sensor for DNA double-strand breaks, and via its interaction with γH2AX, it recruits MRE11 and RAD50 to the site of damage, processing the DNA ends, preparing them for repair, and facilitating the recruitment of other repair factors. NSB1 also plays a role in coordinating the cellular response to DNA damage with the cell cycle. It interacts with various cell cycle checkpoint proteins, such as ATM (ataxia-telangiectasia mutated), to halt the cell cycle progression, allowing time for DNA repair. NBS1 also contributes to the maintenance of telomeres and participates in detecting and repairing damage at telomeres, helping to prevent genomic instability associated with telomere dysfunction. Dysfunction or mutations in NBS1 can lead to genomic instability and predispose individuals to cancer and other genetic disorders.`,
        function: `NBS1 (Nijmegen breakage syndrome 1) plays a crucial part in the cellular response to DNA double-strand breaks in both the Homologous Recombination (HR) and Non-Homologous End Joining (NHEJ) pathways and is a component of the MRE11/RAD50/NBS1 complex (MRN). NBS1 acts as a sensor for DNA double-strand breaks, and via its interaction with γH2AX, it recruits MRE11 and RAD50 to the site of damage, processing the DNA ends, preparing them for repair, and facilitating the recruitment of other repair factors. NSB1 also plays a role in coordinating the cellular response to DNA damage with the cell cycle. It interacts with various cell cycle checkpoint proteins, such as ATM (ataxia-telangiectasia mutated), to halt the cell cycle progression, allowing time for DNA repair. NBS1 also contributes to the maintenance of telomeres and participates in detecting and repairing damage at telomeres, helping to prevent genomic instability associated with telomere dysfunction. Dysfunction or mutations in NBS1 can lead to genomic instability and predispose individuals to cancer and other genetic disorders.`,
        reference: [
            'https://molecular-cancer.biomedcentral.com/articles/10.1186/s12943-019-1100-5',
            'https://www.nature.com/articles/s41580-021-00394-2',
            'https://www.nature.com/articles/7310007'
        ]
    },
    {
        name: 'RAD50',
        preview:"Rad50 recognizes and processes double-strand breaks during DNA repair. It is a component of the MRN complex (Mre11-Rad50-Nbs1), which is crucial for identifying and processing DNA double-strand breaks. Rad50 also provides tethering and bridging of DNA ends. In its homodimeric state, Rad50 contains an ATPase domain at its ends, facilitating the alignment of broken DNA strands. Rad50 is also associated with telomere maintenance. Dysregulation or mutations in Rad50 or the MRN complex can lead to genomic instability, increased susceptibility to cancer, and other genetic disorders.",
        function: `Rad50 recognizes and processes double-strand breaks during DNA repair. It is a component of the MRN complex (Mre11-Rad50-Nbs1), which is crucial for identifying and processing DNA double-strand breaks. Rad50 also provides tethering and bridging of DNA ends. In its homodimeric state, Rad50 contains an ATPase domain at its ends, facilitating the alignment of broken DNA strands. Rad50 is also associated with telomere maintenance. Dysregulation or mutations in Rad50 or the MRN complex can lead to genomic instability, increased susceptibility to cancer, and other genetic disorders.`,
        reference: [
            'https://www.nature.com/articles/s41467-022-29841-0',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4494100/',
            'https://molecular-cancer.biomedcentral.com/articles/10.1186/s12943-019-1100-5'
        ]
    },
    {
        name: 'WRN',
        preview:"The WRN (Werner syndrome ATP-dependent helicase) protein is a member of the RecQ family of DNA helicases, and it plays a crucial role in DNA repair processes, particularly in maintaining genomic stability and preserving the integrity of the genome. The primary protein functions of WRN in DNA repair include:",
        function: `
        The WRN (Werner syndrome ATP-dependent helicase) protein is a member of the RecQ family of DNA helicases, and it plays a crucial role in DNA repair processes, particularly in maintaining genomic stability and preserving the integrity of the genome. The primary protein functions of WRN in DNA repair include:
        <ol style="margin-left:20px">
        <li>
        <strong>DNA Helicase Activity:</strong> WRN functions as a DNA helicase, utilizing the energy from ATP hydrolysis to unwind DNA duplexes in an ATP-dependent manner. Its helicase activity is important for various DNA metabolic processes, including DNA replication, repair, and recombination.
        </li>
        <li><strong>Resolution of DNA Structures:</strong> WRN is involved in the resolution of various DNA structures, including Holliday junctions, which are intermediates in homologous recombination and other DNA repair pathways. WRN's helicase activity promotes the branch migration and resolution of Holliday junctions, facilitating proper DNA repair and preventing the accumulation of DNA damage.
        </li>
        <li><strong>Role in Homologous Recombination (HR):</strong> WRN participates in the homologous recombination (HR) pathway, which is crucial for the repair of DNA double-strand breaks (DSBs) and the maintenance of genomic stability. WRN promotes the unwinding of DNA structures and facilitates strand exchange during HR, thereby promoting the efficient and accurate repair of DNA breaks.</li>
        <li><strong>DNA End Resection:</strong> WRN is involved in the process of DNA end resection, which generates single-stranded DNA (ssDNA) overhangs at DNA break sites and facilitates the initiation of homologous recombination and other DNA repair pathways. WRN's helicase activity helps to unwind DNA duplexes, exposing ssDNA regions for subsequent processing and repair.</li>
        <li><strong>Telomere Maintenance:</strong> WRN plays a role in the maintenance of telomeres, the protective structures at the ends of chromosomes. It helps to resolve telomeric DNA structures and prevent the formation of aberrant DNA structures that can lead to telomere dysfunction and genomic instability. WRN's involvement in telomere maintenance contributes to the regulation of cellular senescence and aging processes.</li>
        </ol>
        Overall, the DNA repair function of WRN is essential for maintaining genomic stability and preventing the accumulation of mutations that can lead to cancer and other genetic disorders. Mutations in the WRN gene are associated with Werner syndrome, a rare genetic disorder characterized by premature aging, genomic instability, and an increased risk of cancer.
        <p>The WRN (Werner syndrome ATP-dependent helicase) and BLM (Bloom syndrome protein) proteins are both members of the RecQ family of DNA helicases, and they are involved in similar DNA metabolic processes, including DNA repair and maintenance of genomic stability. While WRN and BLM can function independently, they can also interact and form a protein complex, known as the WRN-BLM complex or the WRN-BLM helicase complex. Here are some key aspects of the WRN-BLM protein complex:
        </p>
        <ol>
        <li><strong>Physical Interaction:</strong> WRN and BLM can physically interact with each other through protein-protein interactions. The interaction between WRN and BLM has been demonstrated in biochemical studies and co-immunoprecipitation experiments, indicating that these two proteins can form a stable complex in cells.</li>
        <li><strong>Functional Synergy:</strong> The WRN-BLM complex exhibits functional synergy in various DNA repair processes. Both WRN and BLM are DNA helicases with 3' to 5' DNA unwinding activity, and they share similar roles in DNA repair pathways such as homologous recombination (HR) and telomere maintenance. The WRN-BLM complex may enhance the efficiency and fidelity of DNA repair by coordinating their activities and facilitating the processing of DNA intermediates.</li>
        <li><strong>Role in DNA Repair:</strong> The WRN-BLM complex is involved in the repair of DNA double-strand breaks (DSBs) and other forms of DNA damage. It participates in homologous recombination (HR) and non-homologous end joining (NHEJ) pathways, which are critical for repairing DSBs and maintaining genomic stability. The complex may promote the unwinding of DNA structures and facilitate strand exchange during HR, as well as help to process DNA ends and promote end joining during NHEJ.
        </li>
        <li><strong>Telomere Maintenance:</strong> The WRN-BLM complex is also implicated in the maintenance of telomeres, the protective structures at the ends of chromosomes. Both WRN and BLM play roles in resolving telomeric DNA structures and preventing the formation of aberrant DNA structures that can lead to telomere dysfunction. The complex may contribute to the regulation of telomere length and stability, thereby influencing cellular senescence and aging processes.</li>
        </ol>
        Overall, the WRN-BLM protein complex represents a collaborative effort between two DNA helicases with complementary functions in DNA repair and maintenance of genomic stability. The interaction and functional synergy between WRN and BLM contribute to the efficient and accurate repair of DNA damage, as well as the preservation of telomere integrity and genomic stability.
        `,
        reference: []
    },
    {
        name: 'BLM',
        preview:`The BLM (Bloom syndrome protein) is a DNA helicase that plays an essential role in maintaining genomic stability and preserving the integrity of the genome. The primary protein function of BLM in DNA repair includes helicase activity, Homologous Recombination (HR), crossover formation suppression, resolution of DNA structures, and telomere maintenance. BLM is a component of the RecQ family of DNA helicases, enzymes that unwind DNA duplexes in an ATP-dependent manner. BLM possesses 3' to 5' DNA helicase activity, meaning it unwinds DNA in the direction from the 3' to the 5' end. This helicase activity allows BLM to unwind DNA structures during various DNA metabolic processes, including DNA replication, repair, and recombination. BLM plays a crucial role in promoting and regulating homologous recombination and repair of double-strand breaks (DSBs) and maintains genomic stability. BLM participates in the early steps of homologous recombination by unwinding DNA structures, such as displacement loops (D-loops) formed during strand invasion, and by processing recombination intermediates. Its helicase activity facilitates the resolution of recombination intermediates and promotes the efficient and accurate repair of DNA breaks through HR.`,
        function: `
        <p>The BLM (Bloom syndrome protein) is a DNA helicase that plays an essential role in maintaining genomic stability and preserving the integrity of the genome. The primary protein function of BLM in DNA repair includes helicase activity, Homologous Recombination (HR), crossover formation suppression, resolution of DNA structures, and telomere maintenance. BLM is a component of the RecQ family of DNA helicases, enzymes that unwind DNA duplexes in an ATP-dependent manner. BLM possesses 3' to 5' DNA helicase activity, meaning it unwinds DNA in the direction from the 3' to the 5' end. This helicase activity allows BLM to unwind DNA structures during various DNA metabolic processes, including DNA replication, repair, and recombination. BLM plays a crucial role in promoting and regulating homologous recombination and repair of double-strand breaks (DSBs) and maintains genomic stability. BLM participates in the early steps of homologous recombination by unwinding DNA structures, such as displacement loops (D-loops) formed during strand invasion, and by processing recombination intermediates. Its helicase activity facilitates the resolution of recombination intermediates and promotes the efficient and accurate repair of DNA breaks through HR.</p>
        <p>BLM functions to suppress the formation of crossover events during homologous recombination. Crossovers can lead to genomic rearrangements and loss of heterozygosity, which can contribute to genomic instability and cancer development. By promoting non-crossover outcomes and preventing excessive crossover formation, BLM helps to maintain genomic integrity. BLM is involved in the resolution of various DNA structures, including Holliday junctions, which are intermediates in homologous recombination and other DNA repair processes. BLM's helicase activity promotes the branch migration and resolution of Holliday junctions, ensuring proper DNA repair and preventing the accumulation of DNA damage. BLM is also involved in telomere maintenance, helping to resolve telomeric DNA structures and preventing the formation of aberrant DNA structures that can lead to telomere dysfunction and genomic instability.</p>
        <p>BLM is essential for maintaining genomic stability and stopping the assemblage of mutations that can lead to cancer and other genetic disorders. Dysregulation or mutations in the BLM gene are associated with Bloom syndrome, a rare genetic disorder characterized by genomic instability, increased cancer susceptibility, and premature aging.
        </p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/23543275/',
            'https://pubmed.ncbi.nlm.nih.gov/28912125/',
            'https://pubmed.ncbi.nlm.nih.gov/25084169/'
        ]
    },
    {
        name: 'BRCA1',
        preview:"BRCA1 (Breast Cancer 1) is a protein that plays a crucial role in maintaining the genome's stability and preventing cancer development. BRCA1's primary functions include DNA repair, particularly double-strand breaks that provide function in the homologous recombination pathway.",
        function: `
        <p>BRCA1 (Breast Cancer 1) is a protein that plays a crucial role in maintaining the genome's stability and preventing cancer development. BRCA1's primary functions include DNA repair, particularly double-strand breaks that provide function in the homologous recombination pathway.</p>
        <p>BRCA 1 also significantly regulates the cell cycle, ensuring cells progress through each phase appropriately. It interacts with various proteins involved in cell cycle checkpoints, helping to prevent cells with damaged DNA from dividing. Additionally, BRCA1 acts as a transcriptional co-regulator, influencing the expression of genes involved in various cellular processes, including DNA repair, cell cycle control, and apoptosis. Finally, BRCA 1 is involved in modifying the structure of chromatin (chromatin remodeling); this activity helps regulate gene expression and DNA repair processes.</p>
        <p>BRCA1 interacts with numerous proteins, forming complexes involved in the aforementioned cellular processes (DNA repair, cell cycle regulation, transcriptional regulation, and chromatin remodeling). Some fundamental protein-protein interactions of BRCA1 include:</p>
        <ol>
       <li><strong>BRCA2:</strong> BRCA1 forms a complex with BRCA2, which plays a crucial role in homologous recombination-mediated DNA double-strand break repair.</li>
<li><strong>PALB2 (Partner and Localizer of BRCA2):</strong> PALB2 binds to both BRCA1 and BRCA2, facilitating the recruitment of BRCA1-BRCA2 complexes to sites of DNA damage and promoting homologous recombination-mediated repair.</li>
<li><strong>RAD51:</strong> BRCA1 interacts with RAD51, an essential protein involved in homologous recombination. This interaction promotes the loading of RAD51 onto single-stranded DNA at sites of DNA damage, facilitating the repair process.</li>
<li><strong>BARD1 (BRCA1-Associated RING Domain protein 1):</strong> BARD1 forms a heterodimeric complex with BRCA1, and together they possess E3 ubiquitin ligase activity. This complex plays a role in DNA repair and cell cycle regulation.</li>
<li><strong>CtIP (CtBP-interacting protein):</strong> BRCA1 interacts with CtIP, which is involved in the initial processing of DNA double-strand breaks to generate single-stranded DNA ends, a crucial step in homologous recombination-mediated repair.</li>
<li><strong>ATM (Ataxia Telangiectasia Mutated):</strong> BRCA1 interacts with ATM, a kinase that plays a central role in the DNA damage response pathway. This interaction is important for activating ATM-mediated signaling in response to DNA damage.</li>
<li><strong>p53:</strong> BRCA1 interacts with the tumor suppressor protein p53 and participates in regulating p53-mediated transcriptional responses to DNA damage.
        </ol>
In summary, BRCA1 is essential for maintaining genomic stability and preventing the accumulation of mutations that can lead to cancer development. Mutations in the BRCA1 gene are associated with an increased risk of breast, ovarian, and other cancers, as they impair the protein's ability to carry out its normal functions.
        `,
        reference: [
            'https://link.springer.com/article/10.1007/s42764-021-00042-1',
            'https://www.frontiersin.org/articles/10.3389/fcell.2022.813457/full',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8763022/',
            'https://pubmed.ncbi.nlm.nih.gov/16357146/',
            'https://pubmed.ncbi.nlm.nih.gov/10550055/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4917318/'
        ]
    },
    {
        name: 'CtIP (RBBP8)',
        preview:"RBBP8, commonly known as CtIP (C-terminal Binding Protein-Interacting Protein), is a multifunctional protein with essential roles in DNA repair, cell cycle regulation, and transcriptional control. Here's an overview of its functions and significance:",
        function: `
        RBBP8, commonly known as CtIP (C-terminal Binding Protein-Interacting Protein), is a multifunctional protein with essential roles in DNA repair, cell cycle regulation, and transcriptional control. Here's an overview of its functions and significance:
        <ul>
        <li><strong>Role in DNA Repair:</strong> CtIP is best known for its role in the homologous recombination (HR) pathway, particularly in DNA double-strand break (DSB) resection. It interacts with the MRN complex (MRE11, RAD50, NBS1) to initiate end resection, creating single-stranded DNA (ssDNA) overhangs. This step is crucial for downstream HR processes, where RAD51 and other proteins use the ssDNA to find homologous sequences, leading to accurate DNA repair.</li>
        <li><strong>Interaction with BRCA1:</strong> CtIP interacts with BRCA1, a significant tumor suppressor gene implicated in HR and DNA damage response. This interaction supports the coordinated processing of DSBs and efficient repair, contributing to genomic stability.</li>
        <li><strong>Function in Cell Cycle Regulation:</strong> CtIP controls the G1/S transition of the cell cycle, as DNA repair is linked with cell cycle phases. It helps ensure that cells with DNA damage do not progress through the cell cycle without proper repair, preventing the propagation of damaged DNA.</li>
        <li><strong>Interaction with CtBP:</strong> CtIP interacts with CtBP (C-terminal Binding Protein), known for its role in transcriptional repression. This interaction indicates CtIP's involvement in regulatory networks that control gene expression and chromatin structure. It suggests a role for CtIP in broader cellular contexts beyond DNA repair.</li>
        <li><strong>Implications in Cancer:</strong> Due to its role in DNA repair and cell cycle control, mutations or dysregulations of CtIP are associated with increased cancer risk. Defects in homologous recombination, where CtIP plays a key role, are often linked to genomic instability and cancer development.</li>
        </ul>
        Thus, CtIP (RBBP8) is a versatile protein with significant functions in DNA repair, cell cycle regulation, and transcriptional control. Its interactions with major DNA repair proteins like BRCA1 and its involvement in the HR pathway make it a critical factor in maintaining genomic stability and understanding cancer biology.
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/15084581/',
            'https://www.sciencedirect.com/science/article/pii/S1084952119300953',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4884948/',
            'https://pubmed.ncbi.nlm.nih.gov/9738006/'
        ]
    },
    {
        name: 'RAD51',
        preview:"Rad51 is a critical protein in the homologous recombination (HR) process, which is a crucial mechanism for repairing DNA double-strand breaks (DSBs) and maintaining genomic stability. Rad51 foci formation in response to DNA damage is a hallmark of the cellular DNA repair process, particularly Homologous Recombination (HR). When cells encounter DNA Double-Strand Breaks (DSBs) or other forms of DNA damage, Rad51 proteins assemble into discrete nuclear foci at the damage sites. Localization and visualization of Rad51 aggregate via immunofluorescent applications allow for the study of Rad51 response kinetics in response to damage, thereby making the study of Rad51 foci formation critical to the study of the cellular response to DNA damage and how it plays a crucial role in the repair process. Rad51 is essential to recognizing DNA damage, activating the Homologous Recombination (HR) pathway, Rad51 filament formation, and DNA repair and resolution.",
        function: `
        Rad51 is a critical protein in the homologous recombination (HR) process, which is a crucial mechanism for repairing DNA double-strand breaks (DSBs) and maintaining genomic stability. Rad51 foci formation in response to DNA damage is a hallmark of the cellular DNA repair process, particularly Homologous Recombination (HR). When cells encounter DNA Double-Strand Breaks (DSBs) or other forms of DNA damage, Rad51 proteins assemble into discrete nuclear foci at the damage sites. Localization and visualization of Rad51 aggregate via immunofluorescent applications allow for the study of Rad51 response kinetics in response to damage, thereby making the study of Rad51 foci formation critical to the study of the cellular response to DNA damage and how it plays a crucial role in the repair process. Rad51 is essential to recognizing DNA damage, activating the Homologous Recombination (HR) pathway, Rad51 filament formation, and DNA repair and resolution.
        <ul>
        <li><strong>Recognition of DNA Damage:</strong> Various cellular signaling pathways detect DNA damage, such as DSBs induced by ionizing radiation, reactive oxygen species, or chemotherapeutic agents. These pathways activate DNA damage response proteins, including ATM (ataxia telangiectasia mutated) and ATR (ataxia telangiectasia and Rad3-related).</li>
<li><strong>Activation of Homologous Recombination (HR) Pathway:</strong> In response to DSBs, the DNA damage response kinases ATM and ATR phosphorylate critical proteins involved in HR, such as BRCA1 and BRCA2. This activation leads to the recruitment of these proteins to the sites of DNA damage, where they facilitate the formation of Rad51 nucleoprotein filaments on single-stranded DNA (ssDNA) exposed by the DSBs.</li>
<li><strong>Rad51 Filament Formation:</strong> Rad51 proteins polymerize on the exposed ssDNA to form nucleoprotein filaments. These filaments serve as a platform for invading the damaged DNA strand into an intact homologous DNA duplex, initiating the homology search and DNA repair synthesis process.</li>
<li><strong>Formation of Rad51 Foci:</strong> The assembly of Rad51 nucleoprotein filaments results in the formation of discrete Rad51 foci within the nucleus, visualized by immunofluorescence microscopy techniques. These foci represent sites where active homologous recombination repair is occurring.</li>
<li><strong>DNA Repair and Resolution:</strong> The Rad51-coated ssDNA strand invades the intact homologous DNA duplex, forming a DNA repair intermediate known as the Holliday junction. Branch migration and resolution of the Holliday junction ultimately repair the DNA lesion and restore genomic integrity.</li>
</ul>
Overall, Rad51 foci formation serves as a visual indicator of ongoing homologous recombination-mediated DNA repair in response to DNA damage. This process is essential for maintaining genomic stability and preventing the assemblage of mutations that can lead to cancer and other genetic diseases.
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/37097615/',
            'https://pubmed.ncbi.nlm.nih.gov/20299221/',
            'https://pubmed.ncbi.nlm.nih.gov/32938550/',
            'https://pubmed.ncbi.nlm.nih.gov/12359302/',
            'https://pubmed.ncbi.nlm.nih.gov/32663049/',
            'https://pubmed.ncbi.nlm.nih.gov/15372620/'
        ]
    },
    {
        name: 'RAD52',
        preview:"RAD52 (Radiation-sensitive 52) is a crucial protein involved in the homologous recombination (HR) pathway, which provides vital function in repairing DNA double-strand breaks (DSBs) in eukaryotic cells. Here's an overview of the primary functions and significance of RAD52 in HR:",
        function: `
        RAD52 (Radiation-sensitive 52) is a crucial protein involved in the homologous recombination (HR) pathway, which provides vital function in repairing DNA double-strand breaks (DSBs) in eukaryotic cells. Here's an overview of the primary functions and significance of RAD52 in HR:
<ul style="margin-left:15px; list-style-type:disc;">
        <li><strong>Mediator of RAD51 Filament Formation:</strong> RAD52 is known for promoting the formation of RAD51 nucleoprotein filaments on single-stranded DNA (ssDNA). This step is crucial for the HR process, where RAD51, a significant HR protein, binds to ssDNA and facilitates strand invasion to search for homologous sequences on the undamaged sister chromatid.</li>
<li><strong>Annealing of Complementary DNA Strands:</strong> RAD52 has a significant annealing activity, allowing it to align and pair complementary single-stranded DNA sequences. This property is helpful in recombinational DNA repair processes, particularly during the late stages of HR when re-annealing of complementary strands occurs.</li>
<li><strong>Backup Pathway for HR:</strong> RAD52 can compensate for the loss or reduced activity of other HR proteins, such as BRCA1 and BRCA2, in certain situations. While these proteins are crucial for HR, RAD52 has been shown to support alternative recombination pathways, potentially allowing for some degree of repair when the primary HR machinery is compromised.</li>
<li><strong>Interactions with Other DNA Repair Proteins:</strong> RAD52 interacts with various DNA repair proteins, including RAD51, RPA (Replication Protein A), and XRCC3. These interactions facilitate its role in the HR process, contributing to properly coordinating DNA repair events.</li>
<li><strong>Role in Alternative DNA Repair Pathways:</strong> Besides its role in HR, RAD52 has been implicated in alternative pathways like single-strand annealing (SSA). SSA is another mechanism to repair DSBs, particularly in regions with repetitive sequences, where it uses RAD52's annealing ability to join complementary DNA strands.</li>
</ul>
While RAD52 plays a central role in HR and is known for its DNA strand annealing and RAD51 filament mediator activities, its functions are sometimes seen as secondary to those of other HR proteins like BRCA2. Nonetheless, RAD52's role as a potential backup in the HR pathway and its unique functions in annealing makes it a critical component in maintaining genomic stability and exploring new avenues for therapeutic interventions, particularly in cancer treatment.
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6893724/',
            'https://academic.oup.com/nar/article/38/9/2917/3100696',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC125654/',
            'https://pubmed.ncbi.nlm.nih.gov/38081641/'
        ]
    },
    {
        name: 'RAD54',
        preview:"RAD54 is an essential protein involved in the homologous recombination (HR) pathway, a necessary process for repairing DNA double-strand breaks (DSBs) and maintaining genomic stability. Here are the primary functions and roles of RAD54 in HR:",
        function: `
        RAD54 is an essential protein involved in the homologous recombination (HR) pathway, a necessary process for repairing DNA double-strand breaks (DSBs) and maintaining genomic stability. Here are the primary functions and roles of RAD54 in HR:
<ul style="list-style-type:disc; margin-left:15px;">
<li><strong>ATP-Dependent DNA Translocase Activity:</strong> RAD54 is an ATP-dependent DNA translocase that uses ATP hydrolysis energy to move along DNA strands. This property allows RAD54 to alter DNA structure, facilitating different stages of homologous recombination.</li>
<li><strong>RAD51 Interaction and Filament Stabilization:</strong> RAD54 interacts with RAD51, a central protein in HR. It helps to stabilize RAD51 nucleoprotein filaments on single-stranded DNA (ssDNA), enhancing the efficiency of strand invasion—a critical step in HR where the RAD51-coated ssDNA searches for homologous sequences on the intact sister chromatid.</li>
<li><strong>DNA Strand Migration and Branch Migration:</strong> RAD54 can induce DNA strand migration and branch migration, processes essential for HR. These activities assist in resolving DNA recombination intermediates, such as Holliday junctions, allowing proper crossover and repair of DSBs.</li>
<li><strong>Chromatin Remodeling:</strong> RAD54's ATP-dependent translocase activity allows it to alter chromatin structure. This capability helps RAD54 promote access to DNA at damage sites, aiding other HR proteins in their roles and facilitating the resolution of recombination intermediates.</li>
<li><strong>Displacement of Non-Homologous DNA:</strong> RAD54 can displace non-homologous DNA from DNA duplexes, further contributing to the fidelity of homologous recombination by ensuring proper alignment of homologous sequences.</li>
<li><strong>Role in DNA Repair Pathway Coordination:</strong> In addition to its direct role in HR, RAD54 coordinates various steps in DNA repair. Its interactions with other DNA repair proteins help synchronize different aspects of the HR pathway.</li>
</ul>
Given these functions, RAD54 is critical in homologous recombination, ensuring efficient and accurate repair of double-strand breaks. Deficiencies in RAD54 or its dysfunction can lead to genomic instability and increased susceptibility to cancer, making it an essential target for research in DNA repair mechanisms and cancer biology.
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7418177/',
            'https://www.nature.com/articles/s41467-019-12082-z',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8168686/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2447626/',
            'https://www.nature.com/articles/s41467-017-02497-x'
        ]
    },
    {
        name: 'FANCN',
        preview:"FANCN, also known as PALB2 (Partner and Localizer of BRCA2), plays a critical role in DNA repair, particularly in homologous recombination (HR). FANCN is one of the essential components of the Fanconi anemia (FA) pathway and has a unique relationship with the BRCA1/BRCA2 pathway.",
        function: `
        FANCN, also known as PALB2 (Partner and Localizer of BRCA2), plays a critical role in DNA repair, particularly in homologous recombination (HR). FANCN is one of the essential components of the Fanconi anemia (FA) pathway and has a unique relationship with the BRCA1/BRCA2 pathway.
        <ul>
        <li><strong>BRCA1 and BRCA2 Interaction:</strong> FANCN/PALB2 is a bridge between BRCA1 and BRCA2, two critical proteins involved in DNA repair. It binds to BRCA1 and BRCA2, facilitating the formation of the BRCA1-PALB2-BRCA2 axis, which is crucial for the proper functioning of homologous recombination.</li>
        <li><strong>Stabilization and Localization of BRCA2:</strong> A key function of FANCN/PALB2 is to stabilize BRCA2 and help localize it to DNA damage sites. This interaction is crucial as it ensures that BRCA2 is correctly positioned to participate in homologous recombination. Without PALB2, BRCA2's stability and DNA binding are compromised, highlighting the significance of this protein in DNA repair.</li>
        <li><strong>Homologous Recombination (HR) Facilitation:</strong> FANCN/PALB2 plays a central role in HR by facilitating the interaction between BRCA1 and BRCA2. BRCA2 is directly involved in recruiting the Rad51 recombinase to sites of DNA damage, where it mediates strand invasion, a critical step in HR. PALB2 ensures that this process occurs effectively.</li>
        <li><strong>Role in the Fanconi Anemia (FA) Pathway:</strong> As part of the FA pathway, FANCN/PALB2 contributes to the repair of DNA interstrand crosslinks, which require a combination of nucleotide excision repair (NER), homologous recombination, and other pathways. Deficiencies in the FA pathway lead to Fanconi anemia, characterized by increased cancer risk and congenital abnormalities.</li>
        <li><strong>Maintenance of Genomic Stability:</strong> FANCN's role in HR and the FA pathway, FANCN/PALB2 contributes to genomic stability. Proper functioning of this protein helps ensure that DNA damage is repaired efficiently, reducing the risk of mutations, chromosomal aberrations, and cancer development.</li>
        <li><strong>Tumor Suppression:</strong> FANCN/PALB2 has tumor-suppressor functions due to its role in DNA repair. Mutations in PALB2 are connnected with increased risks of breast, ovarian, and pancreatic cancers. PALB2's ability to facilitate homologous recombination and support the FA pathway is crucial in preventing tumorigenesis.</li>
        </ul>
        In summary, FANCN/PALB2's molecular functions in DNA repair include:
        <ul style="margin-left: 15px; margin-top:8px">
        <li>Bridging BRCA1 and BRCA2.</li>
        <li>Stabilizing and localizing BRCA2.</li>
        <li>Facilitating homologous recombination.</li>
        <li>Contributing to the Fanconi anemia pathway.</li>
        <li>Maintaining genomic stability.</li>
        <li>Exhibiting tumor-suppressor properties.</li>
        </ul>
        These roles are critical in preserving DNA integrity and preventing the development of cancers.
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2750839/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6693662/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7142266/'
        ]
    },
    {
        name: 'BRCA2 (FANCD1)',
        preview:"BRCA2, also known as FANCN (Fanconi Anemia Complementation Group N), is a crucial protein with several significant functions in DNA repair, particularly in homologous recombination (HR). Its necessary role in preserving genomic stability and tumor suppression has made it a prominent focus of cancer research. Here's an overview of the primary functions and importance of BRCA2:",
        function: `
        BRCA2, also known as FANCN (Fanconi Anemia Complementation Group N), is a crucial protein with several significant functions in DNA repair, particularly in homologous recombination (HR). Its necessary role in preserving genomic stability and tumor suppression has made it a prominent focus of cancer research. Here's an overview of the primary functions and importance of BRCA2:
        <ul>
        <li><strong>Facilitation of RAD51 Filament Formation:</strong> One of BRCA2's critical roles is in the HR pathway, where it directly interacts with and regulates RAD51, a protein that plays a central role in HR. BRCA2 facilitates the formation of RAD51 nucleoprotein filaments on single-stranded DNA (ssDNA), enabling strand invasion, which is essential for HR.</li>
        <li><strong>Promotion of Homologous Recombination:</strong> BRCA2's interaction with RAD51 ensures the proper search for homologous sequences on the intact sister chromatid, allowing accurate repair of double-strand breaks (DSBs). This process helps maintain genomic stability by enabling error-free repair.</li>
        <li><strong>Role in DNA Damage Response:</strong> BRCA2 is critical in sensing DNA damage and coordinating the cellular response. It is involved in the Fanconi Anemia pathway, which responds to interstrand crosslinks and coordinates various repair mechanisms. BRCA2's function in this context contributes to maintaining DNA integrity.</li>
        <li><strong>Interaction with Other DNA Repair Proteins:</strong> BRCA2 interacts with several proteins implicated in DNA repair and cell cycle regulation. For instance, it works with PALB2 to connect with BRCA1, forming a complex crucial in HR and DNA damage response. This complex is vital for repairing DSBs and maintaining the G2/M checkpoint during the cell cycle.</li>
        <li><strong>Tumor Suppression and Cancer Risk:</strong> Given its role in DNA repair and genomic stability, mutations or dysfunction in BRCA2 are strongly associated with an increased risk of cancers, particularly breast and ovarian cancers. Individuals with harmful mutations in BRCA2 have a significantly elevated risk of developing these and other cancers, underlining its role as a tumor suppressor.
        </li>
        <li><strong>Importance in Cell Cycle Regulation:</strong> BRCA2 has been shown to influence cell cycle checkpoints, ensuring that cells do not move through the cell cycle with unresolved DNA damage. This function helps prevent the propagation of cells with genomic instability.</li>
        </ul>
        Overall, BRCA2's primary function is to ensure accurate and efficient repair of DNA double-strand breaks through homologous recombination, supporting genomic stability and acting as a critical tumor suppressor. Its interactions with other DNA repair proteins, regulation of RAD51, and role in the Fanconi Anemia pathway make it a central player in cellular DNA damage response and cancer prevention.
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/16357146/',
            'https://www.nature.com/articles/ncomms12813',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7142266/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6408219/',
            'https://www.ncbi.nlm.nih.gov/books/NBK1247/',
            'https://www.sciencedirect.com/science/article/pii/S2211124714003258'
        ]
    },
    {
        name: 'FANCJ (BRIP1/BACH1)',
        preview:"FANCJ, also known as BRIP1 or BACH1, plays a pivotal role in DNA repair, particularly in the Fanconi anemia (FA) pathway and homologous recombination (HR). As an ATP-dependent DNA helicase, FANCJ is not just involved, but is a key player in unwinding DNA, a process that is essential for many DNA repair processes.",
        function: `
        FANCJ, also known as BRIP1 or BACH1, plays a pivotal role in DNA repair, particularly in the Fanconi anemia (FA) pathway and homologous recombination (HR). As an ATP-dependent DNA helicase, FANCJ is not just involved, but is a key player in unwinding DNA, a process that is essential for many DNA repair processes.
        <ul>
        <li><strong>DNA Helicase Activity:</strong> FANCJ possesses ATP-dependent helicase activity, allowing it to unwind DNA. This function is crucial for various DNA repair pathways, allowing access to damaged regions for repair proteins and enzymes.</li>
        <li><strong>Interaction with BRCA1:</strong> FANCJ interacts with BRCA1, a central player in DNA repair and homologous recombination. This interaction suggests that FANCJ facilitates the BRCA1-mediated repair processes, contributing to the regulation and stability of the homologous recombination machinery.</li>
        <li><strong>Role in the Fanconi Anemia (FA) Pathway:</strong> FANCJ is part of the FA pathway, which is responsible for repairing DNA interstrand crosslinks (ICLs). The pathway involves a complex network of proteins that detect and repair ICLs, with FANCJ contributing through its helicase activity, allowing access to these DNA lesions.
        </li>
        <li><strong>Resolution of G-Quadruplex Structures:</strong> FANCJ can resolve G-quadruplex (G4) structures in DNA, which can form in regions with high guanine content. These G4 structures can hinder DNA replication and repair, and FANCJ's ability to unwind them is crucial for maintaining DNA stability and preventing genomic instability.
        </li>
        <li><strong>Participation in Homologous Recombination:</strong> Through its interaction with BRCA1 and helicase activity, FANCJ is implicated in homologous recombination. It contributes to processing DNA ends, a critical step in HR, ensuring accurate repair of DNA double-strand breaks.</li>
        <li><strong>Genome Stability and Tumor Suppression:</strong> The functions of FANCJ in DNA repair are not just important, they are crucial for genome stability. Loss of FANCJ function or mutations in the gene are not just associated, but are linked with increased risk of cancer, particularly breast and ovarian cancers. FANCJ's roles in the FA pathway and HR are not just essential, but are vital for maintaining the integrity of the genome and preventing tumorigenesis.
        </li>
        <li><strong>Crosslink Repair:</strong> In addition to its role in the FA pathway, FANCJ is involved in other aspects of crosslink repair. It aids in unwinding DNA to allow repair machinery access to damaged sites. Its helicase activity is essential for this process.</li>
        </ul>
        In summary, FANCJ's molecular functions in DNA repair center on its DNA helicase activity, its interaction with BRCA1, its role in the Fanconi anemia pathway, its ability to resolve G-quadruplex structures, its participation in homologous recombination, its contributions to genome stability, and its involvement in crosslink repair. These functions are critical for maintaining DNA integrity and reducing the risk of genomic instability and cancer.
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2658046/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6226233/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8462899/',
            'https://pubmed.ncbi.nlm.nih.gov/28911102/',
            'https://www.sciencedirect.com/science/article/pii/S0960982208007318'
        ]
    },
    {
        name: 'Ccdc98',
        preview:`CCDC98, also known as "Abraxas" or "FAM175A," plays a critical role in the DNA repair process, particularly in homologous recombination (HR).`,
        function: `
        CCDC98, also known as "Abraxas" or "FAM175A," plays a critical role in the DNA repair process, particularly in homologous recombination (HR).
        <ul>
        <li><strong>Part of the BRCA1-A Complex:</strong> CCDC98 is an essential component of the BRCA1-A complex, a multi-protein complex involved in the DNA damage response and repair by homologous recombination. This complex, sometimes called the BRCA1/BRCC36 complex, consists of other proteins like BRCA1, BRCC36, RAP80, BRCC45, and MERIT40.</li>
        <li><strong>Recruitment to DNA Damage Sites:</strong> CCDC98, through its interactions with other components in the BRCA1-A complex, recruits and localizes the complex to sites of DNA damage. This recruitment is vital for the DNA damage response to be initiated correctly.</li>
        <li><strong>Interacting with BRCA1:</strong> CCDC98 interacts with BRCA1, a key tumor suppressor and critical protein in the DNA repair pathway. The interaction between CCDC98 and BRCA1 helps stabilize the BRCA1-A complex at sites of DNA damage, facilitating proper DNA repair through HR.</li>
        <li><strong>Recognition of Ubiquitin and SUMO Modifications:</strong> CCDC98 interacts with RAP80, which contains a UIM (ubiquitin-interacting motif) and helps recognize ubiquitinated histones at sites of DNA damage. This recognition is essential for recruiting the BRCA1-A complex to DNA double-strand breaks, an initial step in homologous recombination.</li>
        <li><strong>Contribution to Homologous Recombination:</strong> Once the BRCA1-A complex is recruited to sites of DNA damage, it regulates homologous recombination. The presence of BRCA1 within the complex is crucial for promoting HR while suppressing error-prone DNA repair pathways like non-homologous end joining (NHEJ).</li>
        <li><strong>Chromatin Remodeling and Stabilization:</strong> CCDC98's role within the BRCA1-A complex contributes to the regulation of chromatin structure, ensuring that DNA repair proteins have access to the DNA. By stabilizing the complex and assisting in its recruitment to damaged DNA, CCDC98 indirectly supports the chromatin remodeling necessary for efficient homologous recombination.</li>
        </ul>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/17643121/',
            'https://www.sciencedirect.com/science/article/pii/S0021925820434635',
            'https://www.nature.com/articles/ncomms15752',
            'https://pubmed.ncbi.nlm.nih.gov/28569838/'
        ]
    },
    {
        name: 'BRCC36',
        preview:"BRCC36 is a protein with deubiquitinating activity, specifically a member of the JAMM/MPN+ (JAB1/MPN/Mov34 metalloenzyme) family of metalloproteases, and it plays a important role in the DNA repair process, particularly in the context of homologous recombination (HR) and the DNA damage response:",
        function: `
        BRCC36 is a protein with deubiquitinating activity, specifically a member of the JAMM/MPN+ (JAB1/MPN/Mov34 metalloenzyme) family of metalloproteases, and it plays a important role in the DNA repair process, particularly in the context of homologous recombination (HR) and the DNA damage response:
        <ul>
        <li><strong>Part of the BRCA1-A Complex:</strong> BRCC36 is a critical component of the BRCA1-A complex, also known as the RAP80/BRCC36 complex. This complex plays a important role in the DNA damage response by facilitating the repair of double-strand breaks (DSBs) through homologous recombination.</li>
        <li><strong>Deubiquitination Activity:</strong> BRCC36, a deubiquitinating enzyme (DUB) with specificity for K63-linked polyubiquitin chains, is a crucial player in the regulation of protein-protein interactions, protein stability and the assembly/disassembly of protein complexes in the context of DNA repair. Its activity is vital, as it helps remove ubiquitin tags from target proteins, essential for maintaining the integrity of the DNA repair pathway.</li>
        <li><strong>Regulation of BRCA1-A Complex Activity:</strong> Within the BRCA1-A complex, BRCC36's deubiquitination activity contributes to regulating the interaction with ubiquitinated proteins at sites of DNA damage. It helps ensure the correct recruitment and stability of the BRCA1-A complex at double-strand break sites, thus facilitating the DNA damage response.</li>
        <li><strong>Role in DNA Repair Pathway Choice:</strong> BRCC36's deubiquitinating activity can influence the balance between homologous recombination and non-homologous end joining (NHEJ). By removing ubiquitin tags from proteins involved in DNA repair, BRCC36 helps modulate the appropriate DNA repair pathway selection, favoring HR when suitable conditions are present (such as in the S and G2 phases of the cell cycle).</li>
        <li><strong>Coordination with Other BRCA1-A Complex Components:</strong> BRCC36's activity contributes to the regulation of protein turnover and the dynamic assembly of the BRCA1-A complex. It works in concert with other complex members like RAP80, which recognizes ubiquitinated histones at DNA damage sites, to ensure proper recruitment and stabilization of the complex at sites of double-strand breaks.</li>
        </ul>
        In summary, BRCC36 is an important deubiquitinating enzyme within the BRCA1-A complex, responsible for regulating the stability and recruitment of the complex to sites of DNA damage. By removing ubiquitin tags from target proteins, BRCC36 influences the dynamics of the DNA damage response, helping to facilitate homologous recombination and maintain genomic stability. Its role in coordinating the BRCA1-A complex's activity underscores its significance in the DNA repair process and the maintenance of genomic integrity.
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7692841/',
            'https://pubmed.ncbi.nlm.nih.gov/31253574/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4965568/'
        ]
    },
    {
        name: 'BRCC45 (BRE/BABAM2)',
        preview:"BRCC45, also known as BRE, plays several molecular roles in DNA repair processes, particularly in the context of the BRCA1-A complex and homologous recombination (HR).",
        function: `
        BRCC45, also known as BRE, plays several molecular roles in DNA repair processes, particularly in the context of the BRCA1-A complex and homologous recombination (HR).
        <ul>
        <li>Stabilization of the BRCA1-A Complex: BRCC45 contributes to the structural integrity and stability of the BRCA1-A complex. This complex includes proteins like BRCA1, RAP80, BRCC36, and MERIT40. BRCC45's presence helps maintain the complex's integrity, ensuring proper functioning during DNA repair processes.</li>
        <li>Ubiquitin-Binding and Recognition of DNA Damage: BRCC45, along with other components of the BRCA1-A complex, recognizes and binds to ubiquitinated histones at sites of DNA damage. This recognition is crucial for initiating the DNA repair response, particularly through the homologous recombination pathway.
        </li>
        <li>Recruitment of Repair Factors: BRCC45 participates in recruiting DNA repair factors to sites of DNA damage. As part of the BRCA1-A complex, BRCC45 helps recruit BRCA1 and other HR-related proteins to DNA double-strand breaks, facilitating the initiation of the repair process.</li>
        <li>Regulation of BRCA1 Function: BRCC45 indirectly influences the function of BRCA1, a key player in DNA repair and tumor suppression. By stabilizing the BRCA1-A complex and facilitating its recruitment to DNA damage sites, BRCC45 contributes to regulating BRCA1's activity in HR and other DNA repair pathways.</li>
        <li>Coordination with Deubiquitination: The BRCA1-A complex, including BRCC45, removes ubiquitin from specific substrates at DNA damage sites. This deubiquitination process is essential for fine-tuning the DNA repair response and ensuring the proper resolution of DNA damage.</li>
        <li>Maintenance of Genomic Stability: Through its roles in stabilizing the BRCA1-A complex, facilitating DNA repair factor recruitment, and regulating DNA repair processes, BRCC45 contributes to the maintenance of genomic stability. The proper functioning of BRCC45 helps prevent the accumulation of DNA lesions and reduces the risk of genomic instability and cancer development.</li>
        </ul>
        In summary, BRCC45's molecular functions in DNA repair primarily involve participating in the BRCA1-A complex, contributing to complex stability, DNA damage recognition, repair factor recruitment, regulation of BRCA1 function, deubiquitination, and maintenance of genomic stability. These functions collectively ensure an effective and accurate DNA repair response, which is crucial for preserving genome integrity and preventing diseases such as cancer.
        `,
        reference: [
            'https://academic.oup.com/abbs/article/48/7/658/2236652',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7407225/',
            'https://www.nature.com/articles/s41467-018-03020-6'
        ]
    },
    {
        name: 'RAP80',
        preview:"RAP80 (Receptor-Associated Protein 80) is a crucial protein in the DNA damage response. It plays a significant role in repairing DNA double-strand breaks (DSBs), particularly in the context of homologous recombination (HR). RAP80 is a component of the BRCA1-A complex, a subcomplex within the broader BRCA1-related repair machinery. Its ability to recognize ubiquitin chains and contribute to the recruitment of BRCA1 is central to its function in DNA repair.",
        function: `
        RAP80 (Receptor-Associated Protein 80) is a crucial protein in the DNA damage response. It plays a significant role in repairing DNA double-strand breaks (DSBs), particularly in the context of homologous recombination (HR). RAP80 is a component of the BRCA1-A complex, a subcomplex within the broader BRCA1-related repair machinery. Its ability to recognize ubiquitin chains and contribute to the recruitment of BRCA1 is central to its function in DNA repair.
        <ul>
        <li><strong>Ubiquitin-Interacting Motif (UIM):</strong> RAP80 contains ubiquitin-interacting motifs that allow it to bind to K63-linked polyubiquitin chains. This binding capacity enables RAP80 to recognize ubiquitination signals at DNA damage sites, particularly on histones H2A and H2AX.</li>
<li><strong>Recruitment to DNA Damage Sites:</strong> Through its UIMs, RAP80 is recruited to sites of DNA double-strand breaks in response to ubiquitination signals generated by E3 ubiquitin ligases like RNF8 and RNF168. This recruitment is crucial for subsequent events in the DNA repair process.</li>
<li><strong>Assembly of the BRCA1-A Complex:</strong> RAP80 is a vital component of the BRCA1-A complex, along with other proteins like BRCC36, BRCC45, MERIT40, and BRCA1. This complex plays a pivotal role in recognizing ubiquitinated histones and coordinating the recruitment of BRCA1 to DNA damage sites.</li>
<li><strong>Recruitment of BRCA1:</strong> By binding to ubiquitin chains at DSBs, RAP80 facilitates the recruitment of BRCA1, a significant player in the homologous recombination pathway. BRCA1 is responsible for a wide range of functions, including DNA end resection and the promotion of HR.</li>
<li><strong>Support for Homologous Recombination (HR):</strong> RAP80's primary role in DNA repair is to help recruit the BRCA1-A complex to sites of DNA damage, thereby promoting HR. HR is a high-fidelity DNA repair process that uses a homologous template to accurately repair DSBs, reducing the risk of mutations and chromosomal aberrations.</li>
<li><strong>Influence on Non-Homologous End Joining (NHEJ):</strong> Although RAP80 primarily supports HR through its interaction with BRCA1, evidence suggests RAP80 can also impact the choice between HR and NHEJ. By regulating BRCA1's recruitment, RAP80 may indirectly influence whether a cell opts for HR or the more error-prone NHEJ pathway.</li>
<li><strong>Role in Tumor Suppression:</strong> Given its role in recruiting BRCA1 and promoting HR, RAP80 maintains genomic stability. Its proper function helps ensure that DNA double-strand breaks are accurately repaired, reducing the risk of genomic instability and cancer development.</li>
</ul>
RAP80 provides a crucial role in the DNA damage response by recognizing ubiquitination signals at sites of DNA double-strand breaks and facilitating the recruitment of the BRCA1-A complex, particularly BRCA1 itself. RAP80's involvement in the homologous recombination pathway, RAP80 helps ensure accurate DNA damage repair, contributing to genomic stability and tumor suppression. Its ability to interact with and guide essential DNA repair proteins is central to its function in maintaining the integrity of the genome.
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9386975/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC10570032/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5570449/'
        ]
    },
    {
        name: 'SUMO',
        preview:"SUMO (Small Ubiquitin-like Modifier) is a post-translational modification (PTM) that significantly regulates protein function and localization, similar to ubiquitin. In homologous recombination (HR), a high-fidelity DNA repair pathway, SUMOylation has several critical functions:",
        function: `
        SUMO (Small Ubiquitin-like Modifier) is a post-translational modification (PTM) that significantly regulates protein function and localization, similar to ubiquitin. In homologous recombination (HR), a high-fidelity DNA repair pathway, SUMOylation has several critical functions:
        <ul>
        <li><strong>Protein Localization and Dynamics:</strong> SUMOylation can affect the subcellular localization of proteins involved in homologous recombination, guiding them to sites of DNA damage. This modification can also influence protein stability and turnover, impacting the dynamics of HR-associated proteins at DNA repair sites.</li>
        <li><strong>Recruitment and Assembly of Repair Complexes:</strong> SUMOylation facilitates the recruitment of proteins to sites of DNA damage. Specific proteins involved in HR are SUMOylated to promote their interaction with other DNA repair components, forming complexes necessary for efficient homologous recombination. By modifying multiple proteins involved in HR, SUMO contributes to assembling repair complexes.
        </li>
        <li><strong>Regulation of HR Pathway Choice:</strong> In some cases, SUMOylation influences the choice between different DNA repair pathways, promoting HR over other, more error-prone mechanisms like non-homologous end joining (NHEJ). SUMOylation helps guide the cell toward accurate DNA repair by affecting the function of crucial HR proteins.
        </li>
        <li><strong>Interaction with Other PTMs:</strong> SUMOylation often interacts with other post-translational modifications, such as ubiquitination and phosphorylation. These interactions can modulate the function of HR proteins, influencing DNA repair dynamics and outcomes. For example, the crosstalk between SUMOylation and ubiquitination can regulate the turnover of specific HR components.</li>
        <li><strong>Modulation of Protein-Protein Interactions:</strong> SUMOylation can promote or inhibit specific protein-protein interactions, affecting the stability and functionality of HR complexes. By modifying interaction interfaces, SUMO influences the ability of HR proteins to form productive complexes for DNA repair.</li>
        </ul>
        Specific examples of HR-related proteins influenced by SUMOylation include:
        <ul>
        <li><strong>BRCA1/2:</strong> These proteins are critical players in HR and are subject to SUMOylation, impacting their interaction with other DNA repair proteins.</li>
        <li><strong>RAD51:</strong> This crucial protein for HR-mediated DNA strand invasion and exchange can be regulated by SUMOylation, affecting its stability and activity at DNA damage sites.</li>
        <li><strong>RPA (Replication Protein A):</strong> SUMOylation can affect the stability and dynamics of RPA, a single-strand DNA-binding protein essential in HR.</li>
        </ul>
        Overall, SUMOylation in homologous recombination is a regulatory mechanism that influences the localization, stability, interaction, and function of proteins involved in this critical DNA repair pathway. By modulating these factors, SUMO ensures efficient and accurate DNA repair through homologous recombination.
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4030838/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8097099/',
            'https://www.sciencedirect.com/science/article/pii/S1097276523000035',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4380773/'
        ]
    },
    {
        name: 'SUMO.Ub',
        preview:"small ubiquitin-like modifier 1. It regulates the RNF4 activity on RAP80 for recruitment of ccdc98 and BRCA1 to BRCA1-A complex",
        function: 'small ubiquitin-like modifier 1. It regulates the RNF4 activity on RAP80 for recruitment of ccdc98 and BRCA1 to BRCA1-A complex',
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8161396/'
        ]
    },
    {
        name: 'RNF4',
        preview:"RNF4 (RING finger protein 4) is an E3 ubiquitin ligase crucial in regulating DNA repair, particularly in post-translational modifications like SUMOylation and ubiquitination.",
        function: `
        RNF4 (RING finger protein 4) is an E3 ubiquitin ligase crucial in regulating DNA repair, particularly in post-translational modifications like SUMOylation and ubiquitination.
<ul>
<li><strong>Ubiquitin Ligase Activity:</strong> RNF4 is an E3 ubiquitin ligase, facilitating the transfer of ubiquitin from an E2 ubiquitin-conjugating enzyme to target proteins. This ubiquitination can lead to protein degradation or alter the activity and interactions of target proteins, affecting cellular processes such as DNA repair.</li>
<li><strong>Recognition of SUMOylated Proteins:</strong> RNF4 is a SUMO-targeted ubiquitin ligase (STUbL). Its SUMO-interacting motifs (SIMs) recognize proteins modified with multiple SUMO (Small Ubiquitin-like Modifier) groups. By binding to these SUMOylated proteins, RNF4 targets them for ubiquitination, influencing their stability and function.</li>
<li><strong>Regulation of DNA Repair Proteins:</strong> In the context of DNA repair, particularly homologous recombination (HR), RNF4's recognition of SUMOylated proteins helps regulate the stability and turnover of essential repair proteins. This function is critical for resolving DNA damage sites and maintaining proper cellular responses to DNA damage.</li>
<li><strong>Facilitating DNA Repair Complex Turnover:</strong> By targeting SUMOylated proteins for ubiquitination, RNF4 helps regulate the timely removal of repair complexes from DNA damage sites. This turnover is essential for allowing proper repair and preventing the accumulation of repair proteins, which could otherwise interfere with chromatin dynamics and other cellular processes.</li>
<li><strong>Promotion of Ubiquitin-Mediated DNA Repair:</strong> RNF4 helps integrate different post-translational modifications in DNA repair pathways by linking SUMOylation and ubiquitination. This integration can guide proteins to be degraded by the proteasome, thereby facilitating the progression of DNA repair and the resolution of DNA damage sites.</li>
</ul>
<p>Regulation of Chromatin Structure: RNF4's activity in ubiquitinating SUMOylated proteins also contributes to the regulation of chromatin structure. By affecting the turnover of proteins involved in chromatin remodeling, RNF4 helps maintain the proper balance between chromatin condensation and accessibility, allowing efficient DNA repair.</p>
<p>Overall, RNF4 plays a crucial role in the DNA damage response by regulating the ubiquitination of SUMOylated proteins. Its ability to bridge SUMOylation and ubiquitination provides a mechanism for controlling the stability and function of proteins involved in DNA repair, thereby contributing to genomic stability and proper cellular responses to DNA damage.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/22661229/',
            'https://www.nature.com/articles/s41594-024-01294-7',
            'https://www.sciencedirect.com/science/article/pii/S1568786422000180'
        ]
    },
    {
        name: 'Ccdc98 (ABRAXAS1/Abra1)',
        preview:"CCDC98 acts upstream of BRCA1 in HR and colocalizes with it to create and stabilize BRCA1-A foci. CCDC98 actively participates in damage-induced G2/M checkpoint control",
        function: 'CCDC98 acts upstream of BRCA1 in HR and colocalizes with it to create and stabilize BRCA1-A foci. CCDC98 actively participates in damage-induced G2/M checkpoint control',
        reference: [
            'https://www.nature.com/articles/nsmb1277'
        ]
    },
    {
        name: 'BARD1',
        preview:"BARD1 (BRCA1-Associated RING Domain protein 1) interacts with and modulates the function of BRCA1 (Breast Cancer 1). Interaction with BRCA1: BARD1 forms a heterodimeric complex with BRCA1, with each protein binding to the other's RING finger domain. This complex, known as the BRCA1-BARD1 complex, is crucial for the tumor-suppressive functions of both proteins. Together, BRCA1 and BARD1 act synergistically to carry out various cellular processes. The BRCA1-BARD1 complex possesses E3 ubiquitin ligase activity, degrading protein and regulating cellular pathways. The complex catalyzes the transfer of ubiquitin of target proteins, inducing degradation by the proteasome and modulating their activity. Importantly in DNA Repair: BARD1, in conjunction with BRCA1, plays a role in DNA repair processes, particularly Homologous Recombination (HR)-mediated repair of double-strand breaks. The BRCA1-BARD1 complex facilitates DNA end resection, generating single-stranded templates. It enhances the recombinase activity of RAD51 and the recruitment of other proteins to sites of DNA damage, enabling the repair process.",
        function: `
        <p>BARD1 (BRCA1-Associated RING Domain protein 1) interacts with and modulates the function of BRCA1 (Breast Cancer 1). Interaction with BRCA1: BARD1 forms a heterodimeric complex with BRCA1, with each protein binding to the other's RING finger domain. This complex, known as the BRCA1-BARD1 complex, is crucial for the tumor-suppressive functions of both proteins. Together, BRCA1 and BARD1 act synergistically to carry out various cellular processes. The BRCA1-BARD1 complex possesses E3 ubiquitin ligase activity, degrading protein and regulating cellular pathways. The complex catalyzes the transfer of ubiquitin of target proteins, inducing degradation by the proteasome and modulating their activity. Importantly in DNA Repair: BARD1, in conjunction with BRCA1, plays a role in DNA repair processes, particularly Homologous Recombination (HR)-mediated repair of double-strand breaks. The BRCA1-BARD1 complex facilitates DNA end resection, generating single-stranded templates. It enhances the recombinase activity of RAD51 and the recruitment of other proteins to sites of DNA damage, enabling the repair process.
        </p>
        <p>Additionally, BARD1 participates in transcriptional regulation by interacting with various transcription factors and co-regulators. It modulates gene expression in cell cycle control, DNA repair, and other cellular processes. Moreover, BARD1, along with BRCA1, contributes to the regulation of cell cycle progression. The BRCA1-BARD1 complex is involved in cell cycle checkpoints and ensures proper cell cycle control in response to DNA damage. Finally, BARD1 functions as a tumor suppressor protein, and mutations in the BARD1 gene correlate with an increased risk of breast and ovarian cancers. The interaction between BARD1 and BRCA1 is critical for their tumor-suppressive functions, and disruptions in this interaction can impair DNA repair and contribute to cancer development.</p>
        <p>Overall, BARD1 plays essential roles in DNA repair, transcriptional regulation, cell cycle control, and tumor suppression, primarily through its interaction with BRCA1 and the formation of the BRCA1-BARD1 complex.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/35008774/',
            'https://pubmed.ncbi.nlm.nih.gov/28976962/',
            'https://pubmed.ncbi.nlm.nih.gov/34321665/',
            'https://pubmed.ncbi.nlm.nih.gov/11790560/'
        ]
    },
    {
        name: 'BRCA1.p',
        preview:"BRCA1 (Breast Cancer Type 1 susceptibility protein) is a critical tumor suppressor involved in the DNA damage response (DDR), particularly in the repair of DNA double-strand breaks (DSBs) through homologous recombination (HR). Phosphorylation of BRCA1 plays a significant role in regulating its function, stability, and interaction with other proteins.",
        function: `
        <p>BRCA1 (Breast Cancer Type 1 susceptibility protein) is a critical tumor suppressor involved in the DNA damage response (DDR), particularly in the repair of DNA double-strand breaks (DSBs) through homologous recombination (HR). Phosphorylation of BRCA1 plays a significant role in regulating its function, stability, and interaction with other proteins.</p>
        <p>Several kinases are known to phosphorylate BRCA1 in response to DNA damage, with different phosphorylation sites playing specific roles in regulating BRCA1's activity:</p>
        <ul>
        <li><strong>ATM (Ataxia-Telangiectasia Mutated):</strong> ATM is a central kinase in the DDR. It phosphorylates BRCA1 in response to DSBs, activating the DNA repair pathways. Key phosphorylation sites include S1387 and S1423, among others. These phosphorylations facilitate the recruitment of BRCA1 to sites of DNA damage and promote the homologous recombination pathway.</li>
        <li><strong>ATR (Ataxia-Telangiectasia and Rad3-Related):</strong> ATR is activated in response to replication stress and single-strand DNA breaks. It phosphorylates BRCA1 at sites like S1423 and S1457. ATR-mediated phosphorylation supports the replication stress response and promotes DNA repair.</li>
        <li><strong>CHK2 (Checkpoint Kinase 2):</strong> CHK2 is another critical kinase in the DDR. It phosphorylates BRCA1 at S988, influencing BRCA1's role in cell cycle checkpoints and HR.</li>
        </ul>
        <p style="font-weight:600; font-size:20px">Functions of BRCA1 Phosphorylation</p>
        <p>Phosphorylation regulates several aspects of BRCA1's role in the DDR and DNA repair:</p>
        <ul>
        <li><strong>Recruitment to DNA Damage Sites:</strong> Phosphorylation of BRCA1 enhances its recruitment to sites of DNA damage. This recruitment is critical for initiating the homologous recombination pathway.</li>
        <li><strong>Activation of DNA Repair Pathways:</strong> Phosphorylation can activate BRCA1's functions in HR, promoting accurate repair of DSBs. It also supports BRCA1's interactions with other HR proteins like RAD51.</li>
        <li><strong>Cell Cycle Regulation:</strong> Phosphorylation of BRCA1 influences cell cycle checkpoints, ensuring that cells do not proceed with the cell cycle if DNA damage is detected. This mechanism allows time for DNA repair and prevents the propagation of damaged cells.</li>
        <li><strong>Stability and Protein Interactions:</strong> Phosphorylation can affect BRCA1's stability and its interactions with other proteins, like BARD1 (BRCA1-associated RING domain protein 1), which forms a complex with BRCA1 to mediate E3 ubiquitin ligase activity.
        </li>
        </ul>
        <p style="font-weight:600; font-size:20px">Impact of BRCA1 Mutations</p>
        BRCA1 phosphorylation is crucial for regulating its role in the DNA damage response, especially in homologous recombination and cell cycle checkpoints. Essential kinases like ATM, ATR, and CHK2 phosphorylate BRCA1 at specific sites, impacting its recruitment to DNA damage sites, activation of repair pathways, and cell cycle regulation. This complex regulation underscores the importance of BRCA1 in maintaining genomic stability and preventing cancer development.
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/16721040/',
            'https://pubmed.ncbi.nlm.nih.gov/36065181/',
            'https://pubmed.ncbi.nlm.nih.gov/34301763/',
            'https://pubmed.ncbi.nlm.nih.gov/25659039/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1390683/'
        ]
    },
    {
        name: 'CtIP',
        preview:"CtIP (C-terminal binding protein interacting protein) is a crucial protein in the DNA damage response. It plays a important part in the repair of DNA double-strand breaks (DSBs), mainly through the homologous recombination (HR) pathway. CtIP functions in several critical aspects of DNA repair, primarily in DNA end resection, which is a vital step for initiating homologous recombination. Here's an overview of the role and protein function of CtIP in DNA repair:",
        function: `
        CtIP (C-terminal binding protein interacting protein) is a crucial protein in the DNA damage response. It plays a important part in the repair of DNA double-strand breaks (DSBs), mainly through the homologous recombination (HR) pathway. CtIP functions in several critical aspects of DNA repair, primarily in DNA end resection, which is a vital step for initiating homologous recombination. Here's an overview of the role and protein function of CtIP in DNA repair:
        <ul>
        <li><strong>Initiation of End Resection:</strong> CtIP is involved in the early stages of DNA end resection, which is necessary for homologous recombination. It works with the MRN complex (Mre11, Rad50, Nbs1) to initiate resection, allowing for the formation of single-stranded DNA (ssDNA) at DSB sites.</li>
        <li><strong>Collaboration with the MRN Complex:</strong> CtIP interacts with the MRN complex to help process the DNA ends at double-strand breaks. This interaction is essential for preparing the DNA for further processing and recruiting other homologous recombination proteins.</li>
        <li><strong>Creation of Single-Stranded DNA:</strong> By contributing to DNA end resection, CtIP facilitates the creation of ssDNA, a critical intermediate for HR. Proteins like RPA coat this ssDNA and serve as the substrate for RAD51, the recombinase that mediates strand invasion during HR.</li>
        <li><strong>Role in HR Pathway Choice:</strong> CtIP helps promote HR by enabling DNA end resection. The extent of resection can influence the pathway choice between HR and non-homologous end joining (NHEJ), with longer resection favoring HR.</li>
        <li><strong>Recruitment of HR Factors:</strong> CtIP's activity in end resection creates conditions that allow recruitment of HR factors, including BRCA1, BRCA2, and RAD51. These proteins are essential for accurate and high-fidelity repair through HR.</li>
        <li><strong>Involvement in DNA Damage Response Signaling:</strong> CtIP's interactions with the MRN complex and its role in DNA end resection also affect signaling within the DNA damage response. By initiating the process of HR, CtIP contributes to the broader network of DNA repair and checkpoint activation.</li>
        <li><strong>Regulation of Cell Cycle Checkpoints:</strong> CtIP can also be involved in cell cycle regulation. Its activity in promoting HR is typically more robust during the S and G2 phases of the cell cycle, aligning with the cell's natural progression and ensuring accurate repair.</li>
        <li><strong>Maintenance of Genomic Integrity:</strong> By facilitating homologous recombination, CtIP helps ensure accurate repair of DNA double-strand breaks, contributing to genomic stability. Proper functioning of CtIP is crucial for preventing mutations and chromosomal aberrations that could lead to cancer.</li>
        <li><strong>Tumor Suppression:</strong> Given its role in HR and maintaining genomic stability, CtIP contributes to tumor suppression. Disruptions in CtIP function or mutations in its gene can lead to defective DNA repair, increasing the risk of genomic instability and cancer.</li>
        </ul>
        CtIP is a central protein in the DNA damage response. It is a crucial role in homologous recombination by promoting DNA end resection, a critical critical step in preparing DNA for high-fidelity repair. Through its interactions with the MRN complex and other HR-related proteins, CtIP helps ensure that DNA double-strand breaks repair, contributes to genomic stability, and reduces cancer risk.
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5640159/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2801237',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4382744/',
            'https://pubmed.ncbi.nlm.nih.gov/34813349/'
        ]
    },
    {
        name: 'H2AK125',
        preview:"Histone H2A lysine 125 (K125) is a site for post-translational modifications, particularly ubiquitination. Ubiquitination on histones plays a critical role in regulating chromatin structure, gene expression, and DNA repair. Understanding the significance of ubiquitination at specific lysine sites, such as H2A K125, provides insights into how cells respond to DNA damage and manage genomic stability.",
        function: `
        Histone H2A lysine 125 (K125) is a site for post-translational modifications, particularly ubiquitination. Ubiquitination on histones plays a critical role in regulating chromatin structure, gene expression, and DNA repair. Understanding the significance of ubiquitination at specific lysine sites, such as H2A K125, provides insights into how cells respond to DNA damage and manage genomic stability.
        <p>Ubiquitination at lysine 125 on histone H2A is an essential signal in cellular processes.</p>
        <ul>
  <li><strong>Role in DNA Damage Response:</strong> The ubiquitination at H2A K125 is a fascinating aspect of the complex DNA damage response (DDR), particularly after DNA double-strand breaks (DSBs). This modification acts as a signal, intricately orchestrating the recruitment of DNA repair proteins to the sites of DNA damage.</li>
 <li><strong>Recruitment of DNA Repair Proteins:</strong> Ubiquitination at H2A K125 facilitates the recruitment of proteins involved in DNA repair pathways, such as non-homologous end joining (NHEJ) and homologous recombination (HR). It can help establish a signaling cascade that directs repair proteins to damaged DNA.</li>
 <li><strong>Chromatin Remodeling:</strong> Ubiquitination on histones, including H2A K125, can lead to changes in chromatin structure. This remodeling is crucial for allowing repair proteins to access damaged DNA and efficiently complete the repair process.</li>
</ul>
<p style="font-weight:600; font-size:20px">Essential Proteins and Enzymes Involved</p>
<p>Several proteins and enzymes are involved in ubiquitination at H2A K125.</p>
<ul>
<li><strong>E3 Ubiquitin Ligases</strong> Enzymes like RNF8 and RNF168 are vital players in the ubiquitination of histones. These E3 ubiquitin ligases are activated in response to DNA damage and attach ubiquitin to histones like H2A at K125. This modification serves as a signal for recruiting repair proteins and propagating the DNA damage response.</li>
<li><strong>DNA Repair Proteins:</strong> Ubiquitination at H2A K125 can attract DNA repair proteins like 53BP1 and BRCA1, which are involved in different repair pathways. The recruitment of these proteins is critical for orchestrating a coordinated response to DNA damage.</li>
</ul>
Ubiquitination at H2A K125 is not just a process but a crucial player in signaling and coordinating the DNA damage response. This modification is instrumental in recruiting DNA repair proteins to sites of DNA damage and promoting chromatin remodeling, thereby facilitating the efficient repair of DNA double-strand breaks. Understanding the dynamics of ubiquitination at specific lysine sites on histones is key to unraveling the complex mechanisms that maintain genomic stability and prevent the propagation of cells with damaged DNA.
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/32708614/',
            'https://pubmed.ncbi.nlm.nih.gov/16109483/'
        ]
    },
    {
        name: 'H2AK127',
        preview:"Histone H2A lysine 127 (K127) is one of the sites on histone H2A that can undergo post-translational modifications, including ubiquitination, acetylation, or methylation. These histone modifications significantly regulate chromatin structure, gene expression, and the DNA damage response.",
        function: `
        Histone H2A lysine 127 (K127) is one of the sites on histone H2A that can undergo post-translational modifications, including ubiquitination, acetylation, or methylation. These histone modifications significantly regulate chromatin structure, gene expression, and the DNA damage response.
<p>Ubiquitination of histone H2A, especially at specific lysine residues like K127, is a crucial aspect of the DNA damage response (DDR). Here's how ubiquitination at H2A K127 might influence cellular processes:</p>
<ul>
<li><strong>Signal for DNA Repair:</strong> Ubiquitination on histones can signal the presence of DNA damage and initiate the recruitment of repair proteins. This process is crucial for adequate DNA repair and maintaining genomic stability.</li>
<li><strong>Recruitment of DNA Repair Proteins:</strong> Ubiquitination at H2A K127 could play a role in recruiting DNA repair proteins to sites of DNA damage, helping orchestrate the repair process. These proteins could include those involved in homologous recombination (HR) pathways and non-homologous end joining (NHEJ).</li>
<li><strong>Chromatin Remodeling:</strong> Ubiquitination can impact chromatin structure, potentially promoting a more relaxed state that allows repair proteins easier access to DNA damage sites. This chromatin remodeling is a crucial step in facilitating DNA repair.</li>
<ul>
<p style="font-size:18px; font-weight:600">Essential Proteins and Enzymes Involved</p>
<p>Several proteins and enzymes are responsible for ubiquitination at H2A K127, and their activity is crucial in response to DNA damage:</p>
<ul>
<li><strong>E3 Ubiquitin Ligases:</strong> E3 ubiquitin ligases like RNF8 and RNF168 play pivotal roles in adding ubiquitin to histones, including H2A K127. Their activity is triggered by DNA damage, which sets off a cascade of events that recruits DNA repair proteins.</li>
<li><strong>Interaction with Other DNA Repair Proteins:</strong> Ubiquitination at H2A K127 may attract proteins like 53BP1 and BRCA1, integral to various DNA repair pathways. The recruitment of these proteins helps guide the choice of repair pathways and ensures efficient DNA repair.</li>
<ul>
While ubiquitination sites like H2A K15 or H2A K27 have garnered more attention, the role of H2A K127 in the DNA damage response is equally significant. Its ubiquitination can serve as a DNA damage signal, recruit repair proteins, and influence chromatin structure, all crucial for maintaining genomic stability.
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/16772434/',
            'https://pubmed.ncbi.nlm.nih.gov/25131202/'
        ]
    },
    {
        name: 'H2AK129',
        preview:"Histone H2A is one of the core histone proteins packaging DNA into nucleosomes, forming chromatin. Post-translational modifications (PTMs) on specific lysine residues of histones, such as lysine 129 (K129), play significant roles in regulating chromatin structure, gene expression, and DNA damage response. Understanding the modifications at H2A K129 provides insights into cellular processes, particularly those related to DNA repair and genome stability.",
        function: `
        Histone H2A is one of the core histone proteins packaging DNA into nucleosomes, forming chromatin. Post-translational modifications (PTMs) on specific lysine residues of histones, such as lysine 129 (K129), play significant roles in regulating chromatin structure, gene expression, and DNA damage response. Understanding the modifications at H2A K129 provides insights into cellular processes, particularly those related to DNA repair and genome stability.
<p style="font-size:20px; font-weight:600">Potential Roles of H2A K129</p>
<p>The specific role of lysine 129 on histone H2A depends on the type of post-translational modification and the context in which it occurs. Let's explore some possibilities:</p>
<ul>
<li><strong>Ubiquitination at H2A K129:</strong> Ubiquitination is a common PTM on histones that can impact chromatin dynamics, signaling, and DNA repair. If lysine 129 on histone H2A is ubiquitinated, it may play a role in the DNA damage response (DDR), recruiting repair proteins to sites of DNA damage and contributing to chromatin remodeling. E3 ubiquitin ligases, such as RNF8 and RNF168, are known to ubiquitinate histones in response to DNA damage, leading to the recruitment of repair proteins like 53BP1 and BRCA1.</li>
<li><strong>Acetylation or Methylation at H2A K129:</strong> Although ubiquitination is a well-known PTM for histone H2A, other modifications like acetylation or methylation could also occur at this site. Acetylation typically leads to chromatin relaxation, promoting gene expression and facilitating DNA repair. Methylation can have varying effects depending on the context and the specific number of methyl groups added.</li>
</ul>
<p style="font-size:20px; font-weight:600">Impact on DNA Repair and Chromatin Structure</p>
<p>If H2A K129 is ubiquitinated, it could signal the presence of DNA damage, attracting repair proteins and contributing to chromatin remodeling. This process is crucial for adequate DNA repair, allowing proteins involved in homologous recombination (HR) or non-homologous end joining (NHEJ) to access and repair damaged DNA.</p>
<p>Lysine 129 on histone H2A can be subject to post-translational modifications, including ubiquitination, acetylation, and methylation. These modifications are critical in regulating chromatin structure, gene expression, and the DNA damage response. If ubiquitinated, H2A K129 could be involved in signaling DNA damage and recruiting DNA repair proteins. Further research into the specific function of H2A K129 and its PTMs will clarify its role in cellular processes and genome stability.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/16772434/',
            'https://pubmed.ncbi.nlm.nih.gov/16109483/'
        ]
    },
    {
        name: 'H2AK125U',
        preview:`H2AK125u" refers to the ubiquitination of histone H2A at lysine 125 (K125). Ubiquitination is a post-translational modification where ubiquitin, a small regulatory protein, is attached to other proteins, typically on lysine residues. This modification is crucial in various cellular processes, including protein degradation, signal transduction, and chromatin structure regulation.`,
        function: `
        "H2AK125u" refers to the ubiquitination of histone H2A at lysine 125 (K125). Ubiquitination is a post-translational modification where ubiquitin, a small regulatory protein, is attached to other proteins, typically on lysine residues. This modification is crucial in various cellular processes, including protein degradation, signal transduction, and chromatin structure regulation.
<p style="font-size:20px; font-weight:600">Significance of H2AK125 Ubiquitination</p>
<p>Ubiquitination of histone H2A at lysine 125 (H2AK125u) can significantly affect chromatin structure, gene expression, and the DNA damage response (DDR). Key roles and implications of H2AK125u:</p>
<ul>
<li><trong>Chromatin Structure and Dynamics:</strong> Ubiquitination at H2AK125 can affect chromatin structure, leading to nucleosome stability and compaction changes. This can influence gene expression and other DNA-related processes, including DNA repair.</li>
<li><trong>DNA Damage Response and Repair:</strong> In the context of the DDR, ubiquitination of H2A at K125 might signal the presence of DNA damage and recruit repair proteins to the damage site. This is particularly important for adequate DNA repair and maintaining genomic stability. E3 ubiquitin ligases like RNF8 and RNF168 are known to ubiquitinate histones in response to DNA double-strand breaks, facilitating the recruitment of DNA repair machinery.</li>
<li><strong>Recruitment of DNA Repair Proteins:</strong> Ubiquitination at H2AK125 can attract various proteins involved in DNA repair pathways, such as non-homologous end joining (NHEJ) and homologous recombination (HR). This recruitment is crucial for orchestrating a coordinated DNA repair response.</li>
</ul>
<p style="font-size:20px; font-weight:600">Relevance to Gene Regulation</p>
<p>Ubiquitination at H2AK125 can also impact gene expression by modifying chromatin structure. This effect on chromatin dynamics can either promote or inhibit transcription, depending on the context and the specific downstream signaling pathways involved.</p>
<p>H2AK125u, or ubiquitination of histone H2A at lysine 125, is an essential post-translational modification with implications for chromatin structure, gene expression, and the DNA damage response. Its role in signaling DNA damage and recruiting repair proteins is crucial for maintaining genomic stability and ensuring effective DNA repair.</p>
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9332593/'
        ]
    },
    {
        name: 'H2AK127U',
        preview:"Histone protein 2A which leads many crucial points in the Foci formation when either phosphorylated and / or ubiquitinylated initiating pathways for 53BP1 or BRCA1",
        function: 'Histone protein 2A which leads many crucial points in the Foci formation when either phosphorylated and / or ubiquitinylated initiating pathways for 53BP1 or BRCA1',
        reference: [
            'https://www.mdpi.com/2073-4409/9/7/1699'
        ]
    },
    {
        name: 'H2AK129U',
        preview:"Ubiquitination of histone H2A at lysine 129 (H2A K129u) is a critical post-translational modification with significant implications for chromatin structure, gene expression, and the DNA damage response (DDR). Here's a detailed overview of H2A K129 ubiquitination and its role in cellular processes, especially in the context of DNA repair and signaling.",
        function: `
        Ubiquitination of histone H2A at lysine 129 (H2A K129u) is a critical post-translational modification with significant implications for chromatin structure, gene expression, and the DNA damage response (DDR). Here's a detailed overview of H2A K129 ubiquitination and its role in cellular processes, especially in the context of DNA repair and signaling.
        <p style="font-size:20px; font-weight:600">Significance of H2A K129 Ubiquitination</p>
<p>Ubiquitination of histone H2A at lysine 129 is an important event that can influence chromatin dynamics and cellular signaling.</p>
<ul>
<li><strong>Signal for DNA Damage Response:</strong> Ubiquitination at H2A K129 can indicate DNA damage, particularly in response to DNA double-strand breaks (DSBs). This modification can help recruit DNA repair proteins to sites of damage, facilitating effective DNA repair.</li>
<li><strong>Recruitment of DNA Repair Proteins:</strong> E3 ubiquitin ligases such as RNF8 and RNF168 play a central role in ubiquitinating histone H2A at K129 in response to DSBs. This ubiquitination attracts DNA repair proteins, including 53BP1 and BRCA1, to the damage site. The recruitment of these proteins is crucial for guiding the DNA repair process towards specific pathways, such as non-homologous end joining (NHEJ) or homologous recombination (HR).</li>
<li><strong>Chromatin Remodeling:</strong> Ubiquitination of H2A at K129 can affect chromatin structure, influencing the accessibility of DNA repair machinery to the site of damage. By facilitating chromatin relaxation, this modification helps ensure that repair proteins can effectively access and repair damaged DNA.</li>
</ul>
<p style="font-size:20px; font-weight:600">Role in DNA Repair Pathways</p>
<p>Ubiquitination at H2A K129 significantly orchestrates the DDR, particularly by recruiting repair proteins and promoting chromatin remodeling. Here's how it impacts specific DNA repair pathways:</p>
<ul>
<li><strong>Non-Homologous End Joining (NHEJ):</strong> The recruitment of 53BP1, promoted by H2A K129 ubiquitination, is critical for guiding the DNA repair process towards NHEJ, a fast but less accurate repair mechanism.</li>
<li><strong>Homologous Recombination (HR):</strong> The presence of BRCA1 at sites of ubiquitinated H2A K129 can facilitate HR, a more accurate but slower repair process. This interaction helps ensure that DNA is repaired with high fidelity.</li>
</ul>
<p>H2A K129u, or ubiquitination of histone H2A at lysine 129, is a critical post-translational modification that plays a critical function in the DNA damage response, chromatin remodeling, and recruitment of DNA repair proteins. Its role in signaling and guiding the DNA repair process is crucial for ensuring genomic stability and preventing the propagation of DNA damged cells.</p>
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7407225/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4615105/'
        ]
    },
    {
        name: 'HERC2',
        preview:"HERC2 is an E3 ubiquitin ligase regulating protein stability and interactions through ubiquitination. While it has various functions in the cell, also having been implicated in the DNA damage response, including homologous recombination (HR), a DNA repair pathway responsible for fixing double-strand breaks (DSBs) using a homologous DNA template.",
        function: `
        HERC2 is an E3 ubiquitin ligase regulating protein stability and interactions through ubiquitination. While it has various functions in the cell, also having been implicated in the DNA damage response, including homologous recombination (HR), a DNA repair pathway responsible for fixing double-strand breaks (DSBs) using a homologous DNA template.
        <p style="font-weight:600; font-size:18px">Homologous Recombination (HR)</p>
        <p>HR is a high-fidelity DNA repair pathway that uses an undamaged homologous DNA sequence, typically from a sister chromatid, to repair double-strand breaks. This process requires a coordinated set of proteins, including key players like BRCA1, BRCA2, RAD51, and others.
        </p>
        <p style="font-weight:600; font-size:20px">Role of HERC2 in DNA Damage Response and HR</p>
<p>HERC2, an E3 ubiquitin ligase, can influence DNA repair pathways by ubiquitinating other proteins, impacting their stability, localization, or interactions.</p>
<ul>
<li><strong>Protein Ubiquitination:</strong> HERC2's ubiquitination activity can regulate proteins involved in the DNA damage response. By attaching ubiquitin to target proteins, HERC2 can signal protein degradation, modulate protein-protein interactions, or create docking sites for other proteins involved in HR.</li>
<li><strong>Interaction with DNA Repair Proteins:</strong> HERC2 has been shown to interact with several proteins involved in the DNA damage response, including BRCA1, a crucial protein in HR. This interaction suggests that HERC2 may regulate HR through its ubiquitination activity, potentially affecting the stability and function of essential HR proteins.</li>
<li><strong>Influence on HR Regulation:</strong> By regulating protein ubiquitination, HERC2 can influence the DNA repair pathway choice, promoting or inhibiting HR. For example, it could target proteins that control the decision between HR and non-homologous end joining (NHEJ), thereby affecting the DNA repair outcome.</li>
</ul>
<p style="font-weight:600; font-size:18px">Clinical Significance</p>
<p>Mutations in HERC2 have been associated with various diseases, suggesting its role in maintaining genomic stability. Alterations in its function could impair HR, resulting in genomic instability and increased cancer risk. Understanding how HERC2 regulates HR and other DNA repair pathways could provide insights into cancer development and potential therapeutic targets.</p>
<p>HERC2 is an E3 ubiquitin ligase with potential roles in homologous recombination through its regulation of protein ubiquitination and interactions with crucial HR proteins like BRCA1. Its ability to influence the DNA damage response and repair pathway choice highlights its importance in maintaining genomic stability. Further research into HERC2's specific functions in HR will help clarify its role in the DNA damage response and its implications for disease and therapy.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/20023648/',
            'https://pubmed.ncbi.nlm.nih.gov/37655321/',
            'https://pubmed.ncbi.nlm.nih.gov/34142045/',
            'https://pubmed.ncbi.nlm.nih.gov/37655321/'
        ]
    },
    {
        name: 'BAP1',
        preview:"BAP1 (BRCA1-associated protein 1) is a deubiquitinase with critical roles in DNA repair, chromatin dynamics, and cell cycle regulation. Its ability to interact with other proteins central to the DNA damage response is significant in maintaining genomic stability. BAP1 has several identified protein-protein interactions within the HR pathway critical to DNA Damage Response (DDR) signaling.",
        function: `
        BAP1 (BRCA1-associated protein 1) is a deubiquitinase with critical roles in DNA repair, chromatin dynamics, and cell cycle regulation. Its ability to interact with other proteins central to the DNA damage response is significant in maintaining genomic stability. BAP1 has several identified protein-protein interactions within the HR pathway critical to DNA Damage Response (DDR) signaling.
        <p style="font-weight: 600">BAP1 and BRCA1, BARD1, Rad51 and Others!
        </p>
        BRCA1 is a tumor suppressor gene critical for homologous recombination (HR), a high-fidelity DNA repair pathway for double-strand breaks. BAP1's interaction with BRCA1, and other DNA repair proteins, like BARD1 and Rad51 is linked to its deubiquitinating activity, suggesting it regulates critical protein function during DNA repair. This relationship may influence BRCA1's stability, recruitment to DNA damage sites, or ability to mediate HR. This interaction underlines the importance of BAP1 in promoting accurate DNA repair and maintaining genomic stability.
        <p style="font-weight: 600">BAP1 and ATM
        </p>
        ATM (ataxia-telangiectasia mutated) is a central kinase in the DNA damage response, primarily activated by DNA double-strand breaks. It phosphorylates various downstream targets to initiate DNA repair pathways, including homologous recombination and non-homologous end joining (NHEJ). While the direct interaction between BAP1 and ATM isn't as well-documented as other interactions, BAP1 may indirectly influence ATM's activity by affecting the stability or function of proteins involved in DDR. Deubiquitination (BAP1) and phosphorylation (ATM) may well affect how cells respond to DNA damage.
        <p style="font-weight: 600">BAP1 and HERC2
        </p>
        HERC2 is an E3 ubiquitin ligase involved in regulating protein ubiquitination. It has a known role in genomic stability and is implicated in the DNA damage response. BAP1's interaction with HERC2 likely involves deubiquitination, suggesting that BAP1 could regulate HERC2's activity or modulate its effects on other proteins in the DNA repair pathways. This interaction could be critical in balancing ubiquitination and deubiquitination, impacting DNA repair processes and cell cycle control.
        <p style="font-weight: 600">ASXL1/ASXL2
        </p>
        ASXL1 and ASXL2 (additional sex combs-like 1 and 2) are other key interactors of BAP1. This complex can influence chromatin remodeling, potentially affecting the accessibility of DNA repair proteins to sites of DNA damage.
        <p style="font-size:14px;font-weight: 600">Implications in DNA Repair
        </p>
        <p>These interactions underscore BAP1's involvement in DNA repair pathways and the broader DNA damage response. By interacting with BRCA1, BAP1 may facilitate homologous recombination, while interactions with ATM and HERC2 highlight its role in broader DDR signaling. Critical interaction with ASXL1/ASXL2 highlights its importance in chromatin remodeling, potentially affecting the accessibility of DNA repair proteins to sites of DNA damage. BAP1's deubiquitinating activity can stabilize essential proteins, promote the proper assembly of repair complexes, and maintain chromatin structure, all necessary for efficient DNA repair and genomic integrity.</p>
        Mutations in BAP1 that disrupt these interactions can lead to increased genomic instability and are associated with various cancers, emphasizing the clinical relevance of these protein interactions in understanding disease mechanisms and developing therapeutic strategies.
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/32877777/',
            'https://pubmed.ncbi.nlm.nih.gov/37009801/',
            'https://pubmed.ncbi.nlm.nih.gov/24347639/',
            'https://pubmed.ncbi.nlm.nih.gov/16341802/'
        ]
    },
    {
        name: 'BAP1.p',
        preview:"",
        function: '',
        reference: [
            'https://www.nature.com/articles/s41418-020-00709-4'
        ]
    },
    {
        name: 'Chk2',
        preview:"Checkpoint kinase 2 (CHK2), a serine/threonine kinase, is an integral protein in the cellular response to DNA damage. Its primary functions are regulating cell cycle progression, DNA repair, or the initiation of apoptosis if the DNA damage is severe and irreparable. CHK2 DNA damage signaling is activated in response to various types of DNA damage and acts in different DNA repair pathways, including Homologous Recombination (HR), Non-Homologous End Joining (NHRJ), and Base Excision Repair (BER) and Nucleotide Excision Repair (NER). CHK2 phosphorylates and activates proteins within the DNA within the aforementioned repair pathways, such as BRCA1, p53-binding protein 1 (53BP1), and breast cancer susceptibility gene 2 (BRCA2). By promoting the activity of these proteins, CHK2 enhances the cell's ability to repair DNA damage and maintain genomic integrity. CHK2 helps to arrest the cell cycle at checkpoints in response to DNA damage. It phosphorylates and activates several downstream targets, including checkpoint protein p53 and the CDC25 family of phosphatases. Activation of p53 leads to transcriptional activation of genes implicated in cell cycle arrest and apoptosis, while inhibition of CDC25 prevents cell cycle progression. In cases where DNA damage is severe and repair is not feasible, CHK2 triggers apoptosis by phosphorylating and activating pro-apoptotic proteins such as p53 and BCL2-associated X protein (BAX). This phosphorylation and activation lead to the activation of the apoptotic cascade and the elimination of damaged cells to prevent the propagation of genetic abnormalities.",
        function: `
        <p>Checkpoint kinase 2 (CHK2), a serine/threonine kinase, is an integral protein in the cellular response to DNA damage. Its primary functions are regulating cell cycle progression, DNA repair, or the initiation of apoptosis if the DNA damage is severe and irreparable. CHK2 DNA damage signaling is activated in response to various types of DNA damage and acts in different DNA repair pathways, including Homologous Recombination (HR), Non-Homologous End Joining (NHRJ), and Base Excision Repair (BER) and Nucleotide Excision Repair (NER). CHK2 phosphorylates and activates proteins within the DNA within the aforementioned repair pathways, such as BRCA1, p53-binding protein 1 (53BP1), and breast cancer susceptibility gene 2 (BRCA2). By promoting the activity of these proteins, CHK2 enhances the cell's ability to repair DNA damage and maintain genomic integrity. CHK2 helps to arrest the cell cycle at checkpoints in response to DNA damage. It phosphorylates and activates several downstream targets, including checkpoint protein p53 and the CDC25 family of phosphatases. Activation of p53 leads to transcriptional activation of genes implicated in cell cycle arrest and apoptosis, while inhibition of CDC25 prevents cell cycle progression. In cases where DNA damage is severe and repair is not feasible, CHK2 triggers apoptosis by phosphorylating and activating pro-apoptotic proteins such as p53 and BCL2-associated X protein (BAX). This phosphorylation and activation lead to the activation of the apoptotic cascade and the elimination of damaged cells to prevent the propagation of genetic abnormalities.</p>
        <p>Overall, CHK2 functions as a critical regulator of multiple DNA repair pathways, coordinating the cellular response to DNA damage, acting in multiple DNA repair pathways, regulating the cell cycle, and activating Apoptosis to maintain genomic stability. Dysregulation of CHK2-mediated DNA repair processes may lead to genomic instability and increase the risk of cancer development.
        </p>
        `,
        reference: ['https://pubmed.ncbi.nlm.nih.gov/21034966/',
            'https://pubmed.ncbi.nlm.nih.gov/14701743/',
            'https://pubmed.ncbi.nlm.nih.gov/25659039/',
            'https://pubmed.ncbi.nlm.nih.gov/36253861/',
            'https://pubmed.ncbi.nlm.nih.gov/22158418/'
        ]
    },
    {
        name: 'Chk2.p',
        preview:"CHK2 (Checkpoint Kinase 2) is a serine/threonine kinase that plays a significant role in the DNA damage response (DDR), particularly in the context of DNA double-strand breaks (DSBs). Phosphorylation of CHK2 is a critical regulatory event, activating its kinase activity and allowing it to fulfill its role in coordinating cellular responses to DNA damage. Here's an in-depth look at phosphorylated CHK2 and its function in the DDR.",
        function: `
        <p>CHK2 (Checkpoint Kinase 2) is a serine/threonine kinase that plays a significant role in the DNA damage response (DDR), particularly in the context of DNA double-strand breaks (DSBs). Phosphorylation of CHK2 is a critical regulatory event, activating its kinase activity and allowing it to fulfill its role in coordinating cellular responses to DNA damage. Here's an in-depth look at phosphorylated CHK2 and its function in the DDR.</p>
        <p>CHK2 is activated primarily by ATM (ataxia-telangiectasia mutated), another kinase central to the DNA damage response.</p>
        <p style="font-weight: 600; font-size: 20px">Functions of Phosphorylated CHK2</p>
        <p>Once activated, CHK2 has several vital roles in the DNA damage response and related processes:</p>
        <ul>
        <li>
        <strong>Cell Cycle Regulation:</strong> Phosphorylated CHK2 helps enforce cell cycle checkpoints, ensuring that cells do not progress through the cell cycle in the presence of DNA damage. This checkpoint control is crucial for allowing time for DNA repair and propagation of damaged cells.
        </li>
        <li><strong>Phosphorylation of Downstream Targets:</strong> CHK2 phosphorylates several essential proteins involved in the DDR, including:</li>
        <li><strong>p53:</strong> CHK2 phosphorylates p53, a tumor suppressor inducing cell cycle arrest or apoptosis in response to DNA damage.</li>
        <li><strong>BRCA1:</strong> CHK2 phosphorylation of BRCA1 is essential for homologous recombination, a critical DNA repair pathway.</li>
        <li><strong>CDC25:</strong> CHK2 phosphorylates CDC25, leading to its degradation and thus preventing the cell cycle from progressing.</li>
        <li><strong>Apoptosis:</strong> Severe DNA damage results in phosphorylated CHK2 can help induce apoptosis, thereby preventing the survival and propagation of damaged cells.</li>
        </ul>
        Phosphorylated CHK2 is central to the DNA damage response, particularly in regulating cell cycle checkpoints and promoting DNA repair. Its activation through phosphorylation by ATM enables it to fulfill its role in maintaining genomic stability and preventing the propagation of cells with DNA damage. Given its importance, CHK2 is a significant focus in cancer research, with implications for understanding disease mechanisms and potential therapeutic targets.
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/25535901/',
            'https://pubmed.ncbi.nlm.nih.gov/10673500/'
        ]
    },
    {
        name: 'ATM.p',
        preview:"ATM (ataxia-telangiectasia mutated) is a crucial serine/threonine kinase that plays a central role in the DNA damage response (DDR), particularly in response to DNA double-strand breaks (DSBs). ATM's activation and subsequent phosphorylation cascades are essential for detecting DNA damage and initiating repair processes. Here's a detailed overview of how ATM is activated, its targets, and its role in the DDR:",
        function: `
        ATM (ataxia-telangiectasia mutated) is a crucial serine/threonine kinase that plays a central role in the DNA damage response (DDR), particularly in response to DNA double-strand breaks (DSBs). ATM's activation and subsequent phosphorylation cascades are essential for detecting DNA damage and initiating repair processes. Here's a detailed overview of how ATM is activated, its targets, and its role in the DDR:
        <p style="font-weight: 600">Activation of ATM</p>
        In response to DNA damage, particularly DSBs, ATM is activated through a series of events:
        <ul>
        <li><strong>Detection of DNA Breaks:</strong> ATM can sense DNA breaks by associating with the MRN complex (MRE11, RAD50, and NBS1). This complex recognizes DSBs, leading to ATM's recruitment to the damaged site.
        </li>
        <li><strong>Autophosphorylation:</strong> Upon detection of DNA damage, ATM undergoes autophosphorylation at serine 1981 (S1981), leading to its activation. This phosphorylation event is critical for ATM's kinase activity and its ability to phosphorylate downstream targets.
        </li>
        <li><strong>Dimer-to-Monomer Transition:</strong> In its inactive form, ATM is a dimer. It transitions to an active monomeric state upon activation, allowing it to interact with and phosphorylate various downstream proteins.
        </li>
        </ul>
        <p style=" font-weight: 600">Phosphorylation Targets of ATM</p>
        Once activated, ATM phosphorylates many proteins involved in the DNA damage response, cell cycle regulation, and apoptosis. Here are some critical targets of ATM:
        <ul>
        <li><strong>CHK2:</strong> ATM phosphorylates CHK2, a cell cycle checkpoint control kinase. This phosphorylation helps to halt the cell cycle, allowing time for DNA repair.</li>
        <li><strong>p53:</strong> ATM phosphorylates p53, activating this tumor suppressor, leading to cell cycle arrest or apoptosis if the damage is irreparable.
        </li>
        <li><strong>H2AX:</strong> ATM phosphorylates histone H2AX, forming γ-H2AX, a marker of DNA damage. This modification helps signal the presence of DSBs and recruit DNA repair machinery.</li>
        <li><strong>BRCA1:</strong> ATM's phosphorylation of BRCA1 is critical for homologous recombination, a key DNA repair pathway for DSBs.</li>
        <li><strong>NBS1:</strong> As part of the MRN complex, NBS1 phosphorylation by ATM enhances the complex's DNA repair functions.</li>
        <li><strong>53BP1:</strong> ATM phosphorylation of 53BP1 helps recruit this protein to sites of DSBs, guiding repair pathways like non-homologous end joining (NHEJ).</li>
        </ul>
        <p style="font-weight: 600">Role of ATM Phosphorylation in DNA Repair</p>
        ATM-mediated phosphorylation plays a central role in coordinating the DNA damage response:
        <ul>
        <li><strong>Signal Propagation:</strong> Phosphorylation cascades initiated by ATM amplify the signal for DNA damage, ensuring an efficient response.</li>
        <li><strong>Recruitment of Repair Machinery:</strong> Phosphorylation of specific proteins helps recruit DNA repair complexes to the sites of DNA damage.</li>
        <li><strong>Cell Cycle Regulation:</strong> ATM's activation can lead to cell cycle checkpoints, preventing cells from dividing with damaged DNA.</li>
        <li><strong>Apoptosis:</strong> If the DNA damage is too severe to repair, ATM's activation can lead to apoptosis, preventing the propagation of damaged cells</li>
        </ul>
        ATM's phosphorylation in response to DNA damage is crucial for detecting DNA double-strand breaks and coordinating the DNA damage response. By phosphorylating a range of critical proteins, ATM orchestrates a complex network of signaling events that facilitate DNA repair, cell cycle regulation, and apoptosis, thereby maintaining genomic stability.
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/25240135/',
            'https://pubmed.ncbi.nlm.nih.gov/16426422/',
            'https://pubmed.ncbi.nlm.nih.gov/34301763/',
            'https://pubmed.ncbi.nlm.nih.gov/9843217/',
            'https://pubmed.ncbi.nlm.nih.gov/10973490/',
            'https://pubmed.ncbi.nlm.nih.gov/22323184/'
        ]
    },
    {
        name: 'H2Ax',
        preview:"H2AX, a variant of the histone protein H2A, and its primary function relates to the organization and structure of chromatin, as well as the cellular response to DNA damage. Here are the essential functions and roles of H2AX:",
        function: `
        H2AX, a variant of the histone protein H2A, and its primary function relates to the organization and structure of chromatin, as well as the cellular response to DNA damage. Here are the essential functions and roles of H2AX:
        <ul>
        <li><strong>Nucleosome Formation:</strong> H2AX, like other histone proteins, contributes to the formation of nucleosomes. A nucleosome is the fundamental unit of chromatin, consisting of DNA wrapped around a core of histone proteins (including two molecules each of H2A, H2B, H3, and H4). This organization plays a crucial role in compacting DNA within the cell nucleus and regulating gene expression by controlling access to DNA.
        </li>
        <li><strong>DNA Damage Response (DDR):</strong> One of H2AX's key functions, distinct from those of other histone proteins, is its function in the DNA damage response. When DNA suffers double-strand breaks (DSBs), H2AX is phosphorylated at a specific serine residue (serine 139), leading to the formation of γ-H2AX (gamma H2AX). This phosphorylation serves as an early signal of DNA damage.</li>
        <li><strong>Recruitment of DNA Repair Proteins:</strong> Phosphorylation of H2AX to form γ-H2AX creates a binding site for various proteins involved in DNA repair. It helps recruit proteins involved in the recognition, signaling, and repair of DNA damage, such as MDC1 (Mediator of DNA Damage Checkpoint 1), 53BP1 (p53-binding protein 1), BRCA1 (Breast Cancer 1), and others. These proteins work together to repair the damage and maintain genomic stability.
        </li>
        <li><strong>Chromatin Remodeling:</strong> Through its role in signaling and repair, H2AX plays a role in chromatin remodeling during the DNA damage response. The formation of γ-H2AX marks regions where chromatin structure is altered to allow access for repair proteins and facilitate the repair process.</li>
        </ul>
        H2AX's functions are fascinating studies in molecular biology. They involve a complex interplay of structural and signaling roles. Its structural role in chromatin and its specialized role in signaling and facilitating the DNA damage response, particularly in double-strand breaks, are of immense interest. Through its phosphorylation to form γ-H2AX, it acts as a key marker for DNA damage and plays a essential role in the recruitment and organization of proteins implicated in the DNA repair process.
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2756993/',
            'https://pubmed.ncbi.nlm.nih.gov/22941631/',
            'https://pubmed.ncbi.nlm.nih.gov/15694301/',
            'https://pubmed.ncbi.nlm.nih.gov/32998084/'
        ]
    },
    {
        name: 'γH2Ax',
        preview:"Gamma H2AX (γH2AX) is a variant of the histone H2A protein that plays a crucial role in the cellular response to DNA double-strand breaks (DSBs).  Its primary function is as a marker of DNA damage, particularly in the context of DSBs. When DSBs occur in chromatin, γH2AX is rapidly and phosphorylated explicitly at the serine 139 residue (referred to as Ser139 phosphorylation or γ-phosphorylation) near the site of the break.  This phosphorylation event forms γH2AX foci, visualized via immunofluorescence microscopy techniques as an aggregate of fluorescently labeled protein at the site of breaks.  While the aggregates (foci)do not define single breaks in conventional microscopy, super-resolution has led to the ability to identify single break sites and phosphorylation both up and downstream of the break.",
        function: `
        <p>Gamma H2AX (γH2AX) is a variant of the histone H2A protein that plays a crucial role in the cellular response to DNA double-strand breaks (DSBs).  Its primary function is as a marker of DNA damage, particularly in the context of DSBs. When DSBs occur in chromatin, γH2AX is rapidly and phosphorylated explicitly at the serine 139 residue (referred to as Ser139 phosphorylation or γ-phosphorylation) near the site of the break.  This phosphorylation event forms γH2AX foci, visualized via immunofluorescence microscopy techniques as an aggregate of fluorescently labeled protein at the site of breaks.  While the aggregates (foci)do not define single breaks in conventional microscopy, super-resolution has led to the ability to identify single break sites and phosphorylation both up and downstream of the break.</p>
        <p>γH2AX functions to signal DNA damage via interactions with both ATM (ataxia-telangiectasia mutated) and DNA-PK (DNA-dependent protein kinase), triggering a cascade of DNA damage response signaling events.  These interactions recruit and activate various DNA repair factors and checkpoint proteins to the site of damage.  Additionally, γH2AX serves as a binding platform for DNA repair proteins, facilitating their recruitment to the vicinity of DSBs.  These repair proteins include factors involved in both non-homologous end joining (NHEJ) and homologous recombination (HR) pathways.  Moreover,  γH2AX accumulation at DSB sites contributes to local chromatin remodeling, making the damaged DNA more accessible to repair factors.  This chromatin modification facilitates efficient and accurate repair of the break.  Finally, γH2AX foci formation amplifies the signal of DNA damage, allowing for the detection and quantification of DSBs within the cell.  This property makes γH2AX a valuable biomarker for studying DNA damage and repair processes in various biological contexts, including cancer research and clinical diagnostics.</p>
        <p>The phosphorylation of H2AX to form γH2AX is a critical early event in the cellular response to DSBs, playing a central role in orchestrating DNA repair and maintaining genome integrity.</p>`,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/17110439/',
            'https://pubmed.ncbi.nlm.nih.gov/18610740/',
            'https://pubmed.ncbi.nlm.nih.gov/12897845/'
        ]
    },
    {
        name: 'MDC1',
        preview:"MDC1 is activated by Chk2. Binds to ɣ(gamma)H2Ax to increase ɣ(gamma)H2Ax accumulation across long distances on chromatin and also regulates RNF8 for RNF-dependent ubiquitinylation of histone proteins",
        function: 'MDC1 is activated by Chk2. Binds to ɣ(gamma)H2Ax to increase ɣ(gamma)H2Ax accumulation across long distances on chromatin and also regulates RNF8 for RNF-dependent ubiquitinylation of histone proteins',
        reference: [
            'https://www.nature.com/articles/nature01446'
        ]
    },
    {
        name: 'MDC1.p',
        preview:"MDC1 (Mediator of DNA Damage Checkpoint 1) is not just a protein, but a crucial player in the DNA damage response (DDR). Its significant role in signaling and coordinating the repair of DNA double-strand breaks (DSBs) cannot be overstated. The phosphorylation of MDC1 is not just a process, but a central event that shapes its function in the DDR, influencing its ability to recruit other proteins to DNA damage sites and propagate the DNA damage signal.",
        function: `
        MDC1 (Mediator of DNA Damage Checkpoint 1) is not just a protein, but a crucial player in the DNA damage response (DDR). Its significant role in signaling and coordinating the repair of DNA double-strand breaks (DSBs) cannot be overstated. The phosphorylation of MDC1 is not just a process, but a central event that shapes its function in the DDR, influencing its ability to recruit other proteins to DNA damage sites and propagate the DNA damage signal.
<p>MDC1 acts as a scaffold protein, which recruits and interacts with other DDR proteins. Here's how MDC1 functions in response to DNA damage:</p>
<ul>
<li><strong>Binding to γ-H2AX:</strong> MDC1 recognizes and binds to γ-H2AX (phosphorylated histone H2AX), which forms at sites of DNA double-strand breaks. This interaction is critical for recruiting MDC1 to damage DNA and initiate a cascade of signaling events.</li>
<li><strong>Recruitment of DDR Proteins:</strong> Once bound to γ-H2AX, MDC1 serves as a platform to recruit other DDR proteins, such as 53BP1, BRCA1, and the MRN complex (MRE11, RAD50, NBS1). These interactions are vital for orchestrating DNA repair.</li>
</ul>
<p style="font-weight:600; font-size:20px">Phosphorylation of MDC1</p>
<p>MDC1 itself undergoes phosphorylation, which is critical for its function in the DDR. Key aspects of MDC1 phosphorylation include:</p>
<ul>
<li><strong>Kinases Involved in MDC1 Phosphorylation:</strong> ATM (Ataxia-Telangiectasia Mutated) is one of the primary kinases responsible for phosphorylating MDC1. ATM activation in response to DNA double-strand breaks and phosphorylates MDC1 at various sites, enables its role in DDR signaling.</li>
</ul>
<p style="font-weight:600; font-size:18px">Functions of MDC1 Phosphorylation:</p>
<ul>
<li><strong>Stabilization and Activation:</strong> ATM phosphorylates MDC1, which is essential for its stabilization and activation. This phosphorylation enhances MDC1's ability to bind to γ-H2AX and recruit other DDR proteins.</li>
<li><strong>Signal Propagation:</strong> The phosphorylation of MDC1 allows it to propagate the DNA damage signal by interacting with and recruiting other DDR components, leading to effecient DNA repair and activation of cell cycle checkpoints.</li>
<li><strong>Interaction with Other Proteins:</strong> Phosphorylation enables MDC1 to interact with other essential DDR proteins, supporting the assembly of DNA repair complexes and activating checkpoint pathways.</li>
</ul>
MDC1 phosphorylation is not just a central event, but a pivotal one in the DNA damage response, particularly in the context of DNA double-strand breaks. The phosphorylation by ATM and other kinases is not just crucial, but essential for MDC1's role in recruiting DDR proteins, stabilizing interactions with γ-H2AX, and promoting the propagation of DNA damage signals. This coordinated response is not just vital, but critical for ensuring effective DNA repair, preserving genomic stability, and preventing the propagation of DNA damaged cells.
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/16377563/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3030693/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4693261/',
            'https://pubmed.ncbi.nlm.nih.gov/16427009/'
        ]
    },
    {
        name: 'H2A',
        preview:"Histone H2A is a core histones that form nucleosomes, the basic building blocks of chromatin in eukaryotic cells. Nucleosomes consist of an octamer of histones (two copies each of H2A, H2B, H3, and H4) wrapped around by approximately 147 base pairs of DNA. In the context of DNA repair, H2A has specific roles beyond its structural function in nucleosomes.",
        function: `
        Histone H2A is a core histones that form nucleosomes, the basic building blocks of chromatin in eukaryotic cells. Nucleosomes consist of an octamer of histones (two copies each of H2A, H2B, H3, and H4) wrapped around by approximately 147 base pairs of DNA. In the context of DNA repair, H2A has specific roles beyond its structural function in nucleosomes.
        <ul>
        <li><strong>Stabilization of Nucleosomes:</strong> Histone H2A, along with the other core histones, maintains the stability and organization of chromatin. This organization is crucial for regulating access to DNA, affecting processes like transcription, replication, and DNA repair.</li>
        <li><strong>Ubiquitination:</strong> One of the most well-known modifications of H2A is its ubiquitination at specific lysine residues. This modification, typically driven by E3 ubiquitin ligases like RNF8 and RNF168, plays a significant role in DNA repair, especially in DNA double-strand breaks (DSBs). Ubiquitination of H2A can serve as a signal to recruit DNA repair proteins to damage sites.</li>
        <li><strong>Phosphorylation:</strong> Another highly critical modification is the phosphorylation of H2A at a specific site, creating γ-H2AX (phosphorylation of histone H2A at serine 139). γ-H2AX is a marker for DNA double-strand breaks and is one of the earliest signals indicating DNA damage. It recruits various DNA repair factors to the site of damage, facilitating the repair process.</li>
        <li><strong>Ubiquitination and Recruitment of Repair Factors:</strong> The ubiquitination of H2A by RNF8 and RNF168 creates a signal that recruits DNA repair proteins such as 53BP1, BRCA1, and other factors involved in homologous recombination and non-homologous end joining (NHEJ). This recruitment is crucial for coordinating the DNA damage response.</li>
        <li><strong>γ-H2AX and DNA Repair:</strong> The phosphorylation of H2A to γ-H2AX is a platform for assembling DNA repair complexes. Proteins involved in homologous recombination, like MDC1 and others involved in the DNA damage response, are recruited to these phosphorylated sites, facilitating the repair process.</li>
        <li><strong>Choice of Repair Pathways:</strong> The ubiquitination of H2A can influence the choice between DNA repair pathways, such as HR and NHEJ. The type and extent of ubiquitination may determine which repair proteins are recruited, affecting the pathway choice.</li>
        <li><strong>Maintenance of Genomic Integrity:</strong> H2A contributes to genomic stability by signaling and recruiting DNA repair proteins. Proper functioning of H2A and its modifications are essential for accurate DNA repair and prevention of genomic instability.</li>
        <li><strong>Role in Tumor Suppression:</strong> Given its involvement in DNA repair, especially in homologous recombination, H2A modifications like ubiquitination and phosphorylation reduce the risk of tumorigenesis. Accurate DNA damage repair is critical for preventing mutations that could lead to cancer.</li>
       </ul>
        Histone H2A is a core component of nucleosomes and plays a key role in DNA repair through its post-translational modifications, especially ubiquitination and phosphorylation to form γ-H2AX. These modifications are critical for signaling DNA damage, recruiting repair proteins, and facilitating different DNA repair pathways. By contributing to these processes, H2A helps maintain genomic stability and reduce the risk of tumorigenesis.
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/9488723/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5876634/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7235047/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4615105/'
        ]
    },
    {
        name: 'H2AK13',
        preview:"Lysine 13 (K13) on histone H2A is one of many sites on histones where post-translational modifications (PTMs) can occur, including ubiquitination, methylation, acetylation, and others. Histones, as components of nucleosomes, play a crucial role in organizing DNA into chromatin, influencing gene expression, DNA replication, and DNA repair processes.",
        function: `
        Lysine 13 (K13) on histone H2A is one of many sites on histones where post-translational modifications (PTMs) can occur, including ubiquitination, methylation, acetylation, and others. Histones, as components of nucleosomes, play a crucial role in organizing DNA into chromatin, influencing gene expression, DNA replication, and DNA repair processes.
        <p style="font-weight:600; font-size:20px">Importance of PTMs on Histones</p>
        <p>PTMs on histones affect chromatin structure and function, determining whether chromatin is more compact or relaxed and influencing DNA-related processes. Among these modifications, ubiquitination of histones, particularly H2A, is associated with signaling and recruiting proteins involved in DNA repair and other cellular pathways.</p>
        <p style="font-weight:600; font-size:18px">Ubiquitination of H2A K13</p>
        <p>Ubiquitination at lysine 13 on histone H2A might play a role in signaling during the DNA damage response, chromatin remodeling, or regulation of gene expression. This specific site is less well-studied than other ubiquitination sites like H2A K27, which is extensively researched in the context of DNA damage response.</p>
        <p style="font-weight:600; font-size:18px">Potential Functions of H2A K13 Ubiquitination</p>
        <p>Although research on ubiquitination at H2A K13 is limited, it may play roles similar to other histone ubiquitination sites.</p>
        <ul>
        <li><strong>Chromatin Remodeling:</strong> Ubiquitination at H2A K13 could lead to changes in chromatin structure, allowing for more open or compacted chromatin. This could impact gene expression and the accessibility of DNA repair machinery.</li>
<li><strong>DNA Damage Response:</strong> Ubiquitination at this site might signal the presence of DNA damage, helping to recruit DNA repair proteins to facilitate repair. This could be particularly relevant for pathways like homologous recombination or non-homologous end joining.</li>
<li><strong>Regulation of Gene Expression:</strong> PTMs on histones often regulate gene transcription by affecting the binding of transcription factors or other proteins involved in gene regulation.</li>
</ul>
While H2A K13 is an essential site for potential ubiquitination and other PTMs, more research is needed to understand its specific roles and mechanisms fully. Ubiquitination on histones is a critical component of cellular regulation, impacting chromatin structure, DNA repair, and gene expression. If you have further questions about H2A K13 or specific contexts you'd like to explore, I'm here to help provide more insights.
        `,
        reference: [
            'https://www.nature.com/articles/s41467-019-09756-z',
            'https://pubmed.ncbi.nlm.nih.gov/32708614/',
            'https://pubmed.ncbi.nlm.nih.gov/22980979/'
        ]
    },
    {
        name: 'H2AK15',
        preview:"Lysine 15 (K15) on histone H2A is a crucial site for post-translational modifications, notably ubiquitination. Histone modifications can profoundly impact chromatin structure, gene expression, and the DNA damage response, with ubiquitination playing a critical role in signaling and regulating these processes.",
        function: `
        Lysine 15 (K15) on histone H2A is a crucial site for post-translational modifications, notably ubiquitination. Histone modifications can profoundly impact chromatin structure, gene expression, and the DNA damage response, with ubiquitination playing a critical role in signaling and regulating these processes.
        <p style="font-size:20px; font-weight:600">Ubiquitination at H2A K15</p>
<p>Ubiquitination of H2A at lysine 15 (H2A K15) is one of the most studied ubiquitination sites on this histone. This modification has significant implications for DNA damage response and repair.</p>
<ul>
<li><strong>DNA Damage Response (DDR):</strong> Ubiquitination at H2A K15 is a key signal in the DDR. Following DNA damage, particularly double-strand breaks (DSBs), specific E3 ubiquitin ligases (such as RNF8 and RNF168) ubiquitinate H2A at K15, initiating a signaling cascade that recruits repair proteins to the damage site.</li
<li><strong>Recruitment of Repair Proteins:</strong> Ubiquitination at H2A K15 acts as a signal to attract proteins involved in the DDR, such as 53BP1, BRCA1, and others. This recruitment is essential for the assembly of repair complexes and for guiding the repair process toward pathways like non-homologous end joining (NHEJ) or homologous recombination (HR).</li
<li><strong>Chromatin Remodeling:</strong> Ubiquitination at H2A K15 contributes to chromatin remodeling, making the chromatin more accessible to repair proteins. This remodeling facilitates efficient DNA repair by allowing more access to the damaged DNA.</li
</ul>
<p style="font-size:20px; font-weight:600">Role in DNA Repair Pathways</p>
<p>H2A K15 ubiquitination plays a crucial role in guiding the choice between DNA repair pathways. Recruiting proteins like 53BP1 to ubiquitinated H2A K15 helps promote NHEJ, a faster but less accurate DNA repair mechanism. In contrast, the recruitment of proteins like BRCA1 can lead to HR, a more precise but slower repair pathway.</p>
<p>Ubiquitination at H2A K15 is a key regulatory signal in the DNA damage response. It is a marker for recruiting DNA repair proteins and contributes to chromatin remodeling, enabling effective DNA repair. This ubiquitination is pivotal in determining the repair pathway choice and maintaining genomic stability.</p>
        
`,
        reference: [
            'https://www.mdpi.com/2073-4409/9/7/1699',
            'https://www.nature.com/articles/s41467-019-09756-z',
            'https://pubmed.ncbi.nlm.nih.gov/34321665/',
            'https://pubmed.ncbi.nlm.nih.gov/25695757/'
        ]
    },
    {
        name: 'H2AK13(K27)',
        preview:"Ubiquitination of histone H2A at lysine 13 (K13) and lysine 27 (K27) has garnered significant interest due to its particpiation in various cellular processes, including DNA repair and transcriptional regulation.",
        function: `
        Ubiquitination of histone H2A at lysine 13 (K13) and lysine 27 (K27) has garnered significant interest due to its particpiation in various cellular processes, including DNA repair and transcriptional regulation.
        <ul>
        <li><strong>Signaling DNA Damage:</strong> Ubiquitination of H2A at K27 is known to be part of the signaling cascade following DNA damage, especially for double-strand breaks (DSBs). E3 ubiquitin ligases like RNF8 and RNF168 play critical roles in adding ubiquitin to these sites, facilitating recruitment of proteins involved in the DNA damage response.</li>
<li><strong>Role in Homologous Recombination:</strong> While ubiquitination at H2A K27 is involved in signaling DNA damage, its specific role in HR is to aid in the recruitment of essential repair proteins to the site of DNA breaks. Proteins like BRCA1 and 53BP1 are attracted to ubiquitinated H2A K27, initiating the assembly of complexes required for HR.</li>
<li><strong>Chromatin Remodeling and Accessibility:</strong> Ubiquitination at H2A K27 can impact chromatin structure, promoting chromatin relaxation around DNA damage sites. This increased accessibility allows HR machinery to engage with damaged DNA more effectively.</li>
</ul>
Ubiquitination of H2A at lysine 27 (and, to some extent, lysine 13) plays a significant role in signaling and recruiting DNA repair machinery to sites of DNA damage. This process helps coordinate the DNA damage response and facilitates proper DNA repair through homologous recombination. In response to these ubiquitination marks, the balance and interplay between various proteins, such as BRCA1 and 53BP1, play a pivotal role in determining how DNA repair pathways are executed and how genomic stability is maintained.
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/32708614/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9332593/',
            'https://pubmed.ncbi.nlm.nih.gov/25578731/'
        ]
    },
    {
        name: 'H2AK15(K27)',
        preview:"Lysine residues on histone H2A, such as K15 and K27, are key sites for post-translational modifications, especially ubiquitination. These modifications significantly affect chromatin structure, DNA repair, and gene regulation.",
        function: `
        Lysine residues on histone H2A, such as K15 and K27, are key sites for post-translational modifications, especially ubiquitination. These modifications significantly affect chromatin structure, DNA repair, and gene regulation.
        <p>H2A K15 Ubiquitination</p>
        <p>Ubiquitination of H2A at lysine 15 (K15) has a central function in the DNA damage response (DDR), especially after double-strand breaks (DSBs). The following points highlight its significance:
        </p>
        <ul>
        <li><strong>Signal for DNA Repair:</strong> Following DNA damage, ubiquitination at H2A K15 signals to recruit repair proteins. E3 ubiquitin ligases like RNF8 and RNF168 ubiquitinate this site, attracting proteins like 53BP1, a key player in non-homologous end joining (NHEJ).</li>
        <li><strong>Chromatin Remodeling:</strong> Ubiquitination at H2A K15 can lead to chromatin remodeling, allowing repair proteins easier access to the damaged DNA. This increased accessibility is crucial for adequate DNA repair.</li>
        </ul>
        <p style="font-weight:600; font-size:18px">H2A K27 Ubiquitination</p>
        <p>Ubiquitination of H2A at lysine 27 (K27) is also notable for its role in DNA damage response and gene regulation:
        </p>
        <ul>
         <li><strong>DNA Damage Response:</strong> Like H2A K15, ubiquitination at H2A K27 is part of the signaling cascade following DNA damage. This modification can recruit proteins involved in DNA repair, impacting pathways like homologous recombination (HR) and non-homologous end joining (NHEJ).</li>
         <li><strong>Recruitment of Repair Proteins:</strong> H2A K27 ubiquitination aids in recruiting DNA repair proteins, further enhancing the DDR. The specific interactions depend on which proteins are involved in ubiquitinating this site and their role in the DDR.</li>
        </ul>
        <p style="font-size:20px; font-weight:600">Interactions and Functions</p>
<p>H2A K15 and K27 ubiquitination are critical for signaling and coordinating the DNA damage response. The modifications on these sites help determine the type of DNA repair pathway utilized and influence the efficiency of DNA repair. Ubiquitination at these sites often acts as a "beacon," attracting the necessary proteins to repair DNA damage.</p>
<p>H2A K15 and K27 ubiquitination play significant roles in DNA repair and chromatin remodeling. These modifications are critical signals in the DNA damage response, helping to recruit repair proteins and guide the choice of DNA repair pathways. The specific outcomes depend on the nature of the damage and the proteins involved in the signaling cascade.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/25578731/',
            'https://pubmed.ncbi.nlm.nih.gov/32708614/',
            'https://pubmed.ncbi.nlm.nih.gov/32894463'
        ]
    },
    {
        name: 'H2AK13(K67)',
        preview:"Lysine 67 (K67) on histone H2A can also be subject to post-translational modifications, including ubiquitination and methylation. These modifications can impact chromatin dynamics and other cellular processes.",
        function: `
        Lysine 67 (K67) on histone H2A can also be subject to post-translational modifications, including ubiquitination and methylation. These modifications can impact chromatin dynamics and other cellular processes.
        <ul>
        <li><strong>Ubiquitination of H2A K67:</strong> Ubiquitination at H2A K67 is less well-characterized than other sites (like H2A K13 and H2A K27). However, like other ubiquitination events on histones, it may play a role in signaling, chromatin remodeling, or protein-protein interactions related to the DNA damage response. Research into this site is ongoing, and its exact role in DNA repair or other cellular processes may vary.</li>
        <li><strong>Methylation of H2A K67:</strong> Methylation at H2A K67 can also occur, influencing chromatin structure and transcriptional regulation. The impact of this specific modification on DNA repair or other processes is an area of active study.</li>
        </ul>
        <p style="font-weight:600; font-size:20px">Role in Chromatin Structure and DNA Repair</p>
        <p>Post-translational modifications on histones like H2A can affect chromatin compaction and accessibility, influencing DNA repair pathways. In general, ubiquitination on histones, including H2A, is associated with signaling the presence of DNA damage and recruiting repair proteins to facilitate DNA repair processes. It can also lead to chromatin remodeling, allowing easier access for repair machinery.</p>
        <p>Both H2A K13 and K67 can undergo various post-translational modifications, with ubiquitination and methylation being common types. These modifications are essential in regulating chromatin structure, gene expression, and the DNA damage response. While H2A K13 is better understood in terms of its role in DNA repair, H2A K67 is less characterized, with ongoing research exploring its specific functions.</p>
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6172464/',
            'https://pubmed.ncbi.nlm.nih.gov/32708614/'
        ]
    },
    {
        name: 'H2AK15(K67)',
        preview:"Lysine 67 (K67) on histone H2A is another site where post-translational modifications, such as ubiquitination or methylation, can occur. Research on this specific site is less extensive compared to K15, but here's what is generally known:",
        function: `
        Lysine 67 (K67) on histone H2A is another site where post-translational modifications, such as ubiquitination or methylation, can occur. Research on this specific site is less extensive compared to K15, but here's what is generally known:
        <ul>
        <li><strong>Chromatin Structure:</strong> PTMs at H2A K67 can affect chromatin structure, influencing gene expression and other DNA-related processes. Although ubiquitination at this site is less well-studied than at other sites, it can potentially contribute to chromatin remodeling and gene regulation.</li>
<li><strong>Potential Role in DNA Repair:</strong> Given the broader roles of histone modifications in DNA repair, changes at H2A K67 might impact how accessible the chromatin is for repair processes. However, more research is needed to determine the specific function of H2A K67 ubiquitination in DNA repair pathways.</li>
        </ul>
        <p>Ubiquitination at H2A K15 is a well-established signal in the DNA damage response. It plays a key role in recruiting repair proteins and promoting chromatin remodeling after double-strand breaks. It is crucial for guiding the DNA repair pathway choice, particularly toward non-homologous end joining.</p>
<p>In contrast, the role of H2A K67 needs to be understood, with ongoing research exploring its impact on chromatin structure and other cellular processes. Given the importance of histone modifications in regulating DNA repair and gene expression, further studies are likely to reveal more about the specific roles of H2A K67 modifications in these contexts.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/32708614/'
        ]
    },
    {
        name: 'Ub K27',
        preview:"RNF168 (Ring Finger Protein 168) is an E3 ubiquitin ligase known for its role in the DNA damage response (DDR), specifically in signaling and coordinating the repair of DNA double-strand breaks (DSBs). One of its essential functions involves ubiquitination, particularly at lysine 27 (K27) on histone H2A.",
        function: `
        RNF168 (Ring Finger Protein 168) is an E3 ubiquitin ligase known for its role in the DNA damage response (DDR), specifically in signaling and coordinating the repair of DNA double-strand breaks (DSBs). One of its essential functions involves ubiquitination, particularly at lysine 27 (K27) on histone H2A.
<p style="font-weight:600; font-size:18px">RNF168 and Ubiquitination at K27</p>
<p>RNF168 plays a crucial role in the DNA damage response by ubiquitinating histone H2A at lysine 27 (K27). This modification is part of a signaling cascade that facilitates the recruitment of DNA repair proteins to sites of DNA damage. Here's how RNF168's ubiquitination at K27 contributes to the DDR:</p>
<ul>
<li><strong>Signal for DNA Damage Response:</strong> RNF168-mediated ubiquitination at H2A K27 is a critical signal indicating the presence of DNA double-strand breaks. This ubiquitination follows the initial recognition of DNA damage by proteins like RNF8, creating a platform for recruiting additional DNA repair factors.</li>
<li><strong>Recruitment of DNA Repair Proteins:</strong> Ubiquitination at H2A K27 serves as a docking site for various proteins involved in the DDR. This includes proteins like 53BP1 and BRCA1, which play crucial roles in different DNA repair pathways. The recruitment of these proteins is essential for orchestrating an effective DNA repair response.</li>
<li><strong>Propagation of the DNA Damage Signal:</strong> RNF168-mediated ubiquitination amplifies the DNA damage signal, ensuring the appropriate DNA repair machinery is recruited and activated. This amplification is crucial for efficient and accurate DNA repair.</li>
</ul>
<p style="font-weight:600; font-size:18px">Role in DNA Repair Pathways</p>
<p>RNF168's ubiquitination at K27 can impact the choice and effectiveness of DNA repair pathways:</p>
<ul>
<li><strong>Non-Homologous End Joining (NHEJ):</strong> The ubiquitination at H2A K27 facilitates the recruitment of proteins like 53BP1, which promotes NHEJ, a rapid but error-prone DNA repair pathway.</li>
<li><strong>Homologous Recombination (HR):</strong> RNF168 also contributes to homologous recombination by aiding in the recruitment of BRCA1, an essential protein in this high-fidelity DNA repair pathway. This recruitment is especially relevant when the cell has an undamaged homologous template for repair, as in the S and G2 phases of the cell cycle.</li>
</ul>
RNF168's ubiquitination at lysine 27 on histone H2A is pivotal in the DNA damage response. This modification signals the presence of DNA double-strand breaks and facilitates the recruitment of DNA repair proteins, helping guide the choice and execution of DNA repair pathways. This process is essential for maintaining genomic stability and preventing the propagation of cells with damaged DNA.
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/34706224/',
            'https://pubmed.ncbi.nlm.nih.gov/19203579/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9920519/',
            'https://pubmed.ncbi.nlm.nih.gov/33797206/'
        ]
    },
    {
        name: 'Ub K67',
        preview:"RNF168 (Ring Finger Protein 168) is an E3 ubiquitin ligase that acts in a crucial role in the DNA damage response (DDR), particularly in signaling and facilitating repair of DNA double-strand breaks (DSBs). It does so through ubiquitination, where it adds ubiquitin to target proteins, creating a signaling cascade that attracts other proteins to the sites of DNA damage. Histone H2A is a critical substrate for RNF168's ubiquitination activity, with specific lysine residues like K13, K15, K27, and K63 being well-studied in this context. However, ubiquitination at K67 is not as commonly discussed in the literature on RNF168.",
        function: `
        RNF168 (Ring Finger Protein 168) is an E3 ubiquitin ligase that acts in a crucial role in the DNA damage response (DDR), particularly in signaling and facilitating repair of DNA double-strand breaks (DSBs). It does so through ubiquitination, where it adds ubiquitin to target proteins, creating a signaling cascade that attracts other proteins to the sites of DNA damage. Histone H2A is a critical substrate for RNF168's ubiquitination activity, with specific lysine residues like K13, K15, K27, and K63 being well-studied in this context. However, ubiquitination at K67 is not as commonly discussed in the literature on RNF168.
<p style="font-weight:600; font-size:18px">General Role of RNF168 in Ubiquitination</p>
<p>RNF168's primary function is to extend the ubiquitination signal initiated by RNF8, another E3 ubiquitin ligase involved in the DDR. This extension consists of adding ubiquitin chains to histones, specifically H2A, which facilitates the recruitment of DNA repair proteins to damaged sites.</p>
<p style="font-weight:600; font-size:20px">Ubiquitination on H2A K67</p>
<p>If ubiquitination at histone H2A lysine 67 (K67) by RNF168 is referenced, it could suggest a unique or lesser-known role in the DDR or related processes. Ubiquitination at different lysine residues on H2A can have distinct effects:</p>
<ul>
<li><strong>Signaling and Recruitment:</strong> Ubiquitination at specific sites on histone H2A can signal the presence of DNA damage, leading to recruitment of DNA repair proteins like 53BP1 and BRCA1. These proteins are critical for orchestrating the DDR and guiding repair pathway choice (e.g., non-homologous end joining or homologous recombination).</li>
<li><strong>Chromatin Remodeling:</strong> Ubiquitination on histones can lead to changes in chromatin structure, allowing for easier access to DNA repair machinery. This chromatin remodeling is a crucial step in facilitating efficient DNA repair.</li>
</ul>  
`,
        reference: []
    },
    {
        name: 'RNF168',
        preview:"RNF168 is a RING (Really Interesting New Gene) domain-containing E3 ubiquitin ligase that plays a pivotal role in the DNA damage response, particularly in the context of DNA double-strand break (DSB) repair. Its primary function involves the addition of ubiquitin moieties to target proteins, which acts as a signal to recruit DNA repair factors to sites of DNA damage. This ubiquitination is crucial for coordinating the repair process.",
        function: `
        RNF168 is a RING (Really Interesting New Gene) domain-containing E3 ubiquitin ligase that plays a pivotal role in the DNA damage response, particularly in the context of DNA double-strand break (DSB) repair. Its primary function involves the addition of ubiquitin moieties to target proteins, which acts as a signal to recruit DNA repair factors to sites of DNA damage. This ubiquitination is crucial for coordinating the repair process.
        <ul>
        <li><strong>E3 Ubiquitin Ligase Activity:</strong> RNF168 is an E3 ubiquitin ligase, meaning it can attach ubiquitin to specific substrates. It is involved in extending K63-linked polyubiquitin chains on histones like H2A and H2AX, which are vital signals for recruiting DNA repair proteins to DSBs.</li>
        <li><strong>EAmplification of Ubiquitin Signaling:</strong> RNF168 builds upon the ubiquitination activity initiated by RNF8, another E3 ligase. It amplifies the ubiquitin signal, thereby enhancing the recruitment of various DNA repair proteins.</li>
        <li><strong>EAttraction of Repair Proteins:</strong> The K63-linked polyubiquitin chains generated by RNF168 act as a scaffold for the recruitment of crucial DNA repair proteins, including 53BP1 and BRCA1; these proteins are central to different pathways for repairing DSBs, including homologous recombination (HR) and non-homologous end joining (NHEJ).</li>
        <li><strong>EInteraction with DNA Repair Pathways:</strong> RNF168's ubiquitination signal attracts multiple DNA repair factors that determine the cell's pathway to repair DSBs. For example, 53BP1 is typically associated with NHEJ, while BRCA1 is critical to HR. RNF168's function in balancing the recruitment of these factors can influence the choice between these pathways.
        </li>
        <li><strong>ECollaboration with RNF8 and Other Factors:</strong> RNF168 works in concert with other proteins involved in the DNA damage response. It collaborates with RNF8, which initiates the ubiquitination process, and with proteins like MDC1, which facilitates the recruitment of RNF168 to DSBs.</li>
        <li><strong>EPropagation of the DNA Damage Response:</strong> By amplifying ubiquitin signaling, RNF168 plays a role in the broader DNA damage response, ensuring that a robust set of repair proteins is recruited to DSBs. This activity is essential for efficient and effective repair.</li>
        <li><strong>EImpact on DNA Repair Pathways:</strong> RNF168's role in amplifying ubiquitin signaling at DSB sites is critical for adequate DNA repair. Its proper functioning ensures that repair factors are appropriately recruited, reducing the risk of genomic instability.
        </li>
        <li><strong>EContribution to Tumor Suppression:</strong> Given its role in DSB repair, RNF168 contributes to maintaining genomic integrity. Mutations in RNF168 or disruptions in its function can lead to defective DNA repair, increasing the risk of genomic instability and cancer.</li>
        </ul>
        RNF168 is a crucial E3 ubiquitin ligase in the DNA damage response, with a central role in amplifying ubiquitination signals at sites of DNA double-strand breaks. Through its interactions with other DNA repair proteins, its collaboration with RNF8 and MDC1, and its ability to recruit HR and NHEJ factors, RNF168 is critical for efficient DNA repair and maintaining genomic stability. Its function ensures that repair factors are recruited and retained at damage sites, allowing accurate repair and reducing the risk of genomic instability and tumorigenesis.
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/34706224/',
            'https://pubmed.ncbi.nlm.nih.gov/19203579/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9920519/',
            'https://pubmed.ncbi.nlm.nih.gov/33797206/'
        ]
    },
    {
        name: 'H1 Histone.Ub',
        preview:"Histone H1 is a linker histone, playing a crucial role in organizing the higher-order structure of chromatin. It binds to the DNA that enters and exits the nucleosome core, thus stabilizing chromatin's compact structure. The addition of ubiquitin (ub) to Histone H1, commonly referred to as ubiquitination, is a post-translational modification with significant implications for various cellular processes, including DNA repair. Here is an overview of the protein function of Histone H1 with ubiquitination (H1-ub) in the context of DNA repair:",
        function: `
        Histone H1 is a linker histone, playing a crucial role in organizing the higher-order structure of chromatin. It binds to the DNA that enters and exits the nucleosome core, thus stabilizing chromatin's compact structure. The addition of ubiquitin (ub) to Histone H1, commonly referred to as ubiquitination, is a post-translational modification with significant implications for various cellular processes, including DNA repair. Here is an overview of the protein function of Histone H1 with ubiquitination (H1-ub) in the context of DNA repair:
        <ul>
        <li><strong>Stabilization of Higher-Order Chromatin:</strong> Histone H1 helps maintain the compact structure of chromatin by linking nucleosomes facilitating DNA packaging within the nucleus. This compaction plays a crucial role in regulating gene expression and access to DNA for replication and repair.</li>
<li><strong>Ubiquitin Attachment:</strong> The ubiquitination of Histone H1 involves adding ubiquitin molecules to specific lysine residues on the histone. This modification can impact the structure and function of chromatin, influencing processes like transcription, replication, and DNA repair.</li>
<li><strong>Relaxation of Chromatin Structure:</strong> Ubiquitination of Histone H1 can lead to chromatin relaxation, providing greater accessibility to DNA repair machinery. This relaxation is crucial when the cell needs to repair damaged DNA, allowing repair proteins to reach sites of DNA double-strand breaks and other types of DNA lesions.</li>
<li><strong>Recruitment of DNA Repair Proteins:</strong> The addition of ubiquitin to Histone H1 might create signals that recruit DNA repair proteins to sites of DNA damage. This recruitment is essential for the DNA damage response and the effective repair of DNA.</li>
<li><strong>Regulation of DNA Repair Pathways:</strong> By modulating chromatin structure through ubiquitination, Histone H1 can influence which DNA repair pathways are accessible. This modulation can affect the choice between homologous recombination (HR) and non-homologous end joining (NHEJ), impacting genomic stability.</li>
<li><strong>Coordination with Other Histones:</strong> The ubiquitination of Histone H1 can also interact with other histone modifications, like methylation or acetylation, to create a "histone code" that regulates DNA repair. This coordination is part of the broader epigenetic regulation that controls cellular processes.</li>
<li><strong>Impact on Chromatin Remodeling Complexes:</strong> Ubiquitination of Histone H1 may influence the recruitment and activity of chromatin remodeling complexes. These complexes are crucial in repositioning nucleosomes and remodeling chromatin, allowing DNA repair proteins to access damaged sites.</li>
<li><strong>Maintenance of Genomic Integrity:</strong> The ubiquitination of Histone H1 contributes to maintaining genomic integrity by promoting chromatin relaxation and facilitating DNA repair. Proper functioning of this process helps reduce the risk of mutations and genomic instability, which can lead to cancer.</li>
</ul>
The ubiquitination of Histone H1 (H1-ub) plays a significant role in DNA repair by affecting chromatin structure and facilitating the recruitment of DNA repair proteins to sites of DNA damage. This post-translational modification can lead to chromatin relaxation, providing greater access to the DNA repair machinery. It can influence the choice between different DNA repair pathways, contributing to genomic stability. Understanding the specific roles of H1-ub in the broader context of DNA repair and genomic regulation is crucial for insights into cellular responses to DNA damage and the mechanisms underlying genomic stability.
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/26503038/',
            'https://pubmed.ncbi.nlm.nih.gov/28624371/',
            'https://pubmed.ncbi.nlm.nih.gov/29127375/'
        ]
    },
    {
        name: 'L3MBTL2',
        preview:`L3MBTL2 (Lethal 3 malignant brain tumor-like protein 2) is a member of the "polycomb group" proteins involved in regulating gene expression and maintaining chromatin structure. It contains multiple MBT (malignant brain tumor) domains, known for their ability to recognize and bind to methylated histones. The role of L3MBTL2 in DNA repair is less well-studied than other proteins involved in this process. Still, emerging evidence suggests its involvement in maintaining genomic stability and potentially contributing to DNA repair mechanisms.`,
        function: `
        L3MBTL2 (Lethal 3 malignant brain tumor-like protein 2) is a member of the "polycomb group" proteins involved in regulating gene expression and maintaining chromatin structure. It contains multiple MBT (malignant brain tumor) domains, known for their ability to recognize and bind to methylated histones. The role of L3MBTL2 in DNA repair is less well-studied than other proteins involved in this process. Still, emerging evidence suggests its involvement in maintaining genomic stability and potentially contributing to DNA repair mechanisms.
        <ul>
        <li><strong>Chromatin Remodeling:</strong> L3MBTL2 is involved in chromatin remodeling, which plays an essential role in DNA repair by controlling access to DNA. By modifying chromatin structure, L3MBTL2 can influence the accessibility of DNA repair proteins to sites of DNA damage.</li>
<li><strong>Interaction with Transcription Factors:</strong> L3MBTL2 is known to be a component of multi-protein complexes that can repress transcription. This ability to regulate gene expression can indirectly impact DNA repair by controlling the expression of DNA repair-related genes or other factors involved in the DNA damage response.</li>
<li><strong>
<li><strong>Tumor Suppression:</strong> Given its role in chromatin organization and gene regulation, L3MBTL2 may contribute to genomic stability indirectly. Dysregulation of chromatin structure can lead to genomic instability, potentially resulting in cancer. Thus, L3MBTL2's proper function might help prevent tumorigenesis by maintaining the structural integrity of chromatin.</li>
<li><strong>Associations with Polycomb Complexes:</strong> L3MBTL2 is part of polycomb repressive complex 1 (PRC1), which has roles in gene repression. The indirect impact on DNA repair might come from its involvement in chromatin regulation and stabilization of genomic structures.</li>
</ul>
While L3MBTL2's exact role in DNA repair is not as extensively studied as other proteins in this domain, its function in chromatin organization, gene expression regulation, and potential interactions with methylated histones suggest it could contribute to maintaining genomic stability and possibly influence DNA repair pathways. Further research is required to understand its specific role in DNA repair and how it interacts with other DNA damage response machinery components.
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6889272/',
            'https://pubmed.ncbi.nlm.nih.gov/29581593/',
            'https://pubmed.ncbi.nlm.nih.gov/35521536/',
            'https://pubmed.ncbi.nlm.nih.gov/29276099/'
        ]
    },
    {
        name: 'RNF8',
        preview:"RNF8 is a key E3 ubiquitin ligase with a RING (Really Interesting New Gene) domain, playing an vital role in the DNA damage response and DNA repair pathways, particularly in the context of homologous recombination (HR) and non-homologous end joining (NHEJ). Here's an overview of RNF8's function in homologous recombination and its protein interactions:",
        function: `
        RNF8 is a key E3 ubiquitin ligase with a RING (Really Interesting New Gene) domain, playing an vital role in the DNA damage response and DNA repair pathways, particularly in the context of homologous recombination (HR) and non-homologous end joining (NHEJ). Here's an overview of RNF8's function in homologous recombination and its protein interactions:
        <p style="font-weight:600; font-size:18px">Function in Homologous Recombination:</p>
<ul>
        <li><strong>Ubiquitination at DNA Damage Sites:</strong> RNF8's primary function is to ubiquitinate proteins at sites of DNA damage, leading to a cascade of events that recruit additional repair proteins. Ubiquitination by RNF8 provides docking sites for other proteins involved in DNA damage response and homologous recombination.</li>
<li><strong>Recruitment of Repair Factors:</strong> RNF8 initiates a signaling cascade by ubiquitinating histones (like H2A and H2AX) at sites of DNA double-strand breaks. This ubiquitination serves as a signal for recruiting DNA repair proteins, such as BRCA1, 53BP1, and RAD51, to the damage site. These proteins play vital roles in the homologous recombination process.</li>
<li><strong>Interaction with Other DNA Repair Proteins:</strong> RNF8 works in conjunction with other DNA repair proteins, including RNF168, MDC1, and the MRN complex (Mre11, Rad50, Nbs1), to mediate the DNA damage response. RNF8 interacts with MDC1, a key mediator of DNA damage signaling, allowing it to initiate the ubiquitination cascade.</li>
</ul>
<p style="font-weight:600; font-size:18px">Protein Interactions in Homologous Recombination:</p>
<ul>
<li><strong>MDC1:</strong> RNF8 interacts with MDC1, which is involved in signaling and amplifying the DNA damage response. MDC1's binding to phosphorylated histone H2AX facilitates RNF8's recruitment to the damage site, which starts the ubiquitination process.</li>
<li><strong>RNF168:</strong> RNF8 collaborates with RNF168, another E3 ubiquitin ligase, to extend the ubiquitin chains on histones. RNF168's activity helps create a ubiquitination signal that recruits downstream repair factors like 53BP1 and BRCA1, which are critical for HR.</li>
<li><strong>BRCA1 and RAD51:</strong> The ubiquitination signal created by RNF8 and RNF168 leads to the recruitment of BRCA1, a key player in homologous recombination. BRCA1, in turn, recruits RAD51, the recombinase that facilitates strand invasion and homologous pairing, which are essential steps in HR.</li>
<li><strong>53BP1:</strong> Although 53BP1 primarily promotes non-homologous end joining (NHEJ), it is also recruited by ubiquitination signals from RNF8 and RNF168. Its role in HR is more complex, as it inhibits HR and promotes NHEJ. However, its recruitment is part of the broader DNA damage response mediated by RNF8.</li>
</ul>
<p style="font-weight:600; font-size:18px">Contribution to Genomic Stability:</p>
RNF8's function in homologous recombination and its interactions with other DNA repair proteins are crucial for maintaining genomic stability. By facilitating the recruitment of key HR factors and initiating a ubiquitination cascade, RNF8 helps ensure that DNA double-strand breaks are repaired accurately, reducing the risk of genomic instability and tumorigenesis.
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6535783/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7407225/',
            'https://pubmed.ncbi.nlm.nih.gov/23038782/',
            'https://pubmed.ncbi.nlm.nih.gov/25337968/'
        ]
    },
    {
        name: 'RNF8.Ub',
        preview:"RNF8 (Ring Finger Protein 8) is an E3 ubiquitin ligase that plays a crucial role in the DNA damage response (DDR), particularly in signaling and coordinating the repair of DNA double-strand breaks (DSBs). As an E3 ubiquitin ligase, RNF8 mediates the transfer of ubiquitin molecules to target proteins, marking them for various cellular outcomes such as degradation, signaling, or protein-protein interactions.",
        function: `
        RNF8 (Ring Finger Protein 8) is an E3 ubiquitin ligase that plays a crucial role in the DNA damage response (DDR), particularly in signaling and coordinating the repair of DNA double-strand breaks (DSBs). As an E3 ubiquitin ligase, RNF8 mediates the transfer of ubiquitin molecules to target proteins, marking them for various cellular outcomes such as degradation, signaling, or protein-protein interactions.
        <p style="font-weight:600; font-size:20px">Key Functions of RNF8 Ubiquitination</p>
<p>RNF8-mediated ubiquitination has several critical roles in the DDR and other cellular processes:</p>
<ul>
<li><strong>Signaling DNA Damage:</strong> RNF8 is activated upon detection of DNA damage, particularly DSBs, through its interaction with the MRN complex (MRE11, RAD50, NBS1). It facilitates the ubiquitination of histone H2A and other proteins at sites of DNA damage, signaling the presence of DNA breaks and initiating the recruitment of DNA repair proteins.</li>
<li><strong>Recruitment of DNA Repair Proteins:</strong> One of RNF8's primary functions is to recruit other proteins to sites of DNA damage through ubiquitination. RNF8-mediated ubiquitination creates docking sites for repair proteins, essential for coordinating the DNA damage response. This recruitment process involves proteins like 53BP1, BRCA1, and others critical for DNA repair pathways, such as non-homologous end joining (NHEJ) and homologous recombination (HR).</li>
<li><strong>Propagation of the DNA Damage Signal:</strong> RNF8-mediated ubiquitination is an early step in the DDR, amplifying the signal for DNA damage and coordinating the downstream recruitment of repair complexes. This function is crucial for an efficient and organized response to DNA damage.</li>
</ul>
<p style="font-weight:600; font-size:20px"Role in DNA Repair Pathways</p>
<p>RNF8 ubiquitination influences the choice and effectiveness of DNA repair pathways:</p>
<ul>
<li><strong>Non-Homologous End Joining (NHEJ):</strong> RNF8's ubiquitination activity recruits 53BP1, which promotes NHEJ, a quick but error-prone DNA repair pathway often used without a homologous template.</li>
<li><strong>Homologous Recombination (HR):</strong> RNF8 also recruits BRCA1, which is essential for HR, a high-fidelity DNA repair pathway. RNF8-mediated ubiquitination helps guide the repair process towards HR when appropriate, especially during the S and G2 phases of the cell cycle when a homologous template is available.</li>
</ul>
RNF8's ubiquitination function is vital for signaling and coordinating the DNA damage response. By facilitating the ubiquitination of histones and other proteins at sites of DNA damage, RNF8 helps recruit and organize the assembly of DNA repair complexes, influencing the choice and effectiveness of DNA repair pathways like NHEJ and HR. This role is critical for maintaining genomic stability and preventing the propagation of cells with damaged DNA.
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6535783/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7407225/',
            'https://pubmed.ncbi.nlm.nih.gov/23038782/',
            'https://pubmed.ncbi.nlm.nih.gov/25337968/'
        ]
    },
    {
        name: 'UBC13',
        preview:"UBC13, also known as UBE2N, is a ubiquitin-conjugating enzyme (E2 enzyme) with a unique role in the ubiquitination process, particularly in forming K63-linked polyubiquitin chains. These chains are crucial signals for various cellular processes, including DNA repair. In the homologous recombination (HR) context, UBC13 has essential functions in facilitating protein interactions and signaling at DNA damage sites.",
        function: `
        UBC13, also known as UBE2N, is a ubiquitin-conjugating enzyme (E2 enzyme) with a unique role in the ubiquitination process, particularly in forming K63-linked polyubiquitin chains. These chains are crucial signals for various cellular processes, including DNA repair. In the homologous recombination (HR) context, UBC13 has essential functions in facilitating protein interactions and signaling at DNA damage sites.
        <p style="font-size: 20px; font-weight:600">Ubiquitin-Conjugating Activity:</p>
        <p>Formation of K63-Linked Polyubiquitin Chains: UBC13 is unique among E2 enzymes in its ability to synthesize K63-linked polyubiquitin chains. Unlike K48-linked chains, which typically signal protein degradation, K63-linked chains serve as scaffolds for protein-protein interactions and signaling in processes like DNA repair.</p>
        <p style="font-size: 20px; font-weight:600">Role in Homologous Recombination:</p>
        <ul>
        <li><strong>Recruitment of DNA Repair Proteins:</strong> K63-linked polyubiquitin chains created by UBC13 act as a signal for recruiting proteins involved in the DNA damage response and homologous recombination by partnering with E3 ligases like RNF8 and RNF168, UBC13 helps generate the ubiquitin signals that attract essential HR proteins to sites of DNA double-strand breaks.</li>
        <li><strong>Collaboration with E3 Ligases:</strong> UBC13 works closely with E3 ligases like RNF8 and RNF168 to catalyze the formation of K63-linked polyubiquitin chains. This collaboration is critical for recruiting and localizing repair factors essential for HR, such as BRCA1, 53BP1, and others.</li>
        <li><strong>Coordination of DNA Damage Response Signaling:</strong> UBC13's activity in creating K63-linked polyubiquitin chains helps coordinate the DNA damage response. These ubiquitin chains serve as docking sites for proteins involved in the HR pathway, facilitating the assembly of repair complexes and the initiation of homologous recombination.</li>
        </ul>
        <p style="font-size: 20px; font-weight:600">Impact on DNA Repair Pathways:</p>
        <ul>
        <li><strong>Regulation of HR and Other Repair Pathways:</strong> UBC13's role in generating ubiquitin signals can influence the choice between HR and other DNA repair pathways. By recruiting HR factors, UBC13 supports the accurate repair of DNA double-strand breaks through HR, helping to maintain genomic stability.</li>
        </ul>
        <p style="font-size: 20px; font-weight:600">Contribution to Genomic Stability:</p>
        <ul>
        <li><strong>Stabilization of DNA Repair Processes:</strong> UBC13's activity in creating K63-linked polyubiquitin chains is essential for the stability and coordination of DNA repair processes. This stability is crucial for maintaining genomic integrity and preventing mutations or chromosomal aberrations that can lead to cancer.</li>
        <li><strong>Tumor Suppression:</strong> Given its role in HR and genomic stability, UBC13 is indirectly linked to tumor suppression. The proper function of UBC13 helps ensure efficient and accurate DNA repair, reducing the risk of cancer-causing mutations.</li>
        </ul>
        UBC13 plays a pivotal role in homologous recombination by forming K63-linked polyubiquitin chains, which serve as signals for recruiting and coordinating DNA repair proteins. Through its collaboration with E3 ligases and its influence on DNA repair pathways, UBC13 contributes to genomic stability and tumor suppression by facilitating accurate DNA repair. This activity is critical for the successful execution of homologous recombination and the prevention of genomic instability.
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5325457/',
            'https://www.nature.com/articles/s41467-021-25346-4',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7155233/'
        ]
    },
    {
        name: 'Mms2',
        preview:"Mms2 (Maintenance of Methylation-Sensitive 2) is a critical component of the ubiquitin-proteasome system involved in DNA repair, particularly in the context of ubiquitination-mediated signaling. An E2 ubiquitin-conjugating enzyme forms a complex with another E2 enzyme, Ubc13, to catalyze the formation of K63-linked polyubiquitin chains. These chains are unique because they primarily serve as signaling scaffolds rather than signals for protein degradation. Here's a detailed breakdown of Mms2's function in DNA repair:",
        function: `
        Mms2 (Maintenance of Methylation-Sensitive 2) is a critical component of the ubiquitin-proteasome system involved in DNA repair, particularly in the context of ubiquitination-mediated signaling. An E2 ubiquitin-conjugating enzyme forms a complex with another E2 enzyme, Ubc13, to catalyze the formation of K63-linked polyubiquitin chains. These chains are unique because they primarily serve as signaling scaffolds rather than signals for protein degradation. Here's a detailed breakdown of Mms2's function in DNA repair:
<p style="font-size:18px; font-weight:600">E2 Ubiquitin-Conjugating Activity:</p>
<ul>
<li><strong>Formation of K63-Linked Polyubiquitin Chains:</strong> Mms2, in partnership with Ubc13, creates K63-linked polyubiquitin chains. These chains are distinct from the more common K48-linked chains, which usually signal protein degradation. K63-linked chains, in contrast, are used for signaling in various cellular processes, including DNA repair.</li>
<li><strong>Recruitment of DNA Repair Factors:</strong> The K63-linked polyubiquitin chains created by the Mms2/Ubc13 complex act as signals that recruit DNA repair proteins to sites of DNA damage. This recruitment is crucial for coordinating the DNA damage response and initiating repair processes.</li>
<li><strong>Contribution to DNA Double-Strand Break Repair:</strong> Mms2 is significant in repairing DNA double-strand breaks, mainly through homologous recombination (HR) and non-homologous end joining (NHEJ). The polyubiquitin chains generated by Mms2/Ubc13 serve as a scaffold for recruiting repair factors such as BRCA1, RAD51, and 53BP1.</li>
<li><strong>Collaboration with E3 Ligases:</strong> Mms2 works closely with E3 ubiquitin ligases, particularly RNF8 and RNF168, to create the polyubiquitin signals at sites of DNA damage. The E3 ligases initiate ubiquitination, and the Mms2/Ubc13 complex extends these chains, amplifying the ubiquitin signal.</li>
<li><strong>Signal Amplification:</strong> By extending K63-linked polyubiquitin chains, Mms2 helps amplify the DNA damage response signal, ensuring that a robust set of DNA repair proteins is recruited to double-strand breaks. This amplification is critical for adequate DNA repair and maintaining genomic stability.</li>
<li><strong>Support for Homologous Recombination:</strong> Through its role in forming K63-linked polyubiquitin chains, Mms2 supports homologous recombination, a high-fidelity DNA repair mechanism. Proper HR is crucial for maintaining genomic stability and preventing tumorigenesis.</li>
<li><strong>Impact on Tumor Suppression:</strong> Given its role in DNA repair and genomic stability, Mms2 indirectly contributes to tumor suppression. By facilitating accurate repair of DNA damage, Mms2 helps reduce the risk of mutations and chromosomal aberrations that could lead to cancer.</li>
</ul>
Mms2 is a key E2 ubiquitin-conjugating enzyme that, in partnership with Ubc13, catalyzes the formation of K63-linked polyubiquitin chains. These chains are central to signaling and recruitment in DNA repair, particularly in the context of homologous recombination and non-homologous end joining. By contributing to the ubiquitin-mediated signaling cascade, Mms2 plays a crucial role in coordinating the DNA damage response, supporting genomic stability, and reducing the risk of tumorigenesis.
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/10089880/',
            'https://pubmed.ncbi.nlm.nih.gov/36934633/',
            'https://pubmed.ncbi.nlm.nih.gov/16980971/'
        ]
    },
    {
        name: 'Ring',
        preview:"RING (Really Interesting New Gene) domains are structural motifs that define a class of E3 ubiquitin ligases. E3 ubiquitin ligases are critical players in the ubiquitin-proteasome system, responsible for transferring ubiquitin to target proteins. This ubiquitination can lead to various outcomes, from protein degradation to signaling and protein-protein interactions. In the homologous recombination (HR) context, RING domains regulate protein interactions and signaling, affecting DNA repair processes.",
        function: `
        RING (Really Interesting New Gene) domains are structural motifs that define a class of E3 ubiquitin ligases. E3 ubiquitin ligases are critical players in the ubiquitin-proteasome system, responsible for transferring ubiquitin to target proteins. This ubiquitination can lead to various outcomes, from protein degradation to signaling and protein-protein interactions. In the homologous recombination (HR) context, RING domains regulate protein interactions and signaling, affecting DNA repair processes.
        <p style="font-size:20px; font-weight:600">Function of RING Domains in DNA Repair:</p>
<ul>
        <li><strong>E3 Ubiquitin Ligase Activity:</strong> RING domains enable proteins to function as E3 ubiquitin ligases, which mediate the attachment of ubiquitin to specific target proteins. In HR, this ubiquitination activity is central to recruiting, regulating, and orchestrating the repair process at sites of DNA damage.</li>
<li><strong>Creation of Ubiquitin Signals:</strong> RING domain-containing proteins, through their E3 ligase activity, create ubiquitin signals at sites of DNA damage. These ubiquitin signals can attract and retain essential DNA repair proteins necessary for HR. Ubiquitination can result in mono-ubiquitination or poly-ubiquitination, each having different implications for protein interactions.</li>
</ul>
<p style="font-size:20px; font-weight:600">Role of RING Domains in Homologous Recombination:</p>
<ul>
<li><strong>Recruitment of Repair Factors:</strong> The ubiquitination signals generated by RING domain-containing proteins attract a host of DNA repair factors. For example, RNF8 and RNF168 are two key RING domain-containing E3 ligases that play pivotal roles in recruiting BRCA1, 53BP1, and other HR-related proteins to sites of DNA damage.</li>
<li><strong>Regulation of DNA Repair Pathways:</strong> RING domain proteins can influence the balance between DNA repair pathways, such as HR and non-homologous end joining (NHEJ). Through their ubiquitination activity, they can create a favorable environment for HR or, in some cases, inhibit HR to promote NHEJ.</li>
</ul>
<p style="font-size:20px; font-weight:600">Key RING Domain-Containing Proteins in Homologous Recombination:</p>
<ul>
<li><strong>RNF8:</strong> As an E3 ligase, RNF8's RING domain enables it to ubiquitinate histones like H2A and H2AX at DNA double-strand breaks, initiating a cascade that recruits HR factors like BRCA1 and RAD51. RNF8's role is essential for the early stages of HR.</li>
<li><strong>RNF168:</strong> Working downstream of RNF8, RNF168 extends ubiquitin chains at DNA damage sites, strengthening the ubiquitin signal. This facilitates the recruitment of additional HR factors, such as BRCA1 and RAD51, and promotes DNA end resection, a crucial step in HR.</li>
</ul>
<p style="font-size:18px; font-weight:600">Impact on Genomic Stability:</p>
RING domain-containing proteins play a significant role in maintaining genomic stability through their contributions to HR. By regulating the ubiquitination of crucial repair proteins, RING domains ensure that HR is appropriately initiated and executed, reducing the risk of genomic instability and tumorigenesis. The proper function of these proteins is critical for the fidelity of DNA repair and the prevention of mutations or chromosomal aberrations that could lead to cancer.

        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3705210/',
            'https://pubmed.ncbi.nlm.nih.gov/19489725/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8486888/'
        ]
    },
    {
        name: 'FHA',
        preview:"The FHA (Forkhead-Associated) domain is a protein structural motif that recognizes phosphorylated amino acid residues, particularly phosphothreonine, in target proteins. Proteins containing FHA domains play crucial roles in signaling, cell cycle control, and DNA repair processes, including homologous recombination (HR). Here's an outline of the molecular functions of the FHA domain in the context of homologous recombination:",
        function: `
        The FHA (Forkhead-Associated) domain is a protein structural motif that recognizes phosphorylated amino acid residues, particularly phosphothreonine, in target proteins. Proteins containing FHA domains play crucial roles in signaling, cell cycle control, and DNA repair processes, including homologous recombination (HR). Here's an outline of the molecular functions of the FHA domain in the context of homologous recombination:
        <ul>
        <li><strong>Phosphothreonine Recognition:</strong> The FHA domain specializes in binding to phosphorylated threonine residues. This ability allows proteins with FHA domains to recognize and bind other phosphorylated proteins or peptides in response to cell cycle, or DNA damage signaling.</li>
        <li><strong>Recruitment of DNA Repair Proteins:</strong> In homologous recombination, recognizing phosphorylated targets by FHA domains can play a vital role in recruiting DNA repair proteins to sites of DNA damage. This recruitment is crucial for coordinating and assembling the HR machinery at DNA double-strand break sites.</li>
        <li><strong>Signal Transduction in DNA Damage Response:</strong> Proteins with FHA domains often participate in signal transduction pathways associated with the DNA damage response. By binding to phosphorylated targets, these proteins can transmit signals that activate or regulate DNA repair processes, including HR.</li>
        <li><strong>Coordination of Homologous Recombination:</strong> In HR, precise coordination of multiple proteins and enzymes is essential. FHA domain-containing proteins help coordinate these activities by binding to specific phosphorylated targets, thereby regulating the assembly of HR complexes.</li>
        <li>For example, the FHA domain in proteins like Nbs1 (part of the MRN complex), CHK2, and MDC1 are involved in the response to DNA double-strand breaks, helping to initiate and regulate HR. Many FHA domain-containing proteins also interact with kinases involved in the DNA damage response. For example, ATM and ATR are major kinases that phosphorylate various targets in response to DNA damage, creating binding sites for FHA domain proteins. This interaction facilitates the recruitment and regulation of HR-related proteins.</li>
<li><strong>Regulation of Protein-Protein Interactions:</strong> The binding specificity of the FHA domain allows it to regulate protein-protein interactions, which is critical in HR. By recognizing specific phosphorylation patterns, FHA domain-containing proteins can control the formation of protein complexes that mediate DNA repair.</li>
<li><strong>Role in Cell Cycle Control:</strong> Homologous recombination is most active in the S and G2 cell cycle sphases when sister chromatids are available as templates for repair. FHA domain-containing proteins can influence cell cycle regulation, ensuring that HR occurs at the appropriate stage. By binding to phosphorylated targets involved in cell cycle regulation, these proteins can contribute to maintaining genomic stability during HR.</li>
</ul>
In summary, the molecular function of the FHA domain in homologous recombination revolves around its ability to recognize and bind phosphorylated threonine residues. This function is crucial for recruiting DNA repair proteins, transmitting DNA damage response signals, coordinating HR activities, and regulating cell cycle processes. Proteins containing FHA domains play essential roles in ensuring that HR is accurately and efficiently executed, which is crucial in maintaining genome integrity and preventing genomic instability.
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/33220551/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8079451/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3753707/',
            'https://pubmed.ncbi.nlm.nih.gov/18597043/'
        ]
    },
    {
        name: 'USP28',
        preview:`USP28 (Ubiquitin-Specific Protease 28) is a deubiquitinating enzyme (DUB) that removes ubiquitin moieties from target proteins. This deubiquitination can affect protein stability, localization, and function, influencing various cellular processes, including DNA repair. In DNA repair, USP28 regulates the ubiquitin-proteasome system, affecting the stability of proteins involved in DNA damage response. Let's explore USP28's function in DNA repair:`,
        function: `
        USP28 (Ubiquitin-Specific Protease 28) is a deubiquitinating enzyme (DUB) that removes ubiquitin moieties from target proteins. This deubiquitination can affect protein stability, localization, and function, influencing various cellular processes, including DNA repair. In DNA repair, USP28 regulates the ubiquitin-proteasome system, affecting the stability of proteins involved in DNA damage response. Let's explore USP28's function in DNA repair:
        <ul>
        <li><strong>Removal of Ubiquitin:</strong> USP28's primary role is to remove ubiquitin from specific targets. This action can influence the proteins' stability in the DNA damage response, regulating signaling pathways and repair processes.</li>
        <li><strong>Regulation of Protein Stability:</strong> USP28 can stabilize critical proteins involved in the DNA damage response by removing ubiquitin, preventing their degradation. This function is crucial for maintaining the correct levels of DNA repair factors at sites of DNA damage.</li>
        <li><strong>Interactions with DNA Repair Proteins:</strong> USP28 interacts with several proteins involved in DNA repair pathways, including homologous recombination (HR) and non-homologous end joining (NHEJ). Its ability to deubiquitinate these proteins helps ensure their effective function.</li>
        <li><strong>Influence on DNA Repair Pathway Choice:</strong> USP28 can regulate the stability of proteins involved in DNA repair, affecting the choice between different DNA repair pathways. This ability to modulate pathway choice is critical to ensuring proper DNA repair and genomic stability.</li>
        <li><strong>Impact on 53BP1:</strong> USP28 has been shown to influence the stability and function of 53BP1, a protein critical for the DNA damage response and NHEJ. By deubiquitinating proteins that affect 53BP1's activity, USP28 can impact the overall DNA repair process.</li>
        <li><strong>Maintenance of Genomic Stability:</strong> USP28's role in stabilizing proteins involved in DNA repair contributes to genomic stability. The proper function of USP28 can help ensure that DNA damage is repaired accurately, reducing the risk of mutations and chromosomal aberrations.
        </li>
        <li><strong>Tumor Suppression: Given its role in DNA repair and genomic stability, USP28 may have a role in tumor suppression. Disruptions in USP28 function or regulation can lead to defects in the DNA damage response, potentially increasing cancer risk.
        </li>
        </ul>
        USP28 plays an essential role in DNA repair through its deubiquitination activity, which regulates the stability of critical proteins involved in the DNA damage response. By removing ubiquitin from target proteins, USP28 can influence the stability, localization, and interactions of DNA repair factors, contributing to the effective repair of DNA damage and the maintenance of genomic stability.

        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4019063/',
            'https://pubmed.ncbi.nlm.nih.gov/34611298/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5065530/'
        ]
    },
    {
        name: 'USP48',
        preview:"USP48 (Ubiquitin-Specific Protease 48) is a deubiquitinating enzyme (DUB) that can remove ubiquitin moieties from proteins. Deubiquitination by USP48 can affect protein stability, localization, and interactions, thereby influencing cellular processes, including DNA repair. In DNA repair, USP48 plays a role in regulating the ubiquitin signal, particularly at sites of DNA damage.",
        function: `
        USP48 (Ubiquitin-Specific Protease 48) is a deubiquitinating enzyme (DUB) that can remove ubiquitin moieties from proteins. Deubiquitination by USP48 can affect protein stability, localization, and interactions, thereby influencing cellular processes, including DNA repair. In DNA repair, USP48 plays a role in regulating the ubiquitin signal, particularly at sites of DNA damage.
        <ul>
        <li><strong>Removal of Ubiquitin:</strong> USP48's primary function is to remove ubiquitin from target proteins. This deubiquitination activity is critical in modulating the ubiquitin-proteasome system involved in protein degradation and signaling pathways in the DNA damage response.</li>
        <li><strong>Regulation of Ubiquitin Signals at DNA Damage Sites:</strong> USP48 is involved in deubiquitinating proteins at sites of DNA damage. This action can influence the stability and signaling of proteins recruited to repair DNA double-strand breaks, thus affecting the DNA repair process.</li>
        <li><strong>Modulation of DNA Damage Response:</strong> By removing ubiquitin from specific proteins involved in the DNA damage response, USP48 can modulate the signaling pathways that govern DNA repair. This modulation can affect the balance between different DNA repair pathways and the timing of repair processes.</li>
        <li><strong>Influence on 53BP1 Recruitment:</strong> USP48 has been shown to impact the recruitment and function of 53BP1, a critical protein in the DNA damage response, especially in non-homologous end joining (NHEJ). By deubiquitinating proteins that influence 53BP1's recruitment, USP48 can affect the DNA repair pathway choice.</li>
        <li><strong>Interaction with Ubiquitin Chains:</strong> USP48 can remove ubiquitin chains from proteins at DNA damage sites, impacting the recruitment and retention of DNA repair factors. This deubiquitination activity helps fine-tune the DNA damage response, ensuring proper coordination of repair processes.</li>
        <li><strong>Control of DNA Repair Pathways:</strong> USP48 can regulate the ubiquitin signal to influence whether cells undergo homologous recombination (HR) or non-homologous end joining (NHEJ). This regulation is crucial for maintaining genomic stability, as different pathways have different levels of fidelity.</li>
        <li><strong>Prevention of Genomic Instability:</strong> USP48's DNA repair function prevents genomic instability. Proper deubiquitination can ensure that DNA damage is repaired accurately and that the appropriate proteins are stabilized, reducing the risk of mutations and chromosomal aberrations.
        </li>
        </ul>
        USP48 plays a crucial role in DNA repair through its deubiquitination activity. By removing ubiquitin from proteins involved in the DNA damage response, USP48 can modulate the stability and function of DNA repair factors, influencing pathway choice, protein interactions, and the overall efficiency of DNA repair. This role is vital for maintaining genomic stability and preventing tumorigenesis. Through its regulation of ubiquitin signals, USP48 ensures that DNA repair processes are appropriately coordinated, contributing to the accurate repair of DNA double-strand breaks and reducing the risk of genomic instability.
        `,
        reference: [
            'https://www.nature.com/articles/s41467-017-02653-3',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9332593/',
            'https://pubmed.ncbi.nlm.nih.gov/29335415/'
        ]
    },
    {
        name: 'p53',
        preview:"The protein P53, encoded by the TP53 gene, is not just a crucial tumor suppressor and transcription factor. It has a diverse range of roles in maintaining genome stability, including the DNA repair pathway known as homologous recombination (HR). These roles of P53 in DNA repair and the HR pathway are:",
        function: `
        The protein P53, encoded by the TP53 gene, is not just a crucial tumor suppressor and transcription factor. It has a diverse range of roles in maintaining genome stability, including the DNA repair pathway known as homologous recombination (HR). These roles of P53 in DNA repair and the HR pathway are:
        <ul>
        <li>Transcriptional Regulation: The primary function of P53 is as a transcription factor. It regulates the expression of a wide range of genes involved in cell cycle control, apoptosis, and DNA repair. The activation of P53 occurs when DNA damage is detected. This activation leads to the transcription of target genes involved in the DNA damage response, thereby initiating the repair process.</li>
        <li>Cell Cycle Arrest: One of P53's critical roles in DNA repair is inducing cell cycle arrest, which allows cells more time to repair damaged DNA before progressing through the cell cycle. P53 can upregulate genes like CDKN1A (encoding p21), which inhibits cyclin-dependent kinases (CDKs), leading to cell cycle arrest in the G1 phase.</li>
        <li>Apoptosis: If DNA damage is severe and irreparable, P53 can trigger apoptosis, a programmed cell death process, to prevent the propagation of damaged cells. This function is essential for preventing the development and spread of cancer.</li>
        <li>Homologous Recombination (HR): In the context of HR, a high-fidelity DNA repair mechanism for repairing double-strand breaks (DSBs), P53 supports maintaining genomic stability. While P53 does not directly participate in the HR repair process like proteins such as RAD51 or BRCA1, it influences HR through transcriptional regulation of critical genes and maintaining proper cell cycle control to facilitate efficient repair.
        </li>
        <li>Regulation of HR-related Genes: P53 can affect gene expression in HR. For example, P53 can regulate the expression of BRCA1 and BRCA2, critical components in the HR pathway that help stabilize DNA and recruit repair proteins to DSB sites.</li>
        <li>Prevention of Error-Prone Repair: By promoting cell cycle arrest and controlling the levels of specific DNA repair proteins, P53 helps ensure that HR is efficient and that error-prone repair pathways (like non-homologous end joining or NHEJ) do not compromise genome stability.</li>
        </ul>
        Therefore, the protein function of p53 in the DNA repair pathway HR is not just a mere participation, but a crucial regulatory hub. It coordinates transcriptional responses, cell cycle control, and, when necessary, apoptosis, to ensure effective DNA repair and maintain genomic integrity. Its role in regulating key genes involved in HR and facilitating cell cycle arrest during repair is not just important, but critical. It is this criticality that prevents mutations that can lead to cancer and other genetic disorders.
        `,
        reference: [
            'https://www.nature.com/articles/cdd2017174',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4772082/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4235614/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC86298/'
        ]
    },
    {
        name: 'p53.p',
        preview:"It activates p21 axis thus initiating a stop signal for cell cyle in a Cyclin-mediated pathway",
        function: 'It activates p21 axis thus initiating a stop signal for cell cyle in a Cyclin-mediated pathway',
        reference: [
            'https://www.cell.com/molecular-cell/fulltext/S1097-2765(16)30416-6?_returnURL=https%3A%2F%2Flinkinghub.elsevier.com%2Fretrieve%2Fpii%2FS1097276516304166%3Fshowall%3Dtrue'
        ]
    },
    {
        name: 'p21',
        preview:"P21, aCDKN1A (cyclin-dependent kinase inhibitor 1A), is a regulatory protein with multiple roles in cell cycle control, DNA damage response, and repair. It is a well-known target of the tumor suppressor protein P53, and its regulation is crucial for cellular responses to DNA damage. The functions of P21 in the context of DNA repair:",
        function: `
        P21, aCDKN1A (cyclin-dependent kinase inhibitor 1A), is a regulatory protein with multiple roles in cell cycle control, DNA damage response, and repair. It is a well-known target of the tumor suppressor protein P53, and its regulation is crucial for cellular responses to DNA damage. The functions of P21 in the context of DNA repair:
        <ul>
        <li><strong>Cell Cycle Arrest:</strong> P21 is a crucial mediator of cell cycle arrest, particularly in response to DNA damage. It inhibits cyclin-dependent kinases (CDKs), specifically CDK2, CDK4, and CDK6, leading to a halt in cell cycle progression at the G1 phase. This arrest allows cells time to repair damaged DNA before cell division, reducing the risk of transmitting DNA errors.</li>
        <li><strong>Role in DNA Repair:</strong> P21, primarily known for its role in cell cycle regulation, it can also influence DNA repair mechanisms: 
        <ul style="list-style-type:circle; margin-left:15px; margin-top:8px">
        <li><strong>Coordination of Repair Pathways:</strong> Inducing cell cycle arrest, P21 provides a stable environment for DNA repair processes. This action helps ensure that repair mechanisms, like homologous recombination (HR) and nucleotide excision repair (NER), have the time and resources needed to fix DNA damage effectively.</li>
        <li><strong>Interacting with DNA Repair Proteins:</strong> P21 can interact with various DNA repair proteins, affecting their stability and activity. For example, P21 can bind to proliferating cell nuclear antigen (PCNA), a key component in DNA replication and repair. This interaction can modulate PCNA's activity and help regulate the balance between DNA repair and replication.
        </li>
        <li><strong>Protection from Apoptosis:</strong> In some contexts, P21 can protect cells from undergoing apoptosis, providing additional time for DNA repair. This role is complex and context-dependent, as it can also contribute to cellular resistance to specific cancer therapies by allowing damaged cells to survive longer.</li>
        </ul>
        </li>
        <li><strong>Role in Genomic Stability:</strong> By facilitating cell cycle arrest and allowing repair pathways to operate effectively, P21 contributes to maintaining genomic stability. This role is crucial for preventing mutations and chromosomal aberrations leading to cancer and other genetic diseases.</li>
        </ul>
        In summary, P21 plays a multifaceted role in DNA repair by regulating cell cycle progression, coordinating with DNA repair proteins, and providing a stable environment for repair processes. Its ability to interact with various components of the cell cycle and DNA repair machinery makes it a crucial mediator in the cellular response to DNA damage, contributing to genomic integrity and stability.
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/27156098/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3458556/',
            'https://pubmed.ncbi.nlm.nih.gov/35806061/'
        ]
    },
    {
        name: 'CIP1',
        preview:"It is a Cyclin-dependent kinase (CDK) inhibitor",
        function: 'It is a Cyclin-dependent kinase (CDK) inhibitor',
        reference: [
            'The p53/p73 - p21CIP1 tumor suppressor axis guards against chromosomal instability by restraining CDK1 in human cancer cells | Oncogene (nature.com)',
        ]
    },
    {
        name: 'GADD45',
        preview:"GADD45 (Growth Arrest and DNA Damage-Inducible 45) proteins are involved in various cellular stress responses, including DNA repair, apoptosis, and cell cycle regulation. While these proteins are best known for their roles in base excision repair (BER), nucleotide excision repair (NER), and cell cycle arrest, they also have functions in homologous recombination (HR), a critical pathway for repairing DNA double-strand breaks (DSBs).",
        function: `
        GADD45 (Growth Arrest and DNA Damage-Inducible 45) proteins are involved in various cellular stress responses, including DNA repair, apoptosis, and cell cycle regulation. While these proteins are best known for their roles in base excision repair (BER), nucleotide excision repair (NER), and cell cycle arrest, they also have functions in homologous recombination (HR), a critical pathway for repairing DNA double-strand breaks (DSBs).
        <ul>
        <li><strong>DNA Damage Response and Regulation:</strong>GADD45 proteins are induced by cellular stress signals, including DNA damage from ultraviolet (UV) radiation, ionizing radiation, and chemical agents. Through pathways involving p53 and other stress-related transcription factors, GADD45 proteins are upregulated, initiating DNA repair processes, including HR.</li>
        <li><strong>Interaction with DNA Repair Proteins:</strong> GADD45 proteins are known to interact with various DNA repair-related proteins, such as BRCA1 and p53. These interactions suggest a role in coordinating the DNA damage response and facilitating the repair of double-strand breaks through HR.</li>
        <li><strong>Promotion of Homologous Recombination:</strong> Although the primary focus of GADD45 in DNA repair has been on other pathways like BER and NER, research indicates that these proteins may have a supportive role in HR. By stabilizing the DNA repair machinery, GADD45 could help facilitate the alignment and pairing of homologous sequences, a critical step in HR.</li>
        <li><strong>Cell Cycle Regulation for DNA Repair:</strong> One of GADD45's critical roles is inducing cell cycle arrest, which gives cells more time to repair DNA damage. This function is particularly important in the context of homologous recombination, which is typically more active in the S and G2 phases of the cell cycle. Cell cycle arrest induced by GADD45 provides additional time for HR to occur effectively.</li>
        <li><strong>Support for Genomic Stability:</strong> GADD45 proteins help maintain genomic stability by enabling cells to respond to DNA damage and initiate repair properly. Includes facilitation of the accurate repair of double-strand breaks through HR, which is crucial for preserving genetic information and preventing chromosomal aberrations.</li>
        </ul>
        While the exact molecular mechanisms by which GADD45 influences homologous recombination are still being explored, its general role in promoting cell cycle arrest, coordinating with DNA repair proteins and supporting genomic stability likely indirectly contributes to the successful execution of HR. Given that HR is a high-fidelity repair mechanism, the function of GADD45 in this context is critical for maintaining genome integrity and reducing cancer risk.
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3797964/',
            'https://pubmed.ncbi.nlm.nih.gov/32013256/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6754676/'
        ]
    },
    {
        name: 'TIP60',
        preview:`TIP60 (Tat-interactive protein, 60 kDa) is a histone acetyltransferase (HAT) protein that 
        catalyzes the acetylation of lysine residues on histone proteins. TIP60 modifies chromatin 
        structure, leading to the relaxation of the chromatin and increased accessibility of DNA to 
        transcription factors and other regulatory proteins. TIP60 also plays a role in the recruitment of 
        repair proteins in the presence of DNA double-strand breaks in both the Homologous 
        Recombination (HR) and Non-Homologous End Joining (NHEJ) pathways via the acetylation of 
        histones and other proteins, e.g., ATM et, at the sight of DNA damage.`,
        function: `<p>TIP60 (Tat-interactive protein, 60 kDa) is a histone acetyltransferase (HAT) protein that 
        catalyzes the acetylation of lysine residues on histone proteins. TIP60 modifies chromatin 
        structure, leading to the relaxation of the chromatin and increased accessibility of DNA to 
        transcription factors and other regulatory proteins. TIP60 also plays a role in the recruitment of 
        repair proteins in the presence of DNA double-strand breaks in both the Homologous 
        Recombination (HR) and Non-Homologous End Joining (NHEJ) pathways via the acetylation of 
        histones and other proteins, e.g., ATM et, at the sight of DNA damage.</p>
        <p>TIP60 plays diverse and essential roles in chromatin regulation, transcriptional control, DNA 
        repair, and cell cycle regulation, contributing to maintaining genomic stability and proper 
        cellular function. Dysregulation of TIP60-mediated DNA repair processes can lead to genomic 
        instability and may contribute to the development of various diseases, including cancer.</p>`,
        reference: [
            'https://www.sciencedirect.com/science/article/abs/pii/S1568786421001610',
            'https://pubmed.ncbi.nlm.nih.gov/16904321/'
        ]
    },
    {
        name: 'Ac.ATM',
        preview:"Acetylation of ATM (Ataxia Telangiectasia Mutated) at lysine 3016 is a post-translational modification that regulates the activity of the ATM protein in response to DNA damage. ATM is an essential kinase in DNA's cellular response to double-strand breaks (DSBs).",
        function: `
        <ul>
        <li>Acetylation of ATM (Ataxia Telangiectasia Mutated) at lysine 3016 is a post-translational modification that regulates the activity of the ATM protein in response to DNA damage. ATM is an essential kinase in DNA's cellular response to double-strand breaks (DSBs).</li>
        <li>ATM undergoes auto-phosphorylation and activation when DNA damage occurs, such as breaks caused by ionizing radiation or chemical agents. This activation initiates a signaling cascade that triggers diverse cellular responses, including cell cycle arrest, DNA repair, and apoptosis.</li>
        <li>Acetylation at lysine 3016 of ATM has been shown to enhance its kinase activity. Studies have indicated that acetylation at this specific site increases ATM's ability to phosphorylate downstream targets in the DNA damage response pathway, thereby amplifying the cellular response to DNA damage.</li>
        <li>The precise mechanism of acetylation at lysine 3016 regulating ATM activity has yet to be fully understood. It is hypothesized to involve alterations in protein conformation and interactions with other proteins or cofactors involved in the ATM signaling pathway.</li>
        <li>Overall, acetylation of ATM at lysine 3016 represents a critical regulatory mechanism that fine-tunes the cellular response to DNA damage. This ensures an appropriate and effective DNA damage response to maintain genomic stability and prevent an assemblage of mutations that can lead to cancer.</li>
        </ul>
        `,
        reference: ['https://pubmed.ncbi.nlm.nih.gov/17923702/',
            'https://pubmed.ncbi.nlm.nih.gov/25301942/',
            'https://pubmed.ncbi.nlm.nih.gov/28477118/',
            'https://pubmed.ncbi.nlm.nih.gov/16141325/'
        ]
    },
    {
        name: 'DSS1',
        preview:`DSS1 (Deleted in Split Hand/Split Foot 1) is a small, multifunctional protein critical in 
        homologous recombination (HR), a key DNA repair pathway. Homologous recombination is 
        essential for accurately reproducing DNA double-strand breaks (DSBs). Here's an overview of 
        DSS1's function and significance in HR:`,
        function: `
        <p>DSS1 (Deleted in Split Hand/Split Foot 1) is a small, multifunctional protein critical in 
        homologous recombination (HR), a key DNA repair pathway. Homologous recombination is 
        essential for accurately reproducing DNA double-strand breaks (DSBs). Here's an overview of 
        DSS1's function and significance in HR:</p>
        <p>Binding to BRCA2: DSS1 is best known for interacting with BRCA2, a central protein in 
        homologous recombination. This binding is crucial for BRCA2's proper functioning in 
        facilitating RAD51-mediated HR. DSS1 enhances BRCA2's stability and helps maintain its 
        structural integrity, which is necessary for efficient interaction with other HR components.</p>
        <p>Facilitating RAD51 Filament Formation: Through its interaction with BRCA2, DSS1 aids in 
        properly assembling RAD51 nucleoprotein filaments on single-stranded DNA (ssDNA), a 
        critical step in HR. RAD51 filaments search for and align homologous sequences, enabling 
        strand invasion and accurate repair.</p>
        <p>Involvement in DNA Repair Processes: DSS1 contributes to the overall process of HR by 
        ensuring that BRCA2 functions optimally. This role is essential for promoting error-free DNA 
        repair and maintaining genomic stability.</p>
        <p>Role in Proteasomal Degradation: DSS1 has been associated with the proteasome, a protein 
        complex responsible for degrading unneeded or damaged proteins. Although its precise function 
        in this context is still being explored, DSS1 might regulate protein degradation, indirectly 
        supporting cellular homeostasis and DNA repair mechanisms.</p>
        <p>Influence on Cell Cycle Regulation: DSS1's role in stabilizing BRCA2 and facilitating HR can 
        also impact the cell cycle, particularly at the G2/M checkpoint. By ensuring proper repair of 
        DNA damage before cell division, DSS1 contributes to genomic stability.</p>
        <p>DSS1's interactions with BRCA2 and other DNA repair proteins underscore its significance in 
        the homologous recombination pathway. Its role in stabilizing BRCA2 and facilitating RAD51 
        filament formation is critical for ensuring accurate and efficient DNA repair. It highlights DSS1's
        importance in maintaining genomic integrity and preventing genomic instability-associated 
        diseases, including cancer.</p>

        `,
        reference: [
            'https://www.sciencedirect.com/science/article/pii/S1097276503003678',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7641332/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4506714/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6218214/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4232310/'
        ]
    }
]

export const ssbProteinFunctions = [
    {
        name: 'PARP1',
        preview:"PARP1 (Poly(ADP-ribose) Polymerase 1) plays a pivotal role in the cellular process of repairing single-strand breaks (SSBs) in DNA.",
        function: `
        <p>PARP1 (Poly(ADP-ribose) Polymerase 1) plays a pivotal role in the cellular process of repairing single-strand breaks (SSBs) in DNA.</p>
<p style="font-size:18px; font-weight:600">Detection of DNA Damage</p>
<p>PARP1 is one of the first proteins to respond to single-strand breaks in DNA. It can detect DNA damage because its zinc finger domain binds to the site of the break.</p>
<p style="font-size:18px; font-weight:600">Recruitment of Repair Machinery</p>
<p>Once PARP1 detects a single-strand break, it activates and synthesizes poly(ADP-ribose) (PAR) chains using NAD+ as a substrate. This process is known as PARylation. The PAR chains serve as a signal to recruit and organize other proteins involved in DNA repair.</p>
<p style="font-size:18px; font-weight:600">Coordination of DNA Repair Proteins</p>
<p>PARylation by PARP1 leads to the recruitment of critical proteins involved in the SSB repair pathway, such as XRCC1, DNA ligase III, and DNA polymerase β. These proteins play specific roles in the repair process, like filling in missing nucleotides and rejoining DNA strands.</p>
<p style="font-size:18px; font-weight:600">Regulation of Chromatin Structure</p>
<p>PARylation by PARP1 can also impact chromatin structure, loosening it to allow better access for repair proteins, which is crucial, as tightly packed chromatin could impede the repair process.</p>
<p style="font-size:18px; font-weight:600">Auto-regulation and Resolution</p>
<p>After coordinating the repair process, PARP1 is removed from the site of the break by either dissociating from the PAR chains or through degradation, ensuring it doesn't persistently signal for repair or interfere with the completion of the process.</p>
<p style="font-size:18px; font-weight:600">Prevention of Unwanted DNA Damage</p>
<p>PARP1 plays a protective role by preventing single-strand breaks from progressing into more severe double-strand breaks, which are more challenging for the cell to repair.</p>
<p style="font-size:18px; font-weight:600">PARP Inhibition and Cancer Therapy</p>
<p>Due to its critical role in DNA repair, PARP1 has become a target for cancer therapy. PARP inhibitors (PARPi) prevent PARP1 from repairing DNA damage, leading to damage accumulation in cancer cells, particularly those with existing DNA repair defects, such as BRCA1/2 mutations. This strategy exploits the concept of "synthetic lethality," selectively killing cancer cells while sparing normal cells.</p>
<p>In summary, PARP1 is a central component of the single-strand break repair pathway, detecting damage, coordinating the recruitment of repair proteins, and facilitating the proper resolution of DNA breaks. Its role is crucial for maintaining genome stability and is a target for therapeutic interventions, especially in cancer treatment.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/35643889/',
            'https://pubmed.ncbi.nlm.nih.gov/28676700/',
            'https://pubmed.ncbi.nlm.nih.gov/30650352/',
            'https://pubmed.ncbi.nlm.nih.gov/37326017/'
        ]
    },
    {
        name: 'PARP1.pADPR',
        preview:"PARP1 (Poly(ADP-ribose) Polymerase 1) is crucial in detecting and signaling single-strand breaks in DNA. When PARP1 binds to a single-strand break, it activates and synthesizes poly(ADP-ribose) (pADPR) chains from NAD+ molecules.",
        function: `
        <p>PARP1 (Poly(ADP-ribose) Polymerase 1) is crucial in detecting and signaling single-strand breaks in DNA. When PARP1 binds to a single-strand break, it activates and synthesizes poly(ADP-ribose) (pADPR) chains from NAD+ molecules.</p>
        <p style="font-weight:600; font-size:18px">Poly(ADP-ribose) (pADPR) Chains</p>
        <p>These pADPR chains serve multiple functions:</p>     
        <ul>
        <li><strong>Recruitment of Repair Proteins:</strong> The pADPR chains act as signals that attract and organize various DNA repair proteins to the site of the damage. These proteins include XRCC1, DNA polymerase β, and DNA ligase III, which work together to repair the break. </li>      
        <li><strong>Chromatin Remodeling:</strong> The pADPR chains can also influence the structure of chromatin, making it more accessible for repair machinery. It is beneficial in tightly packed genome regions, allowing repair proteins to access the DNA.</li>
        </ul>
        <p style="font-weight:600; font-size:18px">Auto-modification and Release</p>
        <p>PARP1 can also auto-modify by attaching pADPR chains to itself, eventually releasing it from the DNA once repair proteins are in place. This release prevents excessive recruitment of repair machinery and allows the repair process to proceed efficiently.</p>
        <p style="font-weight:600; font-size:18px">Consequences of PARP1 Inhibition</p>
        <p>When PARP1 is inhibited (e.g., by PARP inhibitors used in cancer therapy), its ability to signal and coordinate the repair of single-strand breaks is compromised. This leads to an accumulation of unrepaired single-strand breaks, which can eventually result in double-strand breaks during DNA replication. These double-strand breaks are more challenging to repair, leading to cell death, especially in cancer cells with existing defects in other DNA repair pathways (e.g., those with BRCA1/2 mutations).</p>        
        <p>Overall, PARP1's ability to generate pADPR chains is a crucial aspect of its role in detecting and repairing single-strand breaks. It orchestrates the recruitment of repair proteins and helps to ensure that DNA repair occurs efficiently, thereby maintaining genomic stability.</p>        
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6591728/',
            'https://pubmed.ncbi.nlm.nih.gov/16793420/',
        ]
    },
    {
        name: 'XRCC1',
        preview:"XRCC1 (X-ray Repair Cross-Complementing Protein 1) is a critical scaffolding protein in the SSB repair pathway. It plays a central role in coordinating and organizing the assembly of multiple repair proteins to ensure the efficient repair of SSBs.",
        function: `
        <p>XRCC1 (X-ray Repair Cross-Complementing Protein 1) is a critical scaffolding protein in the SSB repair pathway. It plays a central role in coordinating and organizing the assembly of multiple repair proteins to ensure the efficient repair of SSBs.</p>
        <p style="font-weight:600;font-size:18px">Key Functions of XRCC1</p>
        <ul>
        <li><strong>Scaffold for Repair Proteins:</strong> XRCC1 acts as a platform to bring together various proteins involved in the repair of SSBs. It has binding domains that interact with multiple proteins, allowing them to work together in a coordinated manner.</li>
<li><strong>Recruitment of Repair Enzymes:</strong> XRCC1 recruits enzymes necessary for SSB repair, including DNA polymerase β, DNA ligase III, and others. These enzymes are responsible for processing and resealing the break.</li>
<li><strong>Interaction with PARP1:</strong> XRCC1 interacts with PARP1, which detects and binds to single-strand breaks. PARP1's activation and subsequent poly(ADP-ribose) (pADPR) formation helps recruit XRCC1 to the site of damage</li>
</ul>
<p>XRCC1 is a central player in the repair of single-strand breaks, acting as a scaffold to organize the repair proteins necessary for efficient and accurate repair. Its interaction with PARP1 and other vital enzymes is essential in DNA repair. Additionally, XRCC1's role in coordinating the repair machinery makes it crucial for maintaining genome integrity. Defects or mutations in XRCC1 can impair DNA repair, resulting in genomic instability and increased risk of cancer and other diseases.</p>  
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5443707/',
            'https://www.sciencedirect.com/topics/biochemistry-genetics-and-molecular-biology/xrcc1',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7587305/',
            'https://www.nature.com/articles/cr20087',
            'https://pubmed.ncbi.nlm.nih.gov/37492888/'
        ]
    },
    {
        name: 'PARG',
        preview:"PARG1 (Poly(ADP-ribose) Glycohydrolase 1) plays a crucial role in regulating the poly(ADP-ribose) (PAR) dynamics involved in single-strand break (SSB) repair.",
        function: `
        <p>PARG1 (Poly(ADP-ribose) Glycohydrolase 1) plays a crucial role in regulating the poly(ADP-ribose) (PAR) dynamics involved in single-strand break (SSB) repair.</p>
<p style="font-weight:600; font-size:18px">PARP1 and Poly(ADP-ribose) Chains</p>
<p>When a single-strand break occurs, PARP1 (Poly(ADP-ribose) Polymerase 1) detects and binds to the site of damage. Upon activation, PARP1 begins synthesizing poly(ADP-ribose) chains from NAD+. These chains signal to recruit and organize DNA repair proteins to the break site.</p>
<p style="font-weight:600; font-size:18px">Role of PARG1</p>
<p>PARG1 is an enzyme that specifically targets and degrades these poly(ADP-ribose) chains. Its activity is crucial for regulating the persistence of PARP1 signaling at sites of DNA damage.</p>
<p style="font-weight:600; font-size:18px">Functions of PARG1 in SSB Repair</p>
<ul>
<li><strong>Regulation of PARP1 Activity:</strong> PARG1's primary role in SSB repair is to degrade PAR chains. This degradation effectively "resets" the system, allowing PARP1 to dissociate from DNA, thus preventing excessive recruitment of repair machinery and facilitating the normal completion of repair.</li>
<li><strong>Control of Repair Process:</strong> By breaking down PAR chains, PARG1 ensures that the repair process doesn't become excessively prolonged or dysregulated. This function maintains genomic stability and prevents unnecessary cell stress or apoptosis due to prolonged PARP1 activation.</li>
<li><strong>Contributing to Chromatin Remodeling:</strong> PARG1's breakdown of PAR chains helps restore chromatin to its normal state after repair is complete, which is crucial for re-establishing the regular structure of the DNA and facilitating ongoing cellular functions.</li>
</ul>
<p style="font-weight:600; font-size:18px">PARG1 in DNA Repair and Beyond</p>
<p>While PARG1 plays an essential role in SSB repair, its function has broader implications for other cellular processes. For instance, PARG1's regulation of PARP1 activity has been linked to DNA damage response, cell death pathways, and potentially even cancer therapy. PARG inhibitors, like PARP inhibitors, are being studied for their therapeutic potential in cancer.</p>
<p>In summary, PARG1's role in single-strand break repair revolves around regulating PAR dynamics by degrading poly(ADP-ribose) chains synthesized by PARP1. This regulation ensures proper repair, preventing excessive PARP1 activation and maintaining genomic stability.</p>
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4626836/',
            'https://pubmed.ncbi.nlm.nih.gov/33005627/'
        ]
    },
    {
        name: 'APE1',
        preview:"APE1 (AP endonuclease 1), also known as APEX1 or Ref-1, is a pivotal enzyme in the base excision repair (BER) pathway. This pathway, which is of paramount importance in repairing single-strand breaks (SSBs) and other forms of DNA damage, relies heavily on the function of APE1.",
        function: `
        <p>APE1 (AP endonuclease 1), also known as APEX1 or Ref-1, is a pivotal enzyme in the base excision repair (BER) pathway. This pathway, which is of paramount importance in repairing single-strand breaks (SSBs) and other forms of DNA damage, relies heavily on the function of APE1.</p>
        <p style="font-weight:600; font-size:18px">APE1 in Base Excision Repair (BER)</p>
        <p>Base excision repair (BER) is a significant pathway for correcting small base lesions, such as oxidized or deaminated bases, which can result in single-strand breaks if not repaired properly. APE1 is an essential component of the BER pathway and is specifically involved in the following: </p>       
        <ul>
        <li><strong>AP Site Recognition and Cleavage:</strong> APE1's primary function is to recognize and cleave abasic sites, which are locations in DNA where the base has been removed but the phosphate backbone remains intact. These abasic sites, also known as apurinic/apyrimidinic (AP) sites, can occur due to spontaneous base loss or through the action of DNA glycosylases.  </li>      
        <li><strong>Endonuclease Activity:</strong> After recognizing an AP site, APE1 cleaves the DNA backbone, generating a single-strand break with a 3'-hydroxyl (3'-OH) end and a 5'-deoxyribose phosphate (5'-dRP) end. This cleavage creates a suitable entry point for further repair steps in the BER pathway.  </li>      
        </ul>
        <p style="font-weight:600; font-size:18px">APE1 and Single-Strand Break Repair</p>
        <p>Once APE1 creates a single-strand break during base excision repair, the following steps occur to complete the repair process: </p>      
        <ul>
        <li><strong>Gap Filling and Strand Sealing:</strong> DNA polymerase β (Pol β) extends the 3'-OH end by adding the appropriate nucleotide(s) to fill the gap. DNA ligase III, often in complex with XRCC1 (X-ray Repair Cross-Complementing Protein 1), seals the break by forming a phosphodiester bond at the 5'-dRP end.</li>        
        <li><strong>Interaction with Other Proteins:</strong> APE1 interacts with multiple proteins involved in SSB and BER, including XRCC1 and DNA ligase III. These interactions help coordinate the repair process and ensure efficiency.</li>
        </ul>
        <p style="font-weight:600; font-size:18px">Additional Functions of APE1</p>
        <p>Besides its crucial role in DNA repair, APE1 has other essential functions:  </p>     
        <ul>
        <li><strong>Redox Activity:</strong> APE1 acts as a redox factor, regulating the activity of various transcription factors by maintaining their redox state. This role is critical for controlling gene expression related to stress response, growth, and survival.</li>        
        <li><strong>Therapeutic Implications:</strong> Given its central role in DNA repair, APE1 is a potential therapeutic target in cancer therapy. Modulating APE1 activity may influence DNA repair capacity and cellular response to DNA damage.</li>        
        </ul>
        <p>APE1's role in single-strand break repair is crucial within the base excision repair pathway. By recognizing and processing AP sites, APE1 initiates the repair process, successfully restoring DNA integrity. Its additional functions and therapeutic implications make it a protein of considerable interest in basic research and clinical applications.</p>        
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7038996/',
            'https://pubmed.ncbi.nlm.nih.gov/34285771/',
            'https://pubmed.ncbi.nlm.nih.gov/12624104/',
            'https://pubmed.ncbi.nlm.nih.gov/38165468/'
        ]
    },
    {
        name: 'APE1(+/-) PCNA',
        preview:"In the intricate process of single-strand break (SSB) repair, APE1 (Apurinic/Apyrimidinic Endonuclease 1) and PCNA (Proliferating Cell Nuclear Antigen) play pivotal roles in different pathways.<em>Their intersection in specific contexts, particularly in base excision repair (BER) and long-patch repair,</em> is a crucial area of study. Both APE1 and PCNA significantly contribute to the process of single-strand break repair, underscoring the importance of their roles in this fundamental biological process.",
        function: `
        In the intricate process of single-strand break (SSB) repair, APE1 (Apurinic/Apyrimidinic Endonuclease 1) and PCNA (Proliferating Cell Nuclear Antigen) play pivotal roles in different pathways.<em>Their intersection in specific contexts, particularly in base excision repair (BER) and long-patch repair,</em> is a crucial area of study. Both APE1 and PCNA significantly contribute to the process of single-strand break repair, underscoring the importance of their roles in this fundamental biological process.
        <p style="font-size:18px; font-weight:600">APE1's Role in SSB Repair</p>
        <p>APE1 is a critical enzyme in the BER pathway. It is responsible for recognizing and cleaving abasic sites (AP sites) in DNA, creating a single-strand break with a 3'-hydroxyl (3'-OH) end and a 5'-deoxyribose phosphate (5'-dRP) end. This cleavage is critical for subsequent repair processes that involve gap filling and strand rejoining.</p>
        <p style="font-size:18px; font-weight:600">PCNA's Role in DNA Repair</p>
        <p>PCNA is best known as a processivity factor in DNA replication but also plays a role in DNA repair. In the context of SSB repair, PCNA acts as a scaffold, facilitating the recruitment and coordination of repair proteins. PCNA is particularly important in pathways like nucleotide excision repair (NER), mismatch repair (MMR), and specific aspects of base excision repair.</p>
        <p style="font-size:18px; font-weight:600">Interaction Between APE1 and PCNA</p>
        <p>While APE1 and PCNA do not typically interact directly in standard SSB repair, there are contexts where they can operate in the same pathway, especially in long-patch base excision repair. Here's how they might relate:
        <ul>
        <li><strong>Long-Patch BER:</strong> Besides single-nucleotide BER, there is a longer patch variant where multiple nucleotides are replaced. This form of repair often involves PCNA as a scaffold to coordinate the activities of DNA polymerase δ/ε, FEN1 (Flap Endonuclease 1), and DNA ligase 1. APE1's role in long-patch BER is to create the initial single-strand break by cleaving at the AP site, setting the stage for further repair.</li>
        <li><strong>Scaffolding and Processivity:</strong> If APE1's cleavage leads to longer patch repair, PCNA can facilitate the processivity and coordination of additional enzymes required to complete the repair. PCNA helps ensure that these additional steps are carried out efficiently and accurately.</li>
        </ul>
        <p>APE1 and PCNA, two key players in the field of DNA repair, each have their unique functions. APE1, with its focus on the initial cleavage of abasic sites in the BER pathway, and PCNA, serving as a versatile scaffold for various DNA repair processes, demonstrate the complexity and depth of DNA repair mechanisms. Their interaction, most likely in long-patch base excision repair, where PCNA's scaffolding ability complements APE1's endonuclease activity, adds another layer of intricacy to studying DNA repair.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/31828326/',
            'https://pubmed.ncbi.nlm.nih.gov/28852018/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9225081/'
        ]
    },
    {
        name: 'PCNA',
        preview:"PCNA in its phosphorylated form acts as a clamp / enzyme loader at the ssb for",
        function: 'PCNA in its phosphorylated form acts as a clamp / enzyme loader at the ssb for',
        reference: [
            'https://www.nature.com/articles/nrg2380'
        ]
    },
    {
        name: 'PCNA.Ub',
        preview:"PCNA ubiquitination (PCNA.ub) has significant implications for its role in the DNA damage response, particularly in repair pathways such as translesion synthesis (TLS) and other forms of single-strand break (SSB) repair.",
        function: `
        <p>PCNA ubiquitination (PCNA.ub) has significant implications for its role in the DNA damage response, particularly in repair pathways such as translesion synthesis (TLS) and other forms of single-strand break (SSB) repair.</p>
<p style="font-weight:600; font-size:18px">PCNA: General Functions</p>
<p>PCNA acts as a sliding clamp that encircles DNA, providing a platform for various proteins involved in DNA replication and repair to bind and function efficiently. It plays a central role in coordinating these processes.</p>
<p style="font-weight:600; font-size:18px">PCNA Ubiquitination: Role and Significance</p>
<p>Ubiquitination involves the covalent attachment of ubiquitin to a target protein, affecting the protein's function, stability, or interactions. PCNA ubiquitination, often by the E3 ubiquitin ligase RAD18, has specific roles in the DNA damage response:</p>
<ul>
<li><strong>Translesion Synthesis (TLS):</strong> PCNA ubiquitination is a critical signal for translesion synthesis, a DNA repair mechanism that allows DNA polymerases to bypass lesions or damage during replication. When PCNA is ubiquitinated, specialized TLS polymerases, like Pol η and Pol ι, are recruited to bypass the damage and continue DNA replication. This process is crucial when standard DNA polymerases cannot proceed due to DNA damage.</li>
<li><strong>Coordination of Repair Pathways:</strong> PCNA ubiquitination may also play a role in coordinating other DNA repair pathways, including those involved in resolving single-strand breaks. By acting as a scaffold, ubiquitinated PCNA can recruit and coordinate a variety of repair proteins.</li>
<li><strong>Polyubiquitination and Template Switching:</strong> In some cases, PCNA undergoes polyubiquitination, which involves attaching multiple ubiquitin molecules. This type of ubiquitination is associated with template switching, an error-free repair mechanism where the DNA synthesis machinery uses the undamaged sister chromatid as a template to bypass the lesion.</li>
</ul>
<p style="font-weight:600; font-size:18px">PCNA Ubiquitination in Single-Strand Break Repair</p>
<p>Although PCNA ubiquitination is more commonly associated with translesion synthesis and other replication-associated processes, its role in single-strand break repair can be significant:</p>
<ul>
<li><strong>Recruitment of Repair Proteins:</strong> Ubiquitinated PCNA can recruit proteins involved in SSB repair, such as DNA polymerase δ/ε, DNA ligase 1, and FEN1 (Flap Endonuclease 1). This coordination is essential during long-patch base excision repair (BER), where several nucleotides may be replaced to repair a break.</li>
<li><strong>Modulation of Repair Processes:</strong> PCNA's ubiquitination status can influence the choice of DNA repair pathway. Depending on the context and the type of damage, ubiquitinated PCNA can help direct the repair machinery to the appropriate pathway.</li>
</ul>
<p>PCNA ubiquitination plays a crucial role in regulating DNA repair processes, particularly in translesion synthesis and potentially in single-strand break repair. Its ability to recruit and coordinate a variety of repair proteins makes it a central player in maintaining genomic stability, especially during replication and under conditions of DNA damage.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/21640107/',
            'https://www.nature.com/articles/s41467-020-16096-w',
            'https://pubmed.ncbi.nlm.nih.gov/32726436/'
        ]
    },
    {
        name: 'APE2',
        preview:"APE2 (Apurinic/Apyrimidinic Endonuclease 2) is a lesser-known homolog of APE1, but it has distinct roles in DNA repair, particularly in the context of single-strand breaks (SSBs).",
        function: `
        <p>APE2 (Apurinic/Apyrimidinic Endonuclease 2) is a lesser-known homolog of APE1, but it has distinct roles in DNA repair, particularly in the context of single-strand breaks (SSBs).</p>
<p style="font-weight:600; font-size:18px">Overview of APE2</p>
<p>While APE1 is the primary endonuclease in base excision repair (BER), responsible for cleaving apurinic/apyrimidinic (AP) sites, APE2 has some similar but unique functions. APE2 shares structural similarities with APE1 but exhibits different enzymatic activities and specificities.</p>
<p style="font-weight:600; font-size:18px">APE2's Role in Single-Strand Break Repair</p>
<p>APE2 has been implicated in single-strand break repair through several activities, with distinct roles compared to APE1:</p>
<ul>
<li><strong>3'-Exonuclease Activity:</strong> APE2 possesses 3'-exonuclease activity, allowing it to process the 3' ends of DNA. This function is crucial for preparing the DNA for subsequent repair steps.</li>
<li><strong>AP Endonuclease Activity:</strong> Although less efficient than APE1, APE2 also exhibits AP endonuclease activity, allowing it to cleave at abasic sites. This ability contributes to the repair of DNA damage involving AP sites.</li>
<li><strong>Role in DNA Damage Response:</strong> APE2 is involved in the cellular response to DNA damage and interacts with other proteins involved in DNA repair and cell cycle regulation. It ensures accurate repair and genomic stability.</li>
</ul>
<p style="font-weight:600; font-size:18px">Specific Contexts Where APE2 Operates</p>
<p>APE2's role in single-strand break repair is most apparent in the following contexts:</p>
<ul>
<li><strong>Backup Role in BER:</strong> APE2 can function as a backup to APE1 in base excision repair. When APE1 activity is compromised, APE2 can contribute to repairing AP sites and related single-strand breaks.</li>
<li><strong>Interactions with Other Repair Proteins:</strong> APE2 interacts with several proteins involved in DNA repair and signaling pathways. Its interactions with proteins like PCNA (Proliferating Cell Nuclear Antigen) suggest it plays a role in the broader DNA repair network, including long-patch BER.</li>
</ul>
<p>APE2 contributes to single-strand break repair through its 3'-exonuclease and AP endonuclease activities. While it shares some functions with APE1, it has unique roles in processing DNA and interacting with other repair proteins. APE2's involvement in the DNA damage response and its ability to function as a backup to APE1 underscores its importance in maintaining genomic stability.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/37044098/',
            'https://pubmed.ncbi.nlm.nih.gov/29361157/',
            'https://pubmed.ncbi.nlm.nih.gov/31828326/'
        ]
    },
    {
        name: 'ATR',
        preview:"ATR (Ataxia Telangiectasia and Rad3-related) is a critical serine/threonine kinase that plays a central role in the cellular response to DNA damage, particularly in response to replication stress and single-strand breaks (SSBs). Unlike ATM (Ataxia Telangiectasia Mutated), which is primarily activated by double-strand breaks, ATR is activated by persistent single-stranded DNA (ssDNA) regions that arise during DNA replication or repair processes.",
        function: `
        <p>ATR (Ataxia Telangiectasia and Rad3-related) is a critical serine/threonine kinase that plays a central role in the cellular response to DNA damage, particularly in response to replication stress and single-strand breaks (SSBs). Unlike ATM (Ataxia Telangiectasia Mutated), which is primarily activated by double-strand breaks, ATR is activated by persistent single-stranded DNA (ssDNA) regions that arise during DNA replication or repair processes.</p>
<p style="font-weight:600; font-size:18px">Overview of ATR Activation</p>
<p>ATR is activated when there is extensive ssDNA, often resulting from stalled replication forks or other forms of replication stress. This activation is usually mediated by the presence of RPA (Replication Protein A), which binds to ssDNA, recruiting ATR and its key activator, ATRIP (ATR Interacting Protein).</p>
<ul>
<li><strong>Recruitment to Sites of Damage:</strong> RPA-coated ssDNA signals DNA damage or replication stress. ATR, with ATRIP, is recruited to these sites, activating ATR's kinase activity.</li>
<li><strong>Interaction with the 9-1-1 Complex:</strong> ATR activation is further facilitated by the 9-1-1 complex (comprising RAD9, RAD1, and HUS1), which is loaded onto DNA by RAD17-RFC at sites of DNA damage. This complex interacts with TOPBP1 (Topoisomerase II Binding Protein 1), a key activator of ATR, which assists in fully activating ATR kinase activity.</li>
</ul>
<p style="font-weight:600; font-size:18px">ATR's Role in Single-Strand Break Repair</p>
<p>ATR plays a vital role in facilitating and coordinating the repair of single-strand breaks through various mechanisms:</p>
<ul>
<li><strong>Checkpoint Activation:</strong> ATR is critical for activating checkpoint signaling pathways, notably via the phosphorylation of CHK1. This leads to cell cycle arrest or delay, allowing time for DNA repair before cell division continues. This checkpoint activation is crucial in preventing cells from progressing with unrepaired single-strand breaks.</li>
<li><strong>Coordination of Repair Pathways:</strong> ATR activation helps coordinate DNA repair processes by recruiting repair proteins and integrating signals across various pathways. This coordination ensures that repair proteins involved in base excision repair (BER), homologous recombination, and other SSB-related pathways are recruited and activated as needed.</li>
<li><strong>Stabilization of Replication Forks:</strong> ATR contributes to the stabilization of stalled replication forks, preventing the collapse into double-strand breaks. This is particularly important in preventing the progression of single-strand breaks into more severe DNA damage.</li>
</ul>
<p style="font-weight:600; font-size:18px">ATR in DNA Damage Response</p>
<p>Beyond its direct role in facilitating single-strand break repair, ATR has broader implications in the DNA damage response:</p>
<ul>
<li><strong>Genomic Stability:</strong> By ensuring proper checkpoint activation and coordinating DNA repair, ATR helps maintain genomic stability, reducing the risk of mutations and chromosomal abnormalities.</li>
<li><strong>Response to Replication Stress:</strong> ATR is particularly critical in responding to replication stress, which can result in single-strand breaks due to replication fork stalling or collapse. ATR's activation in these contexts ensures that DNA repair processes are promptly initiated.</li>
</ul>
<p>ATR is a central player in responding to single-strand breaks and replication stress, activating checkpoint pathways, coordinating DNA repair, and stabilizing replication forks. Through these functions, ATR contributes significantly to maintaining genomic stability and ensuring that cells do not proceed with unrepaired DNA damage.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/21034966/',
            'https://pubmed.ncbi.nlm.nih.gov/24003211/',
            'https://pubmed.ncbi.nlm.nih.gov/38472229/',
            'https://pubmed.ncbi.nlm.nih.gov/35318271/',
            'https://pubmed.ncbi.nlm.nih.gov/11163154/'
        ]
    },
    {
        name: 'ATRIP',
        preview:"ATRIP (ATR Interacting Protein) is a key partner and co-activator of ATR (Ataxia Telangiectasia and Rad3-related), a crucial kinase in the DNA damage response, particularly in response to replication stress and single-strand breaks (SSBs). ATRIP plays a pivotal role in recognizing and binding to single-stranded DNA (ssDNA), facilitating ATR activation at DNA damage sites.",
        function: `
        <p>ATRIP (ATR Interacting Protein) is a key partner and co-activator of ATR (Ataxia Telangiectasia and Rad3-related), a crucial kinase in the DNA damage response, particularly in response to replication stress and single-strand breaks (SSBs). ATRIP plays a pivotal role in recognizing and binding to single-stranded DNA (ssDNA), facilitating ATR activation at DNA damage sites.</p>
<p style="font-size:18px; font-weight:600">Overview of ATRIP</p>
<p>ATRIP is the regulatory subunit that associates with ATR and directs it to sites of DNA damage. It contains a domain that allows it to bind to Replication Protein A (RPA), a complex that binds and stabilizes single-stranded DNA. This interaction with RPA-coated ssDNA is crucial for ATRIP's ability to guide ATR to regions where the DNA damage response is needed.</p>
<p style="font-size:16px; font-weight:600">Role of ATRIP in Single-Strand Break Repair</p>
<p>ATRIP plays a central role in the activation of ATR, which is critical to the cellular response to single-strand breaks and replication stress:</p>
<ul>
<li><strong>Recognition of Single-Stranded DNA:</strong> ATRIP, through its interaction with RPA, identifies regions of ssDNA. These regions are commonly found at stalled replication forks; resected DNA ends, or sites of single-strand breaks.</li>
<li><strong>Recruitment of ATR:</strong> Once ATRIP binds to RPA-coated ssDNA, ATR is recruited to the site. This recruitment is crucial for ATR activation, leading to a cascade of events that coordinate the DNA damage response and facilitate DNA repair.</li>
<li><strong>Activation of Checkpoints:</strong> By facilitating ATR activation, ATRIP indirectly contributes to checkpoint signaling. This leads to the phosphorylation of CHK1, which plays a role in cell cycle arrest, providing time for the repair of single-strand breaks before the cell cycle progresses.</li>
</ul>
<p style="font-size:18px; font-weight:600">ATRIP's Broader Role in the DNA Damage Response</p>
<p>Beyond its specific role in single-strand break repair, ATRIP's interaction with ATR has broader implications for the DNA damage response:</p>
<ul>
<li><strong>Coordination of DNA Repair:</strong> The ATR/ATRIP complex is involved in orchestrating the recruitment of DNA repair proteins. This coordination ensures that repair processes like base excision repair (BER) and homologous recombination can proceed efficiently at sites of single-strand breaks.</li>
<li><strong>Response to Replication Stress:</strong> ATRIP is crucial in responding to replication stress, which can lead to single-strand breaks or stalled replication forks. By facilitating ATR activation, ATRIP helps stabilize replication forks and prevent more severe DNA damage, such as double-strand breaks.</li>
</ul>
<p>ATRIP is a critical regulatory protein that guides ATR to sites of single-strand breaks and other forms of replication stress. By recognizing RPA-coated ssDNA and recruiting ATR, ATRIP plays a pivotal role in activating the DNA damage response, leading to checkpoint activation and coordination of DNA repair processes. Its role in stabilizing replication forks and promoting genomic stability underscores its importance in maintaining genome integrity.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/12791985/',
            'https://pubmed.ncbi.nlm.nih.gov/37216274/',
            'https://pubmed.ncbi.nlm.nih.gov/31624858/'
        ]
    },
    {
        name: 'RPA',
        preview:"RPA (Replication Protein A) is a crucial protein complex involved in various aspects of DNA metabolism, including the response to single-strand breaks (SSBs). Its role in SSB repair encompasses several key functions that contribute to the efficient detection, stabilization, and repair of DNA damage.",
        function: `
        <p>RPA (Replication Protein A) is a crucial protein complex involved in various aspects of DNA metabolism, including the response to single-strand breaks (SSBs). Its role in SSB repair encompasses several key functions that contribute to the efficient detection, stabilization, and repair of DNA damage.</p>
        <p style="font-weight:600; font-size:18px">Overview of RPA</p>
<p>RPA is a heterotrimeric protein complex composed of three subunits: RPA1, RPA2, and RPA3. It forms a stable complex that binds to single-stranded DNA (ssDNA) with high affinity and specificity. RPA involves numerous DNA metabolic processes, including DNA replication, repair, recombination, and checkpoint signaling.</p>
<p style="font-weight:600; font-size:18px">Role of RPA in Single-Strand Break Repair</p>
<p>RPA plays several critical roles in the cellular response to single-strand breaks:</p>
<ul>
<li><strong>Recognition and Binding to SSBs:</strong> Upon the occurrence of a single-strand break, RPA rapidly binds to the exposed ssDNA regions. This binding stabilizes the DNA structure, prevents the formation of secondary structures, and protects the ssDNA from degradation.</li>
<li><strong>Recruitment of DNA Repair Proteins:</strong> RPA serves as a platform for recruiting various DNA repair proteins involved in different repair pathways. For example, in base excision repair (BER), RPA facilitates the recruitment of enzymes such as DNA glycosylases, AP endonucleases, and DNA polymerases, which collectively remove damaged bases and repair the resulting gaps.</li>
<li><strong>Activation of Checkpoint Signaling:</strong> RPA-bound ssDNA signals DNA damage, triggering the activation of checkpoint signaling pathways. This involves recruiting and activating protein kinases such as ATR (Ataxia Telangiectasia and Rad3-related) and CHK1 (Checkpoint Kinase 1), which regulate cell cycle progression and DNA repair processes.</li>
<li><strong>Stabilization of Replication Forks:</strong> Single-strand breaks can occur spontaneously or due to replication stress. RPA stabilizes stalled replication forks by coating the exposed ssDNA regions. This prevents the formation of secondary structures and promotes the recruitment of proteins involved in fork stabilization and restart.</li>
</ul>
<p>RPA is a multifunctional protein complex that plays a central role in the cellular response to single-strand breaks. By binding to and stabilizing ssDNA, recruiting DNA repair proteins, activating checkpoint signaling, and stabilizing replication forks, RPA ensures the efficient detection and repair of SSBs, thereby preserving genomic integrity. Its versatile functions make RPA a key player in maintaining genome stability and preventing the accumulation of DNA damage.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/18469000/',
            'https://pubmed.ncbi.nlm.nih.gov/37378431/',
            'https://pubmed.ncbi.nlm.nih.gov/37739410/'
        ]
    },
    {
        name: 'Chk1',
        preview:"CHK1 (Checkpoint Kinase 1) is a critical serine/threonine kinase involved in the cellular response to DNA damage, particularly in checkpoint activation and cell cycle regulation. It significantly ensures that cells do not progress through the cell cycle with unrepaired DNA damage, including single-strand breaks (SSBs). Here's an overview of how CHK1 functions in single-strand break repair and its broader role in maintaining genomic stability.",
        function: `
        <p>CHK1 (Checkpoint Kinase 1) is a critical serine/threonine kinase involved in the cellular response to DNA damage, particularly in checkpoint activation and cell cycle regulation. It significantly ensures that cells do not progress through the cell cycle with unrepaired DNA damage, including single-strand breaks (SSBs). Here's an overview of how CHK1 functions in single-strand break repair and its broader role in maintaining genomic stability.</p>
        <p style="font-size:18px; font-weight:600">Overview of CHK1</p>
        <p>CHK1 is a key downstream effector of the ATR (Ataxia Telangiectasia and Rad3-related) pathway, which is activated in response to replication stress and single-strand breaks. Upon activation by ATR, CHK1 coordinates the cellular response by signaling for cell cycle arrest, stabilizing replication forks, and promoting DNA repair.</p>
        <p style="font-size:18px; font-weight:600">Role of CHK1 in Response to Single-Strand Breaks</p>
        <p>CHK1's role in single-strand break repair involves several vital functions, which contribute to the broader DNA damage response:     </p>   
        <ul>
        <li><strong></strong>Cell Cycle Checkpoint Activation:</strong> When ATR is activated in response to single-strand breaks or replication stress, it phosphorylates CHK1. This phosphorylation triggers checkpoint activation, leading to cell cycle arrest or delay. This pause in the cell cycle allows DNA repair processes to fix the damage before the cell replicates or divides.</li>        
        <li><strong>Regulation of Replication Fork Progression:</strong> CHK1 plays a crucial role in stabilizing and regulating replication forks, preventing them from collapsing into double-strand breaks. This is especially important when single-strand breaks or other forms of damage occur during DNA replication.</li>        
        <li><strong>Coordination of DNA Repair:</strong> CHK1 provides a window for DNA repair pathways to operate by activating cell cycle checkpoints. This coordination ensures that repair proteins involved in base excision repair (BER) and other pathways have sufficient time to correct single-strand breaks and prevent further genomic instability. </li>      
        </ul>
        <p style="font-size:18px; font-weight:600">CHK1's Role in Broader DNA Damage Response</p>
        <p>Beyond its direct role in responding to single-strand breaks, CHK1 contributes to the overall DNA damage response:   </p>     
        <ul>
        <li><strong>Cross-Talk with Other Pathways:</strong> CHK1's activation can lead to interactions with other signaling pathways, including p53 and other cell cycle regulators. This cross-talk helps coordinate a broader response to DNA damage, ensuring genomic stability. </li>      
        <li><strong>Promotion of Genomic Stability:</strong> CHK1 helps maintain genomic stability by preventing cells from progressing through the cell cycle with unrepaired DNA. This function is crucial in reducing the risk of mutations and chromosomal aberrations.</li>      
        </ul>
        <p>CHK1 plays a critical role in the cellular response to single-strand breaks by activating cell cycle checkpoints, stabilizing replication forks, and coordinating DNA repair processes. Its role as a downstream effector of the ATR pathway makes it essential for ensuring that cells do not progress with damaged DNA, thereby maintaining genomic stability.</p>        
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/21034966/',
            'https://pubmed.ncbi.nlm.nih.gov/29054375/',
            'https://pubmed.ncbi.nlm.nih.gov/32991949/',
        ]
    },
    {
        name: 'Chk1.p',
        preview:"CHK1 is a serine/threonine kinase activated through phosphorylation, primarily by ATR (Ataxia Telangiectasia and Rad3-related), an essential protein involved in the DNA damage response to replication stress and single-strand breaks. ATR is activated by single-stranded DNA (ssDNA), which occurs when DNA is damaged or replication forks stall. ATR, with the help of its co-activator ATRIP (ATR Interacting Protein), phosphorylates CHK1 on specific sites like Serine 317 and Serine 345, leading to CHK1 activation.",
        function: `
        <p>CHK1 is a serine/threonine kinase activated through phosphorylation, primarily by ATR (Ataxia Telangiectasia and Rad3-related), an essential protein involved in the DNA damage response to replication stress and single-strand breaks. ATR is activated by single-stranded DNA (ssDNA), which occurs when DNA is damaged or replication forks stall. ATR, with the help of its co-activator ATRIP (ATR Interacting Protein), phosphorylates CHK1 on specific sites like Serine 317 and Serine 345, leading to CHK1 activation.</p>
        <p style="font-weight:600; font-size:18px">Phosphorylated CHK1's Role in SSB Repair</p>
        <p>Once phosphorylated, CHK1 has several functions in the context of SSB repair:</p>
        <ul>
        <li><strong>Checkpoint Activation:</strong> Phosphorylated CHK1 (pCHK1) initiates cell cycle checkpoints, particularly the S phase checkpoint. Delaying cell cycle progression allows cells time to repair single-strand breaks and other DNA damage before proceeding to mitosis. This is critical in preventing the transmission of damaged DNA to daughter cells.</li>
        <li><strong>Replication Fork Stabilization:</strong> Phosphorylated CHK1 plays a role in stabilizing replication forks. By preventing replication forks from collapsing, pCHK1 helps avoid double-strand breaks, which can arise if replication forks encounter single-strand breaks without adequate repair.</li>
        <li><strong>Coordination of Repair Processes:</strong> pCHK1 influences the activity of various DNA repair pathways. Checkpoint activation and cell cycle regulation ensure that repair proteins, like those in base excision repair (BER), have the time and resources needed to address single-strand breaks. This coordination is essential for maintaining genomic stability.</li>
        </ul>
        <p style="font-weight:600; font-size:18px">Broader Implications of pCHK1</p>
        <p>Beyond its role in SSB repair, phosphorylated CHK1 has broader effects on the DNA damage response:</p>
        <ul>
        <li><strong>Cross-Talk with Other Pathways:</strong> pCHK1 can interact with other proteins and signaling pathways, coordinating a comprehensive DNA damage response. This cross-talk helps maintain genomic stability by integrating various cellular responses to damage.</li>
        <li><strong>Response to Replication Stress:</strong> Since SSBs can occur during replication stress, pCHK1's role in stabilizing replication forks and activating checkpoints is crucial in maintaining proper replication and preventing further damage.</li>
        </ul>
        <p>Phosphorylated CHK1 is vital in the DNA damage response, especially in single-strand break repair. By activating cell cycle checkpoints, stabilizing replication forks, and coordinating DNA repair processes, pCHK1 helps maintain genomic stability and prevents the progression of unrepaired DNA damage.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/25748360/',
            'https://pubmed.ncbi.nlm.nih.gov/16629900/'
        ]
    },
    {
        name: 'RPA2',
        preview:"RPA2 (Replication Protein A2) is one of the subunits of Replication Protein A (RPA), a heterotrimeric protein complex essential for DNA replication and repair. RPA is critical in stabilizing single-stranded DNA (ssDNA) and is involved in various DNA repair pathways, including those dealing with single-strand breaks (SSBs).",
        function: `
        <p>RPA2 (Replication Protein A2) is one of the subunits of Replication Protein A (RPA), a heterotrimeric protein complex essential for DNA replication and repair. RPA is critical in stabilizing single-stranded DNA (ssDNA) and is involved in various DNA repair pathways, including those dealing with single-strand breaks (SSBs).</p>
        <p style="font-weight:600; font-size:20px">RPA: General Role in DNA Repair</p>
<p>RPA consists of three subunits: RPA1, RPA2, and RPA3. The complex binds to single-stranded DNA during replication and repair, protecting the ssDNA and preventing secondary structure formation. This binding allows other DNA repair proteins to access and process the damaged DNA.</p>
<p style="font-weight:600; font-size:20px">Role of RPA2 in Single-Strand Break Repair</p>
<p>RPA2 has specific roles and modifications that are relevant to SSB repair and related pathways:</p>
<ul>
<li><strong>Stabilization of ssDNA:</strong> RPA2, as part of the RPA complex, helps stabilize single-strand breaks and facilitates the recruitment of other repair proteins. This stabilization is crucial in pathways like base excision repair (BER), where transient single-strand breaks are common.</li>
<li><strong>Phosphorylation of RPA2:</strong> During DNA damage response, RPA2 undergoes phosphorylation at specific sites. This phosphorylation, typically mediated by kinases such as ATR (Ataxia Telangiectasia and Rad3-related) and DNA-PK (DNA-dependent protein kinase), is a critical regulatory event. Phosphorylation of RPA2 can influence the interaction between RPA and other proteins involved in DNA repair, signaling, and checkpoint activation.</li>
<li><strong>Recruitment of DNA Repair Proteins:</strong> RPA2, through its role in the RPA complex, assists in recruiting various repair proteins to sites of DNA damage. This recruitment is vital for coordinating repair processes and ensuring the correct enzymes are present to resolve the damage.</li>
</ul>
<p style="font-weight:600; font-size:18px">RPA and Pathways Related to SSB Repair</p>
<p>RPA, with RPA2 as an essential subunit, is involved in several DNA repair pathways that deal with or are related to single-strand breaks:</p>
<ul>
<li><strong>Base Excision Repair (BER):</strong> RPA helps stabilize ssDNA during BER, particularly in the long-patch repair variant, where several nucleotides are replaced.</li>
<li><strong>Homologous Recombination (HR):</strong> Although primarily associated with double-strand break repair, HR can also involve processing single-strand breaks. RPA is crucial in HR, stabilizing ssDNA during strand invasion and other recombination steps.</li>
<li><strong>Nucleotide Excision Repair (NER):</strong> RPA plays a role in stabilizing ssDNA during NER, which can repair a variety of DNA lesions, some of which may involve SSBs.</li>
</ul>
<p style="font-weight:600; font-size:18px">Conclusion</p>
<p>RPA2, as a pivotal component of the RPA complex, plays a critical role in stabilizing single-stranded DNA and orchestrating the recruitment of DNA repair proteins during single-strand break repair and related pathways. Its phosphorylation status can significantly influence its function and interactions with other proteins, thereby directly impacting the efficiency and accuracy of the DNA repair process.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/28009302/',
            'https://www.nature.com/articles/s41467-022-31415-z',
            'https://pubmed.ncbi.nlm.nih.gov/38189447/',
            'https://pubmed.ncbi.nlm.nih.gov/36894693/'
        ]
    },
    {
        name: 'RPA2.p',
        preview:"RPA2 (Replication Protein A2) is one of the three subunits of the Replication Protein A (RPA) complex, which is critical for stabilizing single-stranded DNA (ssDNA) during DNA replication and repair. Ubiquitination, the process of attaching ubiquitin molecules to a protein, can significantly affect a protein's function, stability, or interactions with other proteins. Ubiquitination of RPA2 can play an integral role in SSB repair.",
        function: `
        <p>RPA2 (Replication Protein A2) is one of the three subunits of the Replication Protein A (RPA) complex, which is critical for stabilizing single-stranded DNA (ssDNA) during DNA replication and repair. Ubiquitination, the process of attaching ubiquitin molecules to a protein, can significantly affect a protein's function, stability, or interactions with other proteins. Ubiquitination of RPA2 can play an integral role in SSB repair.</p>
        <p style="font-weight: 600; font-size:20px">RPA2 Ubiquitination:</p>
<p>RPA2 can be ubiquitinated in response to DNA damage, and this post-translational modification can have various effects on its function:</p>
<ul>
<li><strong>Regulation of RPA Function:</strong> Ubiquitination of RPA2 might alter the complex's ability to bind ssDNA, potentially affecting how RPA stabilizes ssDNA during repair processes.</li>
<li><strong>Recruitment of Repair Proteins:</strong> Ubiquitination can serve as a signal to recruit additional DNA repair proteins to the site of DNA damage. Ubiquitinated RPA2 could help attract other repair proteins, thereby coordinating the repair process.</li>
<li><strong>Interaction with Ubiquitin-Related Pathways:</strong> RPA2's ubiquitination could interact with the broader ubiquitin-proteasome system, affecting RPA's stability, turnover, or capacity to respond to different types of DNA damage.</li>
</ul>
<p style="font-weight: 600; font-size:16px">RPA2 Ubiquitination in Single-Strand Break Repair</p>
<ul>
<li><strong>Recruitment and Coordination of Repair Proteins:</strong> During SSB repair, RPA plays a key role in stabilizing ssDNA and recruiting proteins involved in the repair process. Ubiquitination of RPA2 could enhance its ability to attract other repair proteins, like those involved in base excision repair (BER) or nucleotide excision repair (NER).</li>
<li><strong>Interaction with DNA Damage Response Pathways:</strong> Ubiquitination of RPA2 may signal to other proteins in the DNA damage response, impacting how cells respond to SSBs. This interaction could be crucial for checkpoint activation and the coordination of repair.</li>
<li><strong>Modulation of RPA Stability:</strong> Ubiquitination can either stabilize or degrade a protein, depending on the context and type of ubiquitin linkage. For RPA2, this could influence its role in SSB repair, determining how long it stays bound to ssDNA and how effectively it stabilizes the repair site.</li>
</ul>
<p>The ubiquitination of RPA2 (RPA2.ub) is not just a mere process, but a significant factor that influences its function and role in single-strand break repair. By modulating its interactions, stability, and ability to recruit other repair proteins, RPA2.ub plays a pivotal role in coordinating the cellular response to SSBs and ensuring proper DNA repair.</p>
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8053108/',
            'https://www.nature.com/articles/cr2014147'
        ]
    },
    {
        name: 'RAD17',
        preview:"RAD17 is critical in the DNA damage response and cell cycle checkpoint control. Its role in maintaining genomic stability involves detecting and signaling DNA damage, particularly in response to replication stress. While RAD17 is generally associated with the response to double-strand breaks, it also contributes to processes that address single-strand breaks (SSBs).",
        function: `
        <p>RAD17 is critical in the DNA damage response and cell cycle checkpoint control. Its role in maintaining genomic stability involves detecting and signaling DNA damage, particularly in response to replication stress. While RAD17 is generally associated with the response to double-strand breaks, it also contributes to processes that address single-strand breaks (SSBs).</p>
        <p style="font-size:18px; font-weight:600">Overview of RAD17</p>
<p>RAD17 is part of the replication factor C-like (RFC-like) complex, which loads the 9-1-1 complex onto DNA. The 9-1-1 complex is a heterotrimer composed of RAD9, RAD1, and HUS1. Its ring structure resembles proliferating cell nuclear antigen (PCNA) in its encircling DNA. RAD17 recruits and loads the 9-1-1 complex onto sites of DNA damage, initiating checkpoint signaling and facilitating repair processes.</p>
<p style="font-size:18px; font-weight:600">RAD17 in Checkpoint Activation</p>
<p>RAD17 is critical in activating the ATR (Ataxia Telangiectasia and Rad3-related) checkpoint pathway. Here's how it contributes to this process:</p>
<ul>
<li><strong>Loading of the 9-1-1 Complex:</strong> RAD17 and its associated RFC-like complex are responsible for loading the 9-1-1 complex onto DNA at damage sites. This action is crucial in activating the ATR pathway, which responds to replication stress and DNA damage.</li>
<li><strong>Checkpoint Signaling:</strong> Once the 9-1-1 complex is loaded onto DNA, it interacts with other proteins like TOPBP1 (Topoisomerase II Binding Protein 1), leading to the activation of ATR and the subsequent phosphorylation of downstream targets such as CHK1. This signaling cascade is crucial for cell cycle checkpoint control, allowing time for DNA repair.</li>
</ul>
<p style="font-size:18px; font-weight:600">RAD17's Role in Single-Strand Break Repair</p>
<p>While RAD17's primary role is in checkpoint activation, its function in loading the 9-1-1 complex onto DNA has implications for single-strand break repair:</p>
<ul>
<li><strong>Recruitment of Repair Proteins:</strong> The 9-1-1 complex, loaded by RAD17, serves as a platform for recruiting DNA repair proteins. This recruitment can be important in base excision repair (BER), where single-strand breaks often occur as part of the repair process.</li>
<li><strong>Coordination of DNA Repair:</strong> By activating the ATR checkpoint pathway, RAD17 indirectly supports the repair of single-strand breaks by providing time for repair processes to occur. This coordination is essential in preventing cells from progressing through the cell cycle with unrepaired DNA damage.</li>
</ul>
<p>RAD17's role in loading the 9-1-1 complex onto DNA and activating checkpoint signaling has broader implications for DNA repair, including single-strand break repair. By initiating checkpoint signaling and coordinating the recruitment of repair proteins, RAD17 helps maintain genomic stability and ensures that cells have time to repair DNA damage.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/15189136/',
            'https://pubmed.ncbi.nlm.nih.gov/31353086/',
            'https://pubmed.ncbi.nlm.nih.gov/24619000/',
            'https://pubmed.ncbi.nlm.nih.gov/36161901/'
        ]
    },
    {
        name: 'HUS1',
        preview:"HUS1 is a vital component of the 9-1-1 complex, a critical part of the DNA damage response and checkpoint control. In the context of single-strand breaks (SSBs), HUS1's role primarily involves detection and signaling, helping to coordinate the cellular response to DNA damage and facilitating the recruitment of repair proteins",
        function: `
        <p>HUS1 is a vital component of the 9-1-1 complex, a critical part of the DNA damage response and checkpoint control. In the context of single-strand breaks (SSBs), HUS1's role primarily involves detection and signaling, helping to coordinate the cellular response to DNA damage and facilitating the recruitment of repair proteins.</p>
<p style="font-size:18px; font-weight:600">Overview of HUS1 and the 9-1-1 Complex</p>
<p>The 9-1-1 complex, a unique entity, is composed of three proteins: RAD9, RAD1, and HUS1. This complex, akin to the proliferating cell nuclear antigen (PCNA), forms a distinctive ring-shaped structure that encircles DNA. The 9-1-1 complex is loaded onto DNA at sites of damage by RAD17 and its associated clamp loader complex (RAD17-RFC), serving as a platform for recruiting other proteins involved in DNA repair and checkpoint signaling.</p>
<p style="font-size:18px; font-weight:600">HUS1 in the DNA Damage Response</p>
<p>HUS1, a key player in the 9-1-1 complex, is a significant contributor to the cellular response to DNA damage. Its role in activating the ATR (Ataxia Telangiectasia and Rad3-related) checkpoint pathway is particularly noteworthy. This pathway, a sentinel for detecting DNA damage, activating cell cycle checkpoints, and promoting DNA repair, owes much to HUS1's involvement.</p>
<ul>
<li><strong>Checkpoint Activation:</strong> When the 9-1-1 complex is loaded onto DNA, it interacts with other proteins involved in checkpoint activation, such as TOPBP1, which helps activate ATR. This activation leads to the phosphorylation of downstream targets, including CHK1, which plays a critical role in cell cycle arrest and DNA repair.</li>
</ul>
<p style="font-size:18px; font-weight:600">HUS1 in Single-Strand Break Repair</p>
<p>Although the 9-1-1 complex is more commonly associated with double-strand break repair and the broader DNA damage response, it has a role in single-strand break repair through the following mechanisms:</p>
<ul>
<li><strong>Recruitment of Repair Proteins:</strong> Once the 9-1-1 complex, including HUS1, is loaded onto DNA at damage sites, it can recruit other DNA repair proteins. This recruitment is significant in base excision repair (BER), where single-strand breaks often occur as part of the repair process.</li>
<li><strong>Interaction with Repair Pathways:</strong> HUS1, as part of the 9-1-1 complex, can interact with various DNA repair proteins and complexes, including FEN1 (Flap Endonuclease 1), DNA polymerase β, and others involved in BER and nucleotide excision repair (NER). These interactions help ensure proper coordination of the repair process.</li>
</ul>
<p style="font-size:18px; font-weight:600">Conclusion</p>
<p>HUS1's role in the DNA damage response and checkpoint control contributes to the effective detection and signaling of DNA damage, including single-strand breaks. As part of the 9-1-1 complex, HUS1 helps recruit and coordinate the proteins needed for proper DNA repair. Its role in activating the ATR checkpoint pathway and recruiting repair proteins underscores its importance in maintaining genomic stability.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/15279787/',
            'https://pubmed.ncbi.nlm.nih.gov/31776186/',
            'https://pubmed.ncbi.nlm.nih.gov/30184152/'

        ]
    },
    {
        name: 'RAD1',
        preview:"RAD1, along with RAD9 and HUS1, is a component of the 9-1-1 complex. This complex plays a significant role in the cellular response to DNA damage, particularly in activating checkpoint signaling pathways and facilitating DNA repair. In the context of single-strand breaks (SSBs), RAD1 contributes to the recruitment of repair proteins and the coordination of DNA damage response mechanisms.",
        function: `
        <p>RAD1, along with RAD9 and HUS1, is a component of the 9-1-1 complex. This complex plays a significant role in the cellular response to DNA damage, particularly in activating checkpoint signaling pathways and facilitating DNA repair. In the context of single-strand breaks (SSBs), RAD1 contributes to the recruitment of repair proteins and the coordination of DNA damage response mechanisms.</p>
<p style="font-weight:600; font-size:18px">Overview of RAD1 and the 9-1-1 Complex</p>
<p>The 9-1-1 complex is a heterotrimeric complex that resembles the structure of proliferating cell nuclear antigen (PCNA). It forms a ring-like structure that encircles DNA, allowing it to serve as a platform for recruiting other DNA repair and signaling proteins. RAD1, RAD9, and HUS1 form this complex, which is loaded onto DNA at damage sites by the RAD17-RFC complex.</p>
<p style="font-weight:600; font-size:18px">Role of RAD1 in Single-Strand Break Repair</p>
<p>While the 9-1-1 complex, including RAD1, is generally associated with double-strand breaks and checkpoint activation, it also has roles in single-strand break repair through the following mechanisms:</p>
<ul>
<li><strong>Recruitment of Repair Proteins:</strong> When the 9-1-1 complex is loaded onto DNA, it is a docking site for other DNA repair proteins. This is particularly important in base excision repair (BER), where single-strand breaks can occur as part of the repair process. RAD1 can help recruit proteins like DNA polymerase β, FEN1 (Flap Endonuclease 1), and others involved in processing and repairing SSBs.</li>
<li><strong>Checkpoint Activation and Coordination:</strong> The 9-1-1 complex activates the ATR (Ataxia Telangiectasia and Rad3-related) pathway, a key checkpoint signaling pathway. This pathway helps ensure that the cell cycle is halted to provide time for DNA repair. RAD1's role in this complex aids in the activation and regulation of checkpoint signaling, allowing for effective coordination of repair processes.</li>
<li><strong>Support of Base Excision Repair:</strong> In the context of BER, the 9-1-1 complex can help stabilize the DNA structure during repair and ensure proper coordination among the repair enzymes. This coordination is crucial for the efficient repair of single-strand breaks.</li>
</ul>
<p>RAD1, as part of the 9-1-1 complex, contributes to the coordination of DNA damage response and repair processes, including those involved in single-strand breaks. By serving as a scaffold for recruiting repair proteins and activating checkpoint signaling, RAD1 plays a critical role in maintaining genomic stability. Its involvement in checkpoint activation and interaction with DNA repair proteins makes it an essential component of the cellular response to DNA damage.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/8052537/',
            'https://pubmed.ncbi.nlm.nih.gov/15687272/',
            'https://pubmed.ncbi.nlm.nih.gov/15279787/',
            'https://pubmed.ncbi.nlm.nih.gov/25602520/'
        ]
    },
    {
        name: 'RAD9',
        preview:"RAD9 is a critical component of the 9-1-1 complex, which plays an essential role in the DNA damage response, particularly in activating checkpoint pathways and coordinating DNA repair. In the context of single-strand breaks (SSBs), RAD9, along with RAD1 and HUS1, is involved in detecting, signaling, and recruitment of repair proteins to sites of damage.",
        function: `
        <p>RAD9 is a critical component of the 9-1-1 complex, which plays an essential role in the DNA damage response, particularly in activating checkpoint pathways and coordinating DNA repair. In the context of single-strand breaks (SSBs), RAD9, along with RAD1 and HUS1, is involved in detecting, signaling, and recruitment of repair proteins to sites of damage.</p>
        <p style="font-size:18px; font-weight:600">The 9-1-1 Complex</p>
<p>The 9-1-1 complex, a unique heterotrimeric structure composed of RAD9, RAD1, and HUS1, forms a ring-like shape that can encircle DNA, akin to the proliferating cell nuclear antigen (PCNA). This distinctive structure is loaded onto DNA at sites of damage by the RAD17-RFC complex, serving as a platform for recruiting other proteins involved in DNA repair and checkpoint signaling.</p>
<p style="font-size:18px; font-weight:600">Role of RAD9 in DNA Damage Response</p>
<p>RAD9 plays a significant role in activating checkpoint signaling pathways, especially the ATR (Ataxia Telangiectasia and Rad3-related) pathway. This pathway is crucial for responding to various forms of DNA damage, including single-strand breaks. RAD9's functions in this context include:</p>
<ul>
<li><strong>Checkpoint Activation:</strong> Once the 9-1-1 complex is loaded onto DNA, it interacts with TOPBP1 (Topoisomerase II Binding Protein 1), which helps activate the ATR pathway. This activation leads to the phosphorylation of CHK1, among other targets, initiating a cascade that results in cell cycle arrest, allowing time for DNA repair.</li>
<li><strong>Recruitment of Repair Proteins:</strong> The 9-1-1 complex, including RAD9, can recruit various DNA repair proteins to sites of damage. This role is crucial in base excision repair (BER), where single-strand breaks can occur during the repair of damaged bases. RAD9 aids in recruiting proteins like FEN1 (Flap Endonuclease 1), DNA ligase 1, and other key players in BER and related pathways.</li>
</ul>
<p style="font-size:18px; font-weight:600">RAD9 in Single-Strand Break Repair</p>
<p>RAD9's role in SSB repair is part of its broader function in the DNA damage response and checkpoint activation:</p>
<ul>
<li><strong>Supporting Base Excision Repair (BER):</strong> In BER, after a DNA glycosylase removes a damaged base, the process creates an apurinic/apyrimidinic (AP) site, leading to a single-strand break. RAD9, as part of the 9-1-1 complex, can help stabilize the DNA structure and recruit the necessary enzymes for further processing and repair, such as DNA polymerase β and DNA ligase III.</li>
<li><strong>Checkpoint-Mediated DNA Repair:</strong> By activating the ATR pathway, RAD9 helps to coordinate a broader DNA damage response, providing a critical window for DNA repair mechanisms to operate before the cell cycle progresses. This coordination ensures that cells do not proceed with unrepaired DNA damage, reducing the risk of genomic instability.</li>
</ul>
<p>RAD9 is a pivotal player in the DNA damage response, especially in activating checkpoint pathways and coordinating the recruitment of repair proteins. Its role in the 9-1-1 complex and its involvement in checkpoint activation and recruitment of DNA repair proteins make it crucial for maintaining genomic stability, particularly in pathways dealing with single-strand breaks.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/21278450/',
            'https://pubmed.ncbi.nlm.nih.gov/28140789/',
            'https://pubmed.ncbi.nlm.nih.gov/35939452/'
        ]
    },
    {
        name: 'TopBP1',
        preview:"TOPBP1 (Topoisomerase II Binding Protein 1) is critical in the DNA damage response, particularly in activating checkpoint signaling pathways. While it is involved in multiple aspects of the DNA damage response, its role in single-strand breaks (SSBs) primarily relates to coordinating checkpoint signaling and interaction with the ATR (Ataxia Telangiectasia and Rad3-related) pathway. Let's explore TOPBP1's functions and how they relate to SSB repair.",
        function: `
        <p>TOPBP1 (Topoisomerase II Binding Protein 1) is critical in the DNA damage response, particularly in activating checkpoint signaling pathways. While it is involved in multiple aspects of the DNA damage response, its role in single-strand breaks (SSBs) primarily relates to coordinating checkpoint signaling and interaction with the ATR (Ataxia Telangiectasia and Rad3-related) pathway. Let's explore TOPBP1's functions and how they relate to SSB repair.</p>
        <p style="font-weight:600; font-size:20px">TOPBP1 and the DNA Damage Response</p>
        <p>TOPBP1 is best known for activating the ATR checkpoint pathway, a critical response mechanism that allows cells to halt the cell cycle and address DNA damage before progressing. This checkpoint pathway is crucial for maintaining genomic stability, especially when cells experience replication stress or DNA damage.</p>
        <p style="font-weight:600; font-size:20px">Role of TOPBP1 in ATR Activation</p>
        <p>TOPBP1 is responsible for facilitating the activation of ATR, which in turn activates the CHK1 kinase, leading to cell cycle arrest and DNA repair. Here's how TOPBP1 contributes to this process:</p>
        <ul>
        <li><strong>Interaction with ATR and 9-1-1 Complex:</strong> TOPBP1 contains multiple BRCT (BRCA1 C-terminal) domains, allowing it to interact with various proteins involved in the DNA damage response. In particular, TOPBP1 interacts with the 9-1-1 complex (RAD9, RAD1, and HUS1), which is loaded onto DNA at damage sites. This interaction helps activate ATR, initiating the signaling cascade required for checkpoint activation.</li>
        <li><strong>Recruitment of Repair Proteins: TOPBP1 can also help recruit other proteins involved in the DNA damage response. Its role in ATR activation ensures that the checkpoint pathway functions correctly, providing time for DNA repair mechanisms to act.</li>
        </ul>
        <p style="font-weight:600; font-size:20px">TOPBP1 in Single-Strand Break Repair</p>
        <p>Although TOPBP1 is primarily associated with checkpoint activation, its role in the DNA damage response has implications for single-strand break repair:</p>
<li><strong>Checkpoint Coordination:</strong> By activating ATR, TOPBP1 helps establish cell cycle checkpoints, allowing cells to pause and repair DNA damage. This checkpoint activation is essential when dealing with single-strand breaks, ensuring that cells do not proceed with damaged DNA.</li>
<li><strong>Interaction with Repair Pathways:</strong> TOPBP1's interaction with the 9-1-1 complex can help coordinate base excision repair (BER), where single-strand breaks often occur. By facilitating checkpoint activation and interacting with repair proteins, TOPBP1 contributes to the efficient repair of SSBs.</li>
<p>TOPBP1 plays a crucial role in the DNA damage response, particularly in activating the ATR checkpoint pathway. Its role in checkpoint activation and interaction with the 9-1-1 complex has significant implications for single-strand break repair, as it helps ensure proper coordination and timing of repair processes.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/35768435/',
            'https://pubmed.ncbi.nlm.nih.gov/10498869/',
            'https://pubmed.ncbi.nlm.nih.gov/35819203/'
        ]
    },
    {
        name: 'LIG1',
        preview:"LIG1 (DNA Ligase 1) is an essential enzyme in sealing nicks in the DNA backbone, particularly in DNA replication and repair. In single-strand break (SSB) repair, LIG1's primary role is to rejoin the breaks after other DNA repair enzymes have processed and filled the gaps.",
        function: `
        <p>LIG1 (DNA Ligase 1) is an essential enzyme in sealing nicks in the DNA backbone, particularly in DNA replication and repair. In single-strand break (SSB) repair, LIG1's primary role is to rejoin the breaks after other DNA repair enzymes have processed and filled the gaps.</p>
<p style="font-weight:600; font-size:18px">Role of LIG1 in Single-Strand Break Repair</p>
<p>LIG1 plays a critical role in maintaining the integrity of the DNA by sealing single-strand breaks. This function is vital in various DNA repair pathways, including base excision repair (BER), where single-strand breaks commonly occur as part of the repair process:</p>
<ul>
<li><strong>Sealing Nicks:</strong> After enzymes like DNA polymerase β (POLβ) have filled in the gaps in the DNA during base excision repair, LIG1 is responsible for sealing the single-strand breaks by forming a phosphodiester bond. This activity restores the continuity of the DNA backbone, ensuring the stability and integrity of the genome.</li>
<li><strong>Long-Patch Base Excision Repair:</strong> In long-patch BER, where several nucleotides are replaced, LIG1 plays an essential role in sealing longer gaps after the excision of damaged bases and adding new nucleotides. This function is vital to ensuring the successful completion of the repair process.</li>
</ul>
<p style="font-weight:600; font-size:18px">LIG1's Interaction with Other Repair Proteins</p>
<p>LIG1 often works in concert with other proteins involved in single-strand break repair, ensuring efficient coordination and repair:</p>
<ul>
<li><strong>PCNA (Proliferating Cell Nuclear Antigen):</strong> LIG1 interacts with PCNA, which acts as a sliding clamp during DNA replication and repair. This interaction helps stabilize LIG1 at sites of DNA damage, facilitating efficient sealing of single-strand breaks.</li>
<li><strong>FEN1 (Flap Endonuclease 1):</strong> LIG1 interacts with FEN1 in long-patch BER, which removes overhanging DNA "flaps" created during gap filling. This coordination between LIG1 and FEN1 ensures the DNA is properly processed and sealed.</li>
</ul>
<p style="font-weight:600; font-size:18px">Importance of LIG1 in Genomic Stability</p>
<p>LIG1's role in sealing single-strand breaks has broader implications for genomic stability and cellular health:</p>
<ul>
<li><strong>Prevention of Double-Strand Breaks:</strong> By efficiently sealing single-strand breaks, LIG1 helps prevent these breaks from progressing to double-strand breaks, which are more complex and challenging to repair.</li>
<li><strong>Support of DNA Replication:</strong> LIG1's function in sealing nicks during DNA replication ensures the newly synthesized DNA is stable and complete, reducing the risk of replication-related errors.</li>
<li><strong>Role in DNA Repair Pathways:</strong> LIG1 is involved in various DNA repair pathways, including base excision repair, nucleotide excision repair (NER), and mismatch repair (MMR), indicating its importance in maintaining genomic integrity across different types of DNA damage.</li>
</ul>
<p>LIG1 is a crucial enzyme in single-strand break repair, responsible for sealing nicks in the DNA backbone. By interacting with other repair proteins and ensuring the successful completion of the repair process, LIG1 plays a vital role in maintaining genomic stability and preventing the progression of DNA damage.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/38522520/',
            'https://pubmed.ncbi.nlm.nih.gov/19329425/',
            'https://pubmed.ncbi.nlm.nih.gov/33087274/',
            'https://pubmed.ncbi.nlm.nih.gov/36993234/'
        ]
    },
    {
        name: 'FEN1',
        preview:"FEN1 (Flap Endonuclease 1) is an enzyme with critical DNA replication and repair functions. It is a structure-specific nuclease that plays a vital role in the processing of single-strand breaks (SSBs), particularly in the context of long-patch base excision repair (BER) and other DNA repair pathways that involve processing DNA intermediates.",
        function: `
        <p>FEN1 (Flap Endonuclease 1) is an enzyme with critical DNA replication and repair functions. It is a structure-specific nuclease that plays a vital role in the processing of single-strand breaks (SSBs), particularly in the context of long-patch base excision repair (BER) and other DNA repair pathways that involve processing DNA intermediates.</p>
<p style="font-weight:600; font-size:18px">Overview of FEN1</p>
<p>FEN1 is a versatile nuclease with two primary activities:</p>
<ul>
<li><strong>Flap Endonuclease Activity:</strong> FEN1 cleaves 5' overhanging "flaps" during DNA replication and repair. This activity is critical for removing displaced DNA strands generated during various repair processes.</li>
<li><strong>5'-3' Exonuclease Activity:</strong> FEN1 can also remove nucleotides from the 5' end of DNA, aiding in DNA processing during repair and replication.</li>
</ul>
<p style="font-weight:600; font-size:18px">FEN1's Role in Single-Strand Break Repair</p>
<p>FEN1 plays a key role in long-patch base excision repair, a form of SSB repair where multiple nucleotides are replaced. Here's how FEN1 contributes to this process:</p>
<ul>
<li><strong>Clearing DNA Flaps:</strong> During long-patch BER, a DNA flap may form due to strand displacement after removing a damaged base and subsequent gap filling by DNA polymerases. FEN1 cleaves these flaps, ensuring the repaired DNA is properly structured without extra overhangs.</li>
<li><strong>Interaction with Repair Proteins:</strong> FEN1 interacts with various proteins involved in DNA repair, such as PCNA (Proliferating Cell Nuclear Antigen), which acts as a sliding clamp during DNA replication and repair. This interaction helps stabilize FEN1 at sites of DNA repair, facilitating efficient processing of DNA flaps.</li>
<li><strong>Preparation for Ligation:</strong> By clearing the DNA flaps, FEN1 creates a proper substrate for DNA ligases, such as LIG1 (DNA Ligase 1), to seal the single-strand break. This preparation is crucial for the successful completion of the repair process.</li>
</ul>
<p style="font-weight:600; font-size:18px">Importance of FEN1 in Genomic Stability</p>
<p>FEN1's role in SSB repair has broader implications for genomic stability:</p>
<ul>
<li><strong>Prevention of Double-Strand Breaks:</strong> By efficiently processing DNA flaps, FEN1 helps prevent errors during repair that could lead to double-strand breaks, which are more complex to repair and pose more significant risks for genomic instability.</li>
<li><strong>Support of DNA Replication:</strong> FEN1's flap endonuclease activity is also critical during DNA replication. It removes Okazaki fragment flaps on the lagging strand, ensuring proper DNA synthesis.</li>
</ul>
<p>FEN1 is an essential enzyme in the repair of single-strand breaks, particularly in the context of long-patch base excision repair. By clearing DNA flaps and interacting with other repair proteins, FEN1 ensures efficient processing and repair of SSBs, contributing to genomic stability and preventing more severe forms of DNA damage.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/9778254/',
            'https://pubmed.ncbi.nlm.nih.gov/37393641/',
            'https://pubmed.ncbi.nlm.nih.gov/36672839/'
        ]
    },
    {
        name: 'Polß',
        preview:"POLβ (DNA Polymerase Beta) is a crucial enzyme in the DNA base excision repair (BER) pathway, which is responsible for repairing small lesions and single-strand breaks (SSBs) in the genome. Its role in SSB repair involves filling in single-nucleotide gaps and processing other repair intermediates that arise during BER.",
        function: `
        <p>POLβ (DNA Polymerase Beta) is a crucial enzyme in the DNA base excision repair (BER) pathway, which is responsible for repairing small lesions and single-strand breaks (SSBs) in the genome. Its role in SSB repair involves filling in single-nucleotide gaps and processing other repair intermediates that arise during BER.</p>
<p style="font-weight:600; font-size:18px">Overview of POLβ</p>
<p>POLβ is a member of the DNA polymerase family but is unique because it lacks proofreading (exonuclease) activity. It is specialized for the repair of small gaps and single-strand breaks. It has both polymerase and lyase activities, allowing it to fill gaps and remove damaged nucleotides.</p>
<p style="font-weight:600; font-size:18px">Role of POLβ in Single-Strand Break Repair</p>
<p>POLβ plays several critical roles in the repair of single-strand breaks, especially within the context of base excision repair (BER):</p>
<ul>
<li><strong>Gap Filling:</strong> POLβ is responsible for filling in single-nucleotide gaps that occur after damaged bases are removed in the BER pathway. This gap-filling is an essential step in restoring the integrity of the DNA backbone.</li>
<li><strong>Lyase Activity:</strong> POLβ possesses a deoxyribose phosphate (dRP) lyase activity, allowing it to remove the 5'-deoxyribose phosphate group left after the action of an AP endonuclease like APE1. This activity helps to prepare the DNA to add new nucleotides during gap filling.</li>
<li><strong>Short-Patch BER:</strong> In short-patch BER, POLβ plays a central role by filling in a single-nucleotide gap and preparing the DNA for ligation. After this step, DNA ligase III, typically in complex with XRCC1, seals the break by forming a phosphodiester bond.</li>
</ul>
<p style="font-weight:600; font-size:18px">Interaction with Other Repair Proteins</p>
<p>POLβ interacts with several other proteins involved in SSB repair, ensuring proper coordination and efficiency of the repair process:</p>
<ul>
<li><strong>XRCC1:</strong> XRCC1 acts as a scaffold protein that interacts with POLβ, coordinating the recruitment of other repair proteins like DNA ligase III. This interaction is crucial for ensuring the continuity of the repair pathway.</li>
<li><strong>APE1:</strong> POLβ follows APE1's action in the BER pathway, filling in the gaps created after APE1 cleaves at apurinic/apyrimidinic (AP) sites. This step is vital in the proper processing of single-strand breaks.</li>
</ul>
<p style="font-weight:600; font-size:18px">Implications for Genomic Stability</p>
<p>The role of POLβ in single-strand break repair has broader implications for genomic stability:</p>
<ul>
<li><strong>Preventing DNA Damage Accumulation:</strong> By efficiently repairing single-strand breaks, POLβ helps prevent these breaks from evolving into double-strand breaks, which are more complex to repair and can lead to genomic instability.</li>
<li><strong>Maintaining Cell Viability:</strong> The proper function of POLβ ensures that cells can effectively repair DNA damage, reducing the risk of mutations and ensuring cell survival.</li>
</ul>
<p>POLβ is a crucial enzyme in the base excision repair pathway, responsible for gap-filling and processing repair intermediates in single-strand break repair. Its interaction with other repair proteins and its specialized activities make it a key player in maintaining genomic stability and preventing the progression of DNA damage.</p>
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3739438/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8405949/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC10148159/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6614843/',
        ]
    },
    {
        name: 'Polδ',
        preview:"POL δ (DNA Polymerase Delta) is a multifunctional enzyme primarily known for its role in DNA replication, specifically synthesizing the lagging strand. However, it also plays a vital role in various DNA repair processes, including long-patch base excision repair (BER), which involves repairing single-strand breaks (SSBs).",
        function: `
        <p>POL δ (DNA Polymerase Delta) is a multifunctional enzyme primarily known for its role in DNA replication, specifically synthesizing the lagging strand. However, it also plays a vital role in various DNA repair processes, including long-patch base excision repair (BER), which involves repairing single-strand breaks (SSBs).</p>
<p>Overview of POL δ</p>
<p>POL δ is a high-fidelity DNA polymerase with proofreading (exonuclease) activity, allowing it to correct its own errors during DNA synthesis. In DNA replication, it plays a central role in lagging strand synthesis, extending Okazaki fragments. POL δ also participates in DNA repair, interacting with other proteins to ensure accurate and efficient repair processes.</p>
<p>Role of POL δ in Single-Strand Break Repair</p>
<p>In the context of SSB repair, POL δ's primary role is in long-patch base excision repair, which involves replacing multiple nucleotides. Here's how POL δ contributes to this process:</p>
<ul>
<li><strong>Gap Filling:</strong> In long-patch BER, after removing a damaged base and initial gap processing, POL δ extends the newly synthesized DNA, often displacing a short DNA flap. This gap-filling is crucial in repairing single-strand breaks and restoring the DNA's integrity.</li>
<li><strong>Interaction with PCNA:</strong> POL δ interacts with PCNA (Proliferating Cell Nuclear Antigen), a sliding clamp encircles DNA. This interaction stabilizes POL δ during DNA synthesis and helps maintain its processivity, ensuring efficient gap-filling.</li>
<li><strong>Collaboration with Other Repair Proteins:</strong> POL δ collaborates with other proteins involved in SSB repair, such as FEN1 (Flap Endonuclease 1), which cleaves the displaced DNA flap, and LIG1 (DNA Ligase 1), which seals the repaired DNA. This collaboration ensures proper coordination of the repair process.</li>
</ul>
<p>Importance of POL δ in Genomic Stability</p>
<p>POL δ's role in single-strand break repair has broader implications for genomic stability and cellular health:</p>
<ul>
<li><strong>Prevention of Double-Strand Breaks:</strong> By efficiently repairing single-strand breaks through long-patch BER, POL δ helps prevent these breaks from evolving into double-strand breaks, which are more complex and challenging to repair.</li>
<li><strong>Support of DNA Replication:</strong> POL δ's activity in DNA replication and proofreading ability ensure high-fidelity DNA synthesis, reducing the risk of mutations and promoting genomic stability.</li>
</ul>
<p>POL δ is an essential enzyme in the context of single-strand break repair, especially in long-patch base excision repair. Its role in gap-filling, interaction with PCNA, and collaboration with other repair proteins make it a key player in maintaining genomic integrity and preventing the progression of DNA damage.</p>
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7861531/',
            'https://pubmed.ncbi.nlm.nih.gov/35850061/',
            'https://pubmed.ncbi.nlm.nih.gov/33608117/'
        ]
    },
    {
        name: 'Pol ε',
        preview:"POL ε (DNA Polymerase Epsilon) is a significant DNA polymerase involved in DNA replication, particularly in leading strand synthesis. Although it is best known for its role in replication, POL ε has additional functions in DNA repair and checkpoint control, contributing to genomic stability. While it is not a primary enzyme for single-strand break (SSB) repair, its functions during replication and in response to DNA damage can influence SSB repair pathways.",
        function: `
        POL ε (DNA Polymerase Epsilon) is a significant DNA polymerase involved in DNA replication, particularly in leading strand synthesis. Although it is best known for its role in replication, POL ε has additional functions in DNA repair and checkpoint control, contributing to genomic stability. While it is not a primary enzyme for single-strand break (SSB) repair, its functions during replication and in response to DNA damage can influence SSB repair pathways.
        <p style="font-weight:600; font-size:18px">Overview of POL ε</p>
        <p>POL ε is a high-fidelity DNA polymerase with intrinsic proofreading activity (3'-5' exonuclease activity), allowing it to correct errors during DNA synthesis. It is critical in leading strand synthesis during DNA replication, ensuring accurate and efficient DNA replication.</p>
        <p style="font-weight:600; font-size:18px">POL ε's Role in DNA Replication and Checkpoints</p>
        <p>Although primarily a replication polymerase, POL ε has functions that indirectly contribute to single-strand break repair:</p>
        <ul>
        <li><strong>Leading Strand Synthesis:</strong> POL ε is responsible for synthesizing the leading strand during DNA replication. Its high fidelity and proofreading ability ensure the replicated DNA is accurate, reducing the risk of mutations or breaks during replication.</li>
<li><strong>Replication Stress Response:</strong> POL ε is part of the DNA replication machinery and can be involved in the response to replication stress. If single-strand breaks or other lesions cause replication fork stalling, POL ε's function in stabilizing replication forks and resuming DNA synthesis is critical to prevent further damage.</li>
<li><strong>Checkpoint Activation:</strong> POL ε activates checkpoint signaling pathways, such as the S-phase checkpoint. This involvement helps ensure that cells do not progress through the cell cycle with unresolved DNA damage, providing time for single-strand breaks and other damage to be repaired.</li>
</ul>
<p style="font-weight:600; font-size:18px">POL ε in DNA Repair</p>
<p>Although POL ε's direct role in SSB repair is limited, it has connections to other DNA repair pathways:</p>
<ul>
<li><strong>Interaction with Repair Proteins:</strong> POL ε can interact with various proteins involved in DNA repair and checkpoint activation. This interaction may facilitate the recruitment of repair proteins to sites of DNA damage.</li>i
<li><strong>Role in Long-Patch Base Excision Repair:</strong> While POL β (DNA Polymerase Beta) is the primary polymerase in base excision repair, POL ε might play a role in long-patch BER, where multiple nucleotides are replaced. This repair process involves DNA synthesis, potentially implicating POL ε.</li>i
</ul>
<p>POL ε is a critical DNA polymerase with a primary role in leading strand synthesis during replication. Although it is not directly involved in single-strand break repair, its functions in replication, checkpoint activation, and response to replication stress can influence the stability of DNA and the broader DNA damage response.</p>
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6153641/',
            'https://pubmed.ncbi.nlm.nih.gov/24861832/'
        ]
    },

]

export const nhejProteinFunctions = [
    {
        name: '53BP1',
        preview:`53BP1 (p53 Binding Protein 1) is not just a factor, but a pivotal player in the DNA damage response. Its role is particularly significant in the Non-Homologous End Joining (NHEJ) pathway, a crucial process in the context of DNA double-strand break (DSB) repair.`,
        function: `
        <p>53BP1 (p53 Binding Protein 1) is not just a factor, but a pivotal player in the DNA damage response. Its role is particularly significant in the Non-Homologous End Joining (NHEJ) pathway, a crucial process in the context of DNA double-strand break (DSB) repair.</p>
        <ul>
        <li><strong>DNA Damage Recognition:</strong> 53BP1 is recruited to sites of DNA damage, particularly DNA double-strand breaks (DSBs). It recognizes specific markers of DNA damage, such as H2AX phosphorylation (γH2AX), a histone modification that occurs at the sites of DNA damage, and binds to methylated histones, which are also enriched at these sites. This binding helps to mark and stabilize the regions with DSBs, facilitating the subsequent repair process.</li>        
        <li><strong>Pathway Choice and Regulation of DNA End Resection:</strong> 53BP1 is critical in determining the repair pathway choice between Non-Homologous End Joining (NHEJ) and Homologous Recombination (HR). NHEJ is a fast but error-prone pathway that directly ligates the broken DNA ends, while HR is a slower but more accurate pathway that uses a homologous DNA template for repair. 53BP1 promotes NHEJ by inhibiting extensive DNA end resection, a process required for HR but not for NHEJ. This inhibition is especially crucial in the G1 phase of the cell cycle when homologous templates for HR are not readily available. </li>       
        <li><strong>Interaction with RIF1 and Shieldin:</strong> 53BP1 recruits other proteins like RIF1 and the Shieldin complex to sites of DSBs. RIF1 and Shieldin further inhibit DNA end resection, a process required for HR but not for NHEJ. RIF1 acts as a physical barrier to prevent the resection of DNA ends, while the Shieldin complex, composed of SHLD1, SHLD2, SHLD3, and REV7, stabilizes the RIF1-DNA complex. This interaction is vital for promoting NHEJ in contexts where HR is not suitable or possible. </li>       
        <li><strong>Facilitating NHEJ Proteins' Recruitment:</strong> By promoting NHEJ, 53BP1 indirectly supports the recruitment and activity of other key NHEJ proteins. The Ku70/80 heterodimer binds to the DNA ends and recruits DNA-PKcs, a kinase that phosphorylates downstream targets to initiate repair. XRCC4 and DNA Ligase IV form a complex that ligates the DNA ends, while Artemis processes the DNA ends to make them suitable for ligation. This facilitation ensures that the NHEJ pathway proceeds efficiently for effective DSB repair.</li>        
        <li><strong>Genomic Stability and Tumor Suppression:</strong> The contribution of 53BP1 to genomic stability is not just significant, it's paramount. By promoting efficient and accurate NHEJ, 53BP1 acts as a guardian against genomic instability. Defects in 53BP1 can lead to a cascade of issues, including increased sensitivity to DNA damage and a higher risk of cancer. In particular, 53BP1 acts as a shield, preventing chromosomal rearrangements and translocations that could potentially lead to tumorigenesis.</li>        
        <li><strong>Role in V(D)J Recombination and Immune Function:</strong> 53BP1 plays a role in V(D)J recombination, a process vital for generating diversity in B and T cell receptors in the immune system. By promoting NHEJ, 53BP1 helps ensure the proper recombination of variable (V), diversity (D), and joining (J) gene segments.</li>        
        </ul>
        <p>In summary, 53BP1 is a critical regulator in the NHEJ pathway, promoting DNA repair by inhibiting extensive DNA end resection and facilitating the recruitment of other NHEJ proteins. Its role in determining pathway choice, maintaining genomic stability, and supporting immune system functions underscores its critical importance in cellular responses to DNA damage.</p>        
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4133096/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9162042/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC10082759/',
            'https://pubmed.ncbi.nlm.nih.gov/37640588/'
        ]
    },
    {
        name: 'ATM',
        preview:`ATM (Ataxia-Telangiectasia Mutated) is a protein kinase that plays a significant role in the cellular response to DNA damage, particularly in recognizing double-strand breaks (DSBs) and initiating a signaling cascade to coordinate the DNA damage response (DDR). While ATM is more commonly associated with the Homologous Recombination (HR) pathway, it also has essential roles in Non-Homologous End Joining (NHEJ).`,
        function: `
        <p>ATM (Ataxia-Telangiectasia Mutated) is a protein kinase that plays a significant role in the cellular response to DNA damage, particularly in recognizing double-strand breaks (DSBs) and initiating a signaling cascade to coordinate the DNA damage response (DDR). While ATM is more commonly associated with the Homologous Recombination (HR) pathway, it also has essential roles in Non-Homologous End Joining (NHEJ).</p>
<ul>
<li><strong>Activation and DNA Damage Recognition:</strong> ATM is activated in response to DSBs, typically through autophosphorylation. This activation triggers a signaling cascade that leads to the phosphorylation of several essential proteins involved in the DDR. While ATM's primary role is in HR, it also contributes to NHEJ by recognizing and signaling DSBs.</li>
<li><strong>Modulating DNA Repair Pathway Choice:</strong> ATM influences the choice between HR and NHEJ, mainly by regulating DNA end resection. Due to its role in DNA end resection, ATM activation is generally associated with promoting HR. However, depending on the cell cycle phase and other factors, ATM can also contribute to NHEJ by signaling the need for quick and efficient repair when HR is not feasible.</li>
<li><strong>Phosphorylation of NHEJ Proteins:</strong> ATM phosphorylates various proteins involved in the NHEJ pathway, such as 53BP1. This phosphorylation can impact these proteins' activity, localization, or interactions, affecting how they contribute to NHEJ. In the case of 53BP1, ATM-mediated phosphorylation is critical in inhibiting DNA end resection, a process that is essential for HR but not for NHEJ. This inhibition promotes NHEJ over HR in specific contexts, demonstrating the direct influence of ATM on the choice of DNA repair pathway.</li>
</ul>
<p><span>ATM's role in the broader</span> DNA damage response is not limited to HR. Its coordination with other DDR proteins can significantly influence how cells respond to DSBs and whether they utilize NHEJ. While ATM often leans towards HR, its signaling activity can create an environment conducive to NHEJ when appropriate. This versatility of ATM's functions underscores its broad impact, making it a key player in maintaining genomic stability.</li>
<ul>
<li><strong>Role in Cell Cycle Regulation and Checkpoints:</strong> ATM's activation can activate cell cycle checkpoints, pausing the cell cycle to allow time for DNA repair. This regulation indirectly affects NHEJ by providing the necessary environment for DNA repair processes to operate.</li>
</ul>
<p>ATM's primary association with HR, driven by its role in DNA end resection, is well-known. However, its broader involvement in the DNA damage response extends to NHEJ, particularly in situations demanding rapid repair of double-strand breaks. Grasping ATM's interplay between HR and NHEJ is not just informative, but crucial for understanding its multifaceted role in maintaining genomic stability.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/32890395/',
            'https://www.nature.com/articles/s41467-018-07729-2',
            'https://pubmed.ncbi.nlm.nih.gov/28624372/',
            'https://pubmed.ncbi.nlm.nih.gov/20026654/'
        ]
    },
    {
        name: '53BP1.p',
        preview:'Phosphorylation plays a key role in the function of 53BP1 (p53 Binding Protein 1), a key component in the DNA damage response and the Non-Homologous End Joining (NHEJ) pathway for repairing double-strand breaks (DSBs). Phosphorylation of 53BP1 can affect its activity, interactions with other proteins, and overall role in the NHEJ process.',
        function: `
        <p>Phosphorylation plays a key role in the function of 53BP1 (p53 Binding Protein 1), a key component in the DNA damage response and the Non-Homologous End Joining (NHEJ) pathway for repairing double-strand breaks (DSBs). Phosphorylation of 53BP1 can affect its activity, interactions with other proteins, and overall role in the NHEJ process.</p>
        <ul>
<li><strong>Activation and Recruitment to DNA Damage Sites:</strong> Phosphorylation of 53BP1 is a critical step in its recruitment to sites of DNA damage. Kinases such as ATM (Ataxia-Telangiectasia Mutated) and ATR (Ataxia Telangiectasia and Rad3-related) are responsible for phosphorylating 53BP1 in response to DNA damage, particularly DSBs. Phosphorylation enhances the ability of 53BP1 to bind to modified histones, such as γH2AX, at the site of DNA breaks.</li>
</ul>
<p>The phosphorylation of 53BP1 is a key determinant in the choice between NHEJ and Homologous Recombination (HR). By restraining extensive DNA end resection, phosphorylated 53BP1 promotes NHEJ, particularly in situations where HR is not feasible, such as the G1 phase of the cell cycle.</p>
<ul>
<li><strong>Interaction with Other DNA Repair Proteins:</strong> Phosphorylation of 53BP1 can facilitate its interactions with other proteins involved in the NHEJ pathway, such as RIF1 and the Shieldin complex. These interactions further promote NHEJ by inhibiting resection and maintaining the broken DNA ends in a state conducive to ligation.</li>
<li><strong>Role in Stabilizing DNA Breaks:</strong> The phosphorylation of 53BP1 contributes to its ability to stabilize broken DNA ends and form repair foci. This stabilization is essential for recruiting other NHEJ components, such as the Ku70/80 heterodimer, DNA-PKcs, XRCC4, and DNA Ligase IV, ensuring efficient and accurate repair.</li>
</ul>
<p>The phosphorylation of 53BP1 is a crucial factor in maintaining genomic stability by facilitating proper repair of DSBs through NHEJ. Any disruptions in the phosphorylation process can lead to impaired DNA repair, heightened genomic instability, and an increased risk of chromosomal abnormalities and cancer.</p>
<p>In summary, phosphorylated 53BP1 is central to the NHEJ pathway, regulating DNA repair pathway choice, stabilizing DNA breaks, and interacting with other essential NHEJ proteins. Its phosphorylation state affects its ability to promote NHEJ and contribute to genomic stability, emphasizing its vital to the role of cellular response to DNA damage.</p>
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4133096/',
            'https://pubmed.ncbi.nlm.nih.gov/34144977/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9162042/'
        ]
    },
    {
        name: 'PTIP',
        preview:'PTIP (Pax Transactivation Domain-Interacting Protein) is a protein that acts in the DNA damage response and has connections to DNA repair pathways, including Non-Homologous End Joining (NHEJ) and Homologous Recombination (HR). Although PTIP is generally known for its role in HR, it has some implications in NHEJ due to its interactions with proteins involved in DNA damage signaling and repair. For instance, PTIP has been found to interact with Ku70 and Ku80, key proteins in the NHEJ pathway, suggesting its involvement in NHEJ.',
        function: `
 <p>PTIP (Pax Transactivation Domain-Interacting Protein) is a protein that acts in the DNA damage response and has connections to DNA repair pathways, including Non-Homologous End Joining (NHEJ) and Homologous Recombination (HR). Although PTIP is generally known for its role in HR, it has some implications in NHEJ due to its interactions with proteins involved in DNA damage signaling and repair. For instance, PTIP has been found to interact with Ku70 and Ku80, key proteins in the NHEJ pathway, suggesting its involvement in NHEJ.</p>
<ul>
<li><strong>Interaction with 53BP1:</strong> PTIP interacts with 53BP1, a critical protein in the NHEJ pathway that regulates DNA repair pathway choice and inhibits DNA end resection, thereby promoting NHEJ over HR. PTIP's interaction with 53BP1 suggests it might play a role in the broader DNA damage response, where 53BP1 is a key participant.</li>
<li><strong>Regulation of DNA Damage Response:</strong> PTIP is known to be involved in recognizing DNA double-strand breaks, a critical step in the DNA damage response, and facilitating the recruitment of other proteins to sites of DNA damage. This role is significant in both HR and NHEJ, as it helps establish the repair machinery needed to process and repair DNA breaks. For example, PTIP's interaction with MDC1 leads to the recruitment of other repair proteins, such as BRCA1, to the site of DNA damage, thereby initiating the repair process.</li>
<li><strong>Involvement in DNA End Resection:</strong> While PTIP is more commonly associated with promoting DNA end resection for HR, its role in regulating this process can also impact NHEJ. DNA end resection is a vital phase in HR, where it promotes the formation of a single-stranded DNA template for repair. However, excessive resection can inhibit NHEJ, which relies on intact DNA ends for repair. By influencing the extent of DNA end resection, PTIP can affect whether a cell leans toward HR or NHEJ to repair double-strand breaks.</li>
<li><strong>Contributing to Genomic Stability:</strong> PTIP's role in DNA damage signaling and repair is not just a mere function, but a crucial contribution to genomic stability. Its influence on DNA repair pathway choice and coordination with other DNA repair proteins, including those in the NHEJ pathway, is instrumental in ensuring efficient and accurate DNA repair.</li>
</ul>
<p>Although PTIP is primarily known for its role in HR, its interactions with 53BP1 and its involvement in the DNA damage response indicate that it may also play a role in NHEJ. However, its exact function in NHEJ needs to be more well-defined than that of other DNA repair pathways. Understanding PTIP's broader role in DNA damage response and its potential impact on NHEJ can provide insights into the complex mechanisms of DNA repair and genomic stability.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/23727112/',
            'https://www.nature.com/articles/s12276-020-00519-1',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9509598/'
        ]
    },
    {
        name: 'Artemis (SNM1C)',
        preview:'The interaction between Artemis (also known as SNM1C), PTIP (Pax Transactivation Domain-Interacting Protein), and 53BP1 (p53 Binding Protein 1) is a complex aspect of the DNA damage response, particularly in Non-Homologous End Joining (NHEJ) and related DNA repair pathways. Each of these proteins plays a crucial role: Artemis has a well-defined role in end processing during NHEJ, 53BP1 and PTIP regulate the choice between NHEJ and Homologous Recombination (HR), with 53BP1 promoting NHEJ by limiting DNA end resection.',
        function: `
        <p>The interaction between Artemis (also known as SNM1C), PTIP (Pax Transactivation Domain-Interacting Protein), and 53BP1 (p53 Binding Protein 1) is a complex aspect of the DNA damage response, particularly in Non-Homologous End Joining (NHEJ) and related DNA repair pathways. Each of these proteins plays a crucial role: Artemis has a well-defined role in end processing during NHEJ, 53BP1 and PTIP regulate the choice between NHEJ and Homologous Recombination (HR), with 53BP1 promoting NHEJ by limiting DNA end resection.</p>
<ul>
<li><strong>Artemis's Role in NHEJ:</strong> Artemis is an endonuclease that is activated by phosphorylation from DNA-PKcs. It plays a crucial role in the NHEJ pathway by precisely processing DNA ends, such as opening hairpin structures and trimming overhangs, thereby preparing the ends for ligation.</li>
<li><strong>53BP1's Role in Pathway Choice:</strong> 53BP1 is a critical regulator of DNA damage response and plays a central role in promoting NHEJ by inhibiting DNA end resection. The choice between NHEJ and Homologous Recombination (HR) is a crucial decision in the DNA damage response pathway. NHEJ is a fast but error-prone repair pathway, while HR is a slower but more accurate pathway. By preventing extensive end resection, 53BP1 fosters an environment where Artemis can process DNA ends and allow the ligation step of NHEJ to proceed.</li>
<li><strong>PTIP's Role and Its Relationship with 53BP1:</strong> PTIP is involved in DNA damage signaling and has a known interaction with 53BP1. While PTIP is generally associated with Homologous Recombination, it can influence the choice between NHEJ and HR by impacting DNA end resection. DNA end resection is a critical step in the DNA damage response pathway as it determines the repair pathway choice. PTIP's ability to modulate DNA end resection can therefore have significant implications for the overall efficiency and accuracy of DNA repair. PTIP may modulate 53BP1's function or contribute to pathway choice by altering the recruitment of proteins to sites of DNA damage.</li>
<li><strong>Potential Interaction and Regulation:</strong> Artemis and 53BP1 are integral to the NHEJ pathway, but their direct interaction is less clear. PTIP, through its role in influencing DNA end resection and its interaction with 53BP1, may impact Artemis's function by affecting the pathway choice between HR and NHEJ. In contexts where PTIP facilitates HR by promoting DNA end resection, it might indirectly influence Artemis's activity by shifting repair preference away from NHEJ.</li>
</ul>
<p>Given these complex interactions, further research into the specific mechanisms by which Artemis, PTIP, and 53BP1 interact could provide deeper insights into how DNA repair pathways are regulated. While direct evidence of Artemis and PTIP's interaction is limited, their shared involvement in the broader DNA damage response and their influence on DNA repair pathway choice offer compelling reasons to explore their roles in maintaining genomic stability and effective DNA repair.</p>
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4265673/',
        ]
    },
    {
        name: 'Artemis',
        preview:'Artemis is a key protein in the Non-Homologous End Joining (NHEJ) pathway, which is crucial for repairing DNA double-strand breaks (DSBs).',
        function: `
        <p>Artemis is a key protein in the Non-Homologous End Joining (NHEJ) pathway, which is crucial for repairing DNA double-strand breaks (DSBs).</p>
        <ul>
<li><strong>Endonuclease Activity:</strong> Artemis is an endonuclease, meaning it can cut DNA. This characteristic is essential for processing DNA ends to make them suitable for ligation during NHEJ.</li>
<li><strong>Activation by DNA-PKcs:</strong> Artemis's activation is a complex process that involves phosphorylation by DNA-PKcs (DNA-dependent protein kinase catalytic subunit). The Ku70/80 heterodimer recruits DNA-PKcs to the site of DSBs, forming the DNA-PK complex. Once activated, Artemis can cleave DNA strands, facilitating repair.</li>
<li><strong>End Processing:</strong> The primary role of Artemis in NHEJ is to process DNA ends. Double-strand breaks can have complex structures such as overhangs, hairpins, or blunt ends that must be trimmed or modified to ensure successful ligation. Once activated by DNA-PKcs, Artemis can perform these processing tasks, ensuring that the DNA ends are compatible for subsequent steps in NHEJ.</li>
<li><strong>Hairpin Opening:</strong> Artemis is critical in opening hairpin structures that can form at DSBs, especially in V(D)J recombination, which is vital for immune system development. Artemis's ability to cleave hairpin loops is essential for generating the required diversity in immune cells.</li>
<li><strong>Coordination with Other NHEJ Proteins:</strong> Artemis operates in concert with other NHEJ proteins to ensure efficient repair of DNA breaks. After processing DNA ends, it hands over the task of ligation to proteins like DNA Ligase IV, XRCC4, and XLF, which join the processed ends to restore the DNA's integrity.</li>
</ul>
<p>In summary, Artemis is an important endonuclease in the NHEJ pathway. DNA-PKcs activate it and are responsible for processing and preparing DNA ends for ligation. Its role in opening hairpins and processing complex DNA structures is crucial for successfully repairing double-strand breaks in general DNA repair and specific contexts like V(D)J recombination in the immune system.</p>
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC404326/',
            'https://pubmed.ncbi.nlm.nih.gov/36270581/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6650781/'
        ]
    },
    {
        name: 'RIF1',
        preview:'RIF1 (Rap1-Interacting Factor 1) is a pivotal protein in DNA repair, particularly in the context of Non-Homologous End Joining (NHEJ), DNA replication, and telomere maintenance. In the NHEJ pathway, RIF1 plays a unique and crucial role, largely centered on its ability to influence DNA end resection and repair pathway choice.',
        function: `
        <p>RIF1 (Rap1-Interacting Factor 1) is a pivotal protein in DNA repair, particularly in the context of Non-Homologous End Joining (NHEJ), DNA replication, and telomere maintenance. In the NHEJ pathway, RIF1 plays a unique and crucial role, largely centered on its ability to influence DNA end resection and repair pathway choice.</p>
<ul>
<li><strong>Inhibition of DNA End Resection:</strong> In the process of repairing double-strand breaks, a critical decision point is whether to proceed with NHEJ or Homologous Recombination (HR). HR requires extensive DNA end resection, while NHEJ does not. RIF1, with its unique function, inhibits DNA end resection, promoting the use of NHEJ for DNA repair. This function is particularly crucial in the G1 phase of the cell cycle, where NHEJ is the predominant pathway for repairing DSBs.</li>
<li><strong>Key Interaction with 53BP1:</strong> RIF1's interaction with 53BP1, a pivotal protein in determining DNA repair pathway choice, is of significant importance. This interaction is crucial for blocking DNA end resection, thereby favoring NHEJ over HR. 53BP1 recruits RIF1 to the site of DNA damage, where RIF1 exerts its resection-blocking activity.</li>
<li><strong>Central role in NHEJ Pathway Choice:</strong> RIF1's inhibition of end resection plays a pivotal role in promoting the NHEJ pathway during double-strand break repair. This role is crucial for ensuring the swift and efficient repair of breaks, particularly in non-dividing or slowly dividing cells, thereby contributing significantly to genomic stability.</li>
<li><strong>Contribution to Genomic Stability:</strong> RIF1 helps maintain genomic stability by promoting NHEJ and preventing excessive DNA end resection. Its proper function is necessary to avoid chromosomal instability and reduce the risk of inappropriate recombination events.</li>
<li><strong>Influence on Cellular Responses to DNA Damage:</strong> RIF1 and other DNA repair factors can influence the broader cellular response to DNA damage. By promoting NHEJ, RIF1 contributes to rapid and accurate DNA repair, reducing the risk of cell death or transformation to a cancerous state.</li>
</ul>
<p>In summary, RIF1 is a critical regulator in the NHEJ pathway, primarily by inhibiting DNA end resection. By promoting NHEJ over HR, especially in cell cycle phases where HR is not preferred, RIF1 plays a significant role in ensuring the effective repair of double-strand breaks and maintaining genomic stability. Its interaction with 53BP1 and its role in the NHEJ pathway choice underline its importance in DNA repair processes.</p>
        `,
        reference: [
            'https://www.sciencedirect.com/science/article/pii/S1568786413002917#:~:text=Rif1%20is%20a%20highly%20conserved,the%20maintenance%20of%20genomic%20integrity.',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3630874/',
            'https://www.nature.com/articles/s41467-019-11246-1',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3815530/'
        ]
    },
    {
        name: 'ASF1',
        preview:"ASF1 (Anti-Silencing Function 1) is not just a histone chaperone involved in chromatin assembly and disassembly. Its role is of paramount importance, intricately linked to histone dynamics, particularly in the context of nucleosome assembly during DNA replication and repair. While ASF1 is primarily acknowledged for its involvement in DNA replication and chromatin remodeling, it has also been implicated in the DNA damage response, a crucial area of studyin DNA repair. It has a potential connection to Non-Homologous End Joining (NHEJ), a process that is fundamental to DNA repair.",
        function: `
        <p>ASF1 (Anti-Silencing Function 1) is not just a histone chaperone involved in chromatin assembly and disassembly. Its role is of paramount importance, intricately linked to histone dynamics, particularly in the context of nucleosome assembly during DNA replication and repair. While ASF1 is primarily acknowledged for its involvement in DNA replication and chromatin remodeling, it has also been implicated in the DNA damage response, a crucial area of studyin DNA repair. It has a potential connection to Non-Homologous End Joining (NHEJ), a process that is fundamental to DNA repair.</p>
<p><span>ASF1's primary function as</span> a histone chaperone, facilitating nucleosome assembly and disassembly, has practical implications in the context of NHEJ. NHEJ, which occurs within the context of chromatin, can be hindered by the chromatin structure. ASF1's role in chromatin remodeling allows NHEJ proteins to access and repair DNA double-strand breaks, making it a crucial player in the DNA repair process.</p>
<ul>
<li><strong>Influence on Histone Supply:</strong> ASF1 can modulate the supply of histones H3 and H4, essential components of nucleosomes. In the context of NHEJ, efficient histone management ensures that chromatin structure is restored after DNA repair. Proper nucleosome reassembly after repair is crucial for maintaining genomic stability.</li>
</ul>
<p>ASF1's role in chromatin dynamics allows it to interact with other DNA repair pathways, such as Homologous Recombination (HR) and NHEJ. While it is primarily associated with HR, ASF1's chromatin-related functions indirectly support NHEJ. It does so by maintaining a suitable chromatin environment for DNA repair, a condition that is beneficial for all DNA repair pathways, including NHEJ.</p>
<ul>
<li><strong>Role in DNA Damage Response:</strong> ASF1 is involved in the broader DNA damage response, influencing cell cycle checkpoints and activating repair pathways. Although its direct role in NHEJ is not as extensively documented as in other DNA repair processes, its ability to impact chromatin structure can facilitate or hinder the access of NHEJ proteins to DNA breaks.</li>
<li><strong>Maintenance of Genomic Stability:</strong> By contributing to chromatin dynamics, ASF1 helps maintain genomic stability, indirectly supporting all DNA repair pathways, including NHEJ. Proper chromatin assembly is critical for avoiding genomic instability, which can lead to chromosomal aberrations or cancer.</li>
</ul>
<p>In summary, ASF1's primary function as a histone chaperone in chromatin assembly and disassembly plays an essential role in the broader DNA damage response. It may impact NHEJ by facilitating chromatin remodeling and providing a suitable environment for repair proteins. Although its role in NHEJ is not as direct as other repair proteins, ASF1's contribution to chromatin structure and genomic stability is vital in DNA repair.</p>
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2981792/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4445473/',
            'https://pubmed.ncbi.nlm.nih.gov/35472331/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5743198/'
        ]
    },
    {
        name: 'REV7',
        preview:"REV7, also known as MAD2L2 or MAD2B, is a multifunctional protein with pivotal roles in various cellular processes, notably DNA repair, cell cycle regulation, and mitotic spindle assembly. In the realm of DNA repair, REV7's involvement in Non-Homologous End Joining (NHEJ) as a component of the Shieldin complex is of paramount importance. This complex aids in regulating the double-strand break (DSB) repair pathway choice, a process crucial for maintaining genomic stability and influencing the response to certain cancer therapies.",
        function: `
        <p>REV7, also known as MAD2L2 or MAD2B, is a multifunctional protein with pivotal roles in various cellular processes, notably DNA repair, cell cycle regulation, and mitotic spindle assembly. In the realm of DNA repair, REV7's involvement in Non-Homologous End Joining (NHEJ) as a component of the Shieldin complex is of paramount importance. This complex aids in regulating the double-strand break (DSB) repair pathway choice, a process crucial for maintaining genomic stability and influencing the response to certain cancer therapies.</p>
<ul>
<li><strong>Part of the Shieldin Complex:</strong> REV7 is a key component of the Shieldin complex, along with other proteins such as SHLD1, SHLD2, and SHLD3. Shieldin acts downstream of 53BP1, reinforcing the choice of NHEJ over Homologous Recombination (HR). The Shieldin complex is critical in promoting NHEJ by inhibiting extensive DNA end resection, ensuring that DSBs are repaired through NHEJ.</li>
<li><strong>Regulation of DNA End Resection:</strong> By being part of the Shieldin complex, REV7 contributes to the suppression of DNA end resection. This function is crucial for directing DNA repair toward NHEJ, especially in contexts where HR might lead to genomic instability or is otherwise inappropriate (such as in non-dividing cells or the G1 phase of the cell cycle).</li>
<li><strong>Facilitating NHEJ Protein Recruitment:</strong> REV7, through its role in Shieldin, helps stabilize and recruit other NHEJ proteins to the sites of DSBs. This facilitation ensures that the NHEJ machinery operates effectively, supporting proper alignment, stabilization, and ligation of DNA ends.</li>
<li><strong>Role in Genomic Stability:</strong> By promoting NHEJ, REV7 plays a pivotal role in maintaining genomic stability. This function is not just significant, but also crucial, as it effectively prevents chromosomal rearrangements and other genomic instabilities that could potentially lead to cancer or other diseases.</li>
<li><strong>Influence on DNA Damage Response:</strong> REV7's involvement in the Shieldin complex places it in the broader context of the DNA damage response. Its role in inhibiting end resection and promoting NHEJ can affect how cells respond to DNA damage and maintain their genomic integrity.</li>
<li><strong>Therapeutic Implications:</strong> The Shieldin complex, and by extension REV7, has emerged as a promising avenue in the context of cancer therapy. The complex's role in pathway choice can significantly impact the response to certain chemotherapies and targeted therapies, potentially revolutionizing cancer treatment outcomes. This underscores the potential of REV7 as a target for improving cancer therapy, instilling hope for more effective and targeted treatments.</li>
</ul>
<p>In summary, REV7, as part of the Shieldin complex, plays a crucial role in the Non-Homologous End Joining pathway by inhibiting DNA end resection, facilitating NHEJ protein recruitment, and promoting genomic stability. Its function in the NHEJ pathway underscores its importance in ensuring efficient and accurate repair of double-strand breaks and its potential implications in cancer therapy and genomic stability.</p>
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6138439/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC10046837/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC10082759/',
            'https://pubmed.ncbi.nlm.nih.gov/26449786/'
        ]
    },
    {
        name: 'TRIP13',
        preview:"TRIP13 (Thyroid Receptor-Interacting Protein 13) is a protein involved in several cellular processes, including DNA repair, chromosome structure maintenance, and the spindle assembly checkpoint. In the context of Non-Homologous End Joining (NHEJ), TRIP13 has been identified as a factor that influences DNA repair pathway choice, especially in relation to the role of the Shieldin complex and its interaction with the NHEJ regulator 53BP1.",
        function: `
<p>TRIP13 (Thyroid Receptor-Interacting Protein 13) is a protein involved in several cellular processes, including DNA repair, chromosome structure maintenance, and the spindle assembly checkpoint. In the context of Non-Homologous End Joining (NHEJ), TRIP13 has been identified as a factor that influences DNA repair pathway choice, especially in relation to the role of the Shieldin complex and its interaction with the NHEJ regulator 53BP1.</p>
<p><span>TRIP13's</span> influence on the Shieldin complex is a key aspect of its function. By disassembling the Shieldin complex, TRIP13 directly affects its ability to inhibit DNA end resection, thereby shifting the balance from NHEJ towards Homologous Recombination (HR), particularly in situations where end resection is necessary.</p>
<ul>
<li><strong>Impact on DNA End Resection and Repair Pathway Choice:</strong> By disassembling the Shieldin complex, TRIP13 effectively facilitates DNA end resection. This action can favor HR over NHEJ, suggesting that TRIP13 plays a role in determining the DNA repair pathway choice based on the cellular context, cell cycle phase, and type of DNA damage.</li>
<li><strong>Interplay with 53BP1:</strong> The Shieldin complex, a downstream player of 53BP1, a key regulator in promoting NHEJ by inhibiting end resection, is intricately involved in TRIP13's function. TRIP13's role in disassembling Shieldin can significantly impact the effectiveness of 53BP1's function in NHEJ, further highlighting the complexity of TRIP13's role in moderating the balance between NHEJ and HR.</li>
</ul>
<p><span>TRIP13's</span> role in genomic stability is multifaceted. By influencing the balance between NHEJ and HR, TRIP13 can either promote or hinder genomic stability. When the Shieldin complex is disassembled, the reduced inhibition of end resection can lead to increased HR, a potential source of genomic instability. However, in certain cellular contexts and cell cycle phases, this shift may be beneficial, highlighting the complex nature of TRIP13's role in maintaining genomic stability.</p>
<ul>
<li><strong>Implications for Cancer and DNA Repair Disorders:</strong> The role of TRIP13 in regulating repair pathway choice is not specifically RUO subject matter. It has direct implications for cancer and other DNA repair disorders. By influencing whether cells use NHEJ or HR, TRIP13 can significantly impact the frequency of genomic rearrangements and mutations, potentially linking to cancer development or therapy resistance.</li>
</ul>
<p>In summary, the role of TRIP13 in regulating the balance between Non-Homologous End Joining and Homologous Recombination, achieved through its unique ability to disassemble the Shieldin complex, is of paramount importance. This function, which promotes DNA end resection, has profound implications for genomic stability, the response to DNA damage, and potential therapeutic strategies for cancer and other diseases related to DNA repair.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/38216586/',
            'https://www.nature.com/articles/s41556-019-0442-y',
            'https://www.nature.com/articles/s41420-022-00824-w',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7480811/'
        ]
    },
    {
        name: 'RIF.p',
        preview:"Phosphorylation, a prevalent post-translational modification, is a key regulator of protein function. Its significance is particularly pronounced in proteins involved in DNA repair, such as RIF1 (Rap1-Interacting Factor 1). RIF1, known for its role in inhibiting DNA end resection and promoting Non-Homologous End Joining (NHEJ), is subject to the influence of phosphorylation in multiple ways, underscoring the importance of this modification in DNA repair pathways.",
        function: `
        <p>Phosphorylation, a prevalent post-translational modification, is a key regulator of protein function. Its significance is particularly pronounced in proteins involved in DNA repair, such as RIF1 (Rap1-Interacting Factor 1). RIF1, known for its role in inhibiting DNA end resection and promoting Non-Homologous End Joining (NHEJ), is subject to the influence of phosphorylation in multiple ways, underscoring the importance of this modification in DNA repair pathways.</p>
<ul>
<li><strong>Regulation of Protein Function:</strong> The phosphorylation of RIF1, a protein with a crucial role in the NHEJ pathway, can significantly impact its activity, localization, stability, and interactions with other proteins. These changes, in turn, can influence how RIF1 contributes to the NHEJ pathway, particularly in regulating DNA end resection and promoting repair by NHEJ over other pathways like Homologous Recombination (HR).</li>
<li><strong>Interaction with 53BP1:</strong> RIF1 is known to interact with 53BP1, an essential protein involved in determining the choice between NHEJ and HR. Phosphorylation of RIF1 might influence its interaction with 53BP1, affecting the efficiency with which these proteins work together to inhibit end resection and promote NHEJ.</li>
<li><strong>Response to DNA Damage:</strong> Phosphorylation often acts as a crucial signaling mechanism in response to DNA damage. The phosphorylation status of RIF1 can serve as a beacon, indicating its role in the cellular response to DNA damage and its readiness to participate in the NHEJ pathway. Specific kinases involved in the DNA damage response, such as ATM (Ataxia-Telangiectasia Mutated), might phosphorylate RIF1, thereby modifying its function during DNA repair.</li>
<li><strong>Cell Cycle Regulation:</strong> RIF1's role in the NHEJ pathway can vary depending on the cell cycle stage. Phosphorylation can act as a regulatory mechanism to determine when RIF1's inhibitory effect on end resection is needed, favoring NHEJ in specific cell cycle phases, such as G1, where HR is less likely to occur.</li>
<li><strong>Contribution to Genomic Stability:</strong> The impact of phosphorylation on genomic stability is significant, as it can influence the activity of RIF1. Phosphorylation events that either promote or inhibit RIF1's role in NHEJ can directly affect the cell's ability to repair double-strand breaks efficiently and accurately, thereby playing a crucial role in maintaining genomic integrity.</li>
</ul>
<p>While ongoing research is underway into the specific phosphorylation sites and kinases involved in regulating RIF1 in the context of NHEJ, it's clear that phosphorylation plays a significant role in controlling its function and interactions within the DNA repair network. Understanding these phosphorylation events is crucial for unraveling the complex regulatory mechanisms underlying DNA repair pathways and how they contribute to genomic stability and preventing diseases like cancer.</p>
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8995355/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6035837/',
            'https://pubmed.ncbi.nlm.nih.gov/19483192/'
        ]
    },
    {
        name: 'Ku70',
        preview:"Ku70 is a crucial protein involved in the Non-Homologous End Joining (NHEJ) pathway, one of DNA's central mechanisms for repairing double-strand breaks (DSBs).",
        function: `
        </p>Ku70 is a crucial protein involved in the Non-Homologous End Joining (NHEJ) pathway, one of DNA's central mechanisms for repairing double-strand breaks (DSBs).</p>
<li><strong>Recognition and Binding to DNA Breaks:</strong> Ku70 and Ku80 form the Ku heterodimer. This heterodimer has a high affinity for DNA ends and is responsible for recognizing and binding to DSBs. Its structure allows it to slide onto the DNA strand and form a ring around the break, stabilizing it.</li>
<li><strong>Recruitment of Additional NHEJ Proteins:</strong> After binding to the DNA break, the Ku heterodimer acts as a scaffold, recruiting and assembling other proteins essential for the NHEJ pathway. This includes DNA-PKcs (DNA-dependent protein kinase catalytic subunit), which are crucial in further processing the break.</li>
<li><strong>Protection of DNA Ends:</strong> By binding to the ends of the DNA at the site of the break, Ku70/80 helps protect the DNA from further degradation or unwanted processing, ensuring that the ends are preserved for the repair process.</li>
<li><strong>Alignment of DNA Ends:</strong> Ku70/80 helps align the broken DNA ends, facilitating the ligation step in NHEJ. This alignment is crucial for accurate repair, even though NHEJ does not require extensive sequence homology.</li>
<li><strong>Participation in End Processing and Ligation:</strong> Once other NHEJ factors have been recruited, Ku70/80 can assist in processing DNA ends if necessary. Ultimately, it helps bring the DNA ends together for ligation by the ligase complex (such as DNA Ligase IV, XRCC4, and XLF), completing the repair process.</li>
</p>In summary, Ku70, as part of the Ku heterodimer, plays a central role in the NHEJ pathway by recognizing and binding to DNA breaks, recruiting and organizing other repair proteins, protecting DNA ends, and facilitating the alignment and ligation necessary to repair double-strand breaks.</p>
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8073936/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3758668/',
            'https://pubmed.ncbi.nlm.nih.gov/17124166/'
        ]
    },
    {
        name: 'Ku80',
        preview:'Ku80 is a critical component of the non-homologous end joining (NHEJ) pathway, an essential mechanism that cells use to repair DNA double-strand breaks (DSBs).',
        function: `
        <p>Ku80 is a critical component of the non-homologous end joining (NHEJ) pathway, an essential mechanism that cells use to repair DNA double-strand breaks (DSBs).</p>
        <ul>
        <li><strong>Formation of the Ku Heterodimer:</strong> Ku80 forms a heterodimer with Ku70, creating a protein complex known as Ku70/80 or the Ku heterodimer. This dimerization is essential for the stability and function of both proteins.</li>        
        <li><strong>Recognition and Binding to DNA Breaks:</strong> The Ku heterodimer (Ku70/80) is the primary factor in recognizing and binding to DNA ends at double-strand breaks. This complex has a toroidal (ring-like) structure that allows it to encircle the DNA, stabilizing the ends and preventing degradation.</li>        
        <li><strong>Recruitment of Additional NHEJ Proteins:</strong> Once bound to a double-strand break, the Ku heterodimer acts as a scaffold for recruiting other critical proteins involved in the NHEJ pathway. This includes proteins such as DNA-PKcs (DNA-dependent protein kinase catalytic subunit), Artemis (a nuclease for DNA end processing), and the DNA ligase complex (DNA Ligase IV, XRCC4, and XLF).</li>        
        </ul>
        <p><span>One of the critical functions of the Ku heterodimer is</span> to bind to the DNA ends, a process in which Ku80 plays a significant role. This binding action helps shield the DNA ends from nucleases or other destabilizing factors, a crucial step in maintaining the integrity of the broken DNA during the repair process. </p>       
        <ul>
        <li><strong>Facilitating DNA End Processing:</strong> In cases where DNA ends are not immediately compatible for direct ligation, Ku80, in conjunction with other NHEJ factors, helps facilitate end processing. This can involve trimming overhangs or filling in gaps to ensure the ends are suitable for joining.</li>       
        </ul>
        <p>Following recruitment and processing, Ku80's role extends to aligning the DNA ends for the final ligation step. It achieves this by engaging with other NHEJ proteins that facilitate the physical joining of DNA ends, restoring the DNA's continuity.</p>        
        <p>In summary, Ku80, as part of the Ku heterodimer, is essential for detecting, stabilizing, and repairing double-strand breaks in DNA through the Non-Homologous End Joining pathway. It is also critical for recruiting other NHEJ proteins, protecting DNA ends, facilitating end processing, and promoting DNA ligation.</p>        
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8073936/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3758668/',
            'https://pubmed.ncbi.nlm.nih.gov/17124166/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3281638/'
        ]
    },
    {
        name: 'DNA-PKcs',
        preview:'DNA-PKcs (DNA-dependent protein kinase catalytic subunit) is a critical component of the Non-Homologous End Joining (NHEJ) pathway, which is a crucial mechanism for repairing double-strand breaks (DSBs) in DNA.',
        function: `
        <p>DNA-PKcs (DNA-dependent protein kinase catalytic subunit) is a critical component of the Non-Homologous End Joining (NHEJ) pathway, which is a crucial mechanism for repairing double-strand breaks (DSBs) in DNA.</p>
<ul>
<li><strong></strong>Complex Formation with Ku70/Ku80:</strong> DNA-PKcs is recruited to DSBs by the Ku heterodimer (Ku70/Ku80), which binds to DNA ends. This intricate interaction between Ku and DNA-PKcs forms the DNA-PK complex, marking the initial and complex step in the NHEJ process.</li>
<li><strong>Protein Kinase Activity:</strong> As its name suggests, DNA-PKcs is a protein kinase, meaning it can phosphorylate other proteins. This kinase activity is vital for regulating the NHEJ pathway. DNA-PKcs phosphorylates various substrates, including Artemis, XRCC4, and DNA Ligase IV, which are involved in end processing and ligation.</li>
<li><strong>Endonuclease Regulation:</strong> DNA-PKcs activates Artemis through phosphorylation, enabling it to function as an endonuclease. This activation allows Artemis to process complex DNA ends, such as hairpins, and prepare them for ligation.</li>
<li><strong>Role in End Processing:</strong> DNA-PKcs's kinase activity regulates DNA end processing, coordinating the actions of different enzymes involved in making DNA ends compatible for ligation. This regulation ensures that end trimming, gap filling, and other modifications are done accurately and in the correct sequence.</li>
<li><strong>Bridging and Stabilizing DNA Ends: The DNA-PK complex helps stabilize the broken DNA ends, preventing further degradation and ensuring they remain in close proximity. This stability is crucial for successful ligation and repair.</li>
<li><strong>Coordination of NHEJ Repair:</strong> DNA-PKcs serve as a central coordinator in the NHEJ pathway, bringing together various proteins and orchestrating their functions. Its kinase activity regulates the timing and order of the repair steps.</li>
<li><strong>Ligation and Resolution:</strong> Once the DNA ends are processed, DNA-PKcs coordinates the recruitment of the ligase complex, including DNA Ligase IV, XRCC4, and XLF. This step completes the NHEJ process by ligating the DNA ends and restoring genome integrity.</li>
<li><strong>Role in Genomic Stability:</strong> DNA-PKcs is critical for maintaining genomic stability through its function in NHEJ. It helps prevent chromosomal abnormalities and influences cellular responses to DNA damage, influencing processes like apoptosis and cell cycle regulation.</li>
</ul>
<p>In summary, DNA-PKcs is a central player in the NHEJ pathway, responsible for kinase activity, end processing, stabilization of DNA ends, and coordination of the repair process. Its role in activating Artemis and recruiting other NHEJ proteins is critical to ensuring efficient and accurate repair of double-strand breaks.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/33424929/',
            'https://pubmed.ncbi.nlm.nih.gov/31380275/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9122494/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4184981/'
        ]
    },
    {
        name: 'Artemis.p',
        preview:'It leads the DNA end-processing in the non homologous end joining',
        function: 'It leads the DNA end-processing in the non homologous end joining',
        reference: [
            'https://www.embopress.org/doi/full/10.1038/embor.2013.99',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8450076/'
        ]
    },
    {
        name: 'SUMO1',
        preview:'SUMO1 (Small Ubiquitin-related Modifier 1) is a member of the SUMO family, consisting of proteins that can covalently attach to target proteins through SUMOylation. SUMOylation is a post-transcriptional modification with roles in protein-protein interactions, subcellular localization, protein stability, and DNA repair pathways, including Non-Homologous End Joining (NHEJ).',
        function: `
        <p>SUMO1 (Small Ubiquitin-related Modifier 1) is a member of the SUMO family, consisting of proteins that can covalently attach to target proteins through SUMOylation. SUMOylation is a post-transcriptional modification with roles in protein-protein interactions, subcellular localization, protein stability, and DNA repair pathways, including Non-Homologous End Joining (NHEJ).</p>
<ul>
<li><strong>Regulation of NHEJ Components:</strong> SUMO1 modification can regulate critical proteins involved in the NHEJ pathway by adding SUMO1 to these proteins; their activity, stability, localization, or interaction with other proteins can be modulated, affecting the overall efficiency and fidelity of NHEJ.</li>
<li><strong>Influencing the Recruitment of Repair Factors:</strong> SUMOylation by SUMO1 can promote or inhibit the recruitment of specific proteins to the sites of DNA damage. This can impact the assembly and organization of the NHEJ machinery at the site of a double-strand break.</li>
<li><strong>DNA End Processing and Protein Turnover:</strong> SUMO1-mediated SUMOylation can influence the function and turnover of NHEJ proteins, affecting how DNA ends are processed and the timing of their processing. For instance, SUMOylation can alter the activity of end-processing enzymes or regulate the degradation of proteins once their role in NHEJ is complete.</li>
</ul>
<p><span>Crucially</span>, SUMO1 is a guardian of genome stability. It ensures that NHEJ proceeds correctly and efficiently, thereby reducing the risk of chromosomal instability and other DNA damage-related issues. By modulating the activities of key NHEJ proteins through SUMOylation, SUMO1 plays a vital role in the proper repair of double-strand breaks.</p>
<ul>
<li><strong>Cross-Talk with Other Post-Translational Modifications:</strong> SUMO1-mediated SUMOylation can interact with other post-translational modifications, like ubiquitination or phosphorylation, to coordinate the cellular response to DNA damage. This cross-talk can fine-tune NHEJ by regulating its components' timing, localization, and activity.</li>
</ul>
<p>While SUMO1 is a key player in the NHEJ pathway, its functions are remarkably diverse, adapting to the specific context, cell type, or stressor. This versatility of SUMO1, in general, provides an additional layer of regulation to the intricate process of NHEJ, underscoring its importance in helping cells maintain genomic integrity by modulating key proteins involved in DNA repair.</p>
        `,
        reference: [
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3310159/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5577459/',
            'https://pubmed.ncbi.nlm.nih.gov/35333860/',
            'https://pubmed.ncbi.nlm.nih.gov/38280996/'
        ]
    },
    {
        name: 'XLF',
        preview:'XLF, also known as NHEJ1 or Cernunnos, is a critical protein involved in the Non-Homologous End Joining (NHEJ) pathway, one of the primary mechanisms cells use to repair double-strand breaks (DSBs) in DNA.',
        function: `
<p>XLF, also known as NHEJ1 or Cernunnos, is a critical protein involved in the Non-Homologous End Joining (NHEJ) pathway, one of the primary mechanisms cells use to repair double-strand breaks (DSBs) in DNA.</p>
<ul>
<li><strong>Interaction with XRCC4 and DNA Ligase IV:</strong> XLF forms a complex with XRCC4 and DNA Ligase IV, central to the NHEJ ligation step. This complex is responsible for joining the DNA ends during the final stage of NHEJ. XLF stabilizes and enhances the activity of XRCC4 and DNA Ligase IV, facilitating efficient ligation.</li>
<li><strong>Bridging DNA Ends:</strong> XLF, in conjunction with XRCC4, can form filaments or bridges that help align and stabilize DNA ends at double-strand breaks. This bridging function is crucial for keeping DNA ends in close proximity, ensuring that they are correctly positioned for ligation.</li>
<li><strong>Role in DNA End Ligation:</strong> XLF assists DNA Ligase IV in ligating DNA ends, ensuring a stable and accurate repair of double-strand breaks. By stabilizing the XRCC4-DNA Ligase IV complex, XLF helps facilitate the joining of DNA ends, contributing to the overall efficiency of the NHEJ pathway.</li>
<li><strong>Facilitating Repair of Complex DNA Breaks:</strong> XLF can aid in repairing complex DNA breaks where the DNA ends may be misaligned or contain damage. By stabilizing the ligase complex and aiding in DNA end alignment, XLF plays a role in processing challenging DNA breaks.</li>
<li><strong>Maintaining Genomic Stability:</strong> XLF's role in the NHEJ pathway contributes to genomic stability by ensuring effective repair of double-strand breaks. Deficiencies or mutations in XLF can lead to genomic instability, increased sensitivity to DNA-damaging agents, and increased cancer risk.</li>
<li><strong>Role in Immunodeficiency:</strong> Given its importance in NHEJ, mutations in XLF can lead to immunodeficiency disorders. NHEJ is critical for V(D)J recombination, the process by which B and T cells generate diverse antigen receptor repertoires. Disruption of XLF can impair V(D)J recombination, leading to immune system deficiencies.</li>
</ul>
<p>In summary, XLF is a key component of the NHEJ pathway. It contributes to the stabilization of DNA ends, enhances the activity of the XRCC4-DNA Ligase IV complex, and plays a crucial role in the ligation step of double-strand break repair. Its functions ensure effective and accurate DNA repair, contributing to genomic stability and immune system health.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/18166980/',
            'https://pubmed.ncbi.nlm.nih.gov/16439205/',
            'https://pubmed.ncbi.nlm.nih.gov/23442139/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7226105/'
        ]
    },
    {
        name: 'XRCC4',
        preview:'XRCC4, a protein of paramount importance, is a key player in the Non-Homologous End Joining (NHEJ) pathway. This pathway, one of the primary mechanisms cells employ to repair DNA double-strand breaks (DSBs), relies heavily on the functions of XRCC4.',
        function: `
<p>XRCC4, a protein of paramount importance, is a key player in the Non-Homologous End Joining (NHEJ) pathway. This pathway, one of the primary mechanisms cells employ to repair DNA double-strand breaks (DSBs), relies heavily on the functions of XRCC4.</p>
<p><span>One crucial role of</span> XRCC4 is its function as a stabilizing partner for DNA Ligase IV. This enzyme, responsible for ligating the broken DNA ends during NHEJ, ensures its proper localization and stability at the site of DNA damage by forming a complex with XRCC4.</p>
<p>XRCC4's primary function is to facilitate the ligation of DNA ends. It achieves this by stabilizing and interacting with DNA Ligase IV, thereby ensuring that the ligation step occurs effectively, leading to the successful joining of the broken DNA strands.</p>
<ul>
<li><strong>Bridging DNA Ends:</strong> XRCC4, in combination with other proteins like XLF, can create filaments that help bring DNA ends into proximity, promoting alignment for ligation. This function is critical for ensuring that broken DNA ends are close enough for successful repair.</li>
<li><strong>Coordination with Other NHEJ Proteins:</strong> XRCC4 coordinates with other NHEJ components, such as the Ku70/80 heterodimer, which initially binds to DNA breaks, and DNA-PKcs, which plays a role in activating various NHEJ proteins. This coordination helps ensure that the DNA repair process proceeds smoothly and in the correct sequence.</li>
<li><strong>Supporting the NHEJ Repair Complex:</strong> XRCC4 serves as a scaffold for recruiting other NHEJ components to the break site. This scaffolding function helps organize the NHEJ complex, facilitating efficient and accurate repair.</li>
<li><strong>Maintaining Genomic Stability:</strong> Through its role in NHEJ, XRCC4 contributes to genomic stability by promoting the effective repair of double-strand breaks. Deficiencies or mutations in XRCC4 can increase genomic instability, resulting in heightened sensitivity to DNA-damaging agents and increased cancer risk.</li>
<li><strong>Role in V(D)J Recombination:</strong> XRCC4 is also essential for V(D)J recombination, which generates the diversity of B and T cell receptors in the immune system. Disruptions in XRCC4 can impair this recombination process, leading to immunodeficiency.</li>
</ul>
</p>In summary, XRCC4 plays a pivotal role in the NHEJ pathway by stabilizing DNA Ligase IV, facilitating DNA end ligation, bridging DNA ends, and coordinating with other NHEJ proteins. Its function is critical for the effective repair of double-strand breaks, contributing to genomic stability and the proper functioning of the immune system.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/8548796/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4151352/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3173232/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8127669/',
            'https://pubmed.ncbi.nlm.nih.gov/36765282/'
        ]
    },
    {
        name: 'LigaseIV',
        preview:'DNA Ligase IV is a key enzyme in the Non-Homologous End Joining (NHEJ) pathway, responsible for sealing DNA double-strand breaks (DSBs). Its role in NHEJ is crucial for maintaining genomic stability and enabling the proper functioning of the immune system through processes like V(D)J recombination.',
        function: `
        <p>DNA Ligase IV is a key enzyme in the Non-Homologous End Joining (NHEJ) pathway, responsible for sealing DNA double-strand breaks (DSBs). Its role in NHEJ is crucial for maintaining genomic stability and enabling the proper functioning of the immune system through processes like V(D)J recombination.</p>
<ul>
<li><strong>Catalyzing DNA Ligation:</strong> DNA Ligase IV catalyzes the ligation of DNA ends, the final step in the NHEJ pathway. It forms a phosphodiester bond between adjacent DNA strands, effectively sealing double-strand breaks.</li>
<li><strong>Interaction with XRCC4:</strong> DNA Ligase IV works in a complex with XRCC4, a protein that stabilizes and enhances the ligase's activity. XRCC4 helps recruit and position DNA Ligase IV at the site of DNA damage, ensuring efficient ligation.</li>
<li><strong>Coordination with Other NHEJ Proteins:</strong> DNA Ligase IV, as part of the XRCC4-Ligase IV complex, interacts with other NHEJ proteins like Ku70/80, DNA-PKcs, XLF, and Artemis. This coordination helps bring DNA ends into alignment and prepares them for ligation.</li>
<li><strong>Role in V(D)J Recombination:</strong> DNA Ligase IV is critical for V(D)J recombination, a process in the immune system that generates diversity in B and T cell receptors. It is responsible for ligating the DNA ends during the recombination of variable (V), diversity (D), and joining (J) gene segments. Without functional DNA Ligase IV, the immune system's ability to create diverse antigen receptors would be compromised.</li>
<li><strong>Stabilizing DNA Ends:</strong> Along with XRCC4 and XLF, DNA Ligase IV helps stabilize and align DNA ends, ensuring they are positioned correctly for ligation. This stabilization is essential for efficient NHEJ and reduces the risk of genomic instability.</li>
<li><strong>Implications in Disease and Genomic Stability:</strong> Mutations or deficiencies in DNA Ligase IV can lead to various disorders, including immunodeficiency, increased susceptibility to DNA damage, and certain cancers. Its role in sealing double-strand breaks is critical for preventing genomic instability and maintaining the proper function of the immune system.</li>
<li><strong>Coordination in NHEJ Pathway:</strong> DNA Ligase IV's coordination with other NHEJ components ensures that the repair process proceeds smoothly, from recognition of DNA breaks by Ku70/80 to end processing by Artemis and final ligation by the Ligase IV complex.</li>
</ul>
<p>In summary, DNA Ligase IV is a central enzyme in the NHEJ pathway, responsible for the crucial step of ligating DNA ends to repair double-strand breaks. Its interactions with other NHEJ proteins ensure efficient and accurate DNA repair, contributing to genomic stability and immune system health.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/9826654/',
            'https://pubmed.ncbi.nlm.nih.gov/10945980/',
            'https://pubmed.ncbi.nlm.nih.gov/9809069/',
            'https://pubmed.ncbi.nlm.nih.gov/38341432/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9956705/'
        ]
    },
    {
        name: 'Pol μ',
        preview:'Pol μ, also known as Polymerase U, is a crucial DNA polymerase in the Non-Homologous End Joining (NHEJ) pathway. This pathway is a fundamental mechanism for repairing DNA double-strand breaks (DSBs), making Pol μ a key player in maintaining genomic stability.',
        function: `
        <p>Pol μ, also known as Polymerase U, is a crucial DNA polymerase in the Non-Homologous End Joining (NHEJ) pathway. This pathway is a fundamental mechanism for repairing DNA double-strand breaks (DSBs), making Pol μ a key player in maintaining genomic stability.</p>
<ul>
<li><strong>Filling in DNA Gaps:</strong> Pol μ plays a role in filling small gaps at the ends of DNA during NHEJ. When double-strand breaks occur, the DNA ends are often not perfectly aligned, resulting in gaps that must be filled before ligation. Pol μ is specialized in synthesizing DNA to bridge these gaps.</li>
</ul>
<p>One of the most intriguing aspects of Pol μ is its unique ability to tolerate mismatches during DNA synthesis. This adaptability is particularly advantageous in NHEJ, where the DNA ends to be joined do not always have extensive homology. Pol μ's flexibility allows it to bridge imperfectly aligned ends, showcasing its specialized function in DNA repair.</p>
<ul>
<li><strong>Interacts with NHEJ Factors:</strong> Pol μ interacts with other NHEJ proteins, such as XRCC4 and DNA Ligase IV. This interaction helps ensure that the DNA synthesis step is coordinated with the ligation step, promoting efficient repair of double-strand breaks.</li>
<li><strong>Insertion of Nucleotides:</strong> Pol μ can insert nucleotides even without a complementary template strand, making it well-suited for situations with minimal or no overlap between the DNA ends. This ability is valuable in NHEJ, as it can accommodate various configurations of DNA breaks.</li>
<li><strong>Promoting Ligation:</strong> By filling in gaps and generating overhangs, Pol μ assists in creating DNA ends that can be ligated by DNA Ligase IV and other components of the NHEJ ligase complex. Its ability to create the necessary structure for ligation is crucial for completing the NHEJ process.</li>
</ul>
<p><span>Pol μ's influence extends</span> beyond DNA repair, as it also plays a significant role in V(D)J recombination. This process is crucial for the development of immune system diversity, and Pol μ's function in filling gaps during the recombination of variable (V), diversity (D), and joining (J) gene segments in B and T cell receptor genes underscores its importance in immune system development.</p>
<p>In summary, Pol μ is a DNA polymerase with specialized functions in the NHEJ pathway. It plays a crucial role in filling gaps and tolerating mismatches during the repair of double-strand breaks, facilitating efficient ligation by creating suitable DNA ends. This flexibility makes Pol μ an essential component of the NHEJ process, contributing to genomic stability and immune system function through its role in V(D)J recombination.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/29247009/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1456414/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC139779/'
        ]
    },
    {
        name: 'Pol λ',
        preview:'Polymerase λ (Pol λ) plays a significant role in the Non-Homologous End Joining (NHEJ) pathway, which is one of the major mechanisms by which cells repair double-strand breaks (DSBs) in DNA.',
        function: `
<p>Polymerase λ (Pol λ) plays a significant role in the Non-Homologous End Joining (NHEJ) pathway, which is one of the major mechanisms by which cells repair double-strand breaks (DSBs) in DNA.</p>
<ul>
<li><strong>Gap Filling and DNA Synthesis:</strong> Pol λ's primary role in NHEJ is to fill in small gaps at the ends of DNA strands. When DSBs occur, the broken DNA ends might not perfectly align or have single-strand overhangs that must be filled before ligation. Pol λ helps to bridge these gaps by synthesizing new DNA, ensuring that the DNA ends are in a suitable state for ligation.</li>
<li><strong>Tolerates Mismatches:</strong> Pol λ has a degree of tolerance for mismatches, allowing it to synthesize DNA even when the broken ends do not align perfectly. This ability is crucial in the NHEJ pathway, where DSBs can be complex, and alignment is often imperfect.</li>
<li><strong>Interaction with Other NHEJ Proteins:</strong> Pol λ coordinates with other proteins involved in the NHEJ pathway, such as the Ku70/80 heterodimer, which binds to DNA ends and recruits the DNA-PK complex (including DNA-PKcs). Pol λ interacts with these and other NHEJ factors to ensure proper repair.</li>
<li><strong>Preparing DNA Ends for Ligation:</strong> After Pol λ fills in the gaps, the DNA ends become more aligned, allowing for successful ligation. This step is critical for completing the NHEJ pathway. The polymerase's action helps ensure that DNA Ligase IV and its co-factors XRCC4 and XLF can join the DNA ends and restore the DNA's integrity.</li>
<li><strong>Maintaining Genomic Stability:</strong> By contributing to the repair of double-strand breaks, Pol λ helps maintain genomic stability. Proper repair of DSBs is critical to preventing chromosomal aberrations and preserving the overall health and functionality of the genome.</li>
</ul>
<p>Overall, Pol λ's ability to fill gaps, tolerate mismatches, and interact with other NHEJ components makes it an essential player in the repair of double-strand breaks. Its role in aligning DNA ends for ligation ensures that the NHEJ pathway can effectively repair damage, contributing to genomic stability.</p>
        `,
        reference: [
            'https://pubmed.ncbi.nlm.nih.gov/26240371/',
            'https://pubmed.ncbi.nlm.nih.gov/38428373/',
            'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4018081/'
        ]
    },
]


export const proteinMapLink = [
    {
        name: 'PARP1',
        maps: ['SSB', 'HR']
    },
    {
        name: 'PARP1.pADPR',
        maps: ['SSB', 'HR']
    },
    {
        name: 'XRCC1',
        maps: ['SSB']
    },
    {
        name: 'PARG',
        maps: ['SSB']
    },
    {
        name: 'APE1',
        maps: ['SSB']
    },
    {
        name: 'APE1(+/-) PCNA',
        maps: ['SSB']
    },
    {
        name: 'PCNA',
        maps: ['SSB']
    },
    {
        name: 'PCNA.Ub',
        maps: ['SSB']
    },
    {
        name: 'APE2',
        maps: ['SSB']
    },
    {
        name: 'ATR',
        maps: ['SSB']
    },
    {
        name: 'ATRIP',
        maps: ['SSB']
    },
    {
        name: 'RPA',
        maps: ['SSB']
    },
    {
        name: 'Chk1',
        maps: ['SSB']
    },
    {
        name: 'Chk1.p',
        maps: ['SSB']
    },
    {
        name: 'RPA2',
        maps: ['SSB, HR']
    },
    {
        name: 'RPA2.p',
        maps: ['SSB']
    },
    {
        name: 'RAD17',
        maps: ['SSB']
    },
    {
        name: 'HUS1',
        maps: ['SSB']
    },
    {
        name: 'RAD1',
        maps: ['SSB']
    },
    {
        name: 'RAD9',
        maps: ['SSB']
    },
    {
        name: 'TopBP1',
        maps: ['SSB']
    },
    {
        name: 'LIG1',
        maps: ['SSB']
    },
    {
        name: 'FEN1',
        maps: ['SSB']
    },
    {
        name: 'Polß',
        maps: ['SSB']
    },
    {
        name: 'Polδ',
        maps: ['SSB']
    },
    {
        name: 'Pol ε',
        maps: ['SSB']
    },
    {
        name: '53BP1',
        maps: ['HR', 'NHEJ']
    },
    {
        name: 'ATM',
        maps: ['HR', 'NHEJ']
    },
    {
        name: '53BP1.p',
        maps: ['NHEJ']
    },
    {
        name: 'PTIP',
        maps: ['NHEJ']
    },
    {
        name: 'Artemis (SNM1C)',
        maps: ['NHEJ']
    },
    {
        name: 'Artemis',
        maps: ['NHEJ']
    },
    {
        name: 'RIF1',
        maps: ['NHEJ']
    },
    {
        name: 'ASF1',
        maps: ['NHEJ']
    },
    {
        name: 'REV7',
        maps: ['NHEJ']
    },
    {
        name: 'TRIP13',
        maps: ['NHEJ']
    },
    {
        name: 'RIF.p',
        maps: ['NHEJ']
    },
    {
        name: 'Ku70',
        maps: ['NHEJ']
    },
    {
        name: 'Ku80',
        maps: ['NHEJ']
    },
    {
        name: 'DNA-PKcs',
        maps: ['NHEJ']
    },
    {
        name: 'Artemis.p',
        maps: ['NHEJ']
    },
    {
        name: 'SUMO1',
        maps: ['NHEJ']
    },
    {
        name: 'XLF',
        maps: ['NHEJ']
    },
    {
        name: 'XRCC4',
        maps: ['NHEJ']
    },
    {
        name: 'LigaseIV',
        maps: ['NHEJ']
    },
    {
        name: 'Pol μ',
        maps: ['NHEJ']
    },
    {
        name: 'Pol λ',
        maps: ['NHEJ']
    },
    {
        name: 'MRE11',
        maps: ['HR']
    },
    {
        name: 'NBS1 (P95)',
        maps: ['HR']
    },
    {
        name: 'RAD50',
        maps: ['HR']
    },
    {
        name: 'WRN',
        maps: ['HR']
    },
    {
        name: 'BLM',
        maps: ['HR']
    },
    {
        name: 'BRCA1',
        maps: ['HR']
    },
    {
        name: 'CtIP (RBBP8)',
        maps: ['HR']
    },
    {
        name: 'RAD51',
        maps: ['HR']
    },
    {
        name: 'RAD52',
        maps: ['HR']
    },
    {
        name: 'RAD54',
        maps: ['HR']
    },
    {
        name: 'FANCN',
        maps: ['HR']
    },
    {
        name: 'BRCA2 (FANCD1)',
        maps: ['HR']
    },
    {
        name: 'FANCJ (BRIP1/BACH1)',
        maps: ['HR']
    },
    {
        name: 'Ccdc98',
        maps: ['HR']
    },
    {
        name: 'BRCC36',
        maps: ['HR']
    },
    {
        name: 'BRCC45 (BRE/BABAM2)',
        maps: ['HR']
    },
    {
        name: 'RAP80',
        maps: ['HR']
    },
    {
        name: 'SUMO',
        maps: ['HR, NHEJ']
    },
    {
        name: 'SUMO.Ub',
        maps: ['HR']
    },
    {
        name: 'RNF4',
        maps: ['HR']
    },
    {
        name: 'Ccdc98 (ABRAXAS1/Abra1)',
        maps: ['HR']
    },
    {
        name: 'BARD1',
        maps: ['HR']
    },
    {
        name: 'BRCA1.p',
        maps: ['HR']
    },
    {
        name: 'CtIP',
        maps: ['HR']
    },
    {
        name: 'H2AK125',
        maps: ['HR']
    },
    {
        name: 'H2AK127',
        maps: ['HR']
    },
    {
        name: 'H2AK129',
        maps: ['HR']
    },
    {
        name: 'H2AK125U',
        maps: ['HR']
    },
    {
        name: 'H2AK127U',
        maps: ['HR']
    },
    {
        name: 'H2AK129U',
        maps: ['HR']
    },
    {
        name: 'HERC2',
        maps: ['HR']
    },
    {
        name: 'BAP1',
        maps: ['HR']
    },
    {
        name: 'BAP1.p',
        maps: ['HR']
    },
    {
        name: 'Chk2',
        maps: ['HR']
    },
    {
        name: 'Chk2.p',
        function: '',
        reference: ['HR']
    },
    {
        name: 'ATM.p',
        maps: ['HR']
    },
    {
        name: 'H2Ax',
        maps: ['HR']
    },
    {
        name: 'γH2Ax',
        maps: ['HR']
    },
    {
        name: 'MDC1',
        maps: ['HR']
    },
    {
        name: 'MDC1.p',
        maps: ['HR']
    },
    {
        name: 'H2A',
        maps: ['HR']
    },
    {
        name: 'H2AK13',
        maps: ['HR']
    },
    {
        name: 'H2AK15',
        maps: ['HR']
    },
    {
        name: 'H2AK13(K27)',
        maps: ['HR']
    },
    {
        name: 'H2AK15(K27)',
        maps: ['HR']
    },
    {
        name: 'H2AK13(K67)',
        maps: ['HR']
    },
    {
        name: 'H2AK15(K67)',
        maps: ['HR']
    },
    {
        name: 'Ub K27',
        maps: ['HR']
    },
    {
        name: 'Ub K67',
        maps: ['HR']
    },
    {
        name: 'RNF168',
        maps: ['HR']
    },
    {
        name: 'H1 Histone.Ub',
        maps: ['HR']
    },
    {
        name: 'L3MBTL2',
        maps: ['HR']
    },
    {
        name: 'RNF8',
        maps: ['HR']
    },
    {
        name: 'RNF8.Ub',
        maps: ['HR']
    },
    {
        name: 'UBC13',
        maps: ['HR']
    },
    {
        name: 'Mms2',
        maps: ['HR']
    },
    {
        name: 'Ring',
        maps: ['HR']
    },
    {
        name: 'FHA',
        maps: ['HR']
    },
    {
        name: 'USP28',
        maps: ['HR']
    },
    {
        name: 'USP48',
        maps: ['HR']
    },
    {
        name: 'p53',
        maps: ['HR']
    },
    {
        name: 'p53.p',
        maps: ['HR']
    },
    {
        name: 'p21',
        maps: ['HR']
    },
    {
        name: 'CIP1',
        maps: ['HR']
    },
    {
        name: 'GADD45',
        maps: ['HR']
    },
    {
        name: 'TIP60',
        maps: ['HR']
    },
    {
        name: 'Ac.ATM',
        maps: ['HR']
    },
    {
        name: 'DSS1',
        maps: ['HR']
    }
]


export const resolutionsFilters = [
    {
        label:"Widefield",
        value:"Widefield"
    },
    {
        label:"Widefield SIM",
        value:"Widefield SIM"
    },
    {
        label:"Confocal",
        value:"Confocal"
    },
    {
        label:"SRM Airyscan",
        value:"Super Resolution - Airyscan"
    },
    {
        label:"SRM SIM",
        value:"Super Resolution - SIM"
    },
    {
        label:"SRM SIM²",
        value:"Super Resolution - SIM²"
    },
    {
        label:"SRM Single Molecule",
        value:"Super Resolution - Single Molecule STORM"
    }
]

export const dyeFilters = [
    {
        label:"405 nm",
        value:"405"
    },
    {
        label:"488 nm",
        value:"488"
    },
    {
        label:"532 nm",
        value:"532"
    },
    {
        label:"555 nm",
        value:"555"
    },
    {
        label:"594 nm",
        value:"594"
    },
    {
        label:"647 nm",
        value:"647"
    },
    {
        label:"680 nm",
        value:"680"
    },
    {
        label:"750 nm",
        value:"750"
    }
]