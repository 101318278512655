import React, { useState, useContext, useEffect } from 'react'
import Layout from "../layout"
import { Wrapper } from './style'
import { Checkbox } from '../../../components/Checkbox'
import { AuthContext } from '../../../context/auth'
import { updateCustomerAddressesApi, currentUserApi, updateCustomerApi } from '../../../api'
import {countryCode, getKeyByValue} from '../../../utils/country'

const Index = () => {
const {setNotification, setBillingAddressModal, setSelectedAddress, user, setUser, setIsLoading} = useContext(AuthContext)
const [address, setAddress] = useState(
    //         [
    //     {
    //         id:1,
    //         name:'Stanford University School Medicine',
    //         is_primary:false,
    //         address:'291 Campus Drive, Stanford, CA 94305, United States',
    //         department: 'Department of Biology',
    //         city: 'Stanford',
    //         state: 'California',
    //         street_address:'291 Campus Drive',
    //         country: 'United States',
    //         postal_code: '94305',
    //         Building:291,
    //         Lab:123,
    //         attention_to:'Dr Alex',
    //         company:'University of California, San Francisco',
    //     },
    //     {
    //         id:2,
    //         name:'University of California, San Francisco',
    //         is_primary:true,
    //         address:'505 Parnassus Ave, San Francisco, CA 94143, United States',
    //         department: 'Department of Biology',
    //         city: 'San Francisco',
    //         state: 'California',
    //         street_address:'505 Parnassus Ave',
    //         country: 'United States',
    //         postal_code: '94143',
    //         Building:291,
    //         Lab:123,
    //         attention_to:'Dr Alex',
    //         company:'University of California, San Francisco',
    //     }
    // ]
)


const fetchCurrentUser = () => {
    setIsLoading(true)
    currentUserApi()
    .then(res => {
        if (res.data) {
           setUser(res.data)
            setIsLoading(false)
        }
    })
    .catch(() => {
        setIsLoading(false)
    })
  }



useEffect(() => {
    setAddress(user?.customer?.shipping_addresses?.filter((item) => item.metadata?.type === 'billing'))

}, [user])


const handlePrimary = (addr) => {
    setIsLoading(true)
    if(address.length > 1) {
address?.map((item) => {
    Promise.all([updateCustomerAddressesApi(item.id, 
    item.id == addr.id ? {
            metadata: {
                ...item.metadata,
                is_primary: true
            }
        }: {
            metadata: {
                ...item.metadata,
                is_primary: false
            }
        }
    )]).then(() => {
        setNotification({
            type: 'success',
            message: 'Primary Billing Address Updated!'
        })
        updateCustomerApi({
            billing_address: {
                first_name: addr.first_name,
                last_name:"",
                company: addr.company,
                address_1: addr.address_1,
                city: addr.city,
                province: addr.province,
                country_code: addr.country_code,
                postal_code: addr.postal_code,
            metadata: {
                ...addr.metadata,
                is_primary: true
            }
        }
        })
        fetchCurrentUser()       
        setIsLoading(false)
    }).catch(() => {
        setIsLoading(false)
        setNotification({
            type: 'red',
            message: 'Something went wrong, please try again!'
        })
    })
})
} else {
    setNotification({
        type: 'red',
        message: 'One Primary Billing Address is required!'
    })
    setIsLoading(false)
}

    // setAddress(address?.map((item) => {
    //     if (item.id == id) {
    //         item.is_primary = true
    //     } else {
    //         item.is_primary = false
    //     }
    //     return item
    // }))

    // setNotification({
    //     type: 'success',
    //     message: 'Primary Shipping Address Updated!'
    // })


}


    return (
        <Layout>
            <Wrapper>
                <div className="header">
                    <h4>Billing Address</h4>
                    <div className="header-btn-container">
                        <button className='btn transparent' style={{ fontSize: '16px' }} onClick={() => {
                            setBillingAddressModal(true)
                            setSelectedAddress(null)
                        }}><i className='far fa-plus'></i> Add New</button>
                    </div>
                </div>
                <div className="address-container">
                {address && address.length > 0 ?
                        address?.map((item) => {
                            return (
                                <div className="address-card" key={item.id}>
                                    <div className="left-container">
                                        <h5>{item.company} {item.metadata?.is_primary && <span>Primary</span>}</h5>
                                        <p>{item.address_1}, {item.city}, {item.province} {item.postal_code}, {getKeyByValue(countryCode, item?.country_code?.toUpperCase())}</p>
                                        <Checkbox label={'Make Primary'} checked={item?.metadata?.is_primary} onChange={() => handlePrimary(item)} />
                                    </div>
                                    <div className="right-container">
                                        <button className='btn transparent' style={{ fontSize: '16px' }} onClick={() => {
                                            setBillingAddressModal(true)
                                            setSelectedAddress(item)
                                        }} >Edit</button>
                                    </div>
                                </div>
                            )
                        })
                        : <div className='no-orders'>
                            <span className='icon'>
                                <i class="fal fa-boxes"></i>
                            </span>
                            <p>No Billing Address Yet.</p>
                        </div>
                    }
                </div>
            </Wrapper>
        </Layout>
    )
}

export default Index