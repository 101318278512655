import React, { useRef, useState, useEffect, useContext } from 'react'
import Layout from "../../components/layout"
import { Header, Content, MiddleWrapper } from "./style"
import Input from "../../components/Input"
import Pagination from "../../components/pagination"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import MethodologyModal from "../../components/modals/MethodologyModal"
import Coa from "../../components/coA"
import ItemPreviewDrawer from "./ItemPreviewDrawer"
import { getGalleryApi } from '../../api'
import { AuthContext } from '../../context/auth'
import { useDebounce } from 'use-debounce'
import { Checkbox } from '../../components/Checkbox'
import { proteinsList, resolutionsFilters, dyeFilters } from '../../utils/proteinName'
import { Footer } from '../../components/Footer'
import { toAbsoluteUrl } from '../../utils/assetHelper'
import { isVideoFile, getMp4Url, isGif } from '../../utils/videoHelper'
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Index = () => {
    const dropdownRef = useRef(null)
    const coARef = useRef(null)
    const counter = useRef(0);

    // const { setIsLoading, isLoading } = useContext(AuthContext);
    const [openMethodologyModal, setOpenMethodologyModal] = useState(false)
    const [images, setImages] = useState({})
    const [currentPage, setCurrentPage] = useState(0);
    const [search, setSearch] = useState('')
    const [proteinSearch, setProteinSearch] = useState('')
    const [tempProteinsList, setTempProteinsList] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [modalName, setModalName] = useState(null)
    const [openFilter, setOpenFilter] = useState(false)
    const [filters, setFilters] = useState({
        proteinTarget: {
            dropDown: true,
            selectedOptions: [],
        },
        conjugateType: {
            dropDown: true,
            selectedOptions: [],
        },
        dye: {
            dropDown: true,
            selectedOptions: [],
        },
        resolution: {
            dropDown: true,
            selectedOptions: [],
        }
    })
    const [tempFilters, setTempFilters] = useState({
        proteinTarget: {
            dropDown: true,
            selectedOptions: [],
        },
        conjugateType: {
            dropDown: true,
            selectedOptions: [],
        },
        dye: {
            dropDown: false,
            selectedOptions: [],
        },
        resolution: {
            dropDown: false,
            selectedOptions: [],
        }
    })
    const [filterApplied, setFilterApplied] = useState(false)
    const [debouncedSearch] = useDebounce(search, 500);
    const [previewProduct, setPreviewProduct] = useState(null)
    const [openItemPreviewModal, setOpenItemPreviewModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [productType, setProductType] = useState("alexafluor")


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                // Handle outside click
                setOpenFilter(false)
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, []);


    const fetchGallery = () => {
        setIsLoading(true)
        const { proteinTarget, conjugateType, dye, resolution } = filters
        const selectedProteins = proteinTarget.selectedOptions?.map(item => item).join(",")
        // const selectedConjugates = conjugateType.selectedOptions?.map(item => item === 'Primary Conjugate' ? 'PA-MM,PA-MR,PA-RR,PA-RP,PA-RTM,DC' : 'SA').join(",")
        const selectedDyes = dye.selectedOptions?.map(item => item).join(",")
        const selectedResolutions = resolution.selectedOptions?.map(item => item).join(",")

        const payload = {
            subtitle: selectedProteins,
            type: productType === 'alexafluor' ? 'SA' : productType === 'primary' ? 'PA-MM,PA-MR,PA-RR,PA-RP,PA-RTM,DC' : "",
            dyes: selectedDyes,
            application: productType !== 'western' ? selectedResolutions : 'Western Blotting'
        };

        const params = Object.keys(payload).length > 0
            ? '&' + Object.entries(payload)
                .map(([key, value]) => `${key}=${value}`)
                .join('&')
            : '';

        getGalleryApi(30, currentPage + 1, debouncedSearch, params).then(res => {
            if (res && res.data && res.data) {
                setImages(res?.data)
                setTimeout(() => {
                    setIsLoading(false)
                }, 2500)
            } else {
                setImages({})
                setIsLoading(false)
            }
        })
    }


    useEffect(() => {
        fetchGallery()
        document.getElementById('gallery').scrollIntoView({ block: 'center', behavior: 'smooth' });
        counter.current = 0;
    }, [currentPage, debouncedSearch, filters, productType])


    useEffect(() => {
        if (proteinSearch) {
            const filteredOptions = proteinsList.filter((option) => {
                return option.toLowerCase().includes(proteinSearch.toLowerCase())
            })
            setTempProteinsList(filteredOptions)
        } else {
            setTempProteinsList(proteinsList)
        }

    }, [proteinsList, proteinSearch])

    const imageLoaded = () => {
        counter.current += 1;
        if (counter.current >= images?.products?.length - 1) {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        if (openFilter || openMethodologyModal) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'unset'
        }
    }, [openFilter, openMethodologyModal])


    useEffect(() => {
        setFilterApplied(Object.values(filters).reduce((total, field) => total + field.selectedOptions.length, 0) > 0 ? true : false)
    }, [filters])


    return (
        <Layout>
            <Header>
                <h1>IDENTiFYN™️ <span>Super Resolution</span> <br />
                    Image Collective</h1>
                {/* <div className="input-container">
                    <Input type='text' search placeholder='Search' style={{ paddingLeft: '30px', width: '100%' }}
                        value={search} onChange={(e) => setSearch(e.target.value)}
                        clear={search} onClear={() => setSearch('')}
                    />
                </div> */}
            </Header>
            <MiddleWrapper>
                <div className="container azure-section-second-container">
                    <h3>Welcome to the Identifyn™ Image Collective, Supporting our Rigorously Vetted Premium Reagents:</h3>
                    <div className="card1">
                        <div className="points-container">
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p>Identifyn™ De Novo Primary Antibodies</p>
                            </div>
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p>Identifyn™ SRM Alexa Fluor™ Primary Direct Conjugate Antibodies</p>
                            </div>
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p>Identifyn™ SRM Alexa Fluor™ Secondary Antibody Conjugates</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container azure-section-second-container">
                    <h3>Identifyn™ provides:</h3>
                    <div className="card1">
                        <div className="points-container">
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p>Images</p>
                            </div>
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p>Sample Preparation Methods</p>
                            </div>
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p>Supporting Products Links to Materials Used in Sample Preparation</p>
                            </div>
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p>Detailed Information about the Optical Instrumentation and all Settings Used in the Acquisition of the Data</p>
                            </div>
                            <div className="points">
                                <img src={toAbsoluteUrl("/media/pointbullet2.svg")} alt="" />
                                <p>Processing Details when Employed, Structured Illumination, Airyscan, STORM, and 3-Dimensional Image Representation</p>
                            </div>
                            <div className="points">
                                <p><span>Identifyn™ does not engage in photo manipulation;</span> we pride ourselves in our methods, our
                                    products, and our competence, and our data does not represent the use of any software to
                                    enhance artificial results; we believe that "what you see, is what you get" is the strongest
                                    statement in support of our products.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <p className='container' style={{ margin: '32px 0 16px 0' }} id='gallery'>Identifyn™ humbly wishes that this collective enables the global academic and non-profit research community. <br/> <br/>
                Copy or redistribution of these images, methods, acquisition parameters, or general presentation for commercial use by for-profit entities is prohibited without prior written consent, or express license.
                </p>
            </MiddleWrapper>
            <Content>
                <div className="gallery-tab-container">
                    <button style={{ borderTopLeftRadius: '12px', borderBottomLeftRadius: '12px' }} className={productType === "alexafluor" && 'tab-active'}
                        onClick={() => {
                            setProductType("alexafluor")
                            setFilters({
                                proteinTarget: {
                                    dropDown: filters.proteinTarget.dropDown,
                                    selectedOptions: [],
                                },
                                conjugateType: {
                                    dropDown: filters.conjugateType.dropDown,
                                    selectedOptions: [],
                                },
                                dye: {
                                    dropDown: filters.dye.dropDown,
                                    selectedOptions: [],
                                },
                                resolution: {
                                    dropDown: filters.resolution.dropDown,
                                    selectedOptions: [],
                                }
                            })
                            setProteinSearch('')
                            setCurrentPage(0)
                        }}
                    >Identifyn™ SRM Alexa Fluor™ Secondary Fluorescent Antibodies</button>
                    <button style={{ borderTopRightRadius: '12px', borderBottomRightRadius: '12px' }} className={productType === "primary" && 'tab-active'}
                        onClick={() => {
                            setProductType("primary")
                            setFilters({
                                proteinTarget: {
                                    dropDown: filters.proteinTarget.dropDown,
                                    selectedOptions: [],
                                },
                                conjugateType: {
                                    dropDown: filters.conjugateType.dropDown,
                                    selectedOptions: [],
                                },
                                dye: {
                                    dropDown: filters.dye.dropDown,
                                    selectedOptions: [],
                                },
                                resolution: {
                                    dropDown: filters.resolution.dropDown,
                                    selectedOptions: [],
                                }
                            })
                            setProteinSearch('')
                            setCurrentPage(0)
                        }}
                    >Primary Antibodies and Primary Antibody Conjugates</button>
                    <button style={{ borderTopRightRadius: '12px', borderBottomRightRadius: '12px' }} className={productType === "western" && 'tab-active'}
                        onClick={() => {
                            setProductType("western")
                            setFilters({
                                proteinTarget: {
                                    dropDown: filters.proteinTarget.dropDown,
                                    selectedOptions: [],
                                },
                                conjugateType: {
                                    dropDown: filters.conjugateType.dropDown,
                                    selectedOptions: [],
                                },
                                dye: {
                                    dropDown: filters.dye.dropDown,
                                    selectedOptions: [],
                                },
                                resolution: {
                                    dropDown: filters.resolution.dropDown,
                                    selectedOptions: [],
                                }
                            })
                            setProteinSearch('')
                            setCurrentPage(0)
                        }}
                    >Western Blots</button>
                </div>
                <div className="header-content">
                    <div className='filter-btn'>
                        <button className='btn'
                            style={{ background: openFilter && 'rgba(255, 141, 36, 0.28)' }}
                            onClick={() => setOpenFilter(!openFilter)}
                        >{Object.values(filters).reduce((total, field) => total + field.selectedOptions.length, 0) > 0 ? <img src={toAbsoluteUrl('/media/filterapplied.png')} width={20} height={20} alt='' /> : <i className='fal fa-filter' />}All Filters {Object.values(filters).reduce((total, field) => total + field.selectedOptions.length, 0) > 0 ? `(${Object.values(filters).reduce((total, field) => total + field.selectedOptions.length, 0)})` : ''}</button>
                        {openFilter && <div className="filter-dropdown" ref={dropdownRef}>
                            <div className="filter-dropdown-container">
                                <div className="filter-header">
                                    <h3>Filters</h3>
                                    {(filters?.proteinTarget.selectedOptions.length > 0 || filters?.conjugateType.selectedOptions.length > 0 || filters?.dye.selectedOptions.length > 0 || filters?.resolution.selectedOptions.length > 0) && <h6 style={{ cursor: 'pointer', color: 'var(--primary-color)', fontWeight: 600 }}
                                        onClick={() => {
                                            setFilters({
                                                proteinTarget: {
                                                    dropDown: filters.proteinTarget.dropDown,
                                                    selectedOptions: [],
                                                },
                                                conjugateType: {
                                                    dropDown: filters.conjugateType.dropDown,
                                                    selectedOptions: [],
                                                },
                                                dye: {
                                                    dropDown: filters.dye.dropDown,
                                                    selectedOptions: [],
                                                },
                                                resolution: {
                                                    dropDown: filters.resolution.dropDown,
                                                    selectedOptions: [],
                                                }
                                            })
                                            setProteinSearch('')
                                            setCurrentPage(0)
                                        }}
                                    >Reset All</h6>
                                    }
                                </div>
                                {productType === 'primary' && <div className="filter-types">
                                    <div className="filter-type-header" style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setFilters(prevFilters => ({
                                                ...prevFilters,
                                                proteinTarget: {
                                                    ...prevFilters.proteinTarget,
                                                    dropDown: !prevFilters.proteinTarget.dropDown
                                                }
                                            }))

                                        }}
                                    >
                                        <h4>Protein Target {filters?.proteinTarget?.selectedOptions.length > 0 && <span>{filters?.proteinTarget?.selectedOptions.length}</span>}</h4>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            {filters?.proteinTarget?.selectedOptions.length > 0 && <i className='far fa-times' style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setFilters(prevFilters => ({
                                                        ...prevFilters,
                                                        proteinTarget: {
                                                            ...prevFilters.proteinTarget,
                                                            selectedOptions: []
                                                        }
                                                    }))
                                                    setCurrentPage(0)
                                                }}
                                            />
                                            }
                                            <i className={`far ${filters?.proteinTarget?.dropDown ? 'fa-angle-up' : 'fa-angle-down'}`} style={{ color: 'var(--primary-color)' }}
                                            />
                                        </div>
                                    </div>
                                    {filters?.proteinTarget?.dropDown && <Input type='text' placeholder='Search' clear={proteinSearch} onClear={() => setProteinSearch('')} search style={{ width: '100%', height: '40px', paddingLeft: '30px' }} value={proteinSearch} onChange={(e) => setProteinSearch(e.target.value)} />}
                                    {filters?.proteinTarget?.dropDown && <div className="filter-type-container" style={{ maxHeight: tempProteinsList.length > 0 ? '300px' : '300px' }}>
                                        {tempProteinsList.length > 0 ? tempProteinsList?.map((item) => {
                                            return <div className="two-column">
                                                <Checkbox label={item} value={item} checked={filters.proteinTarget.selectedOptions.includes(item)}
                                                    onChange={() => {
                                                        if (filters.proteinTarget.selectedOptions.includes(item)) {
                                                            setFilters(prevFilters => ({
                                                                ...prevFilters,
                                                                proteinTarget: {
                                                                    ...prevFilters.proteinTarget,
                                                                    selectedOptions: filters.proteinTarget.selectedOptions.filter(option => option !== item)
                                                                }
                                                            }))
                                                            setCurrentPage(0)
                                                        } else {
                                                            setFilters(prevFilters => ({
                                                                ...prevFilters,
                                                                proteinTarget: {
                                                                    ...prevFilters.proteinTarget,
                                                                    selectedOptions: [...prevFilters.proteinTarget.selectedOptions, item]
                                                                }
                                                            }))
                                                            setCurrentPage(0)
                                                        }
                                                    }}
                                                />
                                                {/* <p>70</p> */}
                                            </div>
                                        })
                                            : <div className='no-orders'>
                                                <span className='icon'>
                                                    <i class="fal fa-boxes"></i>
                                                </span>
                                                <p style={{ color: '#6c6a73', fontWeight: 400, fontSize: '16px' }}>There are no results. <br />Try to broaden your search.</p>
                                            </div>
                                        }
                                        {/* <div className="two-column">
                                        <Checkbox label='Option 2' />
                                        <p>48</p>
                                    </div>
                                    <div className="two-column">
                                        <Checkbox label='Option 3' />
                                        <p>48</p>
                                    </div> */}
                                    </div>
                                    }
                                </div>
                                }
                                {productType === 'primarys' && <div className="filter-types">
                                    <div className="filter-type-header" style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setFilters(prevFilters => ({
                                                ...prevFilters,
                                                conjugateType: {
                                                    ...prevFilters.conjugateType,
                                                    dropDown: !prevFilters.conjugateType.dropDown
                                                }
                                            }))
                                        }}
                                    >
                                        <h4>Conjugate Type {filters?.conjugateType?.selectedOptions.length > 0 && <span>{filters?.conjugateType?.selectedOptions.length}</span>}</h4>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            {filters?.conjugateType?.selectedOptions.length > 0 && <i className='far fa-times' style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setFilters(prevFilters => ({
                                                        ...prevFilters,
                                                        conjugateType: {
                                                            ...prevFilters.conjugateType,
                                                            selectedOptions: []
                                                        }
                                                    }))
                                                    setCurrentPage(0)
                                                }}
                                            />
                                            }
                                            <i className={`far ${filters?.conjugateType?.dropDown ? 'fa-angle-up' : 'fa-angle-down'}`} style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
                                            />
                                        </div>
                                    </div>
                                    {filters?.conjugateType?.dropDown && <div className="filter-type-container">
                                        <div className="two-column">
                                            <Checkbox label='Primary Conjugate' value={'Primary Conjugate'} checked={filters.conjugateType.selectedOptions.includes('Primary Conjugate')}
                                                onChange={(e) => {
                                                    if (!filters.conjugateType.selectedOptions.includes('Primary Conjugate')) {
                                                        setFilters(prevFilters => ({
                                                            ...prevFilters,
                                                            conjugateType: {
                                                                ...prevFilters.conjugateType,
                                                                selectedOptions: [...filters.conjugateType.selectedOptions, e.target.value]
                                                            }
                                                        }))
                                                        setCurrentPage(0)
                                                    } else {
                                                        setFilters(prevFilters => ({
                                                            ...prevFilters,
                                                            conjugateType: {
                                                                ...prevFilters.conjugateType,
                                                                selectedOptions: filters.conjugateType.selectedOptions.filter(item => item !== 'Primary Conjugate')
                                                            }
                                                        }))
                                                        setCurrentPage(0)
                                                    }
                                                }} />
                                            {/* <p>70</p> */}
                                        </div>
                                        <div className="two-column">
                                            <Checkbox label='Secondary Conjugate' value={'Secondary Conjugate'} checked={filters.conjugateType.selectedOptions.includes('Secondary Conjugate')}
                                                onChange={(e) => {
                                                    if (!filters.conjugateType.selectedOptions.includes('Secondary Conjugate')) {
                                                        setFilters(prevFilters => ({
                                                            ...prevFilters,
                                                            conjugateType: {
                                                                ...prevFilters.conjugateType,
                                                                selectedOptions: [...filters.conjugateType.selectedOptions, e.target.value]
                                                            }
                                                        }))
                                                        setCurrentPage(0)
                                                    } else {
                                                        setFilters(prevFilters => ({
                                                            ...prevFilters,
                                                            conjugateType: {
                                                                ...prevFilters.conjugateType,
                                                                selectedOptions: filters.conjugateType.selectedOptions.filter(item => item !== 'Secondary Conjugate')
                                                            }
                                                        }))
                                                        setCurrentPage(0)
                                                    }
                                                }}
                                            />
                                            {/* <p>48</p> */}
                                        </div>
                                    </div>
                                    }
                                </div>
                                }
                                <div className="filter-types">
                                    <div className="filter-type-header" style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setFilters(prevFilters => ({
                                                ...prevFilters,
                                                dye: {
                                                    ...prevFilters.dye,
                                                    dropDown: !prevFilters.dye.dropDown
                                                }
                                            }))

                                        }}
                                    >
                                        <h4>Dye {filters?.dye?.selectedOptions.length > 0 && <span>{filters?.dye?.selectedOptions.length}</span>}</h4>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            {filters?.dye?.selectedOptions.length > 0 && <i className='far fa-times' style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setFilters(prevFilters => ({
                                                        ...prevFilters,
                                                        dye: {
                                                            ...prevFilters.dye,
                                                            selectedOptions: []
                                                        }
                                                    }))
                                                    setCurrentPage(0)
                                                }}
                                            />
                                            }
                                            <i className={`far ${filters?.dye?.dropDown ? 'fa-angle-up' : 'fa-angle-down'}`} style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
                                            />
                                        </div>
                                    </div>
                                    {filters?.dye?.dropDown && <div className="filter-type-container">
                                        {
                                            dyeFilters.map(item => (
                                                <div className="two-column">
                                                    <Checkbox label={item.label} value={item.value} checked={filters.dye.selectedOptions.includes(item.value)}
                                                        onChange={(e) => {
                                                            if (!filters.dye.selectedOptions.includes(item.value)) {
                                                                setFilters(prevFilters => ({
                                                                    ...prevFilters,
                                                                    dye: {
                                                                        ...prevFilters.dye,
                                                                        selectedOptions: [...filters.dye.selectedOptions, e.target.value]
                                                                    }
                                                                }))
                                                                setCurrentPage(0)
                                                            } else {
                                                                setFilters(prevFilters => ({
                                                                    ...prevFilters,
                                                                    dye: {
                                                                        ...prevFilters.dye,
                                                                        selectedOptions: filters.dye.selectedOptions.filter(_i => _i !== item.value)
                                                                    }
                                                                }))
                                                                setCurrentPage(0)
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            ))
                                        }
                                    </div>
                                    }
                                </div>        
                                {productType !== 'western' && <div className="filter-types">
                                    <div className="filter-type-header" style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setFilters(prevFilters => ({
                                                ...prevFilters,
                                                resolution: {
                                                    ...prevFilters.resolution,
                                                    dropDown: !prevFilters.resolution.dropDown
                                                }
                                            }))

                                        }}
                                    >
                                        <h4>Resolution {filters?.resolution?.selectedOptions.length > 0 && <span>{filters?.resolution?.selectedOptions.length}</span>}</h4>
                                        <div style={{ display: 'flex', gap: '10px' }}>
                                            {filters?.resolution?.selectedOptions.length > 0 && <i className='far fa-times' style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
                                                onClick={() => {
                                                    setFilters(prevFilters => ({
                                                        ...prevFilters,
                                                        resolution: {
                                                            ...prevFilters.resolution,
                                                            selectedOptions: []
                                                        }
                                                    }))
                                                    setCurrentPage(0)
                                                }}
                                            />
                                            }
                                            <i className={`far ${filters?.resolution?.dropDown ? 'fa-angle-up' : 'fa-angle-down'}`} style={{ color: 'var(--primary-color)', cursor: 'pointer' }}
                                            />
                                        </div>
                                    </div>
                                    {filters?.resolution?.dropDown && <div className="filter-type-container">
                                        {resolutionsFilters.map(item => (
                                            <div className="two-column">
                                                <Checkbox label={item.label} value={item.value} checked={filters.resolution.selectedOptions.includes(item.value)}
                                                    onChange={(e) => {
                                                        if (!filters.resolution.selectedOptions.includes(item.value)) {
                                                            setFilters(prevFilters => ({
                                                                ...prevFilters,
                                                                resolution: {
                                                                    ...prevFilters.resolution,
                                                                    selectedOptions: [...filters.resolution.selectedOptions, e.target.value]
                                                                }
                                                            }))
                                                            setCurrentPage(0)
                                                        } else {
                                                            setFilters(prevFilters => ({
                                                                ...prevFilters,
                                                                resolution: {
                                                                    ...prevFilters.resolution,
                                                                    selectedOptions: filters.resolution.selectedOptions.filter(_i => _i !== item.value)
                                                                }
                                                            }))
                                                            setCurrentPage(0)
                                                        }
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    }
                                </div>
                                }
                            </div>
                            <hr style={{ height: '1px', background: '#EDEDED' }} />
                            <div className="filter-btn-container">
                                <button className="cancel-btn btn"
                                    onClick={() => {
                                        setOpenFilter(false)
                                    }}
                                >Cancel</button>
                                <button className="btn"
                                    onClick={() => {
                                        setOpenFilter(false)
                                        // setFilterApplied(Object.values(filters).reduce((total, field) => total + field.selectedOptions.length, 0) > 0 ? true : false)
                                        // setTempFilters(filters)
                                    }}
                                >Show {Object.values(filters).reduce((total, field) => total + field.selectedOptions.length, 0) > 0 ? `(${Object.values(filters).reduce((total, field) => total + field.selectedOptions.length, 0)})` : 'All'}</button>
                            </div>
                        </div>
                        }
                    </div>
                    <h5><span>{images.totalcount}</span> images</h5>
                </div>
                {filterApplied && <div className="filters-applied-container">
                    {search && <div className="filters">
                        <span>Search:</span> {search} <i className='fal fa-times' onClick={() => setSearch('')}></i>
                    </div>
                    }
                    {Object.entries(filters).map(([parentName, { selectedOptions }]) => {
                        if (selectedOptions.length > 0) {
                            return (
                                <div key={parentName} className="filters">
                                    <span>{parentName === 'dye' ? 'Dye' : parentName === 'proteinTarget' ? 'Protein Target' : parentName === 'conjugateType' ? 'Conjugate Type' : 'Application'}:</span>
                                    {selectedOptions.map((option, index) => (
                                        <>
                                            {option}{parentName === 'dye' && ' nm'}
                                            {index !== selectedOptions.length - 1 && '; '}
                                        </>
                                    ))}
                                    <i className='fal fa-times'
                                        onClick={() => {
                                            setFilters(prevFilters => ({
                                                ...prevFilters,
                                                [parentName]: {
                                                    ...prevFilters[parentName],
                                                    selectedOptions: []
                                                }
                                            }))
                                            setCurrentPage(0)
                                            setTempFilters(prevFilters => ({
                                                ...prevFilters,
                                                [parentName]: {
                                                    ...prevFilters[parentName],
                                                    selectedOptions: []
                                                }
                                            }))
                                        }}
                                    ></i>
                                </div>
                            );
                        }
                        return null;
                    })
                    }
                </div>
                }
                {images && images?.products?.length > 0 && <div className="images-grid-container" style={{ display: isLoading ? 'flex' : 'none' }}>
                    {Array(17).fill()
                        .map((item, index) => (
                            <Skeleton key={index} height={171} width={171} />
                        ))
                    }
                </div>
                }
                {images && images?.products?.length > 0 ? <>
                    <div className="images-grid-container" style={{ visibility: isLoading ? 'hidden' : 'visible', height: isLoading ? '0px' : 'auto' }}>
                        {images && images?.products?.map((image, i) => (
                            isVideoFile(image) ?
                                <video src={getMp4Url(image?.url)} autoPlay loop muted playsInline
                                    onClick={() => {
                                        setModalName(image?.name)
                                        setSelectedProduct({
                                            metadata: {
                                                images: [image]
                                            }
                                        })
                                        setOpenMethodologyModal(true)
                                    }}

                                />
                                : <div key={image.product_id + i}>
                                {isGif(image) ?
                                    <img src={image?.url} alt=""
                                    onClick={() => {
                                        setModalName(image?.name)
                                        setSelectedProduct({
                                            metadata: {
                                                images: [image]
                                            }
                                        })
                                        setOpenMethodologyModal(true)
                                    }} />
                                    :
                                    <img
                                        src={`${process.env.REACT_APP_GALLERY_URL}500:550:${image?.url?.split('.com/')[1]}`}
                                        alt=""
                                        onClick={() => {
                                            setModalName(image?.name)
                                            setSelectedProduct({
                                                metadata: {
                                                    images: [image]
                                                }
                                            })
                                            setOpenMethodologyModal(true)
                                        }}
                                    />
                                    }
                                </div>
                        ))}


                        {/* <ResponsiveMasonry
                            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4}}
                        >
                            <Masonry gutter="32px"
                                columnsCount={3} >

                            </Masonry>
                        </ResponsiveMasonry> */}
                    </div>
                    {images?.totalcount / images.limit > 1 && <Pagination total={images?.totalcount / images.limit} setCurrentPage={setCurrentPage} currentPage={currentPage} />}
                </>
                    : <div className='no-orders'>
                        <span className='icon'>
                            <i class="far fa-image"></i>
                        </span>
                        <p>No Images Yet.</p>
                    </div>
                }
            </Content>
            <Footer />
            <div className="print-map" style={{ display: 'none' }}>
                <Coa reference={coARef} selectedProduct={previewProduct} productCategory={previewProduct?.mid_code?.search("PA") >= 0 ? 'PA' : previewProduct?.mid_code?.search("SA") >= 0 ? 'SA' : 'DC'} />
            </div>
            {openMethodologyModal && <MethodologyModal setOpenMethodologyModal={setOpenMethodologyModal} selectedProduct={selectedProduct} modalName={modalName} setModalName={setModalName} galleryView={true} setPreviewProduct={setPreviewProduct} setOpenItemPreviewModal={setOpenItemPreviewModal} />}
            {openItemPreviewModal && <ItemPreviewDrawer previewProduct={previewProduct} setPreviewProduct={setPreviewProduct} coARef={coARef} setOpenItemPreviewModal={setOpenItemPreviewModal} setModalName={setModalName} setSelectedProduct={setSelectedProduct} />}
        </Layout>
    )
}

export default Index