import React, { useState, useContext, useEffect } from 'react'
import Layout from "../layout"
import { Wrapper } from './style'
import Input from "../../../components/Input"
import { AuthContext } from '../../../context/auth'
import { updateCustomerApi, currentUserApi } from '../../../api'

const Index = () => {
  const { width, setIsLoading, user, setUser, setNotification } = useContext(AuthContext)
  const [taxExempt, setTaxExempt] = useState(null)
  const [taxExemptNumber, setTaxExemptNumber] = useState("")
  const [isChanged, setIsChanged] = useState(false)


  useEffect(() => {
    if (user?.customer) {
      const { is_tax_exempt, tax_number } = user.customer;
      const fieldsChanged =
        is_tax_exempt !== taxExempt ||
        tax_number !== taxExemptNumber
  
      setIsChanged(fieldsChanged);
    }
  
  },[user, taxExempt, taxExemptNumber])



  const fetchCurrentUser = () => {
    currentUserApi()
    .then(res => {
        if (res.data) {
           setUser(res.data)
            setIsLoading(false)
        }
    })
    .catch(() => {
        setIsLoading(false)
    })
  }



  const reset = () => {
    setIsChanged(false)
    setTaxExempt(user?.customer?.is_tax_exempt)
    setTaxExemptNumber(user?.customer?.tax_number)
  }


  useEffect(() => {
    if(!taxExempt){
      setTaxExemptNumber("")
    }
  }, [taxExempt])


  useEffect(() => {
    if (user) {
      reset()
    }
  }, [user])



  const onSave = () => {
    setIsLoading(true)
    const data = {
      is_tax_exempt: taxExempt,
      tax_number: taxExemptNumber
    }
    updateCustomerApi(data).then(() => {
      fetchCurrentUser()
      setIsLoading(false)
      setNotification({
        type: 'success',
        message: 'Changes saved successfully!'
      })
    }).catch(() => {
      setIsLoading(false)
      setNotification({
        type: 'red',
        message: 'Something went wrong, please try again!'
      })
    })
  }


  return (
    <Layout>
      <Wrapper>
        <div className="header">
          <h4>Tax Exempt</h4>
          {isChanged && <div className="header-btn-container">
            <button className='btn transparent' onClick={() => {
              reset()
            }}>Cancel</button>
            <button className='btn' disabled={taxExempt && !taxExemptNumber} onClick={onSave}>Save Changes</button>
          </div>
}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <h4>Is Your Organization Tax Exempt?</h4>
          <div className="input-wrapper">
            <div className="input-container">
              <div className="select-option" style={{ border: taxExempt === false ? '1px solid var(--primary-color)' : '1px solid #9a9a9a', backgroundColor: taxExempt === false ? '#f8f7fe' : '#fcfcfc' }}>
                <label class="containers" >
                  <input type="radio" checked={taxExempt === false} name="tax" value="false" onChange={(e) => setTaxExempt(false)} />
                  <span class="checkmark"></span>
                  <span>No</span>
                </label>
              </div>
            </div>
            <div className="input-container">
              <div className="select-option" style={{ border: taxExempt ? '1px solid var(--primary-color)' : '1px solid #9a9a9a', backgroundColor: taxExempt ? '#f8f7fe' : '#fcfcfc' }}>
                <label class="containers" >
                  <input type="radio" checked={taxExempt} name="tax" value="true" onChange={(e) => setTaxExempt(true)} />
                  <span class="checkmark"></span>
                  <span>Yes</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        {taxExempt && <div className="input-wrapper">
          <div className="input-container">
            <label htmlFor="Country">Tax Exempt Number</label>
            <Input type='text' placeholder='Tax Exempt Number' value={taxExemptNumber} onChange={(e) => setTaxExemptNumber(e.target.value)} />
          </div>
        </div>
        }
       {width < 1100 && isChanged && <div className="button-container">
          <button className='btn' onClick={onSave}>Save Changes</button>
          <button className='btn transparent' onClick={reset}>Cancel</button>
        </div>
}
      </Wrapper>
    </Layout>
  )
}

export default Index