import React, { useContext, useEffect, useState } from 'react'
import { DrawerWrapper } from './style'
import { toAbsoluteUrl } from '../../../utils/assetHelper'
import { useNavigate } from 'react-router-dom';
import { Tooltip as ReactTooltip } from "react-tooltip";
import ReactPrint from "react-to-print";
import { AuthContext } from '../../../context/auth';
import { createCartApi, addItemsToCartApi } from '../../../api'
import { getLocalStorage, setLocalStorage } from "../../../helpers/localStorage";
import {isVideoFile, getMp4Url} from '../../../utils/videoHelper'

const Index = ({previewProduct, setPreviewProduct, setSelectedProduct, coARef, setOpenItemPreviewModal, setModalName }) => {
    const {setIsLoading,setCartData, isLoading, setTempDisable, tempDisable, setNotification} = useContext(AuthContext)
    const cartId = getLocalStorage('cartId')
    const navigate = useNavigate()
    const [productTab, setProductTab] = useState("primary")
    const [images, setImages] = useState([])
    const [imageSlideIndex, setImageSlideIndex] = useState(0)

    useEffect(() => {
        if (previewProduct?.mid_code.search("PA") >= 0) {
            setProductTab("primary")
        } else if (previewProduct?.mid_code.search("DC") >= 0) {
            setProductTab("alexaflour")
        } else {
            setProductTab("")
        }

        if (previewProduct?.metadata?.images) {
            const arr = previewProduct?.metadata?.images?.filter((item) => item.url !== '')
            const splitArr = Array.from({ length: Math.ceil(arr.length / 4) }, (_, index) =>
                arr.slice(index * 4, index * 4 + 4)
            );
            setImages(splitArr)
        }

    }, [previewProduct])

    const AddToCart = () => {
        setIsLoading(true)
        if (!cartId) {
            createCartApi().then(res => {
                setLocalStorage('cartId', res.data.cart.id)
                addItemsToCartApi(res.data.cart.id, {
                    variant_id: previewProduct?.variants[0]?.id,
                    quantity: 1,
                }).then((res) => {
                    if (res && res.data && res.data?.cart) {
                        setCartData(res.data.cart)
                        setNotification({
                            message: 'Added to cart',
                            type: 'green',
                        })
                        setIsLoading(false)
                    } else {
                        setNotification({
                            message: 'Something went wrong',
                            type: 'red',
                        })
                        setIsLoading(false)
                    }
                })
            })
        } else {
            addItemsToCartApi(cartId, {
                variant_id: previewProduct?.variants[0]?.id,
                quantity: 1,
            }).then((res) => {
                if (res && res.data && res.data?.cart) {
                    setCartData(res.data.cart)
                    setNotification({
                        message: 'Added to cart',
                        type: 'green',
                    })
                    setIsLoading(false)
                } else {
                    setNotification({
                        message: 'Something went wrong',
                        type: 'red',
                    })
                    setIsLoading(false)
                }
            })
        }
    }

    const BuyProduct = () => {
        setIsLoading(true)
        if (!cartId) {
            createCartApi().then(res => {
                setLocalStorage('cartId', res.data.cart.id)
                addItemsToCartApi(res.data.cart.id, {
                    variant_id: previewProduct?.variants[0]?.id,
                    quantity: 1,
                }).then((res) => {
                    if (res && res.data && res.data?.cart) {
                        setCartData(res.data.cart)
                        setNotification({
                            message: 'Added to cart',
                            type: 'green',
                        })
                        setIsLoading(false)
                        navigate('/checkout')
                    } else {
                        setNotification({
                            message: 'Something went wrong',
                            type: 'red',
                        })
                        setIsLoading(false)
                    }
                })
            })
        } else {
            addItemsToCartApi(cartId, {
                variant_id: previewProduct?.variants[0]?.id,
                quantity: 1,
            }).then((res) => {
                if (res && res.data && res.data?.cart) {
                    setCartData(res.data.cart)
                    setNotification({
                        message: 'Added to cart',
                        type: 'green',
                    })
                    setIsLoading(false)
                    navigate('/checkout')
                } else {
                    setNotification({
                        message: 'Something went wrong',
                        type: 'red',
                    })
                    setIsLoading(false)
                }
            })
        }
    }


    return (
        <>
            <DrawerWrapper>
                <div className="drawer-modal-content">
                    {productTab && <div className="header">
                        <h2>Anti-Human {previewProduct?.subtitle}</h2>
                        <div className="icons-container">
                            <i className='fal fa-times' onClick={() => {
                                setOpenItemPreviewModal(false)
                                setPreviewProduct(null)
                            }}></i>
                        </div>
                    </div>
                    }
                    {!productTab && <div className="header">
                        <h2>{previewProduct?.host} {previewProduct?.species_reactivity}</h2>
                        <div className="icons-container">
                            <i className='fas fa-times' onClick={() => setOpenItemPreviewModal(false)}></i>
                        </div>
                    </div>
                    }
                    {productTab && <div className="tab-container">
                        <button style={{ borderTopLeftRadius: '12px', borderBottomLeftRadius: '12px', width:'35%' }} className={productTab === "primary" && 'tab-active'} disabled >Primary Antibodies</button>
                        <button style={{ borderTopRightRadius: '12px', borderBottomRightRadius: '12px', width:'65%' }} className={productTab === "alexaflour" && 'tab-active'} disabled>Identifyn™ SRM AlexaFluor™ Conjugates</button>
                    </div>
                    }
                    {productTab === 'alexaflour' && <button className='dye-btn' disabled >Dye: {previewProduct?.dyes}</button>}
                    {productTab && <div className="items-container">
                        <button style={{ borderTopLeftRadius: '12px', borderBottomLeftRadius: '12px' }} className={previewProduct?.host + " " + previewProduct?.clonality === "Mouse Monoclonal" && 'tab-active'} disabled >Mouse Monoclonal</button>
                        <button style={{ borderTopRightRadius: '12px', borderBottomRightRadius: '12px' }} className={previewProduct?.host + " " + previewProduct?.clonality === "Mouse Recombinant Monoclonal" && 'tab-active'} disabled>Mouse Recombinant Monoclonal</button>
                        <button style={{ borderTopRightRadius: '12px', borderBottomRightRadius: '12px' }} className={previewProduct?.host + " " + previewProduct?.clonality === "Rabbit Polyclonal" && 'tab-active'} disabled>Rabbit Polyclonal</button>
                        <button style={{ borderTopRightRadius: '12px', borderBottomRightRadius: '12px' }} className={previewProduct?.host + " " + previewProduct?.clonality === "Rabbit Recombinant Monoclonal" && 'tab-active'} disabled>Rabbit Recombinant Monoclonal</button>
                        <button style={{ borderTopRightRadius: '12px', borderBottomRightRadius: '12px' }} className={previewProduct?.host + " " + previewProduct?.clonality === "Rat Monoclonal" && 'tab-active'} disabled>Rat Monoclonal</button>
                    </div>
                    }
                    <div className="item-details-container">
                        <div className="item-detail-header">
                            <div className="left-container" style={{width:'85%'}}>
                                <h4 style={{ color: 'var(--primary-color)' }}>{previewProduct?.title}{productTab && `, ${previewProduct?.metadata?.subclass}`}</h4>
                                <h5>Cat ID# - {previewProduct?.mid_code} {previewProduct?.material}</h5>
                            </div>
                            <ReactPrint
                                onBeforeGetContent={() => {
                                    setIsLoading(true)
                                }}
                                onAfterPrint={() => {
                                    setIsLoading(false)
                                }}
                                trigger={() => <span className='coa' style={{cursor:'pointer', alignSelf:'flex-start'}}>
                                    CoA</span>}
                                content={() => coARef.current}
                            />
                            {/* <span>CoA</span> */}
                        </div>
                        <div className="item-content">
                            <div className="left-container">
                                {/* {!productTab && <h4>Excitation Wavelength</h4>} */}
                                {/* {!productTab && <h4>Emission Wavelength</h4>} */}
                                <h4>Concentration</h4>
                                <h4>Buffer</h4>
                                <h4>pH</h4>
                                <h4>Storage</h4>
                                {productTab !== 'primary' && <h4>Dye Ex/Em</h4>}
                                {productTab !== 'primary' && <h4>Extinction Coefficient</h4>}
                                {productTab !== 'primary' && <h4>Molecular Weight</h4>}
                                {productTab !== 'primary' && <h4>Emission Color</h4>}
                                {/* <h4>Applications</h4> */}
                                {/* <h4>Price</h4> */}
                            </div>
                            <div className="right-container">
                                {/* {!productTab && <h4>{previewProduct?.excitation_wavelength}</h4>} */}
                                {/* {!productTab && <h4>{previewProduct?.emission_wavelength}</h4>} */}
                                <h4>{previewProduct?.concentration ? `${previewProduct?.concentration} mg/mL` : '-'} </h4>
                                <h4>{previewProduct?.buffer_molarity ? `${previewProduct?.buffer_molarity}mM` : '-'} {previewProduct?.buffer ? previewProduct?.buffer : '-'}</h4>
                                <h4>{previewProduct?.ph ? previewProduct.ph : '-'}</h4>
                                <h4>{previewProduct?.storage.map((item) => item + "°C").join(", ")}</h4>
                                {productTab !== 'primary' && <h4>{previewProduct?.excitation_wavelength ? `${previewProduct?.excitation_wavelength} nm` : '-'} / {previewProduct?.emission_wavelength ? `${previewProduct?.emission_wavelength} nm` : '-'}</h4>}
                                {productTab !== 'primary' && <h4>{previewProduct?.extinction_coefficient?.includes(',') ? previewProduct?.extinction_coefficient : Number(previewProduct?.extinction_coefficient)?.toLocaleString('en-US')}</h4>}
                                {productTab !== 'primary' && <h4>{previewProduct?.molecular_weight ? `${previewProduct?.molecular_weight} Da` : '-'}</h4>}
                                {productTab !== 'primary' && <h4>{previewProduct?.emission_color ? previewProduct?.emission_color : '-'}</h4>}
                            </div>
                        </div>                       
                        <div className="item-content">
                            <div className="left-container" style={{width:'50%'}}>
                                <h4>Applications</h4>
                            </div>
                            <div className="right-container">
                            <div className="application-container">
                                    {previewProduct?.metadata?.protein_application?.map((item) => {
                                        return <span style={{cursor:'default'}}
                                        onClick={() => {
                                            setSelectedProduct({
                                            metadata:{
                                                images: previewProduct?.metadata?.images.map((image) => {
                                                    return {
                                                       ...image,
                                                       product_name:previewProduct?.title,
                                                       product_id:previewProduct?.id
                                                    }
                                                })
                                            }
                                            })
                                            setModalName(item.name)
                                            setOpenItemPreviewModal(false)
                                        }}
                                        data-tooltip-id="applications" data-tooltip-content={item.name}>{item.abbr}</span>
                                    })
                                    }
                                    {previewProduct?.metadata?.microscopy_application?.map((item) => {
                                        return <span style={{cursor:'default'}}
                                        onClick={() => {
                                            setSelectedProduct({
                                            metadata:{
                                                images: previewProduct?.metadata?.images.map((image) => {
                                                    return {
                                                       ...image,
                                                       product_name:previewProduct?.title,
                                                       product_id:previewProduct?.id
                                                    }
                                                })
                                            }
                                            })
                                            setModalName(item.name)
                                            setOpenItemPreviewModal(false)
                                        }}
                                        data-tooltip-id="applications" data-tooltip-content={item.name}>{item.abbr}</span>
                                    })
                                    }
                                    </div>
                            </div>
                        </div>
                        <div className="item-content">
                            <div className="left-container">
                                <h4>Price / 50 µL Unit Size</h4>
                            </div>
                            <div className="right-container">
                                <h4>${(previewProduct?.variants[0]?.prices[0]?.amount / 100)?.toFixed(2)}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="item-image-container">
                        {images && images.length > 0 && <div className="images-container">
                            {images[imageSlideIndex]?.map((item, index) => {
                                return <div className="image" onClick={() => {
                                    setSelectedProduct({
                                        metadata:{
                                            images: previewProduct?.metadata?.images.map((image) => {
                                                return {
                                                   ...image,
                                                   product_name:previewProduct?.title,
                                                   product_id:previewProduct?.id
                                                }
                                            })
                                        }
                                        })
                                    setModalName(item.name)
                                    setOpenItemPreviewModal(false)
                                }} key={index}>
                                    {isVideoFile(item) ? 
                                    <video src={getMp4Url(item.url)} muted loop autoPlay playsInline /> :
                                    <img src={item.url || toAbsoluteUrl('/media/methodologyplaceholder.png')} alt="" />
                                    }
                                    <h6>{item.name}</h6>
                                </div>
                            })}
                        </div>
                            // :
                            // <div className='no-orders'>
                            //     <span className='icon'>
                            //         <i class="far fa-images"></i>
                            //     </span>
                            //     <p>No Images Available.</p>
                            // </div>
                        }
                        {images && images.length > 1 && <div className="buttons-container">
                            <button onClick={() => {
                                if (imageSlideIndex > 0) {
                                    setImageSlideIndex(imageSlideIndex - 1)
                                } else {
                                    setImageSlideIndex(0)
                                }
                            }}><i className='fas fa-chevron-left'></i></button>
                            <div className="dots">
                                {
                                    images.map((item, index) => {
                                        return <span className={imageSlideIndex === index ? "active-dot" : ""} key={index}></span>
                                    })
                                }
                                {/* <span className='active-dot'></span>
                                <span></span>
                                <span></span> */}
                            </div>
                            <button
                                onClick={() => {
                                    if (imageSlideIndex < images.length - 1) {
                                        setImageSlideIndex(imageSlideIndex + 1)
                                    } else {
                                        setImageSlideIndex(images.length - 1)
                                    }
                                }}
                            ><i className='fas fa-chevron-right'></i></button>
                        </div>
                        }
                    </div>
                    <div className="add-cart-container">
                    <p style={{ padding: '12px 10px', background: '#FFF2E5', borderRadius: '12px', color: '#DC4405', fontWeight: 700, textAlign: 'center', fontSize:'16px', lineHeight:'24px' }}>Identifyn™ Super Resolution Vetted
                        Premium Research Products are Available through
                        our Preferred Industry Partners</p>
                    {/* <button className='btn add-btn'
                        disabled={isLoading}
                        onClick={() => {
                            tempDisable ? setTempDisable(false) :
                            AddToCart()
                            // setSelectedProtein(false)
                            // navigate('/cart')
                        }}
                    >
                        <i className='fal fa-shopping-cart'></i>
                        Add to Cart</button>
                    <button className='btn buy-btn'
                        disabled={isLoading}
                        onClick={() => {
                           tempDisable ? setTempDisable(false) : BuyProduct()
                        }}
                    >Buy Now</button> */}
                </div>
                </div>
                <ReactTooltip
                    id="applications"
                    place="top"
                    variant="light"
                />
            </DrawerWrapper>
        </>
    )
}

export default Index