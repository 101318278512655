import React, { useContext, useEffect } from 'react'
import Layout from "../../../components/layout"
import { LayoutWrapper } from './style'
import { toAbsoluteUrl } from '../../../utils/assetHelper'
import { NavLink } from 'react-router-dom'
import { AuthContext } from '../../../context/auth'
import { currentUserApi, getCustomerGroupApi } from '../../../api'

const Index = ({ children }) => {
    const { setLogOutModal, width, user, setSignUpModal, login, completeModal, setIsLoading, setSignInModal } = useContext(AuthContext)

    useEffect(() => {
        setIsLoading(true)
        currentUserApi()
            .then(res => {
                if (res.data) {
                    let data = res.data
                    getCustomerGroupApi(data?.customer?.id).then((customerGroup) => {
                        data.customer.groups = customerGroup.data?.customerGroups[0]?.groups[0]?.name
                    })
                    login(data)
                    setIsLoading(false)
                }else{
                    setIsLoading(false)
                    setSignInModal(true)
                }
            })
    }, [completeModal])



    return (
        <Layout>
            <LayoutWrapper>
                <div className={`container ${width < 1100 && 'layout-container'}`} style={{ width: '100%', display: 'flex', alignItems: 'start' }}>
                    {width < 1100 && <div className="topbar">
                        <div className="links-wrapper">
                            <NavLink to="/profile/orders" className={'link'}><i class="fal fa-clipboard-check"></i></NavLink>
                            <NavLink to="/profile/account" className={'link'} ><i class="fal fa-id-badge"></i></NavLink>
                            <NavLink to="/profile/shipping-address" className={'link'}><i class="fal fa-shipping-fast"></i></NavLink>
                            <NavLink to="/profile/billing-address" className={'link'}><i class="fal fa-address-card"></i></NavLink>
                            <NavLink to="/profile/tax-exempt" className={'link'} ><i class="fal fa-usd-circle"></i></NavLink>
                            <button className='signout' style={{ color: '#DC1E00', fontWeight: '400', fontSize: '14px', justifyContent: 'start' }}
                                onClick={() => {
                                    setLogOutModal(true)
                                }}
                            >
                                <i class="fal fa-sign-out" style={{ fontSize: '22px' }}></i>
                            </button>
                        </div>
                    </div>
                    }
                    {width > 1100 && <div className="sidebar">
                        <div className="profile-wrapper">
                            {user?.customer?.avatar ?
                                <img src={user?.customer?.avatar} alt="product" className='profile-img' />
                                : <div className="custom-profile">{user?.customer?.first_name[0]?.toUpperCase()}</div>}
                            <div className="profile-details">
                                <h5>{user?.customer?.first_name} {user?.customer?.last_name}</h5>
                                <h6>{user?.customer?.email}</h6>
                                <p>ID: {user?.customer?.id.slice(25, 30)}</p>
                            </div>
                            <hr />
                        </div>
                        <div className="links-wrapper">
                            <NavLink to="/profile/orders" className={'link'}><i class="fal fa-clipboard-check"></i>Order History</NavLink>
                            <NavLink to="/profile/account" className={'link'} ><i class="fal fa-id-badge"></i>Profile Information</NavLink>
                            <NavLink to="/profile/shipping-address" className={'link'}><i class="fal fa-shipping-fast"></i>Shipping Address</NavLink>
                            <NavLink to="/profile/billing-address" className={'link'}><i class="fal fa-address-card"></i>Billing Address</NavLink>
                            <NavLink to="/profile/tax-exempt" className={'link'} ><i class="fal fa-usd-circle"></i>Tax Exempt</NavLink>
                        </div>
                        <button className='signout' style={{ color: '#DC1E00', fontWeight: '400', fontSize: '14px', justifyContent: 'start' }}
                            onClick={() => {
                                setLogOutModal(true)
                            }}
                        >
                            <i class="fal fa-sign-out" style={{ fontSize: '22px' }}></i>Sign Out
                        </button>
                    </div>
                    }
                    <main style={{ height: '100%', width: '100%', overflowY: 'hidden' }}>
                        {(!user?.customer?.shipping_addresses?.length || !user?.customer?.billing_address || user?.customer?.is_tax_exempt === null || !user?.customer?.metadata?.is_email_verified) &&
                            <div className='finish-registration'>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <div
                                        style={{
                                            marginRight: '12px',
                                            width: '40px',
                                            height: '40px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            background: '#DC1E00',
                                            borderRadius: '12px',
                                            color: '#fff',
                                        }}
                                    >
                                        <i className='fal fa-info-circle' style={{ fontSize: '20px' }}></i>
                                    </div>
                                    <h4>Finish your account registration to be able to make orders.</h4>
                                </div>
                                <button className='btn'
                                    onClick={() => {
                                        setSignUpModal(true)
                                    }}
                                >Finish Registration</button>
                            </div>
                        }
                        {children}
                    </main>
                </div>
            </LayoutWrapper>
        </Layout>
    )
}

export default Index