import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap:20px;
  height: 100vh;
  overflow: hidden;


  @media (max-width: 1200px) {
  flex-direction: column;
  gap: 20px;
}



.selection-container{
    width: 67%;
    height: 100%;
    padding: 110px 3rem 0px 6rem;
    overflow-y: auto;

    @media (max-width: 1200px) {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-bottom: 30px;
  width: 100%;
}

@media screen and (min-width: 2160px) {
    padding-left:  25rem !important;
    padding-right: 20rem !important;
  }

h3{
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 28px;
color:var(--primary-color);
}

.selections-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* flex-direction: column; */
    gap: 32px;
    margin-top: 30px;
    flex-wrap: wrap;
    clear: both;

    @media screen and (max-width: 768px) {
        display:flex;
        flex-direction: column;
    }

}


.select-container{
    display: flex;
    flex-direction: column;
    gap:10px;

    h4{
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 24px;
color: #1E1E1E;
    }

.input-wrapper{
    display: flex;
    gap:12px;
    
    .btn{
        width: 100%;
        height: 48px;
        border: 1px solid #F1EEFC;
        background: #FCFCFC;
        color: #1E1E1E;
    }

    .active-btn{
            border: 1px solid #DC4405;
            background: #F8F7FE;
            color: var(--primary-color);
        }
}

.graphs-wrapper{
    /* margin-top: 60px; */
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    position: relative;

    svg{
        width: 100%;
        height:auto;
    }

.product-tip{
width: 256px;
height: 184px;
background-color: rgba(255, 255, 255, 1);
box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
border-radius: 15px;
padding: 12px 16px;
display: flex;
gap: 10px;
flex-direction: column;
position: absolute;
z-index: 0;
top: 0%;

@media screen and (max-width:700px){
    position: relative;
    order: 1;
    width: 100%;
    margin-bottom: 10px;
}


.dye-information{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}


h5{
font-size: 14px;
font-weight: 400;
color:#6C6A73;

span{
color:#1E1E1E;
text-align: right;
font-weight: 600;
}
}

}

.left{
left: 5%;
}

.right{
right: 0;
}

    @media screen and (max-width:1100px) {
       svg{
        width: 100%;
        height: auto;
       }
    }

    .hide-line{
        opacity: 0;
        transition: 0.5s all ease-in-out;
    }
    .active-line{
        opacity: 1;
        transition: 0.5s all ease-in-out;
    }

    .button-container{
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        gap: 8px;
        width: 100%;

        @media screen and (max-width:1100px) {
            width: 100%;
            grid-template-columns: 1fr;
            button{
                width: 100% !important;
            }
    }

        button{
            width: 100%;
            height: 40px;
            
        }

        .blue{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.78) 0%, rgba(255, 255, 255, 0.78) 100%), #09F;
            color: #09F;
        }
        .active-blue{
            background: #0099FF;
        }

        .green{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #33C25E;
            color: #33C25E;
        }

        .active-green{
            background: #33C25E;
        }

        .yellow{    
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.88) 0%, rgba(255, 255, 255, 0.88) 100%), #FFC224;
        color: #FFC224;
        }

        .active-yellow{
            background: #FFC224;
        }

        .orange{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.82) 100%), #FF8D24;
        color: #FF8D24;
        }

        .active-orange{
            background: #FF8D24;
        }

        .coral{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #FC5555;
        color: #FC5555;
        }

        .active-coral{
            background: #FC5555;
        }

        .pink{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.78) 0%, rgba(255, 255, 255, 0.78) 100%), #D94949;
        color: #D94949;
        }

        .active-pink{
            background: #D94949;
        }

        .red{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.76) 0%, rgba(255, 255, 255, 0.76) 100%), #B23C3C;
            color: #B23C3C;
        }

        .active-red{
            background: #B23C3C;
        }

        .dark-red{
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.72) 0%, rgba(255, 255, 255, 0.72) 100%), #993434;
            color: #993434;
        }

        .active-dark-red{
            background: #993434;
        }

        .active{
            color:#fff;
            width: 200px;
        }

    }
}


}


.list-container{
width: 100%;
height: max-content;
/* overflow-y: auto; */
padding: 10px 24px 24px 24px;
margin-top: 32px;
overflow-x: auto;
overflow-y: hidden;

@media screen and (max-width:500px) {
   padding: 10px;
}

.products-cards-container{
    margin-bottom: 60px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .product-card{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    border-radius: 12px;
    background: rgba(245, 245, 245, 0.80);
    padding: 12px;

.product-double-column{
  display: flex;
  justify-content: space-between;
  gap: 8px;

.label-name{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

}

h5{
font-size: 14px;
color: #6C6A73;
font-weight: 400;
}

h4{
font-size: 16px;
font-weight: 400;
}


}
}

.mainTable{
  border-collapse: collapse;
  width: 100%;
  vertical-align: middle;
  margin-bottom: 60px;
  margin-top: 20px;

  @media screen and (max-width: 900px) {
    width: max-content;
  }

 td, th {
  padding: 12px 14px;
}

tr:nth-child(odd){
border-radius: 8px;
background: rgba(245, 245, 245, 0.80);
}
 th {
  padding-top: 12px;
  padding-bottom: 20px;
  text-align: left;
  background-color: white;
  color: #6C6A73;
  font-size: 14px;
  font-weight: 400;

&:last-child{
    text-align: right;
}

}

td{
color: #000;
font-size: 14px;
font-weight: 400;
line-height: 20px;
    &:last-child{
        text-align: right;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    &:first-child{
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }
}
    
    }


    .applications{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    
    span{
    background: linear-gradient(0deg, rgba(255, 141, 36, 0.16) 0%, rgba(255, 141, 36, 0.16) 100%), #FFF;
    color:var(--primary-color);
    cursor: pointer;
    padding: 8px;
    border-radius: 8px;
    font-weight: 700;
    }
}


.coa{
display: flex;
height: 40px;
padding: 12px 10px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 12px;
background:#EDEDED;
color: #1E1E1E;
font-weight: 500;
font-size: 16px;
height: 40px !important;

&:hover{
  background-color: #E2E2E2;
}
}

.add-btn{
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    background: #FFF2E5;
    border-radius: 12px;
    height: 40px !important;

    &:hover{
  background-color: rgba(255, 141, 36, 0.28);
  
}
}

.buy-btn{
    width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    height: 40px !important;
}



}




}

.product-container{
width: 550px;
height: 100vh;
overflow-y: hidden;
background: #FFF;
box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.08);
display: flex;
/* padding: 110px 24px 60px 24px; */
flex-direction: column;
align-items: center;
justify-content: center;

@media screen and (max-width:1200px) {
    width: 100%;
}


.icon{
    width: 120px;
    height: 120px;
    font-size: 30px;
    margin-bottom: 20px;
}

p{
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 28px;
color:#6c6a73;
width: 60%;
margin: 0 auto;
text-align: center;
}

.product-wrapper{
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
    padding: 90px 24px 20px 24px; 

    @media screen and (max-width:1100px) {
        padding-top: 30px;
    }

    .item-details-container{
border-radius: 12px;
background: rgba(237, 237, 237, 0.40);
display: flex;
padding: 16px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 20px;
align-self: stretch;

h4{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

h5{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.item-detail-header{
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
}

span{
display: flex;
height: 40px;
padding: 12px 10px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 12px;
background:#DC4405;
color: #FCFCFC;
font-weight: 500;
font-size: 16px;

&:hover{
    background-color: #C03A00; 
}
}


.item-content{
    width: 100%;
    display: flex;
    justify-content: space-between;

    .left-container{
        display: flex;
        flex-direction: column;
        gap: 8px;
        h4{
            color: #6c6a73;
            font-weight: 400;
        }
    }

    .right-container{
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: flex-end;
        align-items: flex-end;
        text-align: right;

.application-container{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    
    span{
    background: linear-gradient(0deg, rgba(255, 141, 36, 0.16) 0%, rgba(255, 141, 36, 0.16) 100%), #FFF;
    color:var(--primary-color);
    cursor: pointer;
    }
}

    }
}
}

.item-image-container{
position: relative;
width: 100%;

.images-container{
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
gap: 16px;

@media screen and (max-width:1200px) {
    grid-template-columns: 1fr 1fr 1fr;

    .image{
        img{
        width: 100% !important;
        }
    }
}

@media screen and (max-width:500px) {
    grid-template-columns: 1fr 1fr;

    .image{
        img{
        width: 100% !important;
        }
    }
}



.image{
    display: flex;
    flex-direction: column;
    gap: 8px;
    cursor: pointer;
    img, video{
        width: 100%;
        height: 100px;
        object-fit: cover;
        border-radius: 10px;
    }

    h6{
        font-size: 12px;
        font-weight: 400;
        color:#6c6a73;
        text-align: center;
    }

}

}


.buttons-container{
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: 32px;
        height: 32px;
        border-radius: 8px;
    background:#FFF2E5;
    color: #DC4405;
    }

    .dots{
        display: flex;
        gap: 5px;
        align-items: center;

        span{
            width: 8px;
            height: 6px;
            border-radius: 12px;
            background-color: #9a9a9a;
        }

        .active-dot{
            background-color: var(--primary-color);
            width: 16px;
        }
    }


}
}

.add-cart-container{
    width: 100%;
    display: flex;
    gap:20px;
    margin-top: auto;

    @media screen and (max-width:1100px) {
        flex-direction: column;

       .btn{
        width: 100% !important;
        text-align: center;
        justify-content: center;
       }
    }

.add-btn{
    width: 35%;
    display: flex;
    gap: 10px;
    align-items: center;
    color: var(--primary-color);
    background: #FFF2E5;
    &:hover{
background-color: rgba(255, 141, 36, 0.28);  
}
}

.buy-btn{
    width: 65%;
}


}




}


}

.show-product{
    display: block;
@media screen and (max-width: 1200px) {
    display: block;
}

}

.hide-product{
    display: block;
    @media screen and (max-width: 1200px) {
    display: none;
}
}

`