import React, { useContext, useEffect, useState } from 'react'
import Layout from "../../components/layout"
import { Wrapper } from './style'
import { Checkbox } from '../../components/Checkbox'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/auth'
import { updateItemsToCartApi, deleteItemsFromCartApi, createCartApi, getCartApi, calculateTaxApi, getRegionsApi } from '../../api'
import { setLocalStorage, getLocalStorage } from '../../helpers/localStorage'

const Index = () => {
    const { isLoggedIn, setSignInModal, user, setSignUpModal, cartData, setNotification, setIsLoading, setCartData, setCompleteOrderModal, tempDisable, setTempDisable} = useContext(AuthContext)
    const cartId = getLocalStorage('cartId')
    const navigate = useNavigate()
    const [quantity, setQuantity] = useState(1)
    const [isAgree, setIsAgree] = useState(false)
    const [regions, setRegions] = useState([])

    useEffect(() => {
        if (cartData && cartData?.items?.length > 0) {
            setQuantity(cartData?.items?.reduce((acc, curr) => acc + curr?.quantity, 0))
        }
    }, [cartData])

    const fetchRegions = () => {
        getRegionsApi().then(res => {
            if (res && res.data && res.data.regions) {
                setRegions(res.data.regions)
            }
        })
    }

    const calculateTax = () => {
        calculateTaxApi(cartData?.id).then((res) => {
            if (res && res.data && res.data.cart) {
                setCartData(res.data.cart)
            }
        })
    }


    useEffect(() => {
        if (!cartId) {
            createCartApi().then(res => {
                setLocalStorage('cartId', res.data.cart.id)
            })
        }
        // else if (cartId && !user?.customer?.is_tax_exempt){
        //     calculateTax()
        // }
        else if (cartId) {
            getCartApi(cartId).then((res) => {
                if (res && res.data && res.data.cart) {
                    setCartData(res.data.cart)
                } else {
                    setCartData(null)
                }
            })
        }
    }, [cartId, user?.customer?.is_tax_exempt])


    const updateItem = (itemId, quantity) => {
        setIsLoading(true)
        updateItemsToCartApi(cartData?.id, itemId, {
            quantity: quantity
        }).then((res) => {
            if (res.data?.cart) {
                if (user?.customer?.is_tax_exempt) {
                    setCartData(res.data.cart)
                } else {
                    calculateTax()
                }
                setNotification({
                    type: 'success',
                    message: 'Item Quantity updated'
                })
                setIsLoading(false)
            } else {
                setNotification({
                    type: 'red',
                    message: 'Something went wrong, please try again!'
                })
                setIsLoading(false)
            }
        }).catch(() => {
            setNotification({
                type: 'red',
                message: 'Something went wrong, please try again!'
            })
            setIsLoading(false)
        })
    }



    const removeItem = (itemId) => {
        setIsLoading(true)
        deleteItemsFromCartApi(cartData?.id, itemId).then((res) => {
            if (res.data?.cart) {
                if (user?.customer?.is_tax_exempt) {
                    setCartData(res.data.cart)
                } else {
                    calculateTax()
                }
                // setCartData(res.data?.cart)
                setNotification({
                    type: 'success',
                    message: 'Item Removed from cart'
                })
                setIsLoading(false)
            } else {
                setNotification({
                    type: 'red',
                    message: 'Something went wrong, please try again!'
                })
                setIsLoading(false)
            }
        }).catch(() => {
            setNotification({
                type: 'red',
                message: 'Something went wrong, please try again!'
            })
            setIsLoading(false)
        })
    }

    return (
        <Layout>
            <Wrapper>
                <h3>Cart</h3>
                {cartData?.items && cartData?.items?.length > 0 ?
                    <div className="content">
                        <div className="left-container">
                            <div className="items-container">
                                {cartData?.items?.map((item) => {
                                    return <div className="item" key={item.id}>
                                        <div className="item-content">
                                            {item?.mid_code?.search('SA') < 0 ? <h4>{item.variant.product.title}, {item.variant.product.metadata.subclass}</h4>
                                                : <h4>{item.variant.product.title}</h4>
                                            }
                                            <div className="item-details">
                                                <div className="details">
                                                    <h6>Cat ID#</h6>
                                                    <h5>{item.variant.product.mid_code} {item.variant.product.material}</h5>
                                                </div>
                                                <div className="details">
                                                    <h6>Concentration</h6>
                                                    <h5>{item.variant.product.concentration} mg/ml</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="quantity-content">
                                            <span className='quantity-btn' style={{ width: '40px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#EDEDED', color: '#DC1E00', cursor: 'pointer', opacity:item.quantity > 1 ? 1 : 0.4, pointerEvents: item.quantity > 1 ? 'auto' : 'none' }}
                                                onClick={() => {
                                                    if (item.quantity > 1) {
                                                        updateItem(item.id, item.quantity - 1)
                                                    }
                                                     else {
                                                        // removeItem(item.id)
                                                    }
                                                }}
                                            >
                                                <i className="far fa-minus"></i>
                                            </span>
                                            <span  className='quantity'>{item.quantity}</span>
                                            <span  className='quantity-btn' style={{ width: '40px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#EDEDED', color: '#DC1E00', cursor: 'pointer' }}
                                                onClick={() => {
                                                    updateItem(item.id, item.quantity + 1)
                                                }}
                                            >
                                                <i className="far fa-plus"></i>
                                            </span>
                                        </div>
                                        <div className="price-content">
                                            <h4>${(item.subtotal / 100).toFixed(2)}</h4>
                                           {item.quantity > 1 && <h6>${(item.unit_price / 100).toFixed(2)} x {item.quantity}</h6> }
                                        </div>
                                        <span style={{ width: '40px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#EDEDED', color: '#DC1E00', cursor: 'pointer' }} className='delete-item quantity-btn'
                                            onClick={() => removeItem(item.id)}
                                        >
                                            <i className="far fa-trash-alt"></i>
                                        </span>
                                    </div>
                                })}
                                {/* <div className="item">
                                <div className="item-content">
                                    <h4>Mouse Anti-Human PAR, IgG1</h4>
                                    <div className="item-details">
                                        <div className="details">
                                            <h6>Cat ID#</h6>
                                            <h5>PA000001</h5>
                                        </div>
                                        <div className="details">
                                            <h6>Concentration</h6>
                                            <h5>10 mg/mL</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="quantity-content">
                                    <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#EDEDED', color: '#DC1E00', cursor:'pointer' }}>
                                        <i class="far fa-minus"></i>
                                    </span>
                                    <span className='quantity'>2</span>
                                    <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#EDEDED', color: '#DC1E00', cursor:'pointer' }}>
                                        <i class="far fa-plus"></i>
                                    </span>
                                </div>
                                <div className="price-content">
                                    <h4>$198.00</h4>
                                    <h6>$99.00 x 2</h6>
                                </div>
                                <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#EDEDED', color: '#DC1E00', cursor:'pointer' }} className='delete-item'>
                                    <i class="far fa-trash-alt"></i>
                                </span>
                            </div>    
                            <div className="item">
                                <div className="item-content">
                                    <h4>Mouse Anti-Human PAR, IgG1</h4>
                                    <div className="item-details">
                                        <div className="details">
                                            <h6>Cat ID#</h6>
                                            <h5>PA000001</h5>
                                        </div>
                                        <div className="details">
                                            <h6>Concentration</h6>
                                            <h5>10 mg/mL</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="quantity-content">
                                    <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#EDEDED', color: '#DC1E00', cursor:'pointer' }}>
                                        <i class="far fa-minus"></i>
                                    </span>
                                    <span className='quantity'>2</span>
                                    <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#EDEDED', color: '#DC1E00', cursor:'pointer' }}>
                                        <i class="far fa-plus"></i>
                                    </span>
                                </div>
                                <div className="price-content">
                                    <h4>$198.00</h4>
                                    <h6>$99.00 x 2</h6>
                                </div>
                                <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#EDEDED', color: '#DC1E00', cursor:'pointer' }} className='delete-item'>
                                    <i class="far fa-trash-alt"></i>
                                </span>
                            </div>                         */}
                            </div>
                        </div>
                        <div className="right-container">
                            <h4>Summary</h4>
                            <div className="price-summary">
                                <div className="price">
                                    <p>{quantity} Items</p>
                                    <p>${(cartData?.subtotal / 100)?.toFixed(2)}</p>
                                </div>
                                <div className="price">
                                    <p>Tax</p>
                                    <p>${(cartData?.tax_total / 100)?.toFixed(2)}</p>
                                </div>
                                <hr />
                                <div className="price">
                                    <p>Grand Total</p>
                                    <p style={{ fontWeight: 700 }}>${(cartData?.total / 100)?.toFixed(2)}</p>
                                </div>
                            </div>
                            <Checkbox label='I have read and agreed to the' higlighted='Terms and Conditions of Sale'
                                checked={isAgree}
                                onChange={() => setIsAgree(!isAgree)}
                                higlightedLink={() => navigate("/terms-and-conditions-of-sale")}
                            />
                            <button className='btn'
                                disabled={!isAgree}
                                onClick={() => isLoggedIn ? (!user?.customer?.shipping_addresses?.length || !user?.customer?.billing_address || user?.customer?.is_tax_exempt === null || !user?.customer?.metadata?.is_email_verified) ? setSignUpModal(true) : tempDisable ? setTempDisable(false) : navigate("/checkout") : setSignInModal(true)}
                            >Proceed To Checkout</button>
                        </div>
                    </div>
                    :
                    <div className='no-orders'>
                        <span className='icon'>
                            <i className="fal fa-boxes"></i>
                        </span>
                        <p>No items added to the cart yet.</p>
                    </div>
                }
            </Wrapper>
        </Layout>
    )
}

export default Index